import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { Button } from './button'
import { Skeleton } from './skeleton'
import styles from './sections.module.scss'
import { ControllerPlayer, DataPlayer, SettingsPlayer } from '../../types'
import { LockPremium } from '../../components/lock-premium'
import { OperationState } from '../../components/operation-state'
import { trigger } from '../../lib/events'
import { UseEffects } from '../../hooks/use-effects'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { Header } from './header'

interface SectionsProps {
  data?: DataPlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  effects?: UseEffects
}

export type SectionsLabel =
  | 'Intro'
  | 'Verse'
  | 'Chorus'
  | 'Instrumental'
  | 'Instrumental Solo'
  | 'Bridge'
  | 'Pre Chorus'
  | 'Outro'

type SectionsLocalizations = {
  [key in SectionsLabel]: string
}

export const sectionsLocalizations: SectionsLocalizations = {
  Intro: t`intro_row`,
  Verse: t`verse_row`,
  Chorus: t`chorus_row`,
  Instrumental: t`task.label.instrumental`,
  'Instrumental Solo': t`instrumental_solo_row`,
  Bridge: t`bridge_row`,
  'Pre Chorus': t`prechorus_row`,
  Outro: t`outro_row`
}

export const Sections: React.FC<SectionsProps> = ({
  data,
  settings,
  controller,
  effects
}) => {
  const { i18n } = useLingui()
  const loadingPlayer = useAudioEngine((p) => !p.state.isReadyToPlay)

  const onSetCycle = useCallback(
    (fromSeconds: number, toSeconds: number) => {
      trigger('timeline:set-cycle', { fromSeconds, toSeconds })

      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'sections'
      })
    },
    [controller]
  )

  const onLockedClick = useCallback(() => {
    controller?.onLockedFeatureClick?.('sections-lock')
  }, [controller])

  const { empty, loading, update, error, processing, availableParts } =
    useMemo(() => {
      return {
        empty: data?.sections?.empty,
        error: data?.sections?.status === 'failed',
        update: data?.sections?.status === 'update',
        loading: data?.sections?.status === 'loading',
        processing:
          data?.sections?.empty || data?.sections?.status === 'processing',
        availableParts: settings?.sections?.limited
          ? data?.sections?.data?.filter(({ start }: any) => start <= 60)
          : data?.sections?.data
      }
    }, [data?.sections, settings?.sections])

  useEffectOnce(() => {
    if (data?.sections?.empty && controller?.sections?.onAddSections) {
      controller.sections.onAddSections()
    }
  })

  function renderContent(): JSX.Element {
    if (loading || loadingPlayer) {
      return <Skeleton />
    }

    if (error) {
      return (
        <OperationState
          icon="no-sections"
          title={i18n._(t`no_song_parts_available`)}
          className={styles.state}
        />
      )
    }

    if (empty || processing) {
      return (
        <OperationState
          icon="clock"
          title={i18n._(t`song_parts_detecting`)}
          className={styles.state}
        />
      )
    }

    return (
      <div className={styles.content}>
        {update && (
          <div className={styles.updateInProgress}>
            {i18n._(t`updating_sections`)}
          </div>
        )}

        {availableParts?.map((i: any, index: number) => (
          <Button
            key={`${i.start}-${i.label}`}
            index={index}
            canEdit={settings?.sections?.edit}
            editLocked={settings?.sections?.editLocked}
            onClick={onSetCycle}
            onToggleCycle={effects?.toggleCycle}
            onChangeSection={controller?.sections?.onChangeSection}
            onLockedFeatureClick={controller?.onLockedFeatureClick}
            {...i}
          />
        ))}

        {settings?.sections?.limited && (
          <div className={styles.lockContainer}>
            <Button index={0} label="D" start={0} end={0} />
            <Button index={0} label="Hook" start={0} end={0} />
            <Button index={0} label="Chorus" start={0} end={0} />
            <Button index={0} label="Chorus" start={0} end={0} />

            <LockPremium
              type="full"
              className={styles.lock}
              onClick={onLockedClick}
              customText={i18n._(t`unlock_sections`)}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Header title={i18n._(t`sections_label`)} />

      <div className={styles.middle}>
        <div className={styles.scroll}>{renderContent()}</div>
      </div>
    </div>
  )
}
