export async function downloadAudioViaFetch(url: string) {
  const response = await fetch(url)
  const arrayBuffer = await response.arrayBuffer()
  return arrayBuffer
}

function playClick(audioContext: OfflineAudioContext, buffer: AudioBuffer, time: number) {
  const source = audioContext.createBufferSource()
  source.connect(audioContext.destination)
  source.buffer = buffer
  source.start(audioContext.currentTime + time)
}

export async function generateMetronomeFromUrl(beatMapUrl: string) {
  const beatMap = await fetch(beatMapUrl).then((response) => response.json())
  return generateMetronome(beatMap)
}

export async function generateMetronome(beatMap: any[]) {
  const audioContext = new AudioContext()

  const downloadedBuffer = await downloadAudioViaFetch('/audio/assets_click.wav')
  const decodedBuffer = await audioContext.decodeAudioData(downloadedBuffer)

  const downloadedBuffer2 = await downloadAudioViaFetch('/audio/mechanical.wav')
  const decodedBuffer2 = await audioContext.decodeAudioData(downloadedBuffer2)

  function getBeatTime(pos: any) {
    if (typeof pos === 'object') {
      return pos.time
    }
    return pos
  }

  console.time('generateMetronome')
  const totalMetronomeTime = getBeatTime(beatMap[beatMap.length - 1]) + decodedBuffer.duration

  const offlineCtx = new OfflineAudioContext(2, 44100 * totalMetronomeTime, 44100)

  let count = 1
  for (const beat of beatMap) {
    if (count === 1) {
      playClick(offlineCtx, decodedBuffer2, getBeatTime(beat))
    } else {
      playClick(offlineCtx, decodedBuffer, getBeatTime(beat))
    }

    if (count === 4) {
      count = 1
    } else {
      count++
    }
  }

  const renderedBuffer = await offlineCtx.startRendering()

  console.timeEnd('generateMetronome')

  return { renderedBuffer, beatMap }
}
