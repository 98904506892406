import { useEffect, useMemo, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import classNames from 'classnames'
import styles from './waveform-canvas.module.scss'
import { secondsToPixels } from '../../utils/utils'
import { useResampler } from './use-resampler'

export const WaveformCanvas: React.FC<any> = ({
  className,
  data,
  offset,
  bits,
  index = 0,
  limited,
  durationMs,
  bgColor,
  scale,
  height,
  barWidth,
  barGap,
  barColor,
  disabled,
  isMetronome
}) => {
  const canvasRef = useRef(null)
  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = containerRef.current?.offsetWidth || 0
  const [opacity, setOpacity] = useState(0)
  const { canvasWidth, resample } = useResampler({
    containerWidth,
    samples: data.data,
    scale,
    amplitudeFactor: 2 ** (bits - 1)
  })

  useEffectOnce(() => {
    const timeoutId = setTimeout(() => {
      setOpacity(1)
    }, 500)

    return () => timeoutId && clearTimeout(timeoutId)
  })

  function drawFrame(
    cc: any,
    h2: any,
    x: any,
    minPeak: any,
    maxPeak: any,
    width: any,
    gap: any
  ): void {
    const min = Math.abs(minPeak * h2)
    const max = Math.abs(maxPeak * h2)

    // draw max
    cc.fillRect(x, 0, width, h2 - max)
    // draw min
    cc.fillRect(x, h2 + min, width, h2 - min)

    // draw gap
    if (gap !== 0) {
      cc.fillRect(x + width, 0, gap, h2 * 2)
    }
  }

  useEffect(() => {
    if (!canvasRef.current || !data?.length) {
      return
    }

    const canvas: any = canvasRef.current
    const len = canvas.width / scale
    const cc = canvas.getContext('2d')
    const h2 = canvas.height / scale / 2
    const width = barWidth
    const gap = barGap
    const barStart = width + gap

    cc.clearRect(0, 0, canvas.width, canvas.height)

    cc.save()
    cc.fillStyle = disabled ? '#666' : bgColor
    cc.scale(scale, scale)

    for (let pixel = offset * 2; pixel < len; pixel += barStart) {
      const [min, max] = resample(pixel)
      drawFrame(cc, h2, pixel, min, max, width, gap)
    }

    // draw center line
    cc.fillStyle = disabled ? '#404040' : barColor
    cc.fillRect(0, height / 2, canvas.width, 1)

    cc.restore()
  }, [
    barColor,
    barGap,
    height,
    barWidth,
    bgColor,
    data?.length,
    disabled,
    offset,
    resample,
    scale
  ])

  const maxWidth = useMemo(
    () =>
      limited
        ? secondsToPixels(60, containerWidth, durationMs / 1000) || 'auto'
        : '100%',
    [containerWidth, durationMs, limited]
  )

  const minWidth = useMemo(
    () => (maxWidth === 'auto' ? containerWidth : 'auto'),
    [maxWidth, containerWidth]
  )

  return (
    <div ref={containerRef}>
      <div
        className={classNames(className, styles.container)}
        style={{
          maxWidth,
          overflow: 'hidden',
          opacity,
          backgroundColor: disabled ? '#666' : bgColor,
          transitionDelay: isMetronome ? '0ms' : `${index * 100}ms`
        }}
      >
        <canvas
          style={{
            width: maxWidth,
            minWidth,
            height,
            backgroundColor: disabled ? '#404040' : barColor,
            margin: '0px',
            padding: '0px'
          }}
          height={height}
          width={canvasWidth}
          // width={!peaks ? 0 : peaks.length / 2 * scale}
          ref={canvasRef}
        />
      </div>
    </div>
  )
}
