import React, { ReactElement } from 'react'
import { useFirebaseToken } from '../hooks/authentication'
import { useCampaign } from '../hooks/campaign'
import { useModalGetApp } from '../hooks/modal-get-app'
import { useModalShortcutsMenu } from '../hooks/modal-shortcuts-menu'
import { useFailedPaymentAttempt } from '../hooks/checkout-new/common/use-failed-payment-attempt'
import { useToast } from '../hooks/toast'
import { useFailedAuthentication } from '../hooks/authentication/use-failed-authentication'
import { usePluginContext } from '../hooks/plugin/use-plugin-context'
import { useModalReferralLink } from '../hooks/referral-link'
import { AppContext } from './types'
import { useAnnouncement } from '../hooks/announcements/use-announcement'

export const Context = React.createContext<AppContext>({
  userToken: null,
  toast: {
    list: [],
    add: () => {},
    onClearFixedToast: () => {}
  },
  modalGetApp: {
    show: false,
    onOpen: () => {},
    onDismiss: () => {},
    onGoGetApp: () => {}
  },
  modalShortcutsMenu: {
    show: false,
    onOpen: () => {},
    onDismiss: () => {}
  },
  modalReferralLink: {
    show: false,
    onOpen: () => {},
    onDismiss: () => {}
  },
  failedPayments: {
    increaseStripeErrorCount: () => {},
    stripe: 0
  },
  failedAuthentication: {
    disableFetching: false,
    increaseErrorCount: () => {}
  },
  campaign: {
    plans: null,
    activeCampaign: null,
    isGlobalCampaign: false,
    promoEndAtFormated: null,
    hasCampaignYearly: false,
    hasCampaignMonthly: false,
    hasCampaignForPlanCycle: () => {
      return false
    },
    onClearCoupon: () => {},
    onValidateCoupon: async () => {
      return {}
    },
    onVerifyActiveGlobalCampaign: () => {}
  },
  announcement: {
    title: null,
    description: null,
    startAt: null,
    image: null,
    name: null,
    buttonText: null,
    show: false,
    onCloseBanner: () => {},
    onClickBanner: () => {},
    showNewFeature: () => false,
    onClickNewFeature: () => {}
  },
  plugins: {
    loading: false,
    needUpdate: {
      stems: false,
      mastering: false,
      studio: false
    },
    needInstall: {
      stems: false,
      mastering: false,
      studio: false
    },
    release: {
      stems: null,
      mastering: null,
      studio: null
    },
    needUpdateDesktopApp: false,
    showNotification: false,
    setViewedNotification: () => {},
    checkVersionInstaled: async () => {},
    isVersionDesktopAppValid: () => true
  }
})

export const ContextProvider = ({
  children
}: {
  children: React.ReactNode
}): ReactElement => {
  const userToken = useFirebaseToken()
  const toast = useToast()
  const plugins = usePluginContext()
  const modalGetApp = useModalGetApp()
  const modalShortcutsMenu = useModalShortcutsMenu()
  const modalReferralLink = useModalReferralLink()
  const campaign = useCampaign()
  const announcement = useAnnouncement()
  const failedPayments = useFailedPaymentAttempt()
  const failedAuthentication = useFailedAuthentication()

  return (
    <Context.Provider
      value={{
        toast,
        plugins,
        campaign,
        userToken,
        modalGetApp,
        modalShortcutsMenu,
        modalReferralLink,
        failedPayments,
        failedAuthentication,
        announcement
      }}
    >
      {children}
    </Context.Provider>
  )
}
