import { Icon } from 'scala'
import React, { useCallback, useEffect } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { Button } from '../button'
import { ControllerPlayer, StatePlayer } from '../../types'
import { usePanelAside } from '../../modules/panel-aside/edits/store'
import styles from './styles.module.scss'

interface SectionsProps {
  disabled?: boolean
  initialState?: StatePlayer
  controller?: ControllerPlayer
}

export const SectionsButton: React.FC<SectionsProps> = ({
  disabled,
  initialState,
  controller
}) => {
  const { i18n } = useLingui()
  const { showSections, onShowSections, close } = usePanelAside()
  const router = useRouter()

  const onToggle = useCallback(() => {
    const opened = !showSections

    onShowSections(opened)

    if (opened) {
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'sections'
      })
    }
  }, [showSections, onShowSections, controller])

  useEffectOnce(() => {
    if (initialState?.panels?.showSections) {
      onShowSections(true)
    }
  })

  // Updates the route query to contain "sections=true" only if the sections
  // panel is open
  useEffect(() => {
    if (showSections === !!router.query.sections) {
      return
    }

    if (showSections) {
      router.query.sections = 'true'
    } else {
      delete router.query.sections
    }

    router.replace(router, undefined, { shallow: true })
  }, [router, showSections])

  useUnmount(() => {
    close()
  })

  return (
    <Button
      active={showSections}
      disabled={disabled}
      onClick={onToggle}
      className={styles.button}
      text={i18n._(t`sections_label`)}
      icon={<Icon width={20} height={20} name="sections" />}
      id="sections_footer_button"
    />
  )
}
