import { Icon } from 'scala'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useCallback, useMemo } from 'react'
import { useTaskType } from '../../../hooks/tasks'
import { Task } from '../../../types'
import { TrackIcon } from './track-icon'
import styles from './track.module.scss'
import { isOperation } from './utils'
import { UseTaskUtils } from '../../../hooks/cache/use-track-utils/use-track-utils'

export interface TrackProps {
  id?: number
  className?: string
  isReorder?: boolean
  refDrag?: any
  track: Task
  ActionsTask?: React.ReactNode
  onClick?(taskId: string, type?: string): void
  taskUtils?: UseTaskUtils
}

export const Track: React.FC<TrackProps> = ({
  className,
  id,
  track,
  taskUtils,
  refDrag,
  isReorder,
  ActionsTask,
  onClick
}) => {
  const { push } = useRouter()
  const iconTaskOperation = useTaskType(track.operations)

  const isTaskProcessing = useMemo(
    () => isOperation(track.operations, 'STARTED'),
    [track]
  )

  const isTaskQueued = useMemo(
    () => isOperation(track.operations, 'QUEUED'),
    [track]
  )

  const isTaskFailed = useMemo(
    () => isOperation(track.operations, 'FAILED'),
    [track]
  )

  const isProcessingMasteringOrDenoiser =
    (iconTaskOperation === 'mastering' || iconTaskOperation === 'denoiser') &&
    (isTaskProcessing || isTaskQueued)

  const { bpm, key } = useMemo(() => {
    const beatchords = track?.operations
      ?.reverse()
      ?.find((i) => i.name.includes('BEATSCHORDS_'))
    return {
      bpm: beatchords?.result?.bpm,
      key: beatchords?.result?.key
        ?.replace(' minor', 'm')
        ?.replace(' major', '')
    }
  }, [track.operations])

  const loading = useMemo(
    () => taskUtils?.taskId === track.id && taskUtils?.loading,
    [taskUtils, track.id]
  )

  const progress = useMemo(
    () =>
      taskUtils?.taskId === track.id && taskUtils?.loading
        ? taskUtils?.progress
        : 0,
    [taskUtils, track.id]
  )

  const onClickTrack = useCallback(() => {
    if (loading) return

    if (onClick) {
      onClick(track.id, iconTaskOperation || '')
    } else if (taskUtils?.onOpenTrack) {
      taskUtils.onOpenTrack(track.id, iconTaskOperation || '')
    } else {
      push(`/player2/${track.id}?context=${iconTaskOperation}`)
    }
  }, [taskUtils, push, onClick, track, iconTaskOperation, loading])

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.isFailed]: isTaskFailed,
        [styles.isProcessing]: isProcessingMasteringOrDenoiser
      })}
    >
      <button
        id={`song_name_button_${id}`}
        type="button"
        onClick={onClickTrack}
        className={styles.button}
        disabled={isTaskFailed || isProcessingMasteringOrDenoiser}
      >
        {isReorder && <Icon name="drag" className={styles.iconReorder} />}

        <TrackIcon
          classNameIcon={styles.icon}
          isTaskFailed={isTaskFailed}
          isTaskQueued={isTaskQueued}
          isTaskProcessing={isTaskProcessing}
          isTaskLoading={loading}
          progress={progress}
          operations={track.operations}
        />

        <span className={styles.info}>
          <p className={styles.title} title={track.file.name?.slice(0, 140)}>
            <span ref={refDrag}>{track.file.name}</span>
          </p>

          <p className={styles.key}>{key || '-'}</p>
          <p className={styles.bpm}>{bpm || '-'}</p>
        </span>
      </button>

      <div id={`library_song_edit_button_${id}`} className={styles.actions}>
        {ActionsTask &&
          React.isValidElement(ActionsTask) &&
          React.cloneElement(ActionsTask as React.ReactElement<any>, {
            isDemo: track.isDemo,
            taskId: track.id,
            name: track.file.name,
            className: styles.more
          })}
      </div>
    </div>
  )
}
