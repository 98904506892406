import { FirebaseApp } from '@moises-ai/sdk'
import { useCallback, useContext, useState } from 'react'
import axios from 'axios'
import { i18n } from '@lingui/core'
import getConfig from 'next/config'
import { config } from '../../../config'
import { isDesktopApp } from '../../authentication/use-authentication/providers/helpers'
import { Context } from '../../../context'

interface UseFcmToken {
  fcmToken: string | null
  updateFcmToken: (token: string | null) => Promise<void>
  getFcmToken: ({ update }: { update: boolean }) => Promise<string | null>
  isPermissionGranted: () => Promise<boolean>
  requestPermission: () => Promise<boolean>
}

export const useFcmToken = (): UseFcmToken => {
  const [fcmToken, setFcmToken] = useState<string | null>(null)
  const { userToken } = useContext(Context)

  const updateFcmToken = useCallback(
    async (token: string | null) => {
      try {
        if (!userToken) {
          return
        }

        await axios.post(
          `${config.api.endpoint}/v3/set-fcm-token`,
          {
            fcmToken: token,
            userLocale: (i18n.locale || 'en').split('-')[0],
            appVersion: getConfig()?.publicRuntimeConfig.version,
            appBuild: 0,
            appPlatform: isDesktopApp ? 'desktop' : 'web'
          },
          {
            headers: {
              'Content-Type': 'application/json',
              authorization: userToken
            }
          }
        )
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('An error occurred while updating token. ', error)
      }
    },
    [userToken]
  )

  const getFcmToken = useCallback(
    async ({ update = false }: { update: boolean }) => {
      return FirebaseApp.messaging()
        .getToken({
          vapidKey: config.firebase.vapidKey
        })
        .then((currentToken) => {
          if (currentToken) {
            setFcmToken(currentToken)
            if (update) {
              updateFcmToken(currentToken)
            }
          }
          return currentToken
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error('An error occurred while retrieving token. ', error)
          return null
        })
    },
    [updateFcmToken]
  )

  const isPermissionGranted = useCallback(async () => {
    if (typeof window !== 'undefined') {
      const permission = await navigator.permissions.query({
        name: 'notifications'
      })
      return permission.state === 'granted'
    }
    return false
  }, [])

  const requestPermission = useCallback(async () => {
    if ('Notification' in window) {
      return Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          await getFcmToken({ update: true })
          return true
        }
        return false
      })
    }
    return false
  }, [getFcmToken])

  return {
    fcmToken,
    getFcmToken,
    updateFcmToken,
    isPermissionGranted,
    requestPermission
  }
}
