export default {
  key: 'Ab',
  suffix: 'maj13',
  positions: [
    {
      frets: '433343',
      fingers: '211131',
      barres: 3,
      capo: true
    },
    {
      frets: '445564',
      fingers: '112341',
      barres: 4,
      capo: true
    },
    {
      frets: 'xbaa88',
      fingers: '042311',
      barres: 8,
      capo: true
    },
    {
      frets: 'xbbcdd',
      fingers: '011234',
      barres: 11,
      capo: true
    }
  ]
}
