import classnames from 'classnames'
import React, { useCallback } from 'react'
import { Button } from '../button'
import { Icon } from '../icon'
import styles from './alert.module.scss'

export interface AlertProps {
  id: string
  icon?: React.ReactNode | null
  type: string
  column?: boolean
  closable: boolean
  fixed?: boolean
  title?: string | null
  description?: string | null
  buttonText?: string | null
  hasTermsOnDescription?: boolean
  onConfirm?(id: string): void
  onDismiss?(id: string): void
}

export const Alert: React.FC<AlertProps> = ({
  id,
  type,
  title,
  icon,
  closable,
  buttonText,
  description,
  hasTermsOnDescription,
  column,
  onConfirm,
  onDismiss
}) => {
  const handleDismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss(id)
    }
  }, [id, onDismiss])

  const handleConfirm = useCallback(() => {
    if (onConfirm) {
      onConfirm(id)
    }
  }, [id, onConfirm])

  return (
    <div
      className={classnames(styles.alert, {
        [styles.default]: type === 'default',
        [styles.error]: type === 'error',
        [styles.warning]: type === 'warning',
        [styles.success]: type === 'success',
        [styles.aurora]: type === 'aurora',
        [styles.sunrise]: type === 'sunrise',
        [styles.sunset]: type === 'sunset',
        [styles.nightfall]: type === 'nightfall',
        [styles.spotlight]: type === 'spotlight',
        [styles.acqua]: type === 'acqua'
      })}
    >
      {icon && <div className={styles.iconWrapper}>{icon}</div>}

      <div
        className={classnames(styles.container, {
          [styles.column]: column
        })}
      >
        <div className={styles.content}>
          {title && <p className={styles.title}>{title}</p>}

          {description && (
            <p className={styles.description}>
              {hasTermsOnDescription ? (
                <span // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: description.replace(
                      /\*([^*]+)\*/g,
                      `<a class="${styles.link}" target="_BLANK" href="//studio.moises.ai/terms">$1</a>`
                    )
                  }}
                />
              ) : (
                description
              )}
            </p>
          )}
        </div>

        {onConfirm && buttonText && (
          <Button
            small
            stroke
            radius={1}
            transparent
            title={buttonText}
            onClick={handleConfirm}
            dark={type !== 'spotlight'}
            light={type === 'spotlight'}
            className={styles.action}
          />
        )}
      </div>

      {closable && (
        <button type="button" onClick={handleDismiss} className={styles.close}>
          <Icon
            name="close"
            className={classnames({
              [styles.iconDark]: type !== 'spotlight',
              [styles.iconWhite]: type === 'spotlight'
            })}
          />
        </button>
      )}
    </div>
  )
}
