import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { getListTempo, getTempoMarking } from '../../utils/tempo-utils'
import { useAudioEngine } from '../use-audio-engine'
import {
  DataPlayer,
  StatePlayer,
  SettingsPlayer,
  ControllerPlayer
} from '../../types'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { useEffectOnChange } from '../misc/use-effect-on-change'

export interface UseSongTempo {
  error: boolean
  disabled: boolean
  loading: boolean
  value: number | string
  relativeValue: number
  type: string | null
  enabled: boolean
  isPlaybackSpeed: boolean
  lockIncrease: boolean
  lockDecrease: boolean
  onReset(): void
  onIncrease(): void
  onDecrease(): void
}

interface UseSongProps {
  data?: DataPlayer
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  playerApi?: PlayerApi
}

const VALUE_DEFAULT = 100

export const useSongTempo = ({
  data,
  initialState,
  settings,
  controller,
  playerApi
}: UseSongProps): UseSongTempo => {
  const defined = useRef(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [tempo, setTempo] = useState(VALUE_DEFAULT)
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const isAvailable = useAudioEngine((p) =>
    p.api.getAvailableFeatures().has('tempo')
  )
  const limit = settings?.playbackRate?.limited ?? Infinity

  const list = useMemo(
    () => getListTempo(data?.metadata?.bpm),
    [data?.metadata?.bpm]
  )

  const { value, type } = useMemo(() => {
    const item = list.find((k) => k.value === tempo)

    return {
      value: item?.title ?? VALUE_DEFAULT,
      type: getTempoMarking(tempo ?? VALUE_DEFAULT)
    }
  }, [list, tempo])

  const enabled =
    readyToPlay && value !== (data?.metadata?.bpm || VALUE_DEFAULT)

  const { lockIncrease, lockDecrease } = useMemo(() => {
    const val =
      typeof value === 'string' && value.includes('%')
        ? parseInt(value.replace('%', ''), 10)
        : Number(value)

    const { bpm } = data?.metadata || {}

    return {
      lockIncrease: val >= (bpm || VALUE_DEFAULT) + limit,
      lockDecrease: val <= (bpm || VALUE_DEFAULT) - limit
    }
  }, [value, data?.metadata, limit])

  const { shouldLockOnNextIncrease, shouldLockOnNextDecrease } = useMemo(() => {
    const val =
      typeof value === 'string' && value.includes('%')
        ? parseInt(value.replace('%', ''), 10)
        : Number(value)

    const { bpm } = data?.metadata || {}
    const { preLock } = settings?.playbackRate || {}

    return {
      shouldLockOnNextIncrease:
        preLock && val >= (bpm || VALUE_DEFAULT) + (limit - 1),
      shouldLockOnNextDecrease:
        preLock && val <= (bpm || VALUE_DEFAULT) - (limit - 1)
    }
  }, [value, data?.metadata, limit, settings?.playbackRate])

  const onIncrease = useCallback(() => {
    if (lockIncrease) {
      controller?.onLockedFeatureClick?.('speed-changer')
      return
    }

    const current = list.findIndex((k) => k.value === tempo)
    const next = list[current + 1] ? list[current + 1] : null

    if (next) {
      setTempo(next.value)
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'speed_changer'
      })
    }

    if (shouldLockOnNextIncrease) {
      controller?.onLockedFeatureClick?.('speed-changer', true)
    }
  }, [lockIncrease, shouldLockOnNextIncrease, list, controller, tempo])

  const onDecrease = useCallback(() => {
    if (lockDecrease) {
      controller?.onLockedFeatureClick?.('speed-changer')
      return
    }

    const current = list.findIndex((k) => k.value === tempo)
    const previous = list[current - 1] ? list[current - 1] : null

    if (previous) {
      setTempo(previous.value)
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'speed_changer'
      })
    }

    if (shouldLockOnNextDecrease) {
      controller?.onLockedFeatureClick?.('speed-changer', true)
    }
  }, [lockDecrease, shouldLockOnNextDecrease, list, controller, tempo])

  const onReset = useCallback(() => {
    const bpmDefault = list.find((k) => k.value === VALUE_DEFAULT)
    const valueBpm = bpmDefault?.value || VALUE_DEFAULT

    setTempo(valueBpm)
    controller?.onEventDispatch?.({
      event: 'reset_interaction',
      value: 'speed_reset'
    })
  }, [list, controller])

  useEffect(() => {
    if (!readyToPlay || defined.current) {
      return
    }

    defined.current = true

    if (!isAvailable || initialState?.playbackRate === undefined) {
      return
    }

    const preset = list.find((k) => k.value === initialState?.playbackRate)

    if (preset?.value === undefined) {
      return
    }

    const defaultIndex = list.findIndex((k) => k.value === 100)
    const presetIndex = list.findIndex((k) => k.value === preset.value)
    const indexDiff = Math.abs(defaultIndex - presetIndex)

    if (indexDiff > limit) {
      return
    }

    setTempo(preset?.value || VALUE_DEFAULT)
  }, [initialState?.playbackRate, isAvailable, limit, list, readyToPlay])

  useEffectOnChange(() => {
    playerApi?.playbackRate(tempo / 100)
  }, [playerApi?.playbackRate, tempo])

  useEffect(() => {
    const { status } = data?.metadata || {}
    if (!status) return
    if (status === 'loading') setLoading(true)
    if (status === 'failed') setError(true)
  }, [data?.metadata])

  return {
    disabled: !readyToPlay,
    error,
    loading,
    type,
    value,
    relativeValue: tempo / 100,
    isPlaybackSpeed: typeof value === 'string' && value.includes('%'),
    enabled,
    lockIncrease: Boolean(lockIncrease),
    lockDecrease: Boolean(lockDecrease),
    onReset,
    onIncrease,
    onDecrease
  }
}
