import { useCallback, useEffect } from 'react'
import { usePrevious } from 'react-use'
import { useAudioEngine } from '../use-audio-engine'
import { useRepeatInitialState } from './use-repeat-settings'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { ControllerPlayer, StatePlayer } from '../../types'
import { trigger } from '../../lib/events'

export function useRepeat({
  initialState,
  playerApi,
  controller
}: {
  initialState?: StatePlayer
  playerApi?: PlayerApi
  controller?: ControllerPlayer
}): (enabled?: boolean) => void {
  const isRepeating = useAudioEngine((p) => p.state.isRepeating)
  const ending = useAudioEngine(
    (p) =>
      Boolean(
        p.state?.isPlaying &&
          p.state?.isRepeating &&
          p.state?.durationMs &&
          p.state?.positionMs !== undefined
      ) && p.state.durationMs - p.state.positionMs <= 500
  )
  const previouslyEnding = usePrevious(ending)
  const isReset = useAudioEngine(
    (p) => !p.state.isPlaying && p.state.positionMs < 100
  )

  useRepeatInitialState({ initialState, toggleRepeat: playerApi?.repeat })

  useEffect(() => {
    if (previouslyEnding && isReset) {
      trigger('player:start-count-in')
    }
  }, [isReset, playerApi, previouslyEnding])

  return useCallback(
    (enabled?: boolean) => {
      playerApi?.repeat(enabled ?? !isRepeating)

      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'trim'
      })
    },
    [isRepeating, playerApi, controller]
  )
}
