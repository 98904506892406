import React from 'react'
import classnames from 'classnames'
import { Tooltip } from 'scala'
import styles from './info.module.scss'

interface Props {
  className?: string
  id?: string
  title: string
  active?: boolean
  icon?: React.ReactNode
  tooltip?: string
  unreadCount?: number
  onClick?: (e: any) => void
}

export const Info: React.FC<Props> = ({
  className,
  id,
  title,
  icon,
  active,
  tooltip,
  unreadCount = 0,
  onClick
}) => (
  <button
    id={id}
    type="button"
    onClick={onClick}
    className={classnames(className, styles.container, {
      [styles.active]: active
    })}
  >
    <p className={styles.title}>{title}</p>

    <div className={styles.content}>
      {icon && <span className={styles.iconTooltip}>{icon}</span>}

      {unreadCount > 0 && (
        <span
          className={classnames(styles.unreadCount, {
            [styles.large]: unreadCount > 99
          })}
        >
          <p className={styles.count}>
            {unreadCount > 99 ? '99+' : unreadCount}
          </p>
        </span>
      )}

      {icon && tooltip && (
        <Tooltip className={styles.tooltip} title={tooltip} nozzle topRight />
      )}
    </div>
  </button>
)
