import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon, Button } from 'scala'
import React, { useCallback } from 'react'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { OnSelectUploadType } from '../../../modules/library'
import styles from './empty.module.scss'

interface EmptyProps {
  searchTermOnTracks?: string
  onSelectUploadType: (i: OnSelectUploadType) => void
}

export const Empty: React.FC<EmptyProps> = ({
  searchTermOnTracks,
  onSelectUploadType
}) => {
  const { i18n } = useLingui()
  const router = useRouter()

  const onClickToUpload = useCallback(
    ({ target }: { target: string }) => {
      const { filter } = router.query

      if (filter === 'master') {
        onSelectUploadType({ type: 'master', target })
      } else if (filter === 'denoiser') {
        onSelectUploadType({ type: 'denoiser', target })
      } else {
        onSelectUploadType({ type: 'split', target })
      }
    },
    [onSelectUploadType, router.query]
  )

  if (searchTermOnTracks) {
    return (
      <div id="search_upload_empty_state" className={styles.container}>
        <div className={styles.title}>
          {i18n._(t`search.result.noresult`)} <br />
          <strong>“{searchTermOnTracks}”</strong>
          <Button
            id="empty_state_add_button"
            small
            radius={1}
            className={styles.button}
            onClick={() => {
              onClickToUpload({ target: 'search' })
            }}
            icon={<Icon name="upload" />}
            title={i18n._(t`label.upload`)}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      id="search_upload_empty_state"
      className={classNames(styles.container, styles.newDesign)}
    >
      <p className={styles.boxTitle}>{i18n._(t`adding_songs_collection`)}</p>
      <p className={styles.content}>{i18n._(t`upload_message_collection`)}</p>
      <Button
        id="empty_state_add_button"
        small
        radius={1}
        className={styles.button}
        onClick={() => {
          onClickToUpload({ target: 'empty_state' })
        }}
        icon={<Icon name="plus" />}
        title={i18n._(t`collection_add_button`)}
        stroke
        transparent
      />
    </div>
  )
}
