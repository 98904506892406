export default {
  key: 'B',
  suffix: 'dim',
  positions: [
    {
      frets: 'x20x01',
      fingers: '030002'
    },
    {
      frets: 'x2343x',
      fingers: '012430'
    },
    {
      frets: '75x76x',
      fingers: '310420'
    },
    {
      frets: 'xx9axa',
      fingers: '001203'
    }
  ]
}
