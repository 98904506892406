import { Loading } from 'scala'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { Task } from '../../../types'
import { ContainerDrag } from '../../dnd/container-drag'
import { Track } from '../track'
import { Empty } from './empty'
import styles from './track-list.module.scss'
import { OnSelectUploadType } from '../../../modules/library'
import { useMoisesCollection } from '../../../hooks/playlist/use-moises-collection'
import { PlaylistSample } from './playlist-sample'
import { TrackListHeader } from '../track-list-header'
import { UseTaskUtils } from '../../../hooks/cache/use-track-utils/use-track-utils'

export interface TrackListProps {
  className?: string
  loaderRef?: any
  tracks: Task[]
  totalCount?: number
  loading?: boolean
  showTasks?: boolean
  showHeader?: boolean
  disableDragDrop?: boolean
  ActionsTask?: React.ReactNode
  filterByText?: string
  taskUtils?: UseTaskUtils
  onClickTrack?(taskId: string, type?: string): void
  onSelectUploadType: (i: OnSelectUploadType) => void
}

export const TrackList: React.FC<TrackListProps> = ({
  className,
  loaderRef,
  tracks,
  totalCount,
  loading,
  showHeader = true,
  disableDragDrop,
  filterByText,
  taskUtils,
  onClickTrack,
  onSelectUploadType,
  ActionsTask
}) => {
  const router = useRouter()
  const { filter } = router.query
  const moisesCollection = useMoisesCollection()
  const isPlaylistSampleVisible =
    (!filter || filter === 'all') &&
    !filterByText &&
    moisesCollection &&
    (!loading || tracks.length > 0) &&
    Number(totalCount) <= 50

  const getDataDrop = useCallback(
    (track: Task) => ({
      taskName: track.file.name,
      taskId: track.id
    }),
    []
  )

  return (
    <div className={classnames(className, styles.container)}>
      {!loading && tracks.length === 0 ? (
        <Empty
          searchTermOnTracks={filterByText}
          onSelectUploadType={onSelectUploadType}
        />
      ) : (
        <>
          {showHeader && (
            <TrackListHeader
              loading={loading}
              total={totalCount === 1 ? tracks.length : totalCount}
            />
          )}

          {tracks.length > 0 &&
            tracks.map((track, index) => (
              <ContainerDrag
                key={`track-line-${track.id}`}
                type="AddSongToPlaylist"
                data={getDataDrop(track)}
                disableDragDrop={disableDragDrop}
                className={styles.track}
              >
                <Track
                  id={index + 1}
                  track={track}
                  ActionsTask={ActionsTask}
                  taskUtils={taskUtils}
                  onClick={onClickTrack}
                />
              </ContainerDrag>
            ))}

          {loading && (
            <Loading type="skeleton-tasks-v2" className={styles.loading} />
          )}
        </>
      )}

      {isPlaylistSampleVisible && taskUtils && (
        <PlaylistSample
          taskUtils={taskUtils}
          playlistId={moisesCollection.id}
        />
      )}

      {!loading && tracks.length > 0 && loaderRef && <span ref={loaderRef} />}
    </div>
  )
}
