export const getReprocessingMsg = (reason?: string): string => {
  const reasonToUppercase = reason?.toUpperCase() ?? ''
  switch (reasonToUppercase) {
    case 'PROCESSING':
      return 'processing_song'
    case 'FAILED':
      return 'failed_separation'
    case 'CHANGE':
    case 'UPDATE':
      return 'reprocessing_song'
    case 'ADAPT':
      return 'upgrading_song_premium'
    default:
      return ''
  }
}
