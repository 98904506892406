export type Transpose = {
  currentKey?: string
  currentChord: string
  pitch: number
}
type ScaleList = string[]
export type ScaleMap = {
  [key: string]: ScaleList
}

const orderedChords: string[] = [
  'C',
  'C#',
  'D',
  'D#',
  'E',
  'F',
  'F#',
  'G',
  'G#',
  'A',
  'A#',
  'B'
]

export const majorKeys: string[][] = [
  ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'],
  ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B']
]

export const minorKeys: string[][] = [
  ['Cm', 'C#m', 'Dm', 'Ebm', 'Em', 'Fm', 'F#m', 'Gm', 'G#m', 'Am', 'Bbm', 'Bm'],
  ['Cm', 'C#m', 'Dm', 'D#m', 'Em', 'Fm', 'F#m', 'Gm', 'Abm', 'Am', 'A#m', 'Bm']
]

export const minorChordKey = 'm'
export const minorKey = 'minor'
export const majorKey = 'major'

export const majorScale: ScaleMap = {
  C: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'],
  Db: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  D: ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
  Eb: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  E: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'],
  F: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  Gb: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  G: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
  Ab: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  A: ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'],
  Bb: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  B: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  'F#': ['C', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  'C#': ['B#', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B']
}

export const minorScale: ScaleMap = {
  Cm: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'B'],
  'C#m': ['B#', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  Dm: ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
  Ebm: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  Em: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'],
  Fm: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  'F#m': ['C', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  Gm: ['C', 'Db', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'Ab', 'A', 'Bb', 'B'],
  'G#m': ['B#', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  Am: ['C', 'C#', 'D', 'Eb', 'E', 'F', 'F#', 'G', 'G#', 'A', 'Bb', 'B'],
  Bbm: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  Bm: ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  'D#m': ['B#', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B'],
  Abm: ['C', 'Db', 'D', 'Eb', 'Fb', 'F', 'Gb', 'G', 'Ab', 'A', 'Bb', 'Cb'],
  'A#m': ['B#', 'C#', 'D', 'D#', 'E', 'E#', 'F#', 'G', 'G#', 'A', 'A#', 'B']
}

export const isMajor = (chord: string): boolean => chord.includes(majorKey)

const getScaleList = (key: string): ScaleMap =>
  isMajor(key) ? majorScale : minorScale

export const convertSongKeyToShortStyle = (songKey: string): string =>
  songKey
    .replace(minorKey, minorChordKey)
    .replace(majorKey, '')
    .replace(' ', '')

const isSongKeyValid = (songKey?: string): boolean => {
  const songKeyShorted = convertSongKeyToShortStyle(songKey || '')

  return (
    Object.hasOwnProperty.call(majorScale, songKeyShorted) ||
    Object.hasOwnProperty.call(minorScale, songKeyShorted)
  )
}

const transposeKey = (currentKey: string): string => {
  const keyShorted = convertSongKeyToShortStyle(currentKey)

  const keys = isMajor(currentKey) ? majorKeys : minorKeys

  const keyChordList = keys.filter((key) => key.includes(keyShorted))[0]

  const originalKeyIndex = keyChordList.findIndex(
    (value) => value === keyShorted
  )

  return keyChordList[originalKeyIndex]
}

const legacyTranspose = (currentChord: string, pitch: number): string => {
  const semitones = pitch

  const majorChord =
    currentChord.charAt(currentChord.length - 1) === minorChordKey
      ? currentChord.slice(0, -1)
      : currentChord

  const currentPosition = orderedChords.findIndex(
    (chord) => chord === majorChord
  )

  const position = currentPosition !== -1 ? currentPosition : 0

  const transposedChord = orderedChords[position + semitones]

  return currentChord.replace(majorChord, transposedChord)
}

const transposeWithOriginalKeyChord = ({
  currentKey,
  currentChord,
  pitch
}: {
  currentKey: string
  currentChord: string
  pitch: number
}): string => {
  const semitones = pitch

  const scaleList = getScaleList(currentKey)

  const majorChord =
    currentChord[currentChord.length - 1] === minorChordKey
      ? currentChord.slice(0, -1)
      : currentChord

  const currentChordPosition = orderedChords.findIndex(
    (chord) => chord === majorChord
  )

  const transposedKeyChord = transposeKey(currentKey)

  const transposedChordList = scaleList[transposedKeyChord] || currentChord

  let index = currentChordPosition + semitones
  index =
    index < 0
      ? transposedChordList.length + index
      : index >= transposedChordList.length
      ? index - transposedChordList.length
      : index

  const transposedChord = transposedChordList[index]

  return currentChord.replace(majorChord, transposedChord)
}

export const transposeKeyChord = ({
  currentKey,
  currentChord,
  pitch
}: Transpose): string => {
  if (!currentChord || currentChord === 'N') return '•'

  const chord = currentChord?.replace(/^(.)(#|b)?(.*)$/, '$1$2')
  // get the remainer of the above regex
  const remainer = currentChord?.replace(/^(.)(#|b)?(.*)$/, '$3')

  return (
    (currentKey && isSongKeyValid(currentKey)
      ? transposeWithOriginalKeyChord({
          currentKey,
          currentChord: chord,
          pitch
        })
      : legacyTranspose(chord, pitch)) + remainer
  )
}

export const useTransposeKeyChord = (): {
  transpose({ currentKey, currentChord, pitch }: Transpose): string
} => {
  return {
    transpose: transposeKeyChord
  }
}
