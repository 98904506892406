import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React from 'react'
import styles from './panel.module.scss'
import { usePanel } from './edits/store'
import { Header } from '../../components/drop/header'
import { Lyrics } from '../lyrics'
import {
  ControllerPlayer,
  DataPlayer,
  SettingsPlayer,
  StatePlayer
} from '../../types'
import { UseEffects } from '../../hooks/use-effects'
import { Chords } from '../chords'
import { usePanelAside } from '../panel-aside/edits/store'

interface PanelProps {
  data?: DataPlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  initialState?: StatePlayer
  effects?: UseEffects
  large?: boolean
}

export const Panel: React.FC<PanelProps> = ({
  data,
  settings,
  controller,
  initialState,
  effects,
  large
}) => {
  const { i18n } = useLingui()
  const { isOpen, showLyrics, showChords } = usePanel((state: any) => state)
  const { isOpen: isPanelAsideOpened } = usePanelAside()

  return (
    <div
      className={classnames(styles.container, {
        [styles.opened]: isOpen(),
        [styles.fullScreen]: true,
        [styles.large]: large,
        [styles.small]: isPanelAsideOpened()
      })}
    >
      <div className={styles.content}>
        <Header
          preview={!showChords || showLyrics}
          showToggle={false}
          className={styles.header}
          description={
            data?.lyrics?.status === 'update' && i18n._(t`updating_lyrics`)
          }
          title={`${showLyrics ? i18n._(t`lyrics_transcription`) : ''}
            ${showLyrics && showChords ? '+' : ''}
            ${showChords ? i18n._(t`Chords`) : ''}`}
        />

        <div className={styles.components}>
          {showLyrics && (
            <Lyrics
              data={data}
              settings={settings}
              controller={controller}
              smallVersion={showChords}
              className={styles.lyrics}
            />
          )}
          {showLyrics && showChords && <div className={styles.divisor} />}

          {showChords && (
            <Chords
              data={data}
              settings={settings}
              controller={controller}
              initialState={initialState}
              songKey={effects?.songKey?.currentKey}
              pitch={effects?.songKey?.pitch || 0}
              hideDiagram={showLyrics}
              smallVersion={showLyrics}
              className={styles.chords}
              onLockedClick={controller?.onLockedFeatureClick}
            />
          )}
        </div>
      </div>
    </div>
  )
}
