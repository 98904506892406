export default {
  key: 'C',
  suffix: '7/G',
  positions: [
    {
      frets: '3323xx',
      fingers: '231400'
    },
    {
      frets: '312010',
      fingers: '423010'
    },
    {
      frets: '335353',
      fingers: '113141',
      barres: 3
    }
  ]
}
