import { useCallback, useEffect } from 'react'
import { useLatest, usePrevious } from 'react-use'
import { ControllerPlayer, StatePlayer } from '../../types'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { useWindowSize } from '../misc/use-window-size'
import { useCycleInitialState } from './use-cycle-initial-state'
import { useStoreTimeline } from '../../data/timeline-store'
import { moveRangeMsToNearestBeats } from '../../utils/beats'
import { PlayerApi } from '../use-player-methods/use-player-methods'

export type ToggleCycleFn = (
  enabled?: boolean,
  rangeMs?: [number, number]
) => void

interface UseCycleProps {
  initialState?: StatePlayer
  playerApi?: PlayerApi
  controller?: ControllerPlayer
  countInEnabled?: boolean
}

const LOOP_DEFAULT_LENGTH_MS = 15000

export function useCycle({
  initialState,
  playerApi,
  controller,
  countInEnabled = false
}: UseCycleProps): ToggleCycleFn {
  const { width } = useWindowSize()
  const cycleAvailable = width > 639
  const rangeMs = useStoreTimeline((state) => state.rangeMs)
  const setRangeMs = useStoreTimeline((state) => state.setRangeMs)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const durationMs = useAudioEngine((p) => p.state.durationMs)
  const positionMs = useAudioEngine((p) => p.state.positionMs)
  const realtimePositionMs = useLatest(positionMs)
  const beats = useAudioEngine((p) => p.beatMap)
  const endLoop = useAudioEngine<boolean>((p) =>
    Boolean(
      p.state?.loop?.toMs && p.state?.loop?.toMs - p.state.positionMs <= 150
    )
  )
  const previouslyEnding = usePrevious(endLoop)

  const toggleOn = useCallback(
    (loopRangeMs: [number, number] | undefined) => {
      const fallbackRangeMs: [number, number] = [
        realtimePositionMs.current,
        Math.min(
          realtimePositionMs.current + LOOP_DEFAULT_LENGTH_MS,
          durationMs
        )
      ]

      const shouldFallback = loopRangeMs
        ? loopRangeMs[0] === loopRangeMs[1]
        : rangeMs[0] === rangeMs[1]

      const resolvedRangeMs = moveRangeMsToNearestBeats(
        shouldFallback ? fallbackRangeMs : loopRangeMs || rangeMs,
        beats,
        { maxDistance: 2000 }
      )

      if (shouldFallback || loopRangeMs) {
        setRangeMs(resolvedRangeMs)
      }

      playerApi?.loop(...resolvedRangeMs)
    },
    [beats, durationMs, playerApi, rangeMs, realtimePositionMs, setRangeMs]
  )

  const toggleOff = useCallback(() => {
    playerApi?.unLoop()
  }, [playerApi])

  const toggle = useCallback<ToggleCycleFn>(
    (enabled, loopRangeMs) => {
      if (!cycleAvailable) {
        return
      }

      const shouldEnable = enabled ?? !isLooping

      if (shouldEnable === isLooping) {
        return
      }

      if (shouldEnable) {
        toggleOn(loopRangeMs)
      } else {
        toggleOff()
      }
    },
    [cycleAvailable, isLooping, toggleOff, toggleOn]
  )

  useCycleInitialState({ initialState, toggleCycle: toggle })

  useEffect(() => {
    if (!cycleAvailable && isLooping) {
      toggleOff()
    }
  }, [cycleAvailable, isLooping, toggleOff])

  useEffect(() => {
    if (previouslyEnding && countInEnabled) {
      playerApi?.pause()
      playerApi?.seek(0)
    }
  }, [playerApi, previouslyEnding, countInEnabled])

  return useCallback(() => {
    toggle()

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'trim'
    })
  }, [toggle, controller])
}
