export const animation = {
  v: '4.8.0',
  meta: { g: 'LottieFiles AE 3.4.5', a: '', k: '', d: '', tc: '' },
  fr: 30,
  ip: 0,
  op: 76,
  w: 25,
  h: 25,
  nm: 'Processing',
  ddd: 0,
  assets: [],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 4,
      nm: 'Layer 10',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [99.78, 99.78, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.627, 0],
                    [0, 6.627],
                    [6.627, 0],
                    [0, -6.627]
                  ],
                  o: [
                    [6.627, 0],
                    [0, -6.627],
                    [-6.627, 0],
                    [0, 6.627]
                  ],
                  v: [
                    [0, 12],
                    [12, 0],
                    [0, -12],
                    [-12, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.450980395079, 0.450980395079, 0.450980395079, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 2,
      ty: 4,
      nm: 'Layer 7',
      parent: 7,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 16,
              s: [21.468, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 44,
              s: [19.068, 18.148, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 76, s: [51.568, 18.148, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [5.857, 0, 0], ix: 1 },
        s: { a: 0, k: [454.545, 454.545, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.553, 0],
                    [0, 0],
                    [0, 0.552],
                    [-0.552, 0],
                    [0, 0],
                    [0, -0.552]
                  ],
                  o: [
                    [0, 0],
                    [-0.552, 0],
                    [0, -0.552],
                    [0, 0],
                    [0.553, 0],
                    [0, 0.552]
                  ],
                  v: [
                    [21.215, 1],
                    [-9.5, 1],
                    [-10.5, 0],
                    [-9.5, -1],
                    [21.215, -1],
                    [22.215, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.854901969433, 0.909803926945, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 3,
      ty: 4,
      nm: 'Layer 9',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [99.78, 99.78, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.627, 0],
                    [0, 6.627],
                    [6.627, 0],
                    [0, -6.627]
                  ],
                  o: [
                    [6.627, 0],
                    [0, -6.627],
                    [-6.627, 0],
                    [0, 6.627]
                  ],
                  v: [
                    [0, 12],
                    [12, 0],
                    [0, -12],
                    [-12, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.450980395079, 0.450980395079, 0.450980395079, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 4,
      ty: 4,
      nm: 'Layer 6',
      parent: 7,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 16,
              s: [21.368, 0, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 44,
              s: [19.068, -18.148, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 76, s: [51.568, -18.148, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [5.857, 0, 0], ix: 1 },
        s: { a: 0, k: [454.545, 454.545, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.553, 0],
                    [0, 0],
                    [0, 0.552],
                    [-0.552, 0],
                    [0, 0],
                    [0, -0.552]
                  ],
                  o: [
                    [0, 0],
                    [-0.552, 0],
                    [0, -0.552],
                    [0, 0],
                    [0.553, 0],
                    [0, 0.552]
                  ],
                  v: [
                    [21.215, 1],
                    [-9.5, 1],
                    [-10.5, 0],
                    [-9.5, -1],
                    [21.215, -1],
                    [22.215, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.854901969433, 0.909803926945, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 5,
      ty: 4,
      nm: 'Layer 8',
      td: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [99.78, 99.78, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.627, 0],
                    [0, 6.627],
                    [6.627, 0],
                    [0, -6.627]
                  ],
                  o: [
                    [6.627, 0],
                    [0, -6.627],
                    [-6.627, 0],
                    [0, 6.627]
                  ],
                  v: [
                    [0, 12],
                    [12, 0],
                    [0, -12],
                    [-12, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.450980395079, 0.450980395079, 0.450980395079, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 6,
      ty: 4,
      nm: 'Layer 1',
      parent: 7,
      tt: 1,
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [19.943, 0.25, 0], ix: 2 },
        a: { a: 0, k: [5.857, 0, 0], ix: 1 },
        s: { a: 0, k: [454.545, 454.545, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [0.553, 0],
                    [0, 0],
                    [0, 0.552],
                    [-0.552, 0],
                    [0, 0],
                    [0, -0.552]
                  ],
                  o: [
                    [0, 0],
                    [-0.552, 0],
                    [0, -0.552],
                    [0, 0],
                    [0.553, 0],
                    [0, 0.552]
                  ],
                  v: [
                    [21.215, 1],
                    [-9.5, 1],
                    [-10.5, 0],
                    [-9.5, -1],
                    [21.215, -1],
                    [22.215, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: { a: 0, k: [0, 0.854901969433, 0.909803926945, 1], ix: 4 },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 7,
      ty: 3,
      nm: 'Null 3',
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: {
          a: 1,
          k: [
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 0,
              s: [-20.515, 12.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.1, y: 0.1 },
              o: { x: 0.54, y: 0.54 },
              t: 30,
              s: [9.185, 12.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            {
              i: { x: 0.1, y: 1 },
              o: { x: 0.54, y: 0 },
              t: 44,
              s: [9.185, 12.5, 0],
              to: [0, 0, 0],
              ti: [0, 0, 0]
            },
            { t: 76, s: [36.905, 12.5, 0] }
          ],
          ix: 2
        },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [22, 22, 100], ix: 6 }
      },
      ao: 0,
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    },
    {
      ddd: 0,
      ind: 8,
      ty: 4,
      nm: 'Layer 2',
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [12.5, 12.5, 0], ix: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1 },
        s: { a: 0, k: [99.78, 99.78, 100], ix: 6 }
      },
      ao: 0,
      shapes: [
        {
          ty: 'gr',
          it: [
            {
              ind: 0,
              ty: 'sh',
              ix: 1,
              ks: {
                a: 0,
                k: {
                  i: [
                    [-6.627, 0],
                    [0, 6.627],
                    [6.627, 0],
                    [0, -6.627]
                  ],
                  o: [
                    [6.627, 0],
                    [0, -6.627],
                    [-6.627, 0],
                    [0, 6.627]
                  ],
                  v: [
                    [0, 12],
                    [12, 0],
                    [0, -12],
                    [-12, 0]
                  ],
                  c: true
                },
                ix: 2
              },
              nm: 'Path 1',
              mn: 'ADBE Vector Shape - Group',
              hd: false
            },
            {
              ty: 'fl',
              c: {
                a: 0,
                k: [0.450980395079, 0.450980395079, 0.450980395079, 1],
                ix: 4
              },
              o: { a: 0, k: 100, ix: 5 },
              r: 1,
              bm: 0,
              nm: 'Fill 1',
              mn: 'ADBE Vector Graphic - Fill',
              hd: false
            },
            {
              ty: 'tr',
              p: { a: 0, k: [0, 0], ix: 2 },
              a: { a: 0, k: [0, 0], ix: 1 },
              s: { a: 0, k: [100, 100], ix: 3 },
              r: { a: 0, k: 0, ix: 6 },
              o: { a: 0, k: 100, ix: 7 },
              sk: { a: 0, k: 0, ix: 4 },
              sa: { a: 0, k: 0, ix: 5 },
              nm: 'Transform'
            }
          ],
          nm: 'Group 1',
          np: 2,
          cix: 2,
          bm: 0,
          ix: 1,
          mn: 'ADBE Vector Group',
          hd: false
        }
      ],
      ip: 0,
      op: 240,
      st: 0,
      bm: 0
    }
  ],
  markers: []
}
