import { gql } from 'graphql-request'

export type Stem = {
  stems: string[]
  label: string
  id: string
  paywall: string
}

export type Instrument = {
  stems: Stem[]
  label: string
  id: string
  icon: {
    svg: string
    pdf: string
  }
  categories: {
    id: string
  }[]
}

type InstrumentsCli = {
  getInstruments(): Promise<any>
}

const SupportedInstruments = (graphQL: any): InstrumentsCli => {
  const getInstruments = async (): Promise<any> => {
    const query = gql`
      query GetInstruments {
        supportedInstruments {
          instruments {
            stems {
              stems
              label
              id
              paywall
            }
            label
            id
            categories {
              id
            }
            operations
            icon {
              svg
              pdf
            }
          }
        }
      }
    `
    const { supportedInstruments } = await graphQL({ query, variables: {} })
    return supportedInstruments
  }

  return {
    getInstruments
  }
}

export default SupportedInstruments
