import { useCallback, useEffect, useRef, useState } from 'react'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { trigger } from '../../lib/events'
import { ControllerPlayer, StatePlayer } from '../../types'

export interface UseVolumeMaster {
  loading: boolean
  value: number
  onToggleMute(): void
  onChangeVolume(e: React.ChangeEvent<HTMLInputElement>): void
}

interface UseVolumeMasterProps {
  player?: PlayerApi
  initialState?: StatePlayer
  controller?: ControllerPlayer
}

export const useVolumeMaster = ({
  player,
  controller,
  initialState
}: UseVolumeMasterProps): UseVolumeMaster => {
  const defined = useRef(false)
  const [isMuted, setIsMuted] = useState(false)
  const [bkpMasterVolume, setBkpMasterVolume] = useState(0)
  const value = useAudioEngine((p) => p.state.masterVolume)
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)

  const onChangeVolume = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      trigger('player:channel-changed')
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'master_volume'
      })
      player?.masterVolume(parseFloat(e.currentTarget.value))
    },
    [player, controller]
  )

  const onToggleMute = useCallback(() => {
    if (!isMuted) {
      setBkpMasterVolume(value || 0)
      player?.masterVolume(0)
    } else {
      player?.masterVolume(bkpMasterVolume)
    }

    setIsMuted(!isMuted)
    trigger('player:channel-changed')
  }, [player, isMuted, value, bkpMasterVolume])

  useEffect(() => {
    if (!readyToPlay || defined.current) {
      return
    }

    defined.current = true

    if (initialState?.masterVolume) {
      player?.masterVolume(initialState?.masterVolume)
    }
  }, [player, initialState, readyToPlay, defined])

  return {
    value,
    loading: !readyToPlay,
    onToggleMute,
    onChangeVolume
  }
}
