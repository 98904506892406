import classnames from 'classnames'
import React, { useMemo } from 'react'
import styles from './waveforms.module.scss'
import { WaveformCanvas } from './waveform-canvas'
import { getColorsStems } from '../../utils/color-stems'
import { ChannelPlayer, SettingsPlayer, Stem } from '../../types'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { Skeleton } from './skeleton'
import { BeatGrid } from '../beat-grid'
import { stems } from '../../utils/utils'
import { AudioEngineState } from '../../lib/audio-engines/types'
import { WaveformState } from './waveform-processing'

interface WaveformProps {
  className?: string
  channels?: ChannelPlayer[]
  settings?: SettingsPlayer
  activeMetronome?: string
  metronomeLoading?: boolean
}

export const Waveforms: React.FC<WaveformProps> = ({
  className,
  channels,
  activeMetronome,
  settings,
  metronomeLoading
}) => {
  const beatMap = useAudioEngine((p) => p.beatMap)
  const waveforms = useAudioEngine((p) => p.waveforms)
  const durationMs = useAudioEngine((p) => p.state.durationMs)
  const loading = useAudioEngine((p: any) => !p.state.isReadyToPlay)
  const colors = useMemo(() => getColorsStems(channels?.length), [channels])
  const channelsState = useAudioEngine((p) => p.channels)

  const checkShouldDisable = (
    channel: AudioEngineState['waveforms'][number]
  ): boolean => {
    const id =
      channel.id === 'instrumental'
        ? 'accompaniment'
        : channel.id === 'background vocals'
        ? 'backing_vocals'
        : channel.id
    const isMetronome = channel.id?.includes('metronome')
    const muted = !!channelsState[id]?.isMuted || !channelsState[id]?.realVolume
    const disabled =
      settings?.channels?.[id as Stem]?.limitedForPro ||
      !!settings?.channels?.[id as Stem]?.limited
    const limited = isMetronome ? false : disabled

    return muted || limited
  }

  // Combine waveforms if needed
  const waveformsTreatment = useMemo(() => {
    if (!settings?.waveforms || !waveforms?.length) return waveforms

    // eslint-disable-next-line no-console
    console.time('generate waveform combined')
    const listWaveforms = waveforms
    const settingsWaveforms = settings?.waveforms as { [key: string]: any }

    Object.keys(settingsWaveforms).forEach((key) => {
      if (
        settingsWaveforms[key]?.combinedOn &&
        channels?.find((c) => c.id === key)
      ) {
        const indexWaveformFrom = listWaveforms?.findIndex((w) => w.id === key)
        const indexWaveformTo = listWaveforms?.findIndex(
          (w) => w.id === settingsWaveforms[key]?.combinedOn
        )
        const dataFrom = listWaveforms[indexWaveformFrom]?.data?.data
        const dataTo = listWaveforms[indexWaveformTo]?.data?.data
        if (dataFrom?.length && dataTo?.length) {
          listWaveforms[indexWaveformTo].data.data = dataTo.map(
            (value: number, index: number) => {
              if (index < dataFrom.length) {
                return Math.abs(value) > Math.abs(dataFrom[index])
                  ? value
                  : dataFrom[index]
              }
              return value
            }
          )
        }
      }
    })
    // eslint-disable-next-line no-console
    console.timeEnd('generate waveform combined')
    return listWaveforms
  }, [waveforms, settings?.waveforms, channels])

  const reorderedChannels = useMemo(() => {
    return stems.reduce((acc: any, stem) => {
      const hasStem = channels?.find(({ id }) => id === stem)
      if (hasStem) {
        const waveformData: any =
          waveformsTreatment?.find(({ id }) => id === stem) || {}
        const stateStem = channels?.find(({ id }) => id === stem)

        acc.push({
          ...waveformData,
          id: stem,
          error: stateStem?.error,
          processing: stateStem?.processing || !waveformData?.data,
          newStem: stateStem?.processing && !stateStem?.id.includes('metronome')
        })
      }
      return acc
    }, [] as AudioEngineState['waveforms'])
  }, [waveformsTreatment, channels])

  const countSkeleton = useMemo<number>(() => {
    return (
      (channels?.filter(({ id }: ChannelPlayer) => !id.includes('metronome'))
        .length ?? 1) + 1
    )
  }, [channels])

  return (
    <div className={classnames(className, styles.container)}>
      <BeatGrid
        loading={loading}
        beatMap={beatMap}
        wantedWidth={5000}
        bgColor="#545454"
        barColor="transparent"
        barGap={0}
        barWidth={1}
        scale={1}
        durationMs={durationMs}
        bits={16}
        offset={0}
      />
      {loading || !reorderedChannels || reorderedChannels.length === 0 ? (
        <Skeleton channelLength={countSkeleton} />
      ) : (
        reorderedChannels.map((channel: any, index: number) => {
          const isChannelLoading =
            channel?.processing ||
            (metronomeLoading && channel?.id === 'metronome')
          const isErrorOrProcessing = channel?.error || channel?.processing
          const isMetronome = channel?.id?.includes('metronome')
          const newStem = channel?.newStem

          if (isChannelLoading) {
            return (
              <Skeleton
                channelLength={1}
                key={channel?.id}
                className={classnames(styles.waveform, styles.skeleton, {
                  [styles.hide]: isMetronome && channel?.id !== activeMetronome
                })}
                newStem={newStem}
              />
            )
          }

          if (isErrorOrProcessing) {
            return (
              <WaveformState
                key={channel?.id}
                isFailed={channel?.error}
                isProcessing={channel?.processing}
              />
            )
          }

          return (
            <WaveformCanvas
              key={channel?.id}
              id={channel?.id}
              data={channel?.data}
              wantedWidth={5000}
              durationMs={durationMs}
              height={50}
              index={index}
              bgColor={isMetronome ? colors[colors.length - 1] : colors[index]}
              barColor="#00838B"
              barGap={0}
              barWidth={1}
              scale={1}
              bits={16}
              offset={0}
              limited={settings?.channels?.metronome?.limited && isMetronome}
              disabled={checkShouldDisable(channel)}
              className={classnames(styles.waveform, {
                [styles.hide]: isMetronome && channel?.id !== activeMetronome
              })}
              isMetronome={isMetronome}
            />
          )
        })
      )}
    </div>
  )
}
