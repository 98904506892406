import axios from 'axios'
import { useRouter } from 'next/router'
import { useLingui } from '@lingui/react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { Context as ScalaContext } from 'scala'
import { useAsyncFn } from 'react-use'
import { t } from '@lingui/macro'
import { config } from '../../../config'
import { Context } from '../../../context'

export interface UseClaimReferralLink {
  loading: boolean
  error?: any
  value?: any
  code?: string
  showModal: boolean
  goToHome(): void
  setShowModal(showModal: boolean): void
  goHomeLabel: string
}

export const useClaimReferralLink = (): UseClaimReferralLink => {
  const { query, push } = useRouter()
  const {
    userToken,
    modalGetApp: { onOpen: onOpenModal }
  } = useContext(Context)

  const { silentRetry } = useContext(ScalaContext)
  const { i18n } = useLingui()

  const [fetched, setFetched] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const code = query.code as string

  const [state, submitClaim] = useAsyncFn(async () => {
    setFetched(true)

    const { data } = await axios.post(
      `${config.serviceBillingApi.endpoint}/v1/trial/claim-trial-by-referral`,
      { code },
      {
        headers: { Authorization: userToken },
        validateStatus: (statusCode) => [200, 400].includes(statusCode)
      }
    )

    if (data.success !== true) {
      throw new Error(
        data.code?.toUpperCase() ||
          'something went wrong, please contact suport'
      )
    }

    silentRetry()
    setShowModal(true)

    return data.trialDays
  }, [userToken, setFetched, onOpenModal, silentRetry, code])

  useEffect(() => {
    if (userToken && !fetched) {
      submitClaim()
    }
  }, [submitClaim, fetched, userToken])

  const goToHome = useCallback(() => {
    push('/library')
  }, [push])

  return {
    ...state,
    showModal,
    setShowModal,
    code,
    goToHome,
    goHomeLabel: i18n._(t`page404.actions.home`)
  }
}
