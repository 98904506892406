import React, { useRef } from 'react'
import { Icon, Key } from 'scala'
import classnames from 'classnames'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import styles from './master-volume.module.scss'
import { ShortcutTooltip, ShortcutTooltipWrapper } from '../shortcut-tooltip'
import { SettingsPlayer } from '../../types'

interface UseVolumeMaster {
  settings?: SettingsPlayer
  loading: boolean
  value: number
  onToggleMute(): void
  onChangeVolume(e: React.ChangeEvent<HTMLInputElement>): void
}

export const MasterVolume: React.FC<UseVolumeMaster> = ({
  settings,
  value,
  loading,
  onChangeVolume,
  onToggleMute
}) => {
  const { i18n } = useLingui()
  const ref = useRef<HTMLDivElement | null>(null)

  return (
    <ShortcutTooltipWrapper enabled={settings?.shortcuts}>
      <div
        ref={ref}
        className={classnames(styles.container, { [styles.loading]: loading })}
      >
        <button
          type="button"
          disabled={loading}
          onClick={onToggleMute}
          className={styles.button}
        >
          <Icon name={`volume-${Math.ceil(value * 3)}`} width={22} />
        </button>

        <div className={styles.popover}>
          <input
            type="range"
            min={0}
            max={1}
            step={0.01}
            value={value}
            disabled={loading}
            onChange={onChangeVolume}
            className={styles.range}
            style={{ backgroundSize: `${(value * 100) / 1}%` }}
          />
        </div>

        <ShortcutTooltip
          className={styles.tooltip}
          title={i18n._(t`mixer_volume`)}
          tipPosition="left"
        >
          <Key.Up /> , <Key.Down />
        </ShortcutTooltip>
      </div>
    </ShortcutTooltipWrapper>
  )
}
