import { useMemo } from 'react'

export const useSupportsSuperpowered = (): boolean => {
  return useMemo(() => {
    if (typeof window === 'undefined') {
      return false
    }

    const isFirefox =
      window.navigator.userAgent.toLowerCase().indexOf('firefox') > -1

    const isDesktopApp = navigator?.userAgent?.includes('MoisesDesktop')

    const isChrome =
      (!!window.chrome &&
        (!!window.chrome.webstore || !!window.chrome.runtime)) ||
      (window.navigator.userAgent.toLowerCase().indexOf('chrome') > -1 &&
        !!window.chrome)

    // Edge (based on chromium) detection
    const isEdgeChromium = isChrome && navigator.userAgent.indexOf('Edg') !== -1

    const isOpera =
      (!!window.opr && !!window.opr.addons) ||
      !!window.opera ||
      window.navigator.userAgent.indexOf(' OPR/') >= 0

    return !isFirefox && (isDesktopApp || isOpera || isChrome || isEdgeChromium)
  }, [])
}
