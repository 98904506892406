import { gql } from 'graphql-request'

interface PlaylistCli {
  track(data: { id: string }): Promise<any>
  createPlaylist(name: string): Promise<any>
  deletePlaylist(data: { playlistId: string }): Promise<any>
  unsubscribeFromPlaylist(data: { playlistId: string }): Promise<any>
  fetchPlaylists(data: {
    asGuest: boolean
    created: boolean
    type?: string[]
  }): Promise<any>
  addTrackToPlaylist(data: {
    playlistId: string
    trackId: string
  }): Promise<any>
  removeTrackFromPlaylist(data: {
    playlistId: string | null
    trackOrders: number
  }): Promise<any>
  reorderTracksOnPlaylist(data: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any>
  updatePlaylist(data: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any>
  sharePlaylist(data: { playlistId: string; enabled: boolean }): Promise<any>
  joinPlaylist(data: { playlistId: string }): Promise<any>
  membersEditPlaylist(data: { id: string; viewOnly: boolean }): Promise<any>
  recentContacts(data: { playlistId: string }): Promise<any>
  sendPlaylistInvite(data: { playlistId: string; userId: string }): Promise<any>
  removeGuestAccess(data: { playlistId: string; userId: string }): Promise<any>
}

const Playlist = (graphQL: any): PlaylistCli => {
  const createPlaylist = async (name: string): Promise<any> => {
    const variables = {
      name
    }
    const query = gql`
      mutation createPlaylist($name: String, $description: String) {
        createPlaylist(name: $name, description: $description) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result.createPlaylist
  }

  const addTrackToPlaylist = async ({
    playlistId,
    trackId
  }: {
    playlistId: string
    trackId: string
  }): Promise<any> => {
    const variables = {
      playlistId,
      trackId
    }
    const query = gql`
      mutation addTrackToPlaylist($playlistId: ID!, $trackId: ID!) {
        addTrackToPlaylist(playlistId: $playlistId, trackId: $trackId) {
          id
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const removeTrackFromPlaylist = async ({
    playlistId,
    trackOrders
  }: {
    playlistId: string
    trackOrders: number
  }): Promise<any> => {
    const variables = {
      playlistId,
      trackOrders
    }
    const query = gql`
      mutation removeTracksFromPlaylist(
        $playlistId: ID!
        $trackOrders: [Int!]!
      ) {
        removeTracksFromPlaylist(
          playlistId: $playlistId
          trackOrders: $trackOrders
        )
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const deletePlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = {
      id: playlistId
    }
    const query = gql`
      mutation deletePlaylist($id: ID!) {
        deletePlaylist(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const unsubscribeFromPlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = {
      playlistId
    }
    const query = gql`
      mutation unsubscribeFromPlaylist($playlistId: ID!) {
        unsubscribeFromPlaylist(playlistId: $playlistId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const updatePlaylist = async ({
    playlistId,
    name,
    description
  }: {
    playlistId: string
    name: string
    description: string | null
  }): Promise<any> => {
    const variables = {
      id: playlistId,
      data: {
        name,
        description
      }
    }
    const query = gql`
      mutation updatePlaylist($id: ID!, $data: UpdatePlaylistInput!) {
        updatePlaylist(id: $id, data: $data) {
          id
          name
          description
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const reorderTracksOnPlaylist = async ({
    playlistId,
    order,
    moveBefore
  }: {
    playlistId: string
    order: number
    moveBefore: number
  }): Promise<any> => {
    const variables = {
      playlistId,
      reorder: [{ order, move_before: moveBefore }]
    }
    const query = gql`
      mutation reorderTracksOnPlaylist(
        $playlistId: ID!
        $reorder: [TrackReorderInput!]!
      ) {
        reorderTracksOnPlaylist(playlistId: $playlistId, reorder: $reorder)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const track = async ({ id }: { id: string }): Promise<any> => {
    const variables = {
      id
    }
    const query = gql`
      query track($id: String!) {
        track(id: $id) {
          id
          file {
            name
            input
          }
          playlists {
            id
          }
          operations {
            id
            name
            status
            createdAt
            startedAt
            completedAt
            params
            result
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const fetchPlaylists = async ({
    asGuest,
    created,
    type = ['JAMSESSION', 'COLLECTION', 'BRANDED']
  }: {
    asGuest: boolean
    created: boolean
    type?: string[]
  }): Promise<any> => {
    const variables = {
      asGuest,
      created,
      type
    }

    const query = gql`
      query ($asGuest: Boolean!, $created: Boolean!, $type: [PlaylistType!]) {
        playlists(
          sort: DESC
          display: { asGuest: $asGuest, created: $created, type: $type }
        ) {
          id
          type
          name
          description
          isShared
          isGlobal
          viewOnly
          invite
          createdAt
          creator {
            id
            name
            avatar
          }
          guests {
            totalCount
            node {
              id
              name
              avatar
            }
          }
          tracks(pagination: { limit: 0, offset: 0 }) {
            totalCount
          }
          notifications {
            unreadCount
          }
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.playlists || null
  }

  const sharePlaylist = async ({
    playlistId,
    enabled
  }: {
    playlistId: string
    enabled: boolean
  }): Promise<any> => {
    const variables = { id: playlistId, enabled }
    const query = gql`
      mutation SharePlaylist($id: ID!, $enabled: Boolean!) {
        sharePlaylist(id: $id, enabled: $enabled) {
          isShared
          invite
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.sharePlaylist
  }

  const membersEditPlaylist = async ({
    id,
    viewOnly
  }: {
    id: string
    viewOnly: boolean
  }): Promise<any> => {
    const variables = { id, viewOnly }
    const query = gql`
      mutation UpdatePlaylistViewOnly($id: ID!, $viewOnly: Boolean!) {
        updatePlaylistViewOnly(id: $id, viewOnly: $viewOnly) {
          id
          viewOnly
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.updatePlaylistViewOnly
  }

  const joinPlaylist = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = { id: playlistId }
    const query = gql`
      mutation JoinPlaylist($id: ID!) {
        joinPlaylist(id: $id)
      }
    `
    const result = await graphQL({ query, variables })
    return result?.joinPlaylist
  }

  const recentContacts = async ({
    playlistId
  }: {
    playlistId: string
  }): Promise<any> => {
    const variables = { playlistId }
    const query = gql`
      query recentContacts($playlistId: ID) {
        recentContacts(playlistId: $playlistId) {
          id
          name
          avatar
          invited
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result?.recentContacts
  }

  const sendPlaylistInvite = async ({
    playlistId,
    userId
  }: {
    playlistId: string
    userId: string
  }): Promise<any> => {
    const variables = { playlistId, userId }
    const query = gql`
      mutation sendPlaylistInvite($playlistId: ID!, $userId: String!) {
        sendPlaylistInvite(playlistId: $playlistId, userId: $userId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const removeGuestAccess = async ({
    playlistId,
    userId
  }: {
    playlistId: string
    userId: string
  }): Promise<any> => {
    const variables = { playlistId, guestId: userId }
    const query = gql`
      mutation removeGuestAccess($playlistId: ID!, $guestId: String!) {
        removeGuestAccess(playlistId: $playlistId, guestId: $guestId)
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    track,
    fetchPlaylists,
    createPlaylist,
    updatePlaylist,
    deletePlaylist,
    unsubscribeFromPlaylist,
    addTrackToPlaylist,
    removeTrackFromPlaylist,
    reorderTracksOnPlaylist,
    sharePlaylist,
    joinPlaylist,
    membersEditPlaylist,
    recentContacts,
    sendPlaylistInvite,
    removeGuestAccess
  }
}

export default Playlist
