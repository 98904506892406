import { FormCheckbox } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './checkbox.module.scss'
import { CheckboxProps } from './checkbox.types'

export const Checkbox: React.FC<CheckboxProps> = ({
  title,
  description,
  className,
  classNameCheckbox,
  active = false,
  disabled,
  onChange
}) => (
  <button
    type="button"
    disabled={disabled}
    className={classnames(styles.button, className)}
    onClick={onChange}
  >
    {(title || description) && (
      <div className={styles.label}>
        {title && <p className={styles.title}>{title}</p>}
        {description && <p className={styles.description}>{description}</p>}
      </div>
    )}
    <FormCheckbox className={classNameCheckbox} active={active} />
  </button>
)
