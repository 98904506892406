import React, { useMemo } from 'react'
import { Icon } from 'scala'
import { ControllerPlayer, TaskPost } from '../../types'
import styles from './attachments.module.scss'

interface Props {
  attachments: TaskPost['attachments']
  onEventDispatch?: ControllerPlayer['onEventDispatch']
}

export const Attachments: React.FC<Props> = ({
  attachments: _attachments,
  onEventDispatch
}) => {
  const attachments = useMemo(
    () =>
      _attachments?.map((i) => ({
        ...i,
        onClick: () =>
          onEventDispatch?.({
            event: 'branded_media_url_clicked',
            value: i.url
          })
      })),
    [_attachments, onEventDispatch]
  )

  return (
    <div className={styles.container}>
      {attachments?.map((attachment) => (
        <a
          key={attachment.url + attachment.name}
          target="_blank"
          rel="noreferrer"
          href={attachment.url}
          className={styles.button}
          onClick={attachment.onClick}
        >
          <span className={styles.icon}>
            <Icon name="link" />
          </span>

          <span className={styles.column}>
            <span className={styles.title}>{attachment.name}</span>
            <span className={styles.subtitle}>{attachment.url}</span>
          </span>
        </a>
      ))}
    </div>
  )
}
