import classnames from 'classnames'
import React from 'react'
import { Icon } from 'scala'
import styles from './styles.module.scss'

interface Props {
  className?: string
  text?: React.ReactNode
}

export const FeatureNotAvailable: React.FC<Props> = ({ className, text }) => {
  return (
    <div className={classnames(className, styles.container)}>
      <Icon name="alert" width={16} height={16} className={styles.icon} />
      {text && <span className={styles.text}>{text}</span>}
    </div>
  )
}
