import { useCallback, useEffect } from 'react'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { off, on, trigger } from '../../lib/events'
import { ControllerPlayer } from '../../types'

interface Props {
  controller?: ControllerPlayer
}

export interface PlayerApi {
  play(): void
  pause(): void
  backward(seconds: number): void
  forward(seconds: number): void
  seek(seconds: number): void
  masterVolume(value: number): void
  masterVolumeIncrease(value: number): void
  masterVolumeDecrease(value: number): void
  pitchShift(value: number): void
  playbackRate(value: number): void
  enableSpatial(enabled: boolean): void
  loop(fromPositionMs: number, toPositionMs: number): void
  unLoop(): void
  repeat(enabled?: boolean): void
  channel: {
    mute(channelId: string, value: boolean): void
    solo(channelId: string, value: boolean): void
    pan(channelId: string, value: number): void
    volume(channelId: string, value: number): void
    spatialize(
      channelId: string,
      i: { azimuth?: number; inputVolume?: number; elevation?: number }
    ): void
  }
}

export const usePlayerApiMethods = ({ controller }: Props): PlayerApi => {
  const playerApi = useAudioEngine((p) => p.api)
  const progressMs = useAudioEngine((p) => p.state.positionMs)
  const masterVolumeVal = useAudioEngine((p) => p.state.masterVolume)
  const isPlaying = useAudioEngine((p) => p.state.isPlaying)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const loopBetween = useAudioEngine((p) => p.state.loop)
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const enableFallback = useAudioEngine((p) => p.enableFallback)

  const onPlayMedia = useCallback(() => playerApi.play(), [playerApi])
  const onPauseMedia = useCallback(() => playerApi.pause(), [playerApi])

  useEffect(() => {
    enableFallback?.()
  }, [enableFallback])

  useEffect(() => {
    if (isReadyToPlay) {
      trigger('player:ready-to-play')
    }
  }, [isReadyToPlay])

  useEffect(() => {
    on('player:play-media', onPlayMedia)
    on('player:pause-media', onPauseMedia)

    return () => {
      off('player:play-media', onPlayMedia)
      off('player:pause-media', onPauseMedia)
    }
  }, [onPlayMedia, onPauseMedia])

  const play = useCallback(
    (force = false) => {
      if (
        !force &&
        (progressMs < 100 ||
          (isLooping &&
            loopBetween?.fromMs &&
            progressMs <= loopBetween?.fromMs))
      ) {
        trigger('player:start-count-in')
      } else {
        playerApi?.play()
      }
      controller?.onEventDispatch?.({ event: 'played' })
    },
    [playerApi, isLooping, progressMs, loopBetween?.fromMs, controller]
  )

  const pause = useCallback(() => playerApi?.pause(), [playerApi])

  const seek = useCallback(
    (seconds: number) => {
      const position = seconds < 1500 ? 0 : seconds
      playerApi?.seek(position)

      if (
        isPlaying &&
        (position === 0 ||
          (isLooping && loopBetween?.fromMs && position <= loopBetween?.fromMs))
      ) {
        trigger('player:start-count-in')
      }

      if (seconds === 0) trigger('player:start')
    },
    [playerApi, isPlaying, isLooping, loopBetween]
  )

  const backward = useCallback(
    (seconds: number) => {
      const newProgressMs = progressMs - seconds * 1000

      if (isLooping) {
        trigger('timeline:seek-cycle', { seconds: newProgressMs })
      } else {
        seek(newProgressMs)
      }

      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'rewind'
      })
    },
    [seek, progressMs, isLooping, controller]
  )

  const forward = useCallback(
    (seconds: number) => {
      seek(progressMs + seconds * 1000)
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'advance'
      })
    },
    [seek, progressMs, controller]
  )

  const masterVolume = useCallback(
    (value: number) => playerApi?.masterVolume(value),
    [playerApi]
  )

  const masterVolumeDecrease = useCallback(
    (value: number) => {
      let newValue = masterVolumeVal - value
      if (newValue < 0) {
        newValue = 0
      }
      playerApi?.masterVolume(newValue)
    },
    [playerApi, masterVolumeVal]
  )

  const masterVolumeIncrease = useCallback(
    (value: number) => {
      let newValue = masterVolumeVal + value
      if (newValue > 1) {
        newValue = 1
      }
      playerApi?.masterVolume(newValue)
    },
    [playerApi, masterVolumeVal]
  )

  const pitchShift = useCallback(
    (value: number) => playerApi?.pitchShift(value),
    [playerApi]
  )

  const playbackRate = useCallback(
    (value: number) => playerApi?.playbackRate(value),
    [playerApi]
  )

  const enableSpatial = useCallback(
    (enabled: boolean) => playerApi?.enableSpatial(enabled),
    [playerApi]
  )

  const loop = useCallback(
    (fromPositionMs: number, toPositionMs: number) =>
      playerApi?.loop(fromPositionMs, toPositionMs),
    [playerApi]
  )

  const unLoop = useCallback(() => playerApi?.unLoop(), [playerApi])

  const channelMute = useCallback(
    (channelId: string, value: boolean) => {
      if (value) {
        playerApi?.channel?.solo(channelId, false)
      }
      playerApi?.channel?.mute(channelId, value)
    },
    [playerApi]
  )

  const channelSolo = useCallback(
    (channelId: string, value: boolean) => {
      playerApi?.channel?.solo(channelId, value)
      if (value) {
        playerApi?.channel?.mute(channelId, false)
      }
      playerApi?.channel?.solo(channelId, value)
    },
    [playerApi]
  )

  const channelPan = useCallback(
    (channelId: string, value: number) =>
      playerApi?.channel?.pan(channelId, value),
    [playerApi]
  )

  const channelVolume = useCallback(
    (channelId: string, value: number) =>
      playerApi?.channel?.volume(channelId, value),
    [playerApi]
  )

  const channelSpatialize = useCallback(
    (
      channelId: string,
      i: { azimuth?: number; inputVolume?: number; elevation?: number }
    ) => playerApi?.channel?.spatialize(channelId, i),
    [playerApi]
  )

  const repeat = useCallback(
    (enabled?: boolean) => playerApi?.repeat(enabled || false),
    [playerApi]
  )

  return {
    play,
    pause,
    backward,
    forward,
    seek,
    masterVolume,
    masterVolumeIncrease,
    masterVolumeDecrease,
    pitchShift,
    playbackRate,
    enableSpatial,
    loop,
    unLoop,
    repeat,
    channel: {
      mute: channelMute,
      solo: channelSolo,
      pan: channelPan,
      volume: channelVolume,
      spatialize: channelSpatialize
    }
  }
}
