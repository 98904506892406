import classnames from 'classnames'
import React from 'react'
import { useDrag } from 'scala'
import styles from './container-drag.module.scss'

interface ContainerDragProps {
  className?: string
  data?: any
  type: string
  children: React.ReactNode
  disableDragDrop?: boolean
}

export const ContainerDrag: React.FC<ContainerDragProps> = ({
  className,
  type,
  children,
  data,
  disableDragDrop
}) => {
  const [{ isDragging }, drag, dragPreview] = useDrag(() => {
    return {
      type,
      item: {
        ...data
      },
      options: {
        dropEffect: 'move'
      },
      previewOptions: { offsetX: -5, offsetY: -5 },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging()
      })
    }
  })

  return (
    <div
      role="Box"
      ref={disableDragDrop ? null : drag}
      className={classnames(className, styles.container, {
        [styles.isDragging]: isDragging
      })}
    >
      {children &&
        React.isValidElement(children) &&
        React.cloneElement(children as React.ReactElement<any>, {
          refDrag: dragPreview
        })}
    </div>
  )
}
