/* eslint-disable camelcase */
import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChordsType } from '../../modules/chords'
import { ControllerPlayer } from '../../types'

interface UseChordsType {
  chordsType: string
  typeOptions: {
    text: string
    value: string
  }[]
  onChangeType(value: string): void
}

export enum ChordsTypeKey {
  easy = 'prev_chord',
  medium = 'chord_simple_pop',
  advanced = 'chord_complex_pop'
}

export const DEFAULT_CHORDS_TYPE_KEY = ChordsTypeKey.easy

export const useChordsType = ({
  type,
  chords,
  controller,
  setTypeChords
}: {
  type?: string
  chords: ChordsType
  controller?: ControllerPlayer
  setTypeChords?(value: string): void
}): UseChordsType => {
  const router = useRouter()
  const { i18n } = useLingui()
  const [chordsType, setChordsType] = useState(DEFAULT_CHORDS_TYPE_KEY)
  const [defined, setDefined] = useState(false)

  useEffect(() => {
    if (defined) return

    if (chords?.list) {
      setDefined(true)

      const typeChords =
        (type as ChordsTypeKey | null) || DEFAULT_CHORDS_TYPE_KEY

      setChordsType(typeChords)
    }
  }, [chords, type, defined])

  const onChangeType = useCallback(
    (value: ChordsTypeKey) => {
      setChordsType(value)
      setTypeChords?.(value)

      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'extend_chords'
      })

      if (
        !router.query.chords &&
        [ChordsTypeKey.medium, ChordsTypeKey.advanced].includes(value)
      ) {
        router.query.chords = 'true'
        router.replace(router, undefined, { shallow: true })
      }
    },
    [router, controller, setTypeChords]
  )

  const typeOptions: any = useMemo(() => {
    return chords?.list
      ? [
          chords.list[0]?.prev_chord
            ? {
                text: i18n._(t`chord_option_easy`),
                value: ChordsTypeKey.easy
              }
            : null,
          chords.list[0]?.chord_simple_pop
            ? {
                text: i18n._(t`chord_option_medium`),
                value: ChordsTypeKey.medium
              }
            : null,
          chords.list[0]?.chord_complex_pop
            ? {
                text: i18n._(t`chord_option_advanced`),
                value: ChordsTypeKey.advanced
              }
            : null
        ].filter((i) => !!i)
      : []
  }, [i18n, chords])

  return {
    chordsType,
    typeOptions,
    onChangeType
  }
}
