export default {
  key: 'E',
  suffix: '5',
  positions: [
    {
      frets: 'cexxxx',
      fingers: '130000'
    },
    {
      frets: 'x79xxx',
      fingers: '013000'
    },
    {
      frets: 'ceexxx',
      fingers: '134000'
    },
    {
      frets: '022xxx',
      fingers: '023000'
    }
  ]
}
