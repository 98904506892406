import { create } from 'zustand'
import { useCallback } from 'react'
import { trigger } from '../../../lib/events'

interface PanelAsideStore {
  isOpen(): boolean
  showPosts: boolean
  showSections: boolean
  onShowSections(value: boolean): void
  onShowPosts(value: boolean): void
  close(): void
}

export const usePanelAsideStore = create<PanelAsideStore>((set, get) => ({
  isOpen: () => get().showSections || get().showPosts,
  // Common state
  showSections: false,
  showPosts: false,
  // Generic actions
  onShowSections: (value: boolean) => {
    set({ showSections: value })
  },
  onShowPosts: (value: boolean) => {
    set({ showPosts: value })
  },
  close: () => set({ showSections: false, showPosts: false })
}))

export const usePanelAside = (): PanelAsideStore => {
  const storeMethods = usePanelAsideStore((s) => s)

  const handleResize = useCallback(() => {
    trigger('timeline:resize-timeline')
  }, [])

  return {
    ...storeMethods,
    onShowSections: (value: boolean) => {
      handleResize()
      storeMethods.onShowSections(value)
    },
    onShowPosts: (value: boolean) => {
      handleResize()
      storeMethods.onShowPosts(value)
    }
  }
}
