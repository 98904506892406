import classnames from 'classnames'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import styles from './timeline-cursor-small.module.scss'
import { useAudioEngine } from '../../hooks/use-audio-engine'

const useSize = (target: any): any => {
  const [size, setSize] = useState({})

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

interface Props {
  className?: string
  loading: boolean | undefined
  durationMs: number
  onChangeRange(e: React.ChangeEvent<HTMLInputElement>): void
}

export const CursorSmall: React.FC<Props> = ({
  className,
  loading,
  durationMs,
  onChangeRange
}) => {
  const target = useRef(null)
  const { width } = useSize(target)
  const progressMs = useAudioEngine((p) => p.state.positionMs)
  const positionFinal = useMemo(
    () => (progressMs / durationMs) * width || 0,
    [width, progressMs, durationMs]
  )

  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.blur(),
    []
  )

  return (
    <div ref={target} className={classnames(className, styles.container)}>
      <input
        type="range"
        min={-2}
        step="0.01"
        tabIndex={-1}
        max={durationMs}
        disabled={loading}
        value={progressMs}
        onMouseUp={onMouseUp}
        onChange={onChangeRange}
        className={styles.range}
        style={{
          left: `-10px`,
          width: (width || 0) + 18
        }}
      />

      <div
        className={styles.needle}
        style={{
          transform: `translate(${positionFinal}px, 0px)`,
          display: loading ? 'none' : 'block'
        }}
      />
    </div>
  )
}
