export default {
  key: 'A',
  suffix: 'minor',
  positions: [
    {
      frets: 'x02210',
      fingers: '002310'
    },
    {
      frets: 'x02555',
      fingers: '001444',
      barres: 5,
      capo: true
    },
    {
      frets: '577555',
      fingers: '134111',
      barres: 5,
      capo: true
    },
    {
      frets: 'x079a8',
      fingers: '001342'
    }
  ]
}
