export default {
  key: 'Ab',
  suffix: '7b9',
  positions: [
    {
      frets: 'x01112',
      fingers: '001112',
      barres: 1
    },
    {
      frets: 'xx4545',
      fingers: '101213',
      barres: 4
    },
    {
      frets: 'xx6575',
      fingers: '002131',
      barres: 5,
      capo: true
    },
    {
      frets: 'xbabab',
      fingers: '021314',
      barres: 10,
      capo: true
    }
  ]
}
