import { create } from 'zustand'
import { Multitrack } from './module/multitrack'
import { AudioEngine, AudioEngineState } from '../types'
import { attachEmulatedFeatures } from '../emulated-features'

const AVAILABLE_FEATURES = new Set([
  'loop',
  'repeat',
  'pitch',
  'tempo',
  'spatial'
])

const delay = (milliseconds: number): Promise<void> =>
  new Promise((resolve) => setTimeout(resolve, milliseconds))

export const createSuperpoweredHook = (playerId: string): AudioEngine => {
  console.log('superpowered %s create', playerId)

  const deferred = {} as any
  deferred.promise = new Promise((resolve, reject) => {
    deferred.resolve = resolve
    deferred.reject = reject
  })

  let Superpowered: any
  let webaudioManager: any
  let player: any
  let multitrackIsLoadingOrLoaded: any
  let controller: any

  const hook = create<AudioEngineState>(
    (set, get): AudioEngineState => ({
      channels: {
        metronome: {
          id: '',
          url: '',
          isLooping: false,
          isRepeating: false,
          isMuted: false,
          isPlaying: false,
          isSolo: false,
          pan: '',
          pitchShiftCents: '',
          playbackRate: '',
          positionMs: 0,
          realVolume: '',
          spatialize: false,
          volume: '',
          durationMs: 0
        }
      },
      state: {
        isReadyToPlay: false,
        isPlaying: false,
        isLooping: false,
        isRepeating: false,
        isSpatialized: false,

        loop: null,

        durationMs: 0,
        positionMs: 0,

        masterVolume: 1,

        pitchShiftCents: 0,
        playbackRate: 1
      },
      waveforms: [],
      beatMap: null,
      requestedLoadedChannels: [],
      allChannelsReady: false,
      engine: 'superpowered',
      loadMultitrackPlayer: async (): Promise<void> => {
        const { initializeSuperPowered, SuperpoweredWebAudio } = await import(
          './util/superpowered'
        )

        if (multitrackIsLoadingOrLoaded) {
          return deferred.promise
        }
        multitrackIsLoadingOrLoaded = true

        Superpowered = await initializeSuperPowered()

        webaudioManager = new SuperpoweredWebAudio(48000, Superpowered)

        player = await Multitrack({
          webaudioManager,
          onStatusChange: (status: any): void => {
            const newState = {
              // Emulated features
              // isRepeating: status.isRepeating,
              ...get().state,

              isReadyToPlay: status.readyToPlay,
              isPlaying: status.isPlaying,

              loop: status.loop,
              isLooping: status.isLooping,

              durationMs: status.durationMs,
              positionMs: status.positionMs,

              masterVolume: status.masterVolume,

              pitchShiftCents: status.pitchShiftCents,
              playbackRate: status.playbackRate,
              isSpatialized: status.isSpatialized
            }

            const allChannelsReady = status.channels.every(
              (channel: any) => channel.ready
            )
            const allChannelsBecameReady =
              allChannelsReady && !get().allChannelsReady

            // If all channels became ready while playing, it means there are
            // late loaded channels that needs syncing with the rest of the
            // channels - this is the case for the metronome, which is loaded
            // only after the stems are loaded
            if (allChannelsBecameReady && status.isPlaying) {
              player?.syncToFarthestPosition()
            }

            set({
              state: newState,
              channels: status.channelsInObj,
              allChannelsReady
            })
          }
        })

        webaudioManager.audioContext.suspend()
        player.audioNode.connect(webaudioManager.audioContext.destination)

        return deferred.resolve()
      },
      resumeContext: () => {
        // webaudioManager.audioContext.suspend()
        // player.audioNode.connect(
        //     webaudioManager.audioContext.destination
        // )
        webaudioManager.audioContext.resume()
      },
      loadWaveforms: async (channels) => {
        if (!channels.some((i) => i.wavesUrl)) {
          set({ waveforms: [] })
          return
        }

        await delay(1500)

        controller = new AbortController()
        const { signal } = controller

        console.time('generate waveform')

        const { waveforms } = get()

        const options = {
          signal,
          priority: 'low'
        }
        const listWaveData = channels
          // Download only new channels
          .filter((channel) => !waveforms.some((w) => w.id === channel.id))
          .map(async (channel) => {
            const response = channel.wavesUrl
              ? await fetch(channel.wavesUrl, options).catch(() => {})
              : null
            return {
              id: channel.id,
              data: response ? await response.json() : {}
            }
          })

        const result = await Promise.all(listWaveData)

        const dataWaveforms = waveforms
          // Drop missing channels
          .filter((waveform) => channels.some((ch) => ch.id === waveform.id))
          .concat(result)

        set({ waveforms: dataWaveforms })

        console.timeEnd('generate waveform')
      },
      api: {
        getAvailableFeatures: () => AVAILABLE_FEATURES,
        destroy: async () => {
          if (controller) {
            controller.abort()
          }
          set({ requestedLoadedChannels: [], waveforms: [] })
          await get().loadMultitrackPlayer()
          player.loadChannels([])
          player.destruct()
        },
        loadBeatMap: async (beatMapUrl) => {
          const result = await fetch(beatMapUrl).then((response) =>
            response.json()
          )
          if (result?.length) {
            set({ beatMap: result })
          }
        },
        loadChannels: async (channels) => {
          set({ requestedLoadedChannels: channels })
          await get().loadMultitrackPlayer()

          get().loadWaveforms(channels)

          return player.loadChannels(channels)
        },
        seek: (position) => {
          player.seek(position)
        },
        pause: () => {
          player.pause()
        },
        play: () => {
          player.play()
        },
        loop: (fromMs, toMs) => {
          player.loop(fromMs, toMs)
        },
        unLoop: () => {
          player.unLoop()
        },
        set: (key, value, channelId) => {
          player.set(key, value, channelId)
        },
        playbackRate: (value) => {
          player.playbackRate(value)
        },
        pitchShift: (value) => {
          player.pitchShift(value)
        },
        enableSpatial: (value) => {
          player.enableSpatial(value)
        },
        masterVolume: (value) => {
          player.masterVolume(value)
        },
        repeat: () => {
          // Emulated feature
          // player.repeat(enabled)
        },
        channel: {
          volume: (channelId, value) => {
            player.channel.volume(channelId, value)
          },
          spatialize: (channelId, value) => {
            player.channel.spatialize(channelId, value)
          },
          pan: (channelId, value) => {
            player.channel.pan(channelId, value)
          },
          mute: (channelId, value) => {
            player.channel.mute(channelId, value)
          },
          solo: (channelId, value) => {
            player.channel.solo(channelId, value)
          }
        }
      }
    })
  )

  attachEmulatedFeatures(hook)

  return hook
}
