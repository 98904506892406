import { gql } from 'graphql-request'
import { GroupInvite } from 'scala/src/types/group.types'

export interface GroupCli {
  revokeGroupMember(email: string): Promise<boolean>
  groupInvite(email: string): Promise<boolean>
  inviteDetails(id: string): Promise<GroupInvite>
  acceptGroupInvitation(groupId: string): Promise<boolean>
  leaveGroup(groupId: string): Promise<boolean>
}

const Group = (graphQL: any): GroupCli => {
  const revokeGroupMember = async (email: string): Promise<boolean> => {
    const query = gql`
      mutation RevokeGroupMember($email: String!) {
        revokeGroupMember(email: $email)
      }
    `

    const result = await graphQL({
      query,
      variables: {
        email
      }
    })

    return result?.revokeGroupMember
  }

  const groupInvite = async (email: string): Promise<boolean> => {
    const query = gql`
      mutation GroupInvite($email: String!) {
        groupInvite(email: $email)
      }
    `

    const result = await graphQL({
      query,
      variables: {
        email
      }
    })

    return result?.groupInvite
  }

  const inviteDetails = async (id: string): Promise<GroupInvite> => {
    const query = gql`
      query InviteDetails($id: ID!) {
        inviteDetails(id: $id) {
          groupId
          isOwner
          plan
          owner {
            name
            email
            avatar
          }
          # existingGroup
        }
      }
    `

    const result = await graphQL({
      query,
      variables: {
        id
      }
    })

    return result?.inviteDetails
  }

  const leaveGroup = async (groupId: string): Promise<boolean> => {
    const query = gql`
      mutation LeaveGroup($groupId: String!) {
        leaveGroup(groupId: $groupId)
      }
    `

    const result = await graphQL({
      query,
      variables: {
        groupId
      }
    })

    return result?.leaveGroup
  }

  const acceptGroupInvitation = async (groupId: string): Promise<boolean> => {
    const query = gql`
      mutation AcceptGroupInvitation($data: AcceptObjectInput!) {
        acceptGroupInvitation(data: $data)
      }
    `

    const result = await graphQL({
      query,
      variables: {
        data: {
          groupId
        }
      }
    })

    return !!result?.acceptGroupInvitation
  }

  return {
    revokeGroupMember,
    groupInvite,
    inviteDetails,
    acceptGroupInvitation,
    leaveGroup
  }
}

export default Group
