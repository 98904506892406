import { Icon } from 'scala'

export function Button({
  disabled = false,
  onClick = () => {},
  children,
  className,
  iconName
}: {
  disabled?: boolean
  onClick?: () => void
  children: React.ReactNode
  className: string
  iconName: string
}): JSX.Element {
  return (
    <button disabled={disabled} type="button" onClick={onClick}>
      <div className={className}>
        <Icon name={iconName} width={20} height={20} />
        <span>{children}</span>
      </div>
    </button>
  )
}
