import { Button, Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import styles from './download-task-drop.module.scss'
import { ItemSwitch, SwitchTask } from '../switch-task'
import { ControllerPlayer } from '../../types'

export type ItemDownload = {
  title: string
  stem?: string
  bucket?: string | null
  iconName?: string
  link?: string
  disabled?: boolean
  stroke?: boolean
  primary?: boolean
  light?: boolean
  transparent?: boolean
  type?: 'a' | 'button'
  href?: string
  newTab?: boolean
  onClick?(e: React.MouseEvent<HTMLLinkElement>): void
}

export type ListDownload = {
  title: string
  items: ItemDownload[]
}

export type FilesDownload = {
  title: string
  format: string
  locked: boolean
  items: ListDownload[]
}

interface DownloadTaskDropProps {
  className?: string
  controller?: ControllerPlayer
  files?: FilesDownload[]
  isDownloadWavEnabled?: boolean
  onLockedFeatureClick?: ControllerPlayer['onLockedFeatureClick']
}

export const DownloadTaskDrop: React.FC<DownloadTaskDropProps> = ({
  className,
  controller,
  files,
  isDownloadWavEnabled,
  onLockedFeatureClick
}) => {
  const [STORAGE_DOWNLOAD_DROP] = useState('moises:download-drop-index')
  const storage =
    localStorage.getItem(STORAGE_DOWNLOAD_DROP) &&
    parseInt(localStorage.getItem(STORAGE_DOWNLOAD_DROP) as string, 10)
  const [switchIndexActive, setSwitchIndexActive] = useState(
    files?.length === 1
      ? 0
      : storage === null || !files || (!isDownloadWavEnabled && storage === 2)
      ? 1
      : storage < files?.length
      ? storage
      : 1
  )

  const onToggleSwitch = useCallback(
    (index: number) => {
      setSwitchIndexActive(index)
      localStorage.setItem(STORAGE_DOWNLOAD_DROP, `${index}`)
    },
    [STORAGE_DOWNLOAD_DROP]
  )

  const onClickLockedItem = useCallback(() => {
    onLockedFeatureClick?.('export-lock')
  }, [onLockedFeatureClick])

  const handleClick = useCallback(
    (onClick?: any, e?: any) => {
      controller?.onEventDispatch?.({ event: 'media_exported' })
      onClick?.(e)
    },
    [controller]
  )

  const itemsSwitch: ItemSwitch[] = useMemo(
    () =>
      files?.map((item: FilesDownload, index: number) => ({
        title: item.title,
        locked: item.locked,
        active: index === switchIndexActive,
        disabled: index === switchIndexActive,
        onClick: () => onToggleSwitch(index)
      })) || [],
    [switchIndexActive, files, onToggleSwitch]
  )

  if (!files?.length) {
    return <div />
  }

  return (
    <div className={classnames(className, styles.container)}>
      {files && files.length ? (
        <SwitchTask
          dark
          items={itemsSwitch}
          onClickLockedItem={onClickLockedItem}
          className={styles.switch}
        />
      ) : null}

      {files &&
        files.length &&
        files?.map((file: FilesDownload, index: number) => (
          <div
            key={file.title}
            className={classnames(styles.items, {
              [styles.show]: index === switchIndexActive
            })}
          >
            {file.items.map((item: ListDownload, indexY: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${item.title}-${indexY}`} className={styles.item}>
                {item.title && <p className={styles.title}>{item.title}</p>}

                <div
                  className={classnames(styles.buttons, {
                    [styles.extraMargin]: item.items.length >= 4
                  })}
                >
                  {item.items.map((props: ItemDownload) => (
                    <Button
                      small
                      light
                      stroke
                      radius={1}
                      transparent
                      {...(props as any)}
                      onClick={handleClick.bind(this, props.onClick)}
                      key={props.title}
                      icon={
                        props.iconName ? (
                          <Icon
                            name={props.iconName}
                            animation={
                              props.iconName === 'loader' ? 'spin' : undefined
                            }
                            className={
                              props.iconName === 'arrow'
                                ? styles.iconArrow
                                : undefined
                            }
                          />
                        ) : null
                      }
                      className={classnames(styles.button, {
                        [styles.buttonCentered]: props.iconName === 'arrow'
                      })}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        ))}
    </div>
  )
}
