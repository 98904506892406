import { isNil } from 'ramda'
import { useEffect, useRef } from 'react'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { trigger } from '../../lib/events'
import { StatePlayer } from '../../types'

export function useCycleInitialState({
  initialState,
  toggleCycle
}: {
  initialState?: StatePlayer
  toggleCycle: (enabled?: boolean, rangeMs?: [number, number]) => void
}): void {
  const defined = useRef<boolean>(false)
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)

  useEffect(() => {
    if (!initialState || !readyToPlay || defined.current) {
      return
    }

    defined.current = true

    const {
      isLooping: isInitiallyLooping,
      fromMs,
      toMs
    } = initialState.loop || {}

    if (isNil(fromMs) || isNil(toMs)) {
      return
    }

    if (isInitiallyLooping) {
      toggleCycle(true, [fromMs, toMs])
    } else {
      trigger('timeline:set-cycle', {
        fromSeconds: fromMs,
        toSeconds: toMs
      })
    }
  }, [readyToPlay, toggleCycle, initialState])

  // useEffectOnChange(
  //   () => trigger('player:channel-changed'),
  //   [isLooping, rangeMs]
  // )
}
