export interface UseItem {
  title: number | string
  value: number
}

export const getTempoMarking = (bpm: number): string => {
  if (bpm <= 19) {
    return 'Larghissimo'
  }
  if (bpm <= 40) {
    return 'Grave'
  }
  if (bpm <= 45) {
    return 'Lento'
  }
  if (bpm <= 50) {
    return 'Largo'
  }
  if (bpm <= 55) {
    return 'Larghetto'
  }
  if (bpm <= 65) {
    return 'Adagio'
  }
  if (bpm <= 69) {
    return 'Adagietto'
  }
  if (bpm <= 72) {
    return 'Andante Moderato'
  }
  if (bpm <= 77) {
    return 'Andante'
  }
  if (bpm <= 83) {
    return 'Andantino'
  }
  if (bpm <= 85) {
    return 'Marcia Moderato'
  }
  if (bpm <= 97) {
    return 'Moderato'
  }
  if (bpm <= 109) {
    return 'Allegretto'
  }
  if (bpm <= 132) {
    return 'Allegro'
  }
  if (bpm <= 140) {
    return 'Vivace'
  }
  if (bpm <= 150) {
    return 'Vivacissimo'
  }
  if (bpm <= 167) {
    return 'Allegrissimo'
  }
  if (bpm <= 177) {
    return 'Presto'
  }

  return 'Prestissimo'
}

const getBpmList = (bpm: number): UseItem[] => {
  const maxBpm = bpm * 2
  const items = []
  let value = 100

  for (let index = 0; index <= maxBpm; index++) {
    value = (index / bpm) * 100

    if (value > 24.5) {
      items.push({
        value,
        title: index
      })
    }
  }

  return items
}

export const getListTempo = (bpm = 100): UseItem[] => getBpmList(bpm)
