import classnames from 'classnames'
import React from 'react'
import styles from './container.module.scss'

interface ContainerProps {
  className?: string
  open: boolean
  direction?: 'left' | 'right'
  children: React.ReactNode
  onClose?: () => void
}

export const Container: React.FC<ContainerProps> = ({
  className,
  open,
  children,
  direction = 'right',
  onClose
}) => {
  return (
    <>
      <div
        className={classnames(className, styles.container, {
          [styles.show]: open,
          [styles.left]: direction === 'left',
          [styles.right]: direction === 'right'
        })}
      >
        {children}
      </div>
      {open && onClose && (
        <div aria-hidden onClick={onClose} className={styles.overlay} />
      )}
    </>
  )
}
