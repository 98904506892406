import { majorKeys, minorKeys } from '../../utils/chords'

export interface UseItem {
  title: string | number
  value: number
}

const minorKey = ' minor'
const majorKey = ' major'

export const convertShortKeyToFullStyle = (songKey: any): string => {
  if (typeof songKey !== 'string') {
    return ''
  }

  if (songKey.includes('m')) {
    return songKey.replace('m', minorKey)
  }

  return `${songKey}${majorKey}`
}

export const getListKeys = (
  key?: string,
  isMajor?: boolean
): string[] | null => {
  if (!key) {
    return null
  }

  if (isMajor) {
    return majorKeys.find((i) => i.find((x) => x.includes(key))) || null
  }

  return minorKeys.find((i) => i.find((x) => x.includes(key))) || null
}

export const getNextKey = (currentKey: any, list: string[]): string => {
  const keyInList = list.indexOf(currentKey)
  return keyInList + 1 < list.length ? list[keyInList + 1] : list[0]
}

export const getPreviousKey = (currentKey: any, list: string[]): string => {
  const keyInList = list.indexOf(currentKey)
  return keyInList - 1 >= 0 ? list[keyInList - 1] : list[list.length - 1]
}

const getDefaultList = (): UseItem[] => {
  const min = -12
  const max = 12
  const items = []

  for (let index = min; index <= max; index++) {
    items.push({
      value: index,
      title: `${index}`
    })
  }

  return items
}

export const getKeysList = (key: string, list: string[]): UseItem[] => {
  const items: UseItem[] = []

  for (let index = -12; index <= 12; index++) {
    items.push({
      value: index,
      title: index
    })
  }

  const sizeHalf = Math.floor(items.length / 2)

  for (let index = sizeHalf; index < items.length; index++) {
    items[index].title =
      index === sizeHalf ? key : getNextKey(items[index - 1].title, list)
  }

  for (let index = sizeHalf - 1; index >= 0; index--) {
    items[index].title = getPreviousKey(items[index + 1].title, list)
  }

  return items
}

export const getListTempo = (
  key?: string,
  list?: string[] | null
): UseItem[] => {
  if (!key || !list) {
    return getDefaultList()
  }

  return getKeysList(key, list)
}

export const getTuningOffset = (
  tuning: number | null,
  originalTuning: number | null
): number => {
  if (!tuning || !originalTuning) {
    return 0
  }

  return 1200 * Math.log2(tuning / originalTuning)
}

const reverseTuningOffset = (
  tuningOffset: number,
  originalTuning: number
): number => {
  if (!originalTuning) {
    return 0
  }

  return originalTuning * 2 ** (tuningOffset / 1200)
}

/**
 * Find the possible tunning offsets applied to a pitch.
 *
 * @example
 * // Either the base pitch was 100 and the offset was 30, or the pitch was 200
 * // and the offset was -70
 * findTuningOffsetCandidates(130) // [-70, 30]
 */
const findTuningOffsetCandidates = (pitch: number): number[] => {
  const candidate = pitch % 100
  return candidate > 0
    ? [candidate - 100, candidate]
    : [candidate, candidate + 100]
}

export const decomposePitch = (
  pitch: number,
  originalTuning: number
): { basePitch: number; tuning: number; tuningOffset: number } => {
  if (!originalTuning) {
    return { basePitch: 0, tuning: originalTuning, tuningOffset: 0 }
  }

  const offsetCandidates = findTuningOffsetCandidates(pitch)
  const tuningCandidates = offsetCandidates
    .map((value) => reverseTuningOffset(value, originalTuning))
    .map(Math.round)
  const tuning =
    tuningCandidates.find((value) => value >= 428 && value <= 428 + 25) ||
    originalTuning
  const tuningOffset = getTuningOffset(tuning, originalTuning)
  const basePitch = Math.round((pitch - tuningOffset) / 100) * 100
  return { basePitch, tuning, tuningOffset }
}
