import { useAsyncFn } from 'react-use'
import { useCallback } from 'react'
import { Moises } from '../../lib/cli'
import { config } from '../../config'
import { useFirebaseToken } from '../authentication'

type Announcement = {
  lang?: string | null
  image?: string | null
  title?: string | null
  name?: string | null
  button?: {
    url?: string | null
    label?: string | null
    newTab?: boolean
  }
  description?: string | null
  startAt?: string | null
  id?: string | null
}

type UseAnnouncements = {
  loading: boolean
  error?: Error | undefined
  value?: Announcement | undefined
  loadAnnouncements: () => Promise<Announcement | undefined>
  dismissAnnouncement: ({
    id,
    elements
  }: {
    id: string
    elements: string[]
  }) => Promise<void>
}

const MoisesCLI = Moises({ apiEndpoint: config.api.endpoint })

export const useAnnouncements = (): UseAnnouncements => {
  const userToken = useFirebaseToken()

  const [state, loadAnnouncements] = useAsyncFn(async () => {
    if (userToken) {
      MoisesCLI.auth(userToken)

      const response = await MoisesCLI.getAnnouncements()

      if (!response?.announcement?.length) {
        return undefined
      }

      return {
        ...response.announcement[0].cmsPayload.web.sideBanner[0],
        id: response.announcement[0].id,
        startAt: response.announcement[0].startAt,
        name: response.announcement[0].name
      }
    }

    return undefined
  }, [userToken])

  const dismissAnnouncement = useCallback(
    async ({ id, elements }: { id: string; elements: string[] }) => {
      if (userToken) {
        MoisesCLI.auth(userToken)

        const response = await MoisesCLI.dismissAnnouncement({
          id,
          elements
        })
        return response
      }

      return {}
    },
    [userToken]
  )

  return {
    ...state,
    dismissAnnouncement,
    loadAnnouncements
  }
}
