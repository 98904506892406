import classnames from 'classnames'
import React, { useCallback, useEffect, useRef } from 'react'
import Scroll from 'react-scroll'
import { useEffectOnce } from 'react-use'
import { LockPremium } from '../lock-premium'
import styles from './timeline.module.scss'
import { LyricsItem, LyricsOperation } from '../../hooks/use-lyrics-timeline'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { off, on } from '../../lib/events'
import { ControllerPlayer } from '../../types'

const { Element, scroller } = Scroll

export interface LyricsProps {
  list: LyricsItem[]
  limited: boolean
  smallVersion?: boolean
  onLockedClick?: ControllerPlayer['onLockedFeatureClick']
}

export const Timeline: React.FC<LyricsProps> = ({
  list,
  limited,
  smallVersion,
  onLockedClick
}) => {
  const seek = useAudioEngine((p) => p.api.seek)
  const unLoop = useAudioEngine((p) => p.api.unLoop)
  const positionMs = useAudioEngine((p) => p.state.positionMs)

  const onSeek = useCallback(
    (word: LyricsOperation): void => {
      if (!word.inLoop) {
        unLoop()
      }

      const fromMs = parseFloat(word.fromMs + 0.001) * 1000
      seek(fromMs)
    },
    [seek, unLoop]
  )

  const handleLockedLyricsClick = useCallback(() => {
    onLockedClick?.('lyrics-lock')
  }, [onLockedClick])

  const isAutoScrolling = useRef(false)
  const isScrolling = useRef(false)

  const handleScroll = (): void => {
    if (isAutoScrolling.current) return
    if (isScrolling.current) return
    isScrolling.current = true
    setTimeout(() => {
      isScrolling.current = false
    }, 4000)
  }

  const updateScroll = useCallback((id?: number) => {
    scroller.scrollTo(id ? `line-${id}` : 'line-0', {
      duration: 100,
      delay: 0,
      smooth: true,
      containerId: 'lyrics',
      offset: -18 // Scrolls to element + offset
    })
  }, [])

  useEffect(() => {
    if (!list.length) return
    if (isScrolling.current) return
    if (isAutoScrolling.current) return
    try {
      const linesActived = list
        .map((lines, index) => {
          return { ...lines, id: index }
        })
        .filter((line) => line.active)
      let activeLine = linesActived[linesActived?.length - 1]?.id - 1 || 0
      activeLine = activeLine < 0 ? 0 : activeLine
      updateScroll(activeLine)
    } catch (error) {
      // console.log('error', error.message)
    } finally {
      setTimeout(() => {
        isAutoScrolling.current = false
      }, 200)
    }
  }, [list, updateScroll])

  useEffectOnce(() => {
    on('player:start', updateScroll)

    return () => {
      off('player:start', updateScroll)
    }
  })

  return (
    <div className={styles.container}>
      {limited && positionMs > 60000 && (
        <div className={styles.lockContainer}>
          <LockPremium
            type="full"
            customText="Unlock Unlimited AI Lyrics"
            className={styles.lock}
            onClick={handleLockedLyricsClick}
          />
        </div>
      )}
      <div
        id="lyrics"
        className={classnames(styles.content, {
          [styles.smaller]: smallVersion,
          [styles.opacity]: limited && positionMs > 60000
        })}
        onScroll={handleScroll}
      >
        {list.map((item: LyricsItem, index: number) => (
          <Element
            name={`line-${index}`}
            // eslint-disable-next-line react/no-array-index-key
            key={`item-${item?.words[0]?.word}-${index}`}
            className={styles.line}
          >
            {item.words.map((word) => (
              <span
                aria-hidden
                key={word.id}
                onClick={() => onSeek(word)}
                className={classnames(styles.word, {
                  [styles.active]: word.active,
                  [styles.inLoop]: word.inLoop
                })}
              >
                {`${word.word} `}
              </span>
            ))}
          </Element>
        ))}
      </div>
    </div>
  )
}
