import { gql } from 'graphql-request'

export const getUserQuery = gql`
  {
    user {
      featureFlags
      id
      name
      email
      name
      email
      lastSignInAt
      createdAt
      emailVerified
      shouldIdentifyOnCIO
      claims
      profilePictureUrl
      groups {
        id
        plan
        isOwner
        isPending
        owner {
          name
          email
          avatar
        }
        members {
          name
          email
          avatar
          isPending
          inviteLink
        }
        currentSeats
        occupiedSeats
        isFull
      }
      subscription {
        isPro
        isPremium
        plan
        subscriptionType
        availableCredits
        proTrialExpiresAt
        activeStripeCurrency
        currentMonthlyUsage
        mustCancelRevenuecatPremium
        hasUsedStripeTrial
        details {
          pending
          autoRenew
          providerName
          planCycle
          expireDate
          providerGateway
        }
      }
      preferences {
        communication {
          activity {
            push
            email
          }
          updates {
            push
            email
          }
          collaboration {
            push
            email
          }
        }
        defaultSeparation {
          type
          stems
        }
        chordSkillLevel
        demoPlaylist {
          optOut
          hideFromLibrary
        }
      }
      goals
      instruments {
        skillLevel
        instrumentId
      }
      actionNeeded {
        hasTermsToAccept
      }
    }
  }
`
