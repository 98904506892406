import { clamp } from 'ramda'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { useStoreTimeline } from '../../data/timeline-store'

export interface LyricsOperation {
  start(start: any): void
  end: any
  id?: any
  text?: string
  word: string
  // TODO This is not in milliseconds
  fromMs: string
  // TODO This is not in milliseconds
  toMs: string
  active?: string
  inLoop: boolean
}

export interface LyricsItem {
  words: LyricsOperation[]
  active?: boolean
  id?: string
}

interface UseLyricsTimeline {
  list: LyricsItem[]
}

interface UseLyricsProps {
  limited?: boolean
  lyrics: LyricsOperation[]
}

// new algorithm using line breaks ('<EOL>')
const sliceIntoChunks = (_arr: any): any => {
  const arr = _arr.filter((i: any) => i.text !== '<SOL>')

  const lines = []
  let arraytemp = []
  // eslint-disable-next-line no-restricted-syntax
  for (const item of arr) {
    if (item?.text?.length > 30) {
      item.text = item?.text.match(/.{1,15}/g).join(' ')
    }

    if (item.text === '<EOL>') {
      lines.push(arraytemp)
      arraytemp = []
    } else {
      arraytemp.push(item)
    }
  }

  if (arraytemp.length) {
    lines.push(arraytemp)
    arraytemp = []
  }

  return lines.filter((i: any) => i?.text !== '<EOL>')
}

export const useLyricsTimeline = ({
  lyrics,
  limited
}: UseLyricsProps): UseLyricsTimeline => {
  const [list, setList] = useState<LyricsItem[]>([])
  const progressMs = useAudioEngine((p) => p.state.positionMs)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const rangeMs = useStoreTimeline((state) => state.rangeMs)

  const linesLyrics = useMemo(() => {
    if (!lyrics?.length) {
      return []
    }

    const words = lyrics

    const linesWords = sliceIntoChunks(words)
    const lines = linesWords.map((i: any) => {
      const line = {
        fromMs: i[0]?.start,
        toMs: i[i.length - 1]?.end,
        words: i.map((w: any) => {
          const startMs = Math.ceil(w.start * 1000)
          return {
            id: w.id,
            word: w.text,
            fromMs: w.start,
            toMs: w.end,
            active: parseFloat(w.start) <= progressMs / 1000,
            inLoop: isLooping && clamp(...rangeMs, startMs) === startMs
          }
        })
      }
      return line
    })
    return lines
  }, [isLooping, lyrics, progressMs, rangeMs])

  const lastLineContent = useRef([])

  useEffect(() => {
    if (!linesLyrics || !linesLyrics.length) {
      return
    }

    let progress = progressMs / 1000
    const lines = linesLyrics.filter((i: any) => (limited ? i.toMs < 60 : true))

    const lastItem = lines[lines.length - 1]

    if (limited && progressMs > 60000) {
      progress = lastItem?.toMs || 60
    } else if (lastItem?.toMs && progress > lastItem?.toMs) {
      progress = lastItem.toMs
    }

    let indexInit = lines.findIndex(
      (i: any) =>
        parseFloat(i.fromMs) <= progress && parseFloat(i.toMs) >= progress
    )

    if (indexInit < 0) {
      indexInit =
        lines.findIndex((i: any) => parseFloat(i.toMs) >= progress) - 1
    }

    const lastLine = indexInit === lines.length - 1

    const items = lines.map((i: any) => {
      const list1 = {
        words: i.words
          .filter((w: any) =>
            limited && parseFloat(w.toMs) > 60
              ? parseFloat(w.toMs) < progress
              : true
          )
          .map((w: any) => ({
            ...w,
            active: parseFloat(w.fromMs) <= progress
          })),
        active: i.fromMs <= progress
      }

      return list1
    })

    if (items[0]?.active && items[1]?.active) {
      items[0].active = false
    }
    if (items[1]?.active && items[2]?.active) {
      items[1].active = false
    }

    if (lastLine) {
      lastLineContent.current = items
    }

    if (indexInit === -2) {
      setList(lastLineContent.current)
    } else {
      setList(items)
    }
  }, [progressMs, limited, linesLyrics])

  return {
    list
  }
}
