import React from 'react'

const positions: any = {
  string: [50, 40, 30, 20, 10, 0],
  fret: [-4, 6.5, 18, 30, 42, 54],
  finger: [-3, 8, 19.5, 31.5, 43.5]
}

const offset: any = {
  4: 0,
  6: -1
}

const getStringPosition = (string1: any, strings: any): any => {
  return positions.string[string1 + offset[strings]]
}

const radius = {
  open: 2,
  fret: 4
}

export const Dot: React.FC<any> = ({ string1, fret, finger, strings, lite }) =>
  fret === -1 ? (
    <text
      fontSize="0.7rem"
      fill="#fff"
      fontWeight="500"
      fontFamily="Articulat, helvetica, arial, sans-serif"
      textAnchor="middle"
      x={getStringPosition(string1, strings)}
      y="-2"
    >
      x
    </text>
  ) : (
    <g>
      <circle
        strokeWidth="0.5"
        stroke="#666"
        fill={fret === 0 ? 'transparent' : '#333'}
        cx={getStringPosition(string1, strings)}
        cy={positions.fret[fret]}
        r={fret === 0 || !(!lite && finger > 0) ? radius.open : radius.fret}
      />
      {!lite && finger > 0 && (
        <text
          fontSize="3.5pt"
          fontWeight="500"
          fontFamily="Articulat, helvetica, arial, sans-serif"
          textAnchor="middle"
          fill="#fff"
          x={getStringPosition(string1, strings)}
          y={positions.finger[fret] || 1}
        >
          {finger}
        </text>
      )}
    </g>
  )

// Dot.propTypes = {
//   string1: PropTypes.number,
//   fret: PropTypes.any,
//   finger: PropTypes.oneOf([0, 1, 2, 3, 4, 5]),
//   strings: PropTypes.number.isRequired,
//   lite: PropTypes.bool
// }

// Dot.defaultProps = {
//   fret: 0,
//   lite: false
// }

// export default Dot
