import styles from './loader.module.scss'

export function Loader(): JSX.Element {
  return (
    <div className={styles['lds-ring']}>
      <div />
      <div />
      <div />
      <div />
    </div>
  )
}
