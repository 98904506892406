import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import { useCallback, useEffect } from 'react'
import { useEffectOnce } from 'react-use'
import { Button } from '../panel/components'
import styles from './lyrics.module.scss'
import { usePanel } from '../panel/edits/store'
import { ControllerPlayer, DataPlayer, SettingsPlayer } from '../../types'
import { LyricsTimeline } from '../../components/lyrics-timeline'
import { useLyricsTimeline } from '../../hooks/use-lyrics-timeline'

interface LyricsProps {
  className?: string
  smallVersion?: boolean
  data?: DataPlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
}

export const Lyrics: React.FC<LyricsProps> = ({
  className,
  smallVersion,
  data,
  settings,
  controller
}) => {
  const { i18n } = useLingui()
  const { list } = useLyricsTimeline({
    limited: settings?.lyrics?.limited,
    lyrics: data?.lyrics?.data
  })
  const { setShowEditLyrics, showEditChords } = usePanel((state: any) => state)

  const handleEditorLyrics = useCallback(() => {
    controller?.lyrics?.onGoEditorLyrics?.()
    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'lyrics_editor'
    })
  }, [controller])

  useEffect(() => {
    setShowEditLyrics(true)

    return () => {
      setShowEditLyrics(false)
    }
  }, [setShowEditLyrics])

  useEffectOnce(() => {
    if (data?.lyrics?.empty && controller?.lyrics?.onAddLyrics) {
      controller.lyrics.onAddLyrics({ language: 'auto-detect' })
    }
  })

  return (
    <div className={classnames(className, styles.container)}>
      {showEditChords ||
      !data?.lyrics?.data?.length ||
      ['update', 'failed'].includes(data?.lyrics?.status || '') ||
      !settings?.lyrics?.edit ? null : (
        <div className={styles.editGroup}>
          <Button
            onClick={handleEditorLyrics}
            className={classnames(styles.editLine, {
              [styles.edit]: true
            })}
            iconName="edit-simple"
          >
            {i18n._(t`edit_label`)}
          </Button>
        </div>
      )}

      <LyricsTimeline
        list={list}
        data={data}
        settings={settings}
        controller={controller}
        smallVersion={smallVersion}
      />
    </div>
  )
}
