import React from 'react'
import styles from './loading.module.scss'

export const Loading: React.FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.loading}>
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </div>

      <p className={styles.title}>Loading...</p>
    </div>
  )
}
