import classnames from 'classnames'
import React, { useCallback, useMemo, useState } from 'react'
import { useLingui } from '@lingui/react'
import styles from './button.module.scss'
import { ActionsSection } from './actions-section'
import { RenameSection } from './rename-section'
import { useStoreTimeline } from '../../data/timeline-store'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { fmtMSS } from '../../utils/utils'
import { ToggleCycleFn } from '../../hooks/use-cycle'
import { Feature } from '../../types'
import { SectionsLabel, sectionsLocalizations } from './sections'

interface Button {
  label: string
  start: number
  end: number
  index: number
  onClick?(fromSeconds: number, toSeconds: number): void
  onChangeSection?(value: string, index: number): void
  onToggleCycle?: ToggleCycleFn
  onLockedFeatureClick?(feature: Feature): void
  canEdit?: boolean
  editLocked?: boolean
}

export const Button: React.FC<Button> = ({
  label,
  start,
  end,
  index,
  onClick,
  onToggleCycle,
  onChangeSection,
  onLockedFeatureClick,
  canEdit,
  editLocked
}) => {
  const [showRename, setShowRename] = useState(false)
  const { i18n } = useLingui()
  const loopSelected = useStoreTimeline((state) => state.rangeMs)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const active = useMemo(
    () => start * 1000 === loopSelected[0] && end * 1000 === loopSelected[1],
    [start, end, loopSelected]
  )

  const onCloseRenameSection = useCallback(() => {
    setShowRename(false)
  }, [])

  const onRenameSection = useCallback(() => {
    if (editLocked) {
      onLockedFeatureClick?.('sections-edit-lock')
      return
    }

    setShowRename(true)
  }, [editLocked, onLockedFeatureClick])

  const handleSetCycle = useCallback(() => {
    if (onClick) {
      onClick(start * 1000, end * 1000)
    }
  }, [start, end, onClick])

  const labelLocalization = useMemo(() => {
    const labelLocal = sectionsLocalizations[label as SectionsLabel] || null

    return labelLocal ? i18n._(labelLocal) : label
  }, [label, i18n])

  return (
    <div
      role="button"
      tabIndex={0}
      aria-hidden="true"
      onClick={onClick ? handleSetCycle : undefined}
      className={classnames(styles.button, {
        [styles.active]: active,
        [styles.isLooping]: active && isLooping
      })}
    >
      {showRename ? (
        <RenameSection
          index={index}
          valueOriginal={labelLocalization}
          onChangeSection={onChangeSection}
          onCloseRenameSection={onCloseRenameSection}
        />
      ) : (
        <span className={styles.label}>{labelLocalization}</span>
      )}

      <span className={styles.time}>{fmtMSS(start * 1000)}</span>

      <div className={styles.actionsContainer}>
        <ActionsSection
          canEdit={canEdit}
          className={styles.actions}
          onToggleCycle={onToggleCycle}
          onRenameSection={onRenameSection}
        />
      </div>
    </div>
  )
}
