import { t } from '@lingui/macro'

export const samplesToSeconds = (
  samples: number,
  sampleRate: number
): number => {
  return samples / sampleRate
}

export const secondsToSamples = (
  seconds: number,
  sampleRate: number
): number => {
  return Math.ceil(seconds * sampleRate)
}

export const samplesToPixels = (
  samples: number,
  resolution: number
): number => {
  return Math.floor(samples / resolution)
}

export const pixelsToSamples = (pixels: number, resolution: number): number => {
  return Math.floor(pixels * resolution)
}

export const pixelsToSeconds = (
  pixels: number,
  samplesPerPixel: number,
  duration: number
): number => {
  return Math.round(duration * (pixels / samplesPerPixel))
}

export const secondsToPixels = (
  seconds: number,
  samplesPerPixel: number,
  duration: number
): number => {
  return Math.round(samplesPerPixel * (seconds / duration))
}

export const fmtMSS = (seconds: number): string => {
  let s = Math.ceil(seconds / 1000) || 0
  // eslint-disable-next-line no-return-assign
  return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
}

export const getStemLabel = (stem: string, i18n: any): string => {
  let label = ''

  switch (stem) {
    case 'vocals':
      label = i18n._(t`task.label.vocals`)
      break
    case 'bass':
      label = i18n._(t`task.label.bass`)
      break
    case 'drums':
      label = i18n._(t`task.label.drums`)
      break
    case 'electric':
      label = i18n._(t`mixer_electric_guitar`)
      break
    case 'acoustic':
      label = i18n._(t`mixer_acoustic_guitar`)
      break
    case 'piano':
      label = i18n._(t`task.label.piano`)
      break
    case 'keys':
      label = i18n._(t`task.label.keys`)
      break
    case 'guitars':
      label = i18n._(t`task.label.guitar`)
      break
    case 'lead':
      label = i18n._(t`mixer_lead_guitar`)
      break
    case 'rhythm':
      label = i18n._(t`mixer_rhythm_guitar`)
      break
    case 'backing_vocals':
      label = i18n._(t`task.label.backvocal`)
      break
    case 'accompaniment':
      label = i18n._(t`task.label.instrumental`)
      break
    case 'strings':
      label = i18n._(t`task.label.strings`)
      break
    case 'wind':
      label = i18n._(t`instrument_wind_label`)
      break
    case 'music':
      label = i18n._(t`separation_cinematic_soundtrack`)
      break
    case 'dialogue':
      label = i18n._(t`separation_cinematic_dialogue`)
      break
    case 'effects':
      label = i18n._(t`separation_cinematic_effects`)
      break
    case 'cymbals':
      label = i18n._(t`separation_drumparts_cymbals`)
      break
    case 'hat':
      label = i18n._(t`separation_drumparts_hi-hat`)
      break
    case 'kick':
      label = i18n._(t`separation_drumparts_kickdrum`)
      break
    case 'snare':
      label = i18n._(t`separation_drumparts_snare`)
      break
    case 'toms':
      label = i18n._(t`separation_drumparts_toms`)
      break
    case 'drumless':
      label = i18n._(t`mixer.drumless`)
      break
    case 'bassless':
      label = i18n._(t`mixer.bassless`)
      break
    case 'other_kit':
      label = i18n._(t`mixer_acoustic_other_drums`)
      break
    case 'otherless':
      label = i18n._(t`mixer.otherless`)
      break
    case 'metronome':
      label = i18n._(t`task.label.metronome`)
      break
    case 'metronome_half':
      label = i18n._(t`task.label.metronome`)
      break
    case 'metronome_double':
      label = i18n._(t`task.label.metronome`)
      break
    default:
      label = i18n._(t`task.label.other`)
  }

  return label
}

export const stems = [
  'vocals',
  'backing_vocals',
  'drums',
  'bass',
  'guitars',
  'lead',
  'rhythm',
  'piano',
  'keys',
  'strings',
  'wind',
  'dialogue',
  'music',
  'effects',
  'kick',
  'snare',
  'hat',
  'toms',
  'cymbals',
  'drumless',
  'bassless',
  'other_kit',
  'other_drums',
  'otherless',
  'accompaniment',
  'electric',
  'acoustic',
  'other',
  'metronome',
  'metronome_half',
  'metronome_double'
]
