import { Button, Icon, Modal, Context as ContextAppFrame } from 'scala'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useRouter } from 'next/router'
import { usePersistentDismissible } from '../../hooks/use-persistent-dismissable'
import { useGlobalCampaign } from '../../hooks/campaign'
import styles from './styles.module.scss'
import { Context } from '../../context'

export const ModalCampaign: React.FC = () => {
  const { push } = useRouter()
  const {
    toast: { add: addToast }
  } = useContext(Context)
  const { user } = useContext(ContextAppFrame)
  const { campaign, isUserTarget } = useGlobalCampaign()
  const enabled = useMemo(() => !!campaign?.id, [campaign])

  const { show, onDismiss, getStorage } = usePersistentDismissible({
    id: `modal-${campaign?.id}`,
    enabled
  })

  const onClick = useCallback(() => {
    onDismiss()
    push('/pricing')
  }, [push, onDismiss])

  /**
   * Shows toast (floating banner) after day 1 after show the modal
   */
  const [showed, setShowed] = useState(false)
  const [showToast, setShowToast] = useState(false)
  const { show: showToastSession, onDismiss: onDismissToast } =
    usePersistentDismissible({
      id: `toast-${campaign?.id}`,
      type: 'session',
      enabled
    })

  useEffect(() => {
    const storageModal = getStorage()

    if (!showToast && storageModal?.date) {
      const diff = Math.abs(
        new Date().getTime() - new Date(storageModal.date).getTime()
      )
      const diffDays = diff / (1000 * 60 * 60 * 24)

      if (diffDays >= 1) {
        setShowToast(true)
      }
    }
  }, [show, showToast, getStorage])

  useEffect(() => {
    if (showToastSession && showToast && !showed) {
      setShowed(true)
      addToast({
        icon: null,
        fixed: true,
        type: 'success',
        closable: true,
        title: campaign?.floatingBanner.title,
        description: campaign?.floatingBanner.description,
        buttonText: campaign?.floatingBanner.cta,
        onConfirm: onClick
      })
      onDismissToast()
    }
  }, [
    showToast,
    showed,
    showToastSession,
    campaign,
    addToast,
    onClick,
    onDismissToast
  ])

  if (!user || !show || !isUserTarget || !campaign) return null

  return (
    <Modal
      label="Campaign"
      targetId="moises-app"
      isOpen={show}
      onClose={onDismiss}
      shouldCloseOnOverlayClick
    >
      <div className={styles.container}>
        <img
          alt={styles.title}
          src={campaign.mainBannerImages.size2x}
          className={styles.image}
        />
        <button
          className={styles.close}
          type="button"
          onClick={onDismiss}
          tabIndex={0}
        >
          <Icon name="close" />
        </button>

        <div className={styles.modalBody}>
          <div className={styles.modalText}>
            <h1 className={styles.title}>
              {campaign.mainBanner.title.replace(/\*/g, '')}
            </h1>
            <p>{campaign.mainBanner.description}</p>
          </div>

          <Button
            className={styles.cta}
            title={campaign.mainBanner.cta}
            small
            radius={1}
            onClick={onClick}
          />
        </div>
      </div>
    </Modal>
  )
}
