import { Icon } from 'scala'
import classnames from 'classnames'
import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react'
import styles from './select.module.scss'
import { useOnClickOutside } from '../../../hooks/misc/use-on-click-outside'
import { Container } from '../../drop/container'

type Option = {
  text: string
  value: string
}

interface SelectProps {
  className?: string
  loading?: boolean
  options: Option[]
  value?: string
  onChange: (value: string) => void
}

export const SelectCustom: React.FC<SelectProps> = ({
  className,
  loading,
  options,
  value,
  onChange
}) => {
  const ref: RefObject<any> = useRef()
  const [open, setOpen] = useState(false)
  const onToggleDrop = useCallback(() => {
    if (loading) {
      return
    }
    setOpen(!open)
  }, [open, loading])
  useOnClickOutside(ref, () => setOpen(false))

  const textSelected = useMemo(
    () => options.find((i) => i.value === value)?.text,
    [options, value]
  )

  const onChoose = useCallback(
    (val: string) => {
      onChange(val)
      setOpen(false)
    },
    [onChange]
  )

  const items = useMemo(
    () =>
      options.map((i) => ({
        ...i,
        onClick: () => onChoose(i.value)
      })),
    [options, onChoose]
  )

  return (
    <div ref={ref} className={classnames(styles.container, className)}>
      <button
        type="button"
        onClick={onToggleDrop}
        className={classnames(styles.select, {
          [styles.disabled]: loading
        })}
      >
        <p className={styles.label}>{textSelected}</p>
        <Icon
          width={16}
          height={16}
          name="arrow-fill"
          className={classnames(styles.icon, {
            [styles.invert]: open
          })}
        />
      </button>

      <Container open={open} className={styles.drop}>
        <>
          {items.map((i) => (
            <button
              key={i.value}
              type="button"
              className={styles.item}
              onClick={i.onClick}
            >
              <p className={styles.label}>{i.text}</p>
              {i.value === value ? (
                <Icon
                  width={24}
                  height={24}
                  name="check-simple"
                  className={styles.icon}
                />
              ) : null}
            </button>
          ))}
        </>
      </Container>
    </div>
  )
}
