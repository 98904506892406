import C from './C/index'
import Csharp from './C#/index'
import D from './D/index'
import Eb from './Eb/index'
import E from './E/index'
import F from './F/index'
import Fsharp from './F#/index'
import G from './G/index'
import A from './A/index'
import Ab from './Ab/index'
import B from './B/index'
import Bb from './Bb/index'

export default {
  C,
  Csharp,
  D,
  Eb,
  E,
  F,
  Fsharp,
  G,
  Ab,
  A,
  Bb,
  B
}
