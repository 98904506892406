export default {
  key: 'E',
  suffix: 'alt',
  positions: [
    {
      frets: '0121xx',
      fingers: '013200'
    },
    {
      frets: 'xx2354',
      fingers: '001243'
    },
    {
      frets: '076x56',
      fingers: '042013'
    },
    {
      frets: '078990',
      fingers: '012340'
    }
  ]
}
