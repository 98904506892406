import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'scala'
import Image from 'next/image'
import React, { useCallback, useMemo } from 'react'
import styles from './error-track.module.scss'
import { trigger } from '../../lib/events'
import { useAudioEngine } from '../../hooks/use-audio-engine'

export const ErrorTrack: React.FC = () => {
  const { i18n } = useLingui()
  const audioEngine = useAudioEngine((p) => p.engine)
  const isWaves = useMemo(() => audioEngine === 'wavesjs', [audioEngine])

  const reloadPlayer = useCallback(() => {
    if (isWaves) {
      return window.location.reload()
    }

    return trigger('player:reset-page')
  }, [isWaves])

  return (
    <div className={styles.container}>
      <Image alt="" width="100" height="100" src="/assets/images/warning.svg" />

      <p className={styles.title}>{i18n._(t`error_something_went_wrong`)}</p>

      <Button
        small
        light
        stroke
        transparent
        onClick={reloadPlayer}
        className={styles.button}
        title={i18n._(t`actions.tryagain`)}
      />
    </div>
  )
}
