import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React from 'react'
import classNames from 'classnames'
import styles from './skeleton.module.scss'

interface SkeletonProps {
  isProcessing?: boolean
  smallVersion?: boolean
}

export const Skeleton: React.FC<SkeletonProps> = ({
  isProcessing,
  smallVersion
}) => {
  const { i18n } = useLingui()

  return (
    <div className={styles.container}>
      {isProcessing && (
        <p
          className={classNames(styles.title, {
            [styles.smaller]: smallVersion
          })}
        >
          {i18n._(t`transcribe_message`)}
        </p>
      )}

      <div
        className={classNames(styles.skeleton, {
          [styles.smaller]: smallVersion
        })}
      >
        <span />
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  )
}
