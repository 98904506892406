export default {
  key: 'F#',
  suffix: '69',
  positions: [
    {
      frets: '211122',
      fingers: '211134',
      barres: 1,
      capo: true
    },
    {
      frets: 'xx4344',
      fingers: '002134'
    },
    {
      frets: 'x66676',
      fingers: '011121',
      barres: 6,
      capo: true
    },
    {
      frets: 'x98899',
      fingers: '021134',
      barres: 8,
      capo: true
    }
  ]
}
