import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon, Key } from 'scala'
import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { always } from 'ramda'
import { ShortcutTooltip, ShortcutTooltipWrapper } from '../shortcut-tooltip'
import styles from './control-media.module.scss'
import { MasterVolume } from '../master-volume'
import { ProgressBar } from './progress-bar'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { PlayerApi } from '../../hooks/use-player-methods/use-player-methods'
import { useVolumeMaster } from '../../hooks/use-volume-master'
import { StatePlayer, SettingsPlayer, ControllerPlayer } from '../../types'
import { UseEffects } from '../../hooks/use-effects'
import { useWindowSize } from '../../hooks/misc/use-window-size'

interface ControlsProps {
  player?: PlayerApi
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  effects: UseEffects
}

export const ControlMedia: React.FC<ControlsProps> = ({
  player,
  initialState,
  settings,
  effects,
  controller
}) => {
  const { i18n } = useLingui()
  const { width } = useWindowSize()

  const loading = useAudioEngine((p: any) => !p.state.isReadyToPlay)
  const isPlaying = useAudioEngine((p: any) => p.state.isPlaying)
  const isLooping = useAudioEngine((p: any) => p.state.isLooping)
  const durationMs = useAudioEngine((p: any) => p.state.durationMs)
  const progressMs = useAudioEngine((p: any) => p.state.positionMs)

  const onPlay = useCallback(() => player?.play(), [player])
  const onPause = useCallback(() => player?.pause(), [player])
  const onBackward = useCallback(() => player?.backward(10), [player])
  const onForward = useCallback(() => player?.forward(10), [player])
  const onChangeRange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      player?.seek(parseInt(e.currentTarget.value, 10))
    },
    [player]
  )
  const volumeMaster = useVolumeMaster({ player, initialState, controller })

  const onToggleLoop = useCallback(() => {
    effects.toggleCycle()
  }, [effects])

  const disabledLoop = useMemo(() => loading || width < 640, [width, loading])

  return (
    <div className={styles.container}>
      <div className={styles.buttons}>
        {settings?.volumeMaster && (
          <MasterVolume
            settings={settings}
            loading={volumeMaster.loading || false}
            value={volumeMaster.value || 0}
            onChangeVolume={volumeMaster.onChangeVolume || always}
            onToggleMute={
              volumeMaster.onToggleMute || (always as unknown as () => void)
            }
          />
        )}

        <ShortcutTooltipWrapper enabled={settings?.shortcuts}>
          <button
            type="button"
            className={styles.button}
            disabled={loading}
            onClick={onBackward}
          >
            <Icon name="forward" className={styles.rotate} />
          </button>
          <ShortcutTooltip title={i18n._(t`rewind_sec`)}>
            <Key.Left />
          </ShortcutTooltip>
        </ShortcutTooltipWrapper>

        <ShortcutTooltipWrapper enabled={settings?.shortcuts}>
          <button
            type="button"
            className={styles.button}
            onClick={isPlaying ? onPause : onPlay}
            disabled={loading}
          >
            <Icon name={isPlaying ? 'pause' : 'play'} />
          </button>
          <ShortcutTooltip title={i18n._(t`play_pause`)}>
            <Key.Spacebar />
          </ShortcutTooltip>
        </ShortcutTooltipWrapper>

        <ShortcutTooltipWrapper enabled={settings?.shortcuts}>
          <button
            type="button"
            className={styles.button}
            disabled={loading}
            onClick={onForward}
          >
            <Icon name="forward" />
          </button>
          <ShortcutTooltip title={i18n._(t`fast_forward_sec`)}>
            <Key.Right />
          </ShortcutTooltip>
        </ShortcutTooltipWrapper>

        {settings?.loop && (
          <ShortcutTooltipWrapper enabled={settings?.shortcuts}>
            <button
              type="button"
              className={classnames(styles.button, styles.buttonLoop, {
                [styles.activeLoop]: isLooping
              })}
              disabled={disabledLoop}
              onClick={onToggleLoop}
            >
              <Icon name="play-on-repeat" width={20} />
              {isLooping && <div className={styles.cycleDecoration} />}
            </button>

            <ShortcutTooltip title={i18n._(t`loop_label`)}>
              <Key.C />
            </ShortcutTooltip>
          </ShortcutTooltipWrapper>
        )}
      </div>

      <ProgressBar
        loading={loading}
        progressMs={progressMs || 0}
        durationMs={durationMs || 0}
        onChangeRange={onChangeRange}
      />
    </div>
  )
}
