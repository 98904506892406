export default {
  key: 'C',
  suffix: 'mmaj7',
  positions: [
    {
      frets: 'x3100x',
      fingers: '031000'
    },
    {
      frets: '335443',
      fingers: '114231',
      barres: 3,
      capo: true
    },
    {
      frets: '8a9888',
      fingers: '132111',
      barres: 8,
      capo: true
    },
    {
      frets: 'xxaccb',
      fingers: '001342'
    }
  ]
}
