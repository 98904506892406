import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { useCallback, useContext, useMemo } from 'react'
import { useEffectOnce } from 'react-use'
import { Context } from 'scala'
import { StateOperation } from 'player'
import { definitions } from '../../../../data/operations-defs'
import { TaskConfig } from '../../../../hooks/tasks/use-create-split-task/types'
import { SeparationOption } from '../../../../hooks/use-default-separation'
import { DefineDefaultSeparation } from '../../common/define-default-separation'
import { HeaderSectionUpload } from '../../common/header-section-upload'
import styles from './config-split.module.scss'
import { UseModalPaywall } from '../../../../hooks/event/use-modal-paywall'

interface ConfigSplitProps {
  splitConfig: TaskConfig | null
  onSetSplitConfig(config: TaskConfig): void
  activeDefaultSeparation: boolean
  defaultSeparationEnabled: boolean
  defaultSeparationSelected?: SeparationOption
  onChangeSelectSeparation(key: string): void
  onToggleSetDefaultSeparation(): void
  onOpenModalGetPremium: UseModalPaywall['onOpen']
}

export const ConfigSplit: React.FC<ConfigSplitProps> = ({
  splitConfig,
  onSetSplitConfig,
  activeDefaultSeparation,
  defaultSeparationEnabled,
  defaultSeparationSelected,
  onChangeSelectSeparation,
  onToggleSetDefaultSeparation,
  onOpenModalGetPremium
}) => {
  const { i18n } = useLingui()
  const { user } = useContext(Context)

  const onOpenModalPremium = useCallback(
    (source: string) => {
      onOpenModalGetPremium('lock-separate-premium', false, source)
    },
    [onOpenModalGetPremium]
  )

  const onOpenModalPro = useCallback(
    (source: string) => {
      onOpenModalGetPremium('lock-separate-pro', false, source)
    },
    [onOpenModalGetPremium]
  )

  const onOpenModalSpecialized = useCallback(
    (source: string) => {
      onOpenModalGetPremium('lock-separate-specialized', false, source)
    },
    [onOpenModalGetPremium]
  )

  useEffectOnce(() => {
    onSetSplitConfig(
      definitions[
        defaultSeparationSelected?.key || user?.subscription?.isPro
          ? 'SEPARATE_vocals-drums-bass-other-hifi'
          : 'SEPARATE_vocals-drums-bass-other'
      ]
    )
  })

  const handleSelectOption = useCallback(
    (key: any) => () => {
      onSetSplitConfig(definitions[key])
      onChangeSelectSeparation(key)
    },
    [onChangeSelectSeparation, onSetSplitConfig]
  )

  const isPremium = useMemo(() => user?.subscription?.isPremium, [user])
  const isPro = useMemo(() => user?.subscription?.isPro, [user])

  const items = useMemo(() => {
    return [
      {
        title: i18n._(t`separation.options.4tracks.title`),
        description: i18n._(t`separation.options.4tracks`),
        type: 'vocals-drums-bass-other',
        onClick: handleSelectOption('SEPARATE_vocals-drums-bass-other')
      },
      {
        title: i18n._(t`separation.options.4tracks.title`),
        description: i18n._(t`separation.options.4tracks`),
        type: 'vocals-drums-bass-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(null, 'paywall-stems-vocalsDrumsBassOther-hifi')
          : handleSelectOption('SEPARATE_vocals-drums-bass-other-hifi')
      },
      {
        title: i18n._(t`separation.options.2tracks.title`),
        description: i18n._(t`separation.options.2tracks`),
        type: 'vocals-accompaniment',
        onClick: handleSelectOption('SEPARATE_vocals-accompaniment')
      },
      {
        title: i18n._(t`separation.options.2tracks.title`),
        description: i18n._(t`separation.options.2tracks`),
        type: 'vocals-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(null, 'paywall-stems-vocalsAccompaniment-hifi')
          : handleSelectOption('SEPARATE_vocals-other-hifi')
      },
      {
        title: i18n._(t`separation.options.3tracks.title`),
        description: i18n._(t`separation.options.3tracks`),
        type: 'vocals-backing_vocals-accompaniment',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsBackingVocalsAccompaniment'
            )
          : handleSelectOption('SEPARATE_vocals-backing_vocals-accompaniment')
      },
      {
        title: i18n._(t`separation.options.3tracks.title`),
        description: i18n._(t`separation.options.3tracks`),
        type: 'vocals-backing_vocals-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsBackingVocalsAccompaniment-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-backing_vocals-other-hifi')
      },
      {
        title: i18n._(t`separation.options.5tracks.guitar.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-guitars-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassGuitarsOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-guitars-other')
      },
      {
        title: i18n._(t`separation.options.5tracks.guitar.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-guitars-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassGuitarsOther-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-guitars-other-hifi')
      },
      {
        title: i18n._(t`separation_options_guitars`),
        description: i18n._(t`separation.options.6tracks`),
        type: 'vocals-drums-bass-lead-rhythm-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassLeadRhythmOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-lead-rhythm-other')
      },
      {
        title: i18n._(t`separation_options_guitars`),
        description: i18n._(t`separation.options.6tracks`),
        type: 'vocals-drums-bass-lead-rhythm-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassLeadRhythmOther-hifi'
            )
          : handleSelectOption(
              'SEPARATE_vocals-drums-bass-lead-rhythm-other-hifi'
            )
      },
      {
        title: i18n._(t`separation_options_eletricguitar_title`),
        description: i18n._(t`separation.options.6tracks`),
        type: 'vocals-drums-bass-acoustic-electric-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassAcousticGuitarElectricGuitarOther'
            )
          : handleSelectOption(
              'SEPARATE_vocals-drums-bass-acoustic-electric-other'
            )
      },
      {
        title: i18n._(t`separation_options_eletricguitar_title`),
        description: i18n._(t`separation.options.6tracks`),
        type: 'vocals-drums-bass-acoustic-electric-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassAcousticGuitarElectricGuitarOther-hifi'
            )
          : handleSelectOption(
              'SEPARATE_vocals-drums-bass-acoustic-electric-other-hifi'
            )
      },
      {
        title: i18n._(t`separation.options.5tracks.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-piano-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassPianoOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-piano-other')
      },
      {
        title: i18n._(t`separation.options.5tracks.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-piano-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassPianoOther-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-piano-other-hifi')
      },
      {
        title: i18n._(t`separations_options_keys`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-keys-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassKeysOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-keys-other')
      },
      {
        title: i18n._(t`separations_options_keys`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-keys-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassKeysOther-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-keys-other-hifi')
      },
      {
        title: i18n._(t`separation.tracks.5`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-strings-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassStringsOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-strings-other')
      },
      {
        title: i18n._(t`separation.tracks.5`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-strings-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassStringsOther-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-strings-other-hifi')
      },
      {
        title: i18n._(t`separation.options.wind.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-wind-other',
        locked: !isPremium,
        onClick: !isPremium
          ? onOpenModalPremium.bind(
              null,
              'paywall-stems-vocalsDrumsBassWindOther'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-wind-other')
      },
      {
        title: i18n._(t`separation.options.wind.title`),
        description: i18n._(t`separation.options.5tracks`),
        type: 'vocals-drums-bass-wind-other-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalPro.bind(
              null,
              'paywall-stems-vocalsDrumsBassWindOther-hifi'
            )
          : handleSelectOption('SEPARATE_vocals-drums-bass-wind-other-hifi')
      },
      {
        title: i18n._(t`separation_options_alldrums_title`),
        description: i18n._(t`separation_options_7tracks`),
        type: 'kick-snare-toms-hat-cymbals-other_drums-other_kit-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalSpecialized.bind(
              null,
              'paywall-stems-DrumsPartsOther-hifi'
            )
          : handleSelectOption(
              'SEPARATE_kick-snare-toms-hat-cymbals-other_drums-other_kit-hifi'
            )
      },
      {
        title: i18n._(t`separation_options_cinematic`),
        description: i18n._(t`separation.options.3tracks`),
        type: 'dialogue-music-effects-hifi',
        hifi: true,
        locked: !isPro,
        disabled: false,
        onClick: !isPro
          ? onOpenModalSpecialized.bind(null, 'paywall-stems-Cinematic-hifi')
          : handleSelectOption('SEPARATE_dialogue-music-effects-hifi')
      }
    ]
  }, [
    i18n,
    isPro,
    isPremium,
    onOpenModalPro,
    onOpenModalPremium,
    onOpenModalSpecialized,
    handleSelectOption
  ])

  return (
    <div className={styles.container}>
      <HeaderSectionUpload
        className={styles.header}
        title={i18n._(t`upload.separation.type.title`)}
      />

      <div className={styles.buttons}>
        {items.map((item) => (
          <button
            key={item.type}
            id={item.type}
            type="button"
            className={classnames(styles.button, {
              [styles.active]: splitConfig?.params.type === item.type,
              [styles.disabled]: item.disabled
            })}
            onClick={item.onClick}
          >
            <div className={styles.content}>
              <p>{item.title}</p>
              <small>{item.description}</small>

              {item.locked || item.hifi ? (
                <StateOperation
                  hifi={item.hifi}
                  locked={item.locked}
                  className={styles.lock}
                />
              ) : null}
            </div>
          </button>
        ))}

        {defaultSeparationEnabled && (
          <DefineDefaultSeparation
            active={activeDefaultSeparation}
            onToggleSetDefaultSeparation={onToggleSetDefaultSeparation}
          />
        )}
      </div>
    </div>
  )
}
