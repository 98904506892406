import { create } from 'zustand'

interface PanelStore {
  isOpen(): boolean
  showLyrics: boolean
  showChords: boolean
  typeChords: string | null
  showEditChords: boolean
  showEditLyrics: boolean
  onShowLyrics(value: boolean): void
  onShowChords(value: boolean): void
  setTypeChords(value: string): void
  setShowEditChords(value: boolean): void
  setShowEditLyrics(value: boolean): void
  close(): void
}

export const usePanel = create<PanelStore>((set, get) => ({
  isOpen: () => get().showLyrics || get().showChords,
  // Common state
  showLyrics: false,
  showChords: false,
  typeChords: null,
  showEditChords: false,
  showEditLyrics: false,
  // Generic actions
  onShowLyrics: (value: boolean) => set({ showLyrics: value }),
  onShowChords: (value: boolean) => set({ showChords: value }),
  setTypeChords: (value: string) => set({ typeChords: value }),
  setShowEditChords: (value: boolean) => set({ showEditChords: value }),
  setShowEditLyrics: (value: boolean) => set({ showEditLyrics: value }),
  close: () =>
    set({
      showLyrics: false,
      showChords: false,
      showEditChords: false,
      showEditLyrics: false,
      typeChords: null
    })
}))
