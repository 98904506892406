/* eslint-disable camelcase */
import { useEffect, useMemo, useState } from 'react'
import { UseChords as Chords } from './types'
import { ChordsDiagram, getDiagram } from './utils'
import { Transpose, useTransposeKeyChord } from './use-simplify-chords'

interface UseChords {
  previous: ChordsItem
  current: ChordsItem
  next: ChordsItem
}

interface ChordsItem {
  key: string
  transposed?: string
  bass?: string | null
  diagram?: ChordsDiagram | null
}

interface ChordInfo {
  key: string
  curr_beat_time: number
}

export const getClosestTime = (list: number[], target: number): number =>
  list.reduce((prev: number, curr: number) =>
    Math.abs(curr - target) < Math.abs(prev - target) ? curr : prev
  )

export const generateDiagram = (fullChord: any): any => {
  // regex to get first char of string and
  // ignore the rest if is't not a # char or b char or @ char
  const key = fullChord?.replace(/^(.)(b|#)?(.*)$/, '$1$2')
  let suffix = fullChord?.replace(/^(.)(b|#)?(.*)$/, '$3')
  suffix =
    suffix === ':min' || suffix === 'm'
      ? 'minor'
      : suffix === ':maj'
      ? 'major'
      : suffix
  const diagram = getDiagram(key, suffix)
  return diagram
}

export const getKey = (item: any, type1: string): string => {
  return (item && item[type1]) || ''
}

export const formatTransposed = (value: string): string => {
  const key = value.replace(/^(.)(b|#)?(.*)$/, '$1$2')
  let suffix = value?.replace(/^(.)(b|#)?(.*)$/, '$3')
  suffix =
    suffix === ':min' || suffix === 'm' ? 'm' : suffix === ':maj' ? '' : suffix
  return `${key}${suffix}`
}

const getBass = (item: any): string | null => {
  return (item && item?.bass) || null
}

const getAllChords = (
  chords: Chords,
  type: string,
  songKey: string | undefined,
  pitch: number,
  transpose: (value: Transpose) => string
): ChordInfo[] => {
  const filterChords = chords.filter((item, index, array) => {
    return (
      index === 0 ||
      item.prev_chord !== array[index - 1].prev_chord ||
      item.bass !== array[index - 1].bass
    )
  })

  return filterChords.map((chord) => {
    const key = getKey(chord, type)
    const bass = getBass(chord)

    return {
      key,
      curr_beat_time: chord.curr_beat_time,
      transposed: transpose({
        currentKey: songKey,
        currentChord: key,
        pitch
      }),
      bass: bass
        ? transpose({
            currentKey: songKey,
            currentChord: bass,
            pitch
          })
        : null
    }
  })
}

const getKeyByIndex = (chords: ChordInfo[], indexTime: number): ChordsItem => {
  const result = chords[indexTime]
  return result || { key: '' }
}

const getChord = (chords: ChordInfo[], index: number): ChordsItem => {
  const chord = getKeyByIndex(chords, index)
  chord.diagram = generateDiagram(chord?.transposed)
  chord.transposed = formatTransposed(chord?.transposed || '')
  return chord
}

export const useChords = ({
  chords,
  loading,
  error,
  songKey,
  pitch,
  audioTime,
  shouldLock,
  type = 'prev_chord'
}: {
  chords: Chords
  loading: boolean
  error: boolean
  songKey?: string
  pitch: number
  audioTime: number
  shouldLock: boolean
  type?: string
}): UseChords => {
  const [allChords, setAllChords] = useState<ChordInfo[]>([])
  const { transpose } = useTransposeKeyChord()

  useEffect(() => {
    const chordsLoaded = getAllChords(chords, type, songKey, pitch, transpose)
    setAllChords(chordsLoaded)
  }, [chords, type, songKey, pitch, transpose])

  const isEmpty = useMemo(
    () => loading || error || !allChords.length,
    [allChords, loading, error]
  )

  const limit = 60

  const time = useMemo(
    () => (shouldLock && audioTime > limit ? limit : audioTime),
    [shouldLock, audioTime]
  )

  if (isEmpty) {
    const empty = { key: '', transposed: '' }
    return {
      previous: empty,
      current: empty,
      next: empty
    }
  }

  const closestTimeIndex = allChords?.findIndex((t, index) => {
    const isLastItem = index === allChords.length - 1
    const nextTime = isLastItem ? Infinity : allChords[index + 1].curr_beat_time
    return t.curr_beat_time >= time || nextTime > time
  })

  const previousIndex = closestTimeIndex > 0 ? closestTimeIndex - 1 : 0
  const nextIndex =
    closestTimeIndex < allChords?.length - 1 ? closestTimeIndex + 1 : null

  const current = getChord(allChords, closestTimeIndex)
  const next = nextIndex ? getChord(allChords, nextIndex) : { key: '' }
  const previous = closestTimeIndex
    ? getChord(allChords, previousIndex)
    : { key: 'N', transposed: '•' }

  return {
    previous,
    current,
    next
  }
}
