import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCallback } from 'react'

interface UseSeparationType {
  getTitle(n: number): string
  getDescription(operation: string): string
}

/**
 * deprecated
 * TODO: remove this hook
 */
export const useSeparationType = (): UseSeparationType => {
  const { i18n } = useLingui()

  const getTitle = useCallback(
    (n: number) => {
      const titles: { [key: number]: string } = {
        1: i18n._(t`separation.options.1track`),
        2: i18n._(t`separation.options.2tracks`),
        3: i18n._(t`separation.options.3tracks`),
        4: i18n._(t`separation.options.4tracks`),
        5: i18n._(t`separation.options.5tracks`),
        6: i18n._(t`separation.options.6tracks`),
        7: i18n._(t`separation.options.7tracks`),
        8: i18n._(t`separation.options.8tracks`),
        9: i18n._(t`separation.options.9tracks`),
        10: i18n._(t`separation.options.10tracks`),
        11: i18n._(t`separation.options.11tracks`),
        12: i18n._(t`separation.options.12tracks`),
        13: i18n._(t`separation.options.13tracks`),
        14: i18n._(t`separation.options.14tracks`),
        15: i18n._(t`separation.options.15tracks`),
        16: i18n._(t`separation.options.16tracks`),
        17: i18n._(t`separation.options.17tracks`),
        18: i18n._(t`separation.options.18tracks`),
        19: i18n._(t`separation.options.19tracks`),
        20: i18n._(t`separation.options.20tracks`)
      }

      return titles[n] || `${n} tracks`
    },
    [i18n]
  )

  const getDescription = useCallback(
    (operation: string) => {
      let description

      switch (operation) {
        case '1-drumless':
          description = i18n._(t`mixer.drumless`)
          break
        case '1-otherless':
          description = i18n._(t`mixer.otherless`)
          break
        case '1-bassless':
          description = i18n._(t`mixer.bassless`)
          break
        case 'bass-other':
          description = i18n._(t`separation.options.2tracks.bass`)
          break
        case 'drums-other':
          description = i18n._(t`separation.options.2tracks.drums`)
          break
        case 'guitar-other':
          description = i18n._(t`separation.options.2tracks.guitar.title`)
          break
        case 'vocals-accompaniment':
          description = i18n._(t`separation.options.2tracks.title`)
          break
        case 'vocals-backing_vocals-accompaniment':
          description = i18n._(t`separation.options.3tracks.title`)
          break
        case 'vocals-drums-bass-other':
          description = i18n._(t`separation.options.4tracks.title`)
          break
        case 'vocals-drums-bass-piano-other':
          description = i18n._(t`separation.options.5tracks.title`)
          break
        case 'vocals-drums-bass-guitars-other':
          description = i18n._(t`separation.options.5tracks.guitar.title`)
          break
        case 'vocals-drums-bass-backing_vocals-guitar-other':
          description = 'Vocals, Drums, Bass, Background vocals, Guitar, Other'
          break
        case 'vocals-backing_vocals-drums-bass-guitar-other':
          description = 'Vocals, Drums, Bass, Background vocals, Guitar, Other'
          break
        default:
          description = operation.replace(/_/g, ' ').split('-').join(', ')
          break
      }

      return description
    },
    [i18n]
  )

  return {
    getTitle,
    getDescription
  }
}
