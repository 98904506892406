export default {
  key: 'Ab',
  suffix: '9',
  positions: [
    {
      frets: '43434x',
      fingers: '213140',
      barres: 3,
      capo: true
    },
    {
      frets: '464546',
      fingers: '131214',
      barres: 4,
      capo: true
    },
    {
      frets: 'xx6576',
      fingers: '002143'
    },
    {
      frets: 'bbabbb',
      fingers: '221334',
      barres: 11
    }
  ]
}
