export default {
  key: 'F#',
  suffix: 'm69',
  positions: [
    {
      frets: '201122',
      fingers: '201134',
      barres: 1
    },
    {
      frets: '244244',
      fingers: '122134',
      barres: 4
    },
    {
      frets: '544644',
      fingers: '211311',
      barres: 4,
      capo: true
    },
    {
      frets: 'x9789x',
      fingers: '031240'
    }
  ]
}
