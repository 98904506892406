import { Playlist } from '../../../types'

type CustomAttr = {
  [key: string]: any
}

interface PlaylistWithOwner extends Playlist {
  isOwner: boolean
}

export const getCustomAttributesPlaylist = (
  playlist?: PlaylistWithOwner,
  events?: CustomAttr
): CustomAttr => ({
  number_of_media_in_playlist: playlist?.tracks?.totalCount,
  public_status: playlist?.isShared ? 'public' : 'private',
  shared_toggle: playlist?.isShared,
  joined_guests: playlist?.guests?.totalCount,
  user_type: playlist?.isOwner ? 'owner' : 'guest',
  allow_edit: playlist?.isShared && !playlist?.viewOnly,
  access_role: playlist?.isOwner
    ? 'owner'
    : !playlist?.viewOnly
    ? 'editor'
    : 'viewer',
  ...events
})
