import classnames from 'classnames'
import Image from 'next/image'
import { SplitLoader } from '../split-loader'
import styles from './split-loader-with-message.module.scss'

interface SplitLoaderWithMessageParams {
  message: string
  className?: string
  isFailed?: boolean
  style?: React.CSSProperties
}

export const SplitLoaderWithMessage: React.FC<SplitLoaderWithMessageParams> = ({
  message,
  className,
  isFailed,
  style
}) => {
  return (
    <div className={classnames(className, styles.container)} style={style}>
      <p className={styles.message}>{message}</p>
      {isFailed ? (
        <Image
          alt={message}
          width="64"
          height="64"
          src="/assets/images/warning.svg"
        />
      ) : (
        <SplitLoader />
      )}
    </div>
  )
}
