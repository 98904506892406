import { useCallback, useState } from 'react'
import { pixelsToSeconds } from './utils'

interface UseTimelineInteractions {
  isInteracting: boolean
  isCreating: boolean
  isMovingArea: boolean
  isResizeLeft: boolean
  isResizeRight: boolean
  events: {
    onMouseUp(e: any): void
    onMouseDown(e: any): void
    onMouseMove(e: any): void
    onMouseLeave(e: any): void
  }
}

export type ChangeCycle = (
  range: [number, number],
  eventType?: 'creating' | 'resizing' | 'moving' | 'clearing'
) => void

interface UseTimelineInteractionsProps {
  range: [number, number]
  duration: number
  containerRef: any
  onSeekTo?(seconds: number): void
  onChangeCycle?: ChangeCycle
}

export const useTimelineInteractions = ({
  range,
  duration,
  containerRef,
  onSeekTo,
  onChangeCycle
}: UseTimelineInteractionsProps): UseTimelineInteractions => {
  const [isInteracting, setIsInteracting] = useState<boolean>(false)
  const [targetClick, setTargetClick] = useState<string | null>(null)
  const [startX, setStartX] = useState<any>(null)

  const [isCreating, setIsCreating] = useState(false)
  const [isMovingArea, setIsMoving] = useState(false)
  const [isResizeLeft, setIsResizeLeft] = useState(false)
  const [isResizeRight, setIsResizeRight] = useState(false)

  const defineAreaClick = useCallback((clickTarget: string) => {
    setTargetClick(clickTarget)

    switch (clickTarget) {
      case 'resize-right':
        setIsResizeRight(true)
        break
      case 'resize-left':
        setIsResizeLeft(true)
        break
      case 'header-moving':
        setIsMoving(true)
        break
      case 'moving':
        setIsMoving(true)
        break
      default:
        setIsCreating(true)
    }
  }, [])

  const handleCreateArea = useCallback(
    (x: number) => {
      const minX = Math.min(x, startX || 0)
      const maxX = Math.max(x, startX || 0)

      if (onChangeCycle) {
        onChangeCycle([minX, maxX], 'creating')
      }
    },
    [startX, onChangeCycle]
  )

  const handleResizeRight = useCallback(
    (x: number) => {
      const newMaxX = x + startX

      if (onChangeCycle) {
        onChangeCycle([range[0], newMaxX], 'resizing')
      }
    },
    [startX, range, onChangeCycle]
  )

  const handleResizeLeft = useCallback(
    (x: number) => {
      const newMinX = x + startX

      if (onChangeCycle) {
        onChangeCycle([newMinX, range[1]], 'resizing')
      }
    },
    [startX, range, onChangeCycle]
  )

  const handleMovingArea = useCallback(
    (x: number) => {
      const samplesPerPixel =
        parseInt(
          window.getComputedStyle(containerRef.current).width.replace('px', ''),
          10
        ) - 20
      const startXParent = startX + range[0]
      const newPosition = x - startXParent
      const initTimeline = range[0] + newPosition < 0
      const finalTimeline = range[1] + newPosition > samplesPerPixel

      const newMinX =
        initTimeline || finalTimeline ? range[0] : range[0] + newPosition
      const newMaxX =
        initTimeline || finalTimeline ? range[1] : range[1] + newPosition

      if (onChangeCycle) {
        onChangeCycle([newMinX, newMaxX], 'moving')
      }
    },
    [startX, range, containerRef, onChangeCycle]
  )

  const emitSelection = useCallback(
    (x: number) => {
      if (isMovingArea) {
        handleMovingArea(x)
      } else if (isResizeLeft) {
        handleResizeLeft(x)
      } else if (isResizeRight) {
        handleResizeRight(x)
      } else {
        handleCreateArea(x)
      }
    },
    [
      isMovingArea,
      isResizeLeft,
      isResizeRight,
      handleCreateArea,
      handleMovingArea,
      handleResizeLeft,
      handleResizeRight
    ]
  )

  const complete = useCallback(
    (x: number, doubleClick: boolean) => {
      const xPosition = x
      let startXPosition = startX

      if (isMovingArea) {
        startXPosition = startX + range[0]
      }

      emitSelection(xPosition)
      setIsInteracting(false)
      setIsCreating(false)
      setIsMoving(false)
      setIsResizeLeft(false)
      setIsResizeRight(false)

      const minX = Math.min(xPosition, startXPosition || 0)
      const maxX = Math.max(xPosition, startXPosition || 0)

      const samplesPerPixel = parseInt(
        window.getComputedStyle(containerRef.current).width.replace('px', ''),
        10
      )
      const fromSeconds = pixelsToSeconds(minX, samplesPerPixel, duration)
      const toSeconds = pixelsToSeconds(maxX, samplesPerPixel, duration)
      const totalSeconds = Math.round((toSeconds - fromSeconds) / 1000)

      if (isCreating && minX !== maxX && totalSeconds <= 2) {
        if (onChangeCycle) {
          onChangeCycle([0, 0], 'clearing')
        }
      }

      if (onSeekTo && minX === maxX && !isMovingArea) {
        onSeekTo(fromSeconds)
      }

      if (doubleClick && targetClick === 'header-moving') {
        if (onChangeCycle) {
          onChangeCycle([0, 0], 'clearing')
        }
      }

      setTargetClick(null)
    },
    [
      targetClick,
      startX,
      isMovingArea,
      isCreating,
      range,
      duration,
      containerRef,
      onChangeCycle,
      onSeekTo,
      emitSelection
    ]
  )

  const onMouseUp = useCallback(
    (e: any) => {
      if (isInteracting) {
        e.preventDefault()
        complete(e.nativeEvent.offsetX, e.detail === 2)
      }
    },
    [isInteracting, complete]
  )

  const onMouseDown = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()

      defineAreaClick(e.currentTarget.getAttribute('data-area-click'))
      setIsInteracting(true)
      setStartX(e.nativeEvent.offsetX)
    },
    [defineAreaClick]
  )

  const onMouseMove = useCallback(
    (e: any) => {
      if (isInteracting) {
        e.preventDefault()
        emitSelection(e.nativeEvent.offsetX)
      }
    },
    [isInteracting, emitSelection]
  )

  const onMouseLeave = useCallback(
    (e: any) => {
      if (isInteracting) {
        e.preventDefault()
        complete(e.nativeEvent.offsetX, e.detail === 2)
      }
    },
    [isInteracting, complete]
  )

  return {
    isInteracting,
    isCreating,
    isMovingArea,
    isResizeLeft,
    isResizeRight,
    events: {
      onMouseUp,
      onMouseDown,
      onMouseMove,
      onMouseLeave
    }
  }
}
