export default {
  key: 'F',
  suffix: '/D',
  positions: [
    {
      frets: 'x57565',
      fingers: '013121',
      barres: 5
    },
    {
      frets: 'x57568',
      fingers: '013124'
    }
  ]
}
