import { create } from 'zustand'

type RangeValues = [number, number]

interface UseStoreTimeline {
  rangeMs: RangeValues
  setRangeMs(range: RangeValues): void
  clearStore(): void
}

export const useStoreTimeline = create<UseStoreTimeline>((set) => ({
  rangeMs: [0, 0],
  setRangeMs: (newRange) => set(() => ({ rangeMs: newRange })),
  clearStore: () => set(() => ({ rangeMs: [0, 0] }))
}))
