import classnames from 'classnames'
import React, { useCallback } from 'react'
import styles from './controls.module.scss'

interface ControlsProps {
  className?: string
  isSolo: boolean
  isMuted: boolean
  isSilentMuted: boolean
  onSolo(value: boolean): void
  onMute(value: boolean): void
}

export const Controls: React.FC<ControlsProps> = ({
  className,
  isSolo,
  isMuted,
  isSilentMuted,
  onSolo,
  onMute
}) => {
  const handleMute = useCallback(() => {
    onMute(!isMuted)
  }, [isMuted, onMute])

  const handleSolo = useCallback(() => {
    onSolo(!isSolo)
  }, [isSolo, onSolo])

  return (
    <div className={classnames(className, styles.container)}>
      <button
        type="button"
        onClick={handleMute}
        className={classnames(styles.button, styles.buttonMute, {
          [styles.isMuted]: isMuted,
          [styles.isSilentMuted]: isSilentMuted
        })}
      >
        <svg
          width="9"
          height="7"
          viewBox="0 0 8 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.82 0H0V7H1.35V2L3.17 6.41H4.48L6.3 2V7H7.65V0H5.83L3.82 4.77L1.82 0Z"
            fill="currentColor"
          />
        </svg>
      </button>

      <button
        type="button"
        onClick={handleSolo}
        className={classnames(styles.button, styles.buttonSolo, {
          [styles.active]: isSolo
        })}
      >
        <svg
          width="5"
          height="8"
          viewBox="0 0 5 7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.41 0C1.07 0 0.21 0.81 0.21 1.99C0.21 4.23 3.23 4.03 3.23 5.23C3.23 5.68 2.92 5.95 2.4 5.95C1.77 5.95 1.38 5.52 1.19 5.06L0 5.64C0.2 6.21 0.82 7.26 2.32 7.26C3.78 7.26 4.66 6.36 4.66 5.13C4.66 2.96 1.62 3.13 1.62 1.97C1.62 1.59 1.9 1.3 2.38 1.3C2.99 1.3 3.25 1.73 3.4 2.06L4.57 1.53C4.4 1.02 3.82 0 2.41 0Z"
            fill="currentColor"
          />
        </svg>
      </button>
    </div>
  )
}
