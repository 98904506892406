import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUnmount } from 'react-use'
import { shallow } from 'zustand/shallow'
import styles from './styles.module.scss'
import {
  ChannelPlayer,
  ControllerPlayer,
  DataPlayer,
  StatePlayer,
  SettingsPlayer,
  FeaturesPlayer
} from '../../types'
import { Header } from '../../components/header'
import { Footer } from '../../components/footer/footer'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { usePlayerApiMethods } from '../../hooks/use-player-methods/use-player-methods'
import { Multitrack } from '../multitrack'
import { useEffects } from '../../hooks/use-effects'
import { LyricsButton } from '../../components/lyrics-button'
import { Panel } from '../panel'
import { PanelAside } from '../panel-aside'
import { SectionsButton } from '../../components/sections-button'
import { usePersistState } from '../../hooks/use-persist-state'
import { ChordsButton } from '../chords-button'
import { useCountIn } from '../../hooks/use-count-in'
import { useControlPlayer } from '../../hooks/use-control-player'
import { Singletrack } from '../singletrack'
import { useControlProgress } from '../../hooks/use-control-progress'
import { ErrorTrack } from '../../components/error-track'

interface Props {
  data?: DataPlayer
  initialState?: StatePlayer
  settings?: SettingsPlayer
  channels?: ChannelPlayer[]
  controller?: ControllerPlayer
  features?: FeaturesPlayer
}

export const Player: React.FC<Props> = ({
  data,
  initialState,
  settings,
  channels,
  controller,
  features
}) => {
  const [errorRequest, setErrorRequest] = useState(false)
  const playerApi = usePlayerApiMethods({ controller })
  const audioEngine = useAudioEngine((p) => p.api)
  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)

  const effects = useEffects({
    data,
    initialState,
    settings,
    controller,
    playerApi
  })

  const countIn = useCountIn({
    initialState,
    settings,
    controller,
    bpm: effects?.songTempo?.value
  })

  useEffect(() => {
    if (countIn.enabled) {
      return effects.setCountInEnabled(true)
    }
    return effects.setCountInEnabled(false)
    // eslint-disable-next-line
  }, [countIn.enabled])

  usePersistState({ controller, countIn, effects })
  useControlProgress({ settings, controller })
  useControlPlayer({ playerApi, effects, settings, countIn, controller })

  const metronomeChannels = useAudioEngine(
    (p: any) =>
      Object.values(p.channels).filter(
        (channel: any) => channel.id && channel.id.includes('metronome')
      ),
    shallow
  )

  const loadMetronomes = useCallback(() => {
    const channelsWithState = channels
      ?.filter((channel) => !!channel?.url)
      ?.map((channel) => {
        const state = initialState?.channels?.find(
          (channelState) => channelState.id === channel.id
        ) || {
          volume: 0.75,
          isMuted: channel?.id?.includes('metronome')
        }

        return { ...channel, ...state }
      })

    if (channelsWithState) {
      audioEngine.loadChannels(channelsWithState)
    }
  }, [audioEngine, channels, initialState])

  const defined = useRef(false)

  const loadInstruments = useCallback(() => {
    defined.current = true

    const channelsWithState = channels
      ?.filter((channel) => !!channel?.url)
      ?.filter((channel) => !channel.id.includes('metronome'))
      .map((channel) => {
        const state = initialState?.channels?.find(
          (channelState) => channelState.id === channel.id
        ) || {
          volume: 0.75
        }
        return { ...channel, ...state }
      })

    if (channelsWithState) {
      audioEngine
        .loadChannels(channelsWithState)
        .catch(() => setErrorRequest(true))
    }
  }, [audioEngine, channels, initialState])

  useEffect(() => {
    if (metronomeChannels.length) {
      return
    }

    const hasChannelValid = channels?.some(({ url }) => url)
    const hasMetronomeValid = channels?.some(
      ({ id, url }) => id?.includes('metronome') && url
    )

    if (!loading && hasMetronomeValid && channels) {
      loadMetronomes()
    }

    if (channels && hasChannelValid && !defined.current) {
      loadInstruments()
    }
  }, [
    channels,
    data,
    loading,
    metronomeChannels,
    loadInstruments,
    loadMetronomes
  ])

  useEffect(() => {
    if (!data?.beatMapUrl) {
      return
    }

    audioEngine.loadBeatMap(data.beatMapUrl)
  }, [audioEngine, data?.beatMapUrl])

  const refTimeLoading = useRef<any>(null)

  useEffect(() => {
    if (loading) {
      refTimeLoading.current = Date.now()
    } else {
      if (!refTimeLoading.current) return
      const loadedTime = Date.now()
      // eslint-disable-next-line no-console
      console.log(
        `player loaded: ${(loadedTime - refTimeLoading.current) / 1000} seconds`
      )
      refTimeLoading.current = null
    }
  }, [loading])

  useUnmount(() => audioEngine?.destroy())

  const isSucceeded = useMemo(
    () => data?.status === 'succeeded',
    [data?.status]
  )

  useEffect(() => {
    if (isSucceeded) {
      const timeout = setTimeout(() => {
        if (loading) {
          setErrorRequest(true)
        }
      }, 5 * 60 * 1000)

      return () => clearTimeout(timeout)
    }
    return () => {}
  }, [loading, isSucceeded])

  if (errorRequest) {
    return (
      <div className={styles.container}>
        <div className={styles.content}>
          <ErrorTrack />
        </div>
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Header
        data={data}
        settings={settings}
        controller={controller}
        effects={effects}
        countIn={countIn}
        features={features}
      />

      <div className={styles.content}>
        <div className={styles.module}>
          {settings?.singletrack ? (
            <Singletrack
              type={settings?.singletrack}
              channels={channels}
              playerApi={playerApi}
              settings={settings}
            />
          ) : (
            <Multitrack
              data={data}
              channels={channels}
              playerApi={playerApi}
              settings={settings}
              initialState={initialState}
              controller={controller}
            />
          )}
        </div>

        <PanelAside
          data={data}
          settings={settings}
          controller={controller}
          effects={effects}
        />
      </div>

      <Panel
        data={data}
        settings={settings}
        initialState={initialState}
        controller={controller}
        effects={effects}
        large={!!settings?.singletrack}
      />

      <Footer
        player={playerApi}
        initialState={initialState}
        settings={settings}
        effects={effects}
        controller={controller}
      >
        {settings?.lyrics?.view && (
          <LyricsButton
            disabled={loading || data?.lyrics?.blocked}
            initialState={initialState}
            controller={controller}
            isDialogue={settings?.lyrics?.isDialogue}
          />
        )}

        {settings?.chords?.view && (
          <ChordsButton
            data={data}
            disabled={loading}
            settings={settings}
            initialState={initialState}
            effects={effects}
            controller={controller}
            onLockedClick={controller?.onLockedFeatureClick}
          />
        )}

        {settings?.sections?.view && (
          <SectionsButton
            controller={controller}
            initialState={initialState}
            disabled={loading || data?.sections?.blocked}
          />
        )}
      </Footer>
    </div>
  )
}
