import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import {
  ChangeCycle,
  useTimelineInteractions
} from '../../hooks/use-timeline-interactions'
import { fmtMSS } from '../../utils/utils'
import styles from './timeline-cycle.module.scss'
import { useStoreTimeline } from '../../data/timeline-store'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import {
  convertRangeMsToRange,
  convertRangeToRangeMs,
  getSamplesPerPixel
} from './utils'
import { moveRangeMsToNearestBeats } from '../../utils/beats'

interface CycleProps {
  duration?: number
  isLooping?: boolean
  onSeekTo?(seconds: number): void
  onChangeCycle?(range: [number, number]): void
}

export const TimelineCycle: React.FC<CycleProps> = ({
  duration = 0,
  isLooping,
  onSeekTo,
  onChangeCycle
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null)
  const rangeMs = useStoreTimeline((state) => state.rangeMs)
  const beatMap = useAudioEngine((p) => p.beatMap)
  const downbeatMap = useMemo(
    () => beatMap?.filter((i) => i.beatNum === 4) || null,
    [beatMap]
  )

  const range = useMemo(() => {
    const samplesPerPixel = getSamplesPerPixel(containerRef.current)
    return convertRangeMsToRange(rangeMs, duration, samplesPerPixel)
  }, [duration, containerRef, rangeMs])

  const setRange = useCallback<ChangeCycle>(
    (newRange, eventType): void => {
      if (eventType === 'clearing') {
        onChangeCycle?.([0, 0])
      }

      const samplesPerPixel = getSamplesPerPixel(containerRef.current)
      const newRangeMs = convertRangeToRangeMs(
        newRange,
        duration,
        samplesPerPixel
      )
      const fixedRangeMs = moveRangeMsToNearestBeats(newRangeMs, downbeatMap, {
        maxDistance: 2000,
        leftOnly: eventType === 'moving'
      })
      onChangeCycle?.(fixedRangeMs)
    },
    [downbeatMap, duration, onChangeCycle]
  )

  const {
    isInteracting,
    isCreating,
    isMovingArea,
    isResizeLeft,
    isResizeRight,
    events
  } = useTimelineInteractions({
    range,
    duration,
    onSeekTo,
    onChangeCycle: setRange,
    containerRef
  })

  return (
    <div
      ref={containerRef}
      className={classnames(styles.container, {
        [styles.isCreating]: isCreating,
        [styles.isMoving]: isMovingArea,
        [styles.isResizeLeft]: isResizeLeft,
        [styles.isResizeRight]: isResizeRight
      })}
    >
      <div
        {...events}
        role="button"
        tabIndex={-1}
        className={styles.rangeArea}
      />
      {range[0] !== range[1] && (
        <div
          className={classnames(styles.rangeSelected, {
            [styles.isLooping]: isLooping
          })}
          style={{
            left: `${range[0]}px`,
            width: `${range[1] - range[0]}px`
          }}
        >
          <div
            role="button"
            tabIndex={-1}
            aria-label="resize left"
            data-area-click="resize-left"
            onMouseDown={events.onMouseDown}
            className={classnames(styles.resizeLeft, {
              [styles.isInteracting]: isInteracting
            })}
          />
          <div
            role="button"
            tabIndex={-1}
            data-area-click="moving"
            onMouseDown={events.onMouseDown}
            className={classnames(styles.movingArea, {
              [styles.isInteracting]: isInteracting
            })}
          >
            <div
              role="button"
              tabIndex={-1}
              data-area-click="header-moving"
              onMouseDown={events.onMouseDown}
              className={classnames(styles.header, {
                [styles.isLooping]: isLooping
              })}
            >
              <Icon
                name="arrow"
                width={16}
                height={16}
                className={classnames(styles.iconLeft, {
                  [styles.isInteracting]: isInteracting
                })}
              />
              <Icon
                name="arrow"
                width={16}
                height={16}
                className={classnames(styles.iconRight, {
                  [styles.isInteracting]: isInteracting
                })}
              />
              <span
                className={classnames(styles.rangeLabelLeft, {
                  [styles.isInteracting]: isInteracting
                })}
              >
                {fmtMSS(rangeMs[0])}
              </span>
              <span
                className={classnames(styles.rangeLabelRight, {
                  [styles.isInteracting]: isInteracting
                })}
              >
                {fmtMSS(rangeMs[1])}
              </span>
            </div>
          </div>
          <div
            role="button"
            tabIndex={-1}
            aria-label="resize right"
            data-area-click="resize-right"
            onMouseDown={events.onMouseDown}
            className={classnames(styles.resizeRight, {
              [styles.isInteracting]: isInteracting
            })}
          />
        </div>
      )}
    </div>
  )
}
