import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import React, { useCallback, useMemo } from 'react'
import { ControllerPlayer, DataPlayer } from '../../types'
import { Header } from './header'
import { Avatar } from './avatar'
import { Post } from './post'
import styles from './posts.module.scss'
import { trigger } from '../../lib/events'

interface Props {
  data?: DataPlayer
  controller?: ControllerPlayer
  onClose?(): void
}

export const Posts: React.FC<Props> = ({ data, controller, onClose }) => {
  const { i18n } = useLingui()
  const posts = useMemo(() => data?.posts, [data?.posts])

  const handleClose = useCallback(() => {
    onClose?.()
    trigger('instructions:close')
  }, [onClose])

  return (
    <div className={styles.container}>
      <Header
        title={i18n._(t`branded_setlist_instructions_title`)}
        onClose={handleClose}
      />

      <div className={styles.middle}>
        <div className={styles.scroll}>
          <div className={styles.content}>
            {posts?.map((post) => (
              <Post
                key={post.id}
                content={post.content}
                attachments={post.attachments}
                onEventDispatch={controller?.onEventDispatch}
              >
                <Avatar image={post.avatar} title={post.name} />
              </Post>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
