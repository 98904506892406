import classnames from 'classnames'
import React, { useCallback, useMemo, useRef } from 'react'
import styles from './metronome.module.scss'
import { ChannelPlayer, ControllerPlayer } from '../../types'
import { LockPremium } from '../lock-premium'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { secondsToPixels } from '../../utils/utils'

interface MetronomeProps {
  className?: string
  metronomes?: ChannelPlayer[]
  active?: string
  limited?: boolean
  stems?: number
  onChange?(key: string): void
  onLockedClick?: ControllerPlayer['onLockedFeatureClick']
}

export const Metronome: React.FC<MetronomeProps> = ({
  className,
  metronomes,
  limited,
  active,
  stems = 2,
  onChange,
  onLockedClick
}) => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerWidth = containerRef.current?.offsetWidth || 0
  const durationMs = useAudioEngine((p) => p.state.durationMs)
  const lockIconLeftMargin = secondsToPixels(
    60,
    containerWidth,
    durationMs / 1000
  )
  const buttons = useMemo(() => {
    const list = [
      {
        key: 'metronome_half',
        value: '0.5x'
      },
      {
        key: 'metronome',
        value: '1x'
      },
      {
        key: 'metronome_double',
        value: '2x'
      }
    ]

    return list.reduce((acc: any[], metronome) => {
      const hasMetronome = metronomes?.find(({ id }) => id === metronome.key)

      if (hasMetronome) {
        acc.push({
          key: metronome.key,
          value: metronome.value,
          onClick: onChange ? () => onChange(metronome.key || '') : () => {}
        })
      }
      return acc
    }, [])
  }, [metronomes, onChange])

  const handleLockedLyricsClick = useCallback(() => {
    onLockedClick?.('metronome-limited')
  }, [onLockedClick])

  return (
    <div
      ref={containerRef}
      className={classnames(className, styles.container)}
      data-stems={stems}
    >
      {buttons?.map((i) => (
        <button
          key={i.key}
          type="button"
          onClick={i.onClick}
          className={classnames(styles.button, {
            [styles.active]: i.key === active
          })}
        >
          {i.value}
        </button>
      ))}

      {limited && (
        <LockPremium
          className={styles.lockIcon}
          style={{ left: lockIconLeftMargin }}
          type="icon"
          onClick={handleLockedLyricsClick}
        />
      )}
    </div>
  )
}
