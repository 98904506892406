import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button, Icon } from 'scala'
import classnames from 'classnames'
import React, { RefObject, useCallback, useRef, useState } from 'react'
import styles from './download-task.module.scss'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import {
  DownloadTaskDrop,
  FilesDownload
} from '../../components/download-task-drop'
import { ControllerPlayer } from '../../types'

interface DownloadTaskProps {
  files?: FilesDownload[]
  disabled?: boolean
  limited?: boolean
  controller?: ControllerPlayer
  onLockedFeatureClick?: ControllerPlayer['onLockedFeatureClick']
}

export const DownloadTask: React.FC<DownloadTaskProps> = ({
  files,
  disabled,
  limited,
  controller,
  onLockedFeatureClick
}) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()

  const [openedDrop, setOpenedDrop] = useState(false)
  const onToggleDrop = useCallback(
    () => setOpenedDrop(!openedDrop),
    [openedDrop]
  )
  useOnClickOutside(ref, () => setOpenedDrop(false))

  return (
    <div ref={ref} className={styles.container}>
      <Button
        small
        light
        stroke
        transparent
        radius={1}
        title={i18n._(t`export_label_options`)}
        onClick={onToggleDrop}
        disabled={disabled}
        icon={<Icon name="download" width={20} height={20} />}
        className={classnames(styles.buttonExport, styles.onlyDesktop)}
      />

      {openedDrop ? (
        <DownloadTaskDrop
          files={files}
          controller={controller}
          className={styles.drop}
          isDownloadWavEnabled={!limited}
          onLockedFeatureClick={onLockedFeatureClick}
        />
      ) : null}
    </div>
  )
}
