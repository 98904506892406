export const definitions: any = {
  'SEPARATE_vocals-drums-bass-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-other'
    },
    traits: {
      stems: 4,
      title: 'separation.options.4tracks',
      description: 'separation.options.4tracks.title',
      premiumOnly: false
    }
  },
  'SEPARATE_vocals-drums-bass-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-other-hifi'
    },
    traits: {
      stems: 4,
      title: 'separation.options.4tracks',
      description: 'separation.options.4tracks.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-accompaniment': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-accompaniment'
    },
    traits: {
      stems: 2,
      title: 'separation.options.2tracks',
      description: 'separation.options.2tracks.title',
      premiumOnly: false
    }
  },
  'SEPARATE_vocals-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-other-hifi'
    },
    traits: {
      stems: 2,
      title: 'separation.options.2tracks',
      description: 'separation.options.2tracks.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-backing_vocals-accompaniment': {
    id: 'SEPARATE_C',
    params: {
      type: 'vocals-backing_vocals-accompaniment',
      model: 'MOISES-v1-bg-vocal'
    },
    traits: {
      stems: 3,
      title: 'separation.options.3tracks',
      description: 'separation.options.3tracks.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-backing_vocals-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-backing_vocals-other-hifi'
    },
    traits: {
      stems: 3,
      title: 'separation.options.3tracks',
      description: 'separation.options.3tracks.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-guitars-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-guitars-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.guitar.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-guitars-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-guitars-other-hifi'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.guitar.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-lead-rhythm-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-lead-rhythm-other'
    },
    traits: {
      stems: 6,
      title: 'separation.options.6tracks',
      description: 'separation_options_guitars',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-lead-rhythm-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-lead-rhythm-other-hifi'
    },
    traits: {
      stems: 6,
      title: 'separation.options.6tracks',
      description: 'separation_options_guitars',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-acoustic-electric-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-acoustic-electric-other'
    },
    traits: {
      stems: 6,
      title: 'separation.options.6tracks',
      description: 'separation_options_eletricguitar_title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-acoustic-electric-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-acoustic-electric-other-hifi'
    },
    traits: {
      stems: 6,
      title: 'separation.options.6tracks',
      description: 'separation_options_eletricguitar_title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-piano-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-piano-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-piano-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-piano-other-hifi'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.5tracks.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-keys-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-keys-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separations_options_keys',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-keys-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-keys-other-hifi'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separations_options_keys',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-strings-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-strings-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.tracks.5',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-strings-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-strings-other-hifi'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.tracks.5',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_vocals-drums-bass-wind-other': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-wind-other'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.wind.title',
      premiumOnly: true
    }
  },
  'SEPARATE_vocals-drums-bass-wind-other-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'vocals-drums-bass-wind-other-hifi'
    },
    traits: {
      stems: 5,
      title: 'separation.options.5tracks',
      description: 'separation.options.wind.title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_kick-snare-toms-hat-cymbals-other_drums-other_kit-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'kick-snare-toms-hat-cymbals-other_drums-other_kit-hifi'
    },
    traits: {
      stems: 7,
      title: 'separation_options_7tracks',
      description: 'separation_options_alldrums_title',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  'SEPARATE_dialogue-music-effects-hifi': {
    id: 'SEPARATE_B',
    params: {
      type: 'dialogue-music-effects-hifi'
    },
    traits: {
      stems: 3,
      title: 'separation.options.3tracks',
      description: 'separation_options_cinematic',
      premiumOnly: true,
      proOnly: true,
      isHiFi: true
    }
  },
  BEATSCHORDS: {
    id: 'BEATSCHORDS_A'
  },
  LYRICS: {
    id: 'LYRICS_B'
  },
  SEGMENTATION: {
    id: 'SEGMENTATION_C'
  }
}

export const supportedOperations = Object.values(definitions)

export const getType = (op: any): string => {
  const prefix = op.name.split('_')[0]

  if (prefix === 'SEPARATE') return `SEPARATE_${op.params.type}`

  return prefix
}
