import classnames from 'classnames'
import { Icon } from 'scala'
import Image from 'next/image'
import styles from './styles.module.scss'

interface Props {
  className?: string
  size?: 'small' | 'large'
  hifi?: boolean
  locked?: boolean
  check?: boolean
}

export const StateOperation: React.FC<Props> = ({
  className,
  size = 'large',
  hifi,
  locked,
  check
}) => (
  <span className={classnames(styles.container, className)}>
    {hifi && (
      <Image
        alt=""
        width={size === 'large' ? '45' : '35'}
        height="10"
        src="/assets/images/hifi.svg"
      />
    )}

    {locked && (
      <Icon
        width={size === 'large' ? 20 : 12}
        height={size === 'large' ? 20 : 12}
        name="lock"
        className={styles.icon}
      />
    )}

    {check && (
      <Icon
        width={size === 'large' ? 20 : 12}
        height={size === 'large' ? 20 : 12}
        name="check-simple"
        className={styles.icon}
      />
    )}
  </span>
)
