import { useCallback, useMemo, useState } from 'react'
import { shallow } from 'zustand/shallow'
import { useAudioEngine } from '../use-audio-engine'
import { ChannelPlayer, StatePlayer } from '../../types'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { trigger } from '../../lib/events'

export interface UseControlMetronome {
  loading?: boolean
  activeMetronome?: ChannelPlayer
  metronomes?: ChannelPlayer[]
  changed: boolean
  onChangeMetronome(key: string): void
  onMuteMetronome(id: string, value: boolean): void
  onSoloMetronome(id: string, value: boolean): void
  onVolumeMetronome(value: number, id: string): void
  onPanMetronome(value: number, id: string): void
  updateMetronome(): void
}

interface UseMetronomeProps {
  playerApi?: PlayerApi
  initialState?: StatePlayer
  channels?: ChannelPlayer[]
  setChangedChannels?(value: boolean): void
}

export const useControlMetronome = ({
  playerApi,
  initialState,
  channels,
  setChangedChannels
}: UseMetronomeProps): UseControlMetronome => {
  const [changedMetronomes, setChangedMetronomes] = useState(false)
  const metronomeChannels = useAudioEngine((p: any) =>
    Object.values(p.channels).filter(
      (channel: any) => channel.id && channel.id.includes('metronome')
    )
  )

  const metronomes = useMemo(() => {
    return channels?.filter(({ id }) => id.includes('metronome')) ?? []
  }, [channels])

  const active = useMemo(
    () =>
      (initialState?.channels?.find(
        ({ id, isMuted, isSolo }) =>
          id.includes('metronome') && (!isMuted || isSolo)
      ) &&
        metronomes?.find(({ id }) => id === 'metronome')) || {
        id: 'metronome'
      },
    [metronomes, initialState?.channels]
  )
  const [activeMetronome, setActiveMetronome] = useState(active)

  const updateMetronome = useCallback(
    () => setActiveMetronome(active),
    [active]
  )

  const loading = useMemo(
    () =>
      metronomeChannels.length > 0 &&
      metronomeChannels.every((channel: any) => channel?.ready),
    [metronomeChannels]
  )

  const metronomeState = useAudioEngine((p) => {
    return metronomes?.map((i) => ({
      id: i.id,
      isMuted: p.channels[i.id]?.isMuted,
      isSolo: p.channels[i.id]?.isSolo
    }))
  }, shallow)

  const onPanMetronome = useCallback(
    (value: number) => {
      metronomes?.forEach((i) => {
        playerApi?.channel.pan(i.id, value)
      })

      trigger('player:event-dispatch', {
        event: 'feature_interaction',
        value: 'smart_metronome'
      })
      setChangedMetronomes(true)
      setChangedChannels?.(true)
    },
    [metronomes, playerApi?.channel, setChangedChannels]
  )

  const onMuteMetronome = useCallback(
    (id: string, value: boolean) => {
      playerApi?.channel.mute(id, value)

      trigger('player:event-dispatch', {
        event: 'feature_interaction',
        value: 'smart_metronome'
      })
      setChangedChannels?.(true)
    },
    [playerApi?.channel, setChangedChannels]
  )

  const onSoloMetronome = useCallback(
    (id: string, value: boolean) => {
      playerApi?.channel.solo(id, value)

      trigger('player:event-dispatch', {
        event: 'feature_interaction',
        value: 'smart_metronome'
      })
      setChangedMetronomes(true)
      setChangedChannels?.(true)
    },
    [playerApi?.channel, setChangedChannels]
  )

  const onVolumeMetronome = useCallback(
    (value: number) => {
      channels
        ?.filter((i) => i.id.includes('metronome'))
        ?.forEach((i) => {
          playerApi?.channel.volume(i.id, value)
        })

      trigger('player:event-dispatch', {
        event: 'feature_interaction',
        value: 'smart_metronome'
      })
      setChangedMetronomes(true)
      setChangedChannels?.(true)
    },
    [channels, playerApi?.channel, setChangedChannels]
  )

  const onChangeMetronome = useCallback(
    (key: string) => {
      let hasMetronomeSolo = false
      let hasMetronomeMuted = false

      metronomeState?.forEach((i) => {
        hasMetronomeMuted ||= !i.isMuted
        hasMetronomeSolo ||= i.isSolo
      })

      if (hasMetronomeMuted) {
        metronomes?.forEach((i) => {
          playerApi?.channel.mute(i.id, i.id !== key)

          if (hasMetronomeSolo && i.id === key) {
            playerApi?.channel.solo(i.id, true)
          }
        })
      }

      const update = channels?.find(({ id }) => id === key)

      if (update) {
        setActiveMetronome(update)
      }

      trigger('player:metronome-changed', key)
      trigger('player:event-dispatch', {
        event: 'feature_interaction',
        value: 'smart_metronome_subdivision'
      })
    },
    [channels, playerApi?.channel, metronomes, metronomeState]
  )

  return {
    loading: !loading,
    activeMetronome,
    metronomes,
    changed: changedMetronomes,
    onMuteMetronome,
    onSoloMetronome,
    onChangeMetronome,
    onVolumeMetronome,
    onPanMetronome,
    updateMetronome
  }
}
