import { useCallback, useContext, useMemo } from 'react'
import { useLingui } from '@lingui/react'
import { Context as ContextAppFrame } from 'scala'
import { Context } from '../../context'
import { UseCampaign } from './use-campaign'

export interface UseGlobalCampaign {
  campaign?: {
    id: string
    endDate: string
    pricingBanner: any
    mainBanner: any
    floatingBanner: any
    mainBannerImages: any
    countdownBannerImage: string
    profileBanner: any
    paywallsText: any
    plans: UseCampaign['plans']
    hasCampaignMonthly: boolean
    hasCampaignYearly: boolean
  }
  isUserTarget: boolean
  hasCampaign: (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => boolean
}

interface HasLanguage {
  lang: string
}

const findByLanguage = (
  options: HasLanguage[],
  language: string
): HasLanguage => {
  const result = options?.find(
    ({ lang }) => lang?.replace('-', '_') === language
  )
  return result || options?.[0]
}

export const useGlobalCampaign = (): UseGlobalCampaign => {
  const { campaign } = useContext(Context)
  const { i18n } = useLingui()
  const { user } = useContext(ContextAppFrame)

  const isUserTarget = useMemo(() => !user?.subscription?.isPremium, [user])

  const dataCampaign = useMemo(() => {
    if (!campaign.activeCampaign || !campaign?.activeCampaign?.metadata)
      return undefined

    const {
      web,
      mainBannerWeb,
      profileBannerWeb,
      floatingBannerWeb,
      mainBannerImages,
      countdownBannerImage,
      paywallsText,
      endDate
    } = campaign.activeCampaign?.metadata

    return {
      id: campaign.activeCampaign.id,
      endDate,
      pricingBanner: {
        monthly: campaign?.hasCampaignMonthly
          ? findByLanguage(web?.monthly, i18n.locale)
          : null,
        yearly: campaign?.hasCampaignYearly
          ? findByLanguage(web?.yearly, i18n.locale)
          : null
      },
      mainBanner: findByLanguage(mainBannerWeb, i18n.locale),
      floatingBanner: findByLanguage(floatingBannerWeb, i18n.locale),
      profileBanner: findByLanguage(profileBannerWeb, i18n.locale),
      paywallsText: findByLanguage(paywallsText, i18n.locale),
      mainBannerImages,
      countdownBannerImage,
      plans: campaign.plans,
      hasCampaignMonthly: campaign.hasCampaignMonthly && isUserTarget,
      hasCampaignYearly: campaign.hasCampaignYearly && isUserTarget
    }
  }, [
    campaign.activeCampaign,
    campaign.hasCampaignMonthly,
    campaign.hasCampaignYearly,
    campaign.plans,
    i18n.locale,
    isUserTarget
  ])

  const hasCampaign = useCallback(
    (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => {
      return (
        isUserTarget &&
        campaign.isGlobalCampaign &&
        campaign.hasCampaignForPlanCycle(plan, cycle)
      )
    },
    [campaign, isUserTarget]
  )

  return {
    campaign: dataCampaign,
    hasCampaign,
    isUserTarget
  }
}
