export const compareVersions = (
  version1?: string,
  version2?: string
): number => {
  const v1parts = version1?.split('.')?.map(Number)
  const v2parts = version2?.split('.')?.map(Number)

  if (!v1parts || !v2parts) {
    return 0
  }

  for (let i = 0; i < v1parts?.length; ++i) {
    if (v2parts?.length === i) {
      return 1
    }

    if (v1parts?.[i] === v2parts?.[i]) {
      //   continue
    } else if (v1parts?.[i] > v2parts?.[i]) {
      return 1
    } else {
      return -1
    }
  }

  return v1parts?.length !== v2parts?.length ? -1 : 0
}

export const getOS = (): string | null => {
  const { userAgent } = window.navigator
  const platform =
    (window.navigator as any)?.userAgentData?.platform ||
    window.navigator.platform
  const macosPlatforms = ['macOS', 'Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
  const iosPlatforms = ['iPhone', 'iPad', 'iPod']
  let os = null

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS'
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows'
  } else if (/Android/.test(userAgent)) {
    os = 'Android'
  } else if (/Linux/.test(platform)) {
    os = 'Linux'
  }

  return os
}
