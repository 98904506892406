export default {
  key: 'F#',
  suffix: 'aug9',
  positions: [
    {
      frets: '212130',
      fingers: '213140',
      barres: 1
    },
    {
      frets: 'x98790',
      fingers: '032140'
    },
    {
      frets: 'x9899a',
      fingers: '021334',
      barres: 9
    },
    {
      frets: 'excdb0',
      fingers: '402310'
    }
  ]
}
