import React from 'react'
import classnames from 'classnames'
import { UserContext } from 'scala/src/types'
import { Icon } from 'scala'
import styles from './user-avatar.module.scss'

interface Props {
  user?: UserContext
  badge?: boolean
}

export const UserAvatar: React.FC<Props> = ({ user, badge }) => {
  return (
    <div
      className={classnames(styles.container, {
        [styles.badge]: badge
      })}
    >
      {(user && user.profilePictureUrl && (
        <img
          alt="User Profile"
          className={styles.image}
          src={user.profilePictureUrl}
        />
      )) || (
        <div className={styles.icon}>
          <Icon name="user" />
        </div>
      )}
    </div>
  )
}
