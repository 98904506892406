import { Icon } from 'scala'
import React from 'react'
import styles from './styles.module.scss'

interface Props {
  icon?: string
  title?: string
  description?: string
}

export const StateFeature: React.FC<Props> = ({ icon, title, description }) => {
  return (
    <div className={styles.container}>
      {icon && <Icon name={icon} width={60} height={60} />}
      {title && <p className={styles.title}>{title}</p>}
      {description && <p className={styles.description}>{description}</p>}
    </div>
  )
}
