import classnames from 'classnames'
import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import styles from './timeline-cursor.module.scss'
import { ChannelPlayer } from '../../types'
import { useAudioEngine } from '../../hooks/use-audio-engine'

const useSize = (target: any): any => {
  const [size, setSize] = useState({})

  useLayoutEffect(() => {
    setSize(target.current.getBoundingClientRect())
  }, [target])

  // Where the magic happens
  useResizeObserver(target, (entry) => setSize(entry.contentRect))
  return size
}

interface CursorProps {
  className?: string
  loading: boolean | undefined
  durationMs: number
  channels?: ChannelPlayer[]
  onChangeRange(e: React.ChangeEvent<HTMLInputElement>): void
}

export const Cursor: React.FC<CursorProps> = ({
  className,
  loading,
  channels = [],
  durationMs,
  onChangeRange
}) => {
  const target = useRef(null)
  const { width } = useSize(target)
  const progressMs = useAudioEngine((p) => p.state.positionMs)

  const positionFinal = useMemo(
    () => (progressMs / durationMs) * width || 0,
    [width, progressMs, durationMs]
  )

  const heightCursor = useMemo(() => {
    const count = channels.filter(({ id }) => !id.includes('metronome_')).length
    const heightBeatGrid =
      count > 13 ? 350 : count > 9 ? 230 : count > 6 ? 210 : 150
    return `${count * 55 + heightBeatGrid}px`
  }, [channels])

  const onMouseUp = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => e.currentTarget.blur(),
    []
  )

  return (
    <div ref={target} className={classnames(className, styles.container)}>
      <input
        type="range"
        min={-2}
        step="0.01"
        tabIndex={-1}
        max={durationMs}
        disabled={loading}
        value={progressMs}
        onMouseUp={onMouseUp}
        onChange={onChangeRange}
        className={styles.range}
        style={{
          left: `-10px`,
          width: (width || 0) + 18
        }}
      />

      <div
        className={styles.needle}
        style={{
          transform: `translate(${positionFinal}px, 0px)`,
          display: loading ? 'none' : 'block',
          height: heightCursor
        }}
      >
        <svg
          width="14"
          height="20"
          fill="none"
          viewBox="0 0 14 20"
          xmlns="http://www.w3.org/2000/svg"
          className={styles.needleTop}
        >
          <path d="M0 0H15V14.6939L7.5 20L0 14.6939V0Z" fill="white" />
        </svg>
      </div>
    </div>
  )
}
