import { gql } from 'graphql-request'

interface CampaignCli {
  getGlobalCampaign(): Promise<CampaignResponse>
  getCampaignIndividual(couponCode: string): Promise<CampaignResponse>
}

export type CampaignResponse = {
  campaign: Campaign
  flags?: {
    offerModeOnWeb: boolean
  }
}

export type Campaign = {
  id: string
  name: string
  couponCode: string
  discountPercentage: number
  eligiblePlans: string[]
  planNames: string[]
  endAt: string
  metadata: any
}

const Campaign = (graphQL: any): CampaignCli => {
  const getGlobalCampaign = async (): Promise<any> => {
    const variables = {}
    const query = gql`
      query campaign {
        campaign {
          id
          name
          couponCode
          discountPercentage
          eligiblePlans
          planNames
          endAt
          metadata
        }
        flags: getFlags(flags: ["offerModeOnWeb"])
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  const getCampaignIndividual = async (couponCode: string): Promise<any> => {
    const variables = {
      couponCode
    }
    const query = gql`
      query campaign($couponCode: String!) {
        campaign(couponCode: $couponCode) {
          id
          name
          couponCode
          discountPercentage
          eligiblePlans
          planNames
          endAt
          metadata
        }
      }
    `
    const result = await graphQL({ query, variables })
    return result
  }

  return {
    getGlobalCampaign,
    getCampaignIndividual
  }
}

export default Campaign
