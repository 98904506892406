import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ButtonIcon, Context, Icon } from 'scala'
import React, { useCallback, useContext, useState } from 'react'
import { ModalConfirmation } from '../../../components'
import { useUnsubscribePlaylist } from '../../../hooks/playlist/use-unsubscribe-playlist'
import styles from './unsubscribe-playlist.module.scss'
import { useEvent } from '../../../hooks/analytics'
import { trigger } from '../../../lib/events'

interface UnsubscribePlaylistProps {
  playlistId?: string
  events?: {
    [key: string]: string
  }
}

export const UnsubscribePlaylist: React.FC<UnsubscribePlaylistProps> = ({
  playlistId,
  events
}) => {
  const { i18n } = useLingui()
  const { sendEvent } = useEvent()
  const { user } = useContext(Context)
  const { unsubcribePlaylist, loading } = useUnsubscribePlaylist()

  const [openedModal, setOpenedModal] = useState(false)
  const onOpenModal = useCallback(() => setOpenedModal(true), [])
  const onDismissModal = useCallback(() => setOpenedModal(false), [])

  const onConfirmUnsubscribe = useCallback(async () => {
    if (playlistId) {
      trigger('playlist:event-interacted', { event: 'left_playlist' })
      await unsubcribePlaylist({ playlistId })
      setTimeout(() => trigger('playlist:update-playlist'), 150)

      sendEvent({
        userId: user?.id,
        name: 'playlist_removed',
        category: 'retention',
        customAttributes: {
          playlist_id: playlistId,
          timestamp: new Date().toISOString(),
          ...events,
          reason: 'left'
        }
      })
    }
    onDismissModal()
  }, [
    events,
    playlistId,
    user?.id,
    sendEvent,
    unsubcribePlaylist,
    onDismissModal
  ])

  return (
    <div className={styles.container}>
      <ButtonIcon
        light
        onClick={onOpenModal}
        icon={<Icon name="logout" />}
        title={i18n._(t`leave_playlist`)}
      />

      <ModalConfirmation
        danger
        loading={loading}
        isOpen={openedModal}
        onDismiss={onDismissModal}
        onConfirm={onConfirmUnsubscribe}
        title={i18n._(t`leaving_playlist`)}
        info={i18n._(t`leaving_playlist_message`)}
        image="warning"
        confirmIcon="logout"
        confirmLabel={i18n._(t`leave_playlist`)}
        cancelLabel={i18n._(t`cancel`)}
        loadingLabel={i18n._(t`label.loading`)}
        showBackground
      />
    </div>
  )
}
