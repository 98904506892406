export default {
  key: 'C',
  suffix: 'mmaj7b5',
  positions: [
    {
      frets: 'x3x442',
      fingers: '020341'
    },
    {
      frets: 'x3444x',
      fingers: '012340'
    },
    {
      frets: '8998x8',
      fingers: '123101',
      barres: 8,
      capo: true
    },
    {
      frets: 'xxabcb',
      fingers: '001243'
    }
  ]
}
