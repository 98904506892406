import { t } from '@lingui/macro'
import { Icon } from 'scala'
import React, { useCallback, useEffect } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { Button } from '../button'
import { ControllerPlayer, StatePlayer } from '../../types'
import { usePanel } from '../../modules/panel/edits/store'
import styles from './styles.module.scss'

interface LyricsProps {
  disabled?: boolean
  initialState?: StatePlayer
  controller?: ControllerPlayer
  isDialogue?: boolean
}

export const LyricsButton: React.FC<LyricsProps> = ({
  disabled,
  initialState,
  controller,
  isDialogue = false
}) => {
  const { i18n } = useLingui()
  const { showLyrics, onShowLyrics, close } = usePanel()
  const router = useRouter()

  const toggleLyrics = useCallback(
    (expectedOpen?: boolean) => {
      const opened = expectedOpen ?? !showLyrics

      onShowLyrics(opened)

      return opened
    },
    [showLyrics, onShowLyrics]
  )

  const onToggle = useCallback(() => {
    const opened = toggleLyrics()

    if (opened) {
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'ai_lyrics'
      })
    }
  }, [toggleLyrics, controller])

  useEffectOnce(() => {
    if (initialState?.panels?.showLyrics) {
      toggleLyrics(true)
    }
  })

  // Updates the route query to contain "lyrics=true" only if the lyrics panel
  // is open
  useEffect(() => {
    if (showLyrics === !!router.query.lyrics) {
      return
    }

    if (showLyrics) {
      router.query.lyrics = 'true'
    } else {
      delete router.query.lyrics
    }

    router.replace(router, undefined, { shallow: true })
  }, [router, showLyrics])

  useUnmount(close)

  const textLabel = isDialogue ? t`separation_cinematic_dialogue` : t`lyrics`

  return (
    <Button
      text={i18n._(textLabel)}
      active={showLyrics}
      disabled={disabled}
      onClick={onToggle}
      className={styles.button}
      icon={<Icon width={20} height={20} name="lyrics" />}
      id="lyrics_footer_button"
    />
  )
}
