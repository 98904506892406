export default {
  key: 'C',
  suffix: '9b5',
  positions: [
    {
      frets: 'x32332',
      fingers: '021341',
      barres: 2,
      capo: true
    },
    {
      frets: 'x34330',
      fingers: '014230'
    },
    {
      frets: '878778',
      fingers: '213114',
      barres: 7,
      capo: true
    },
    {
      frets: '8989xa',
      fingers: '121304',
      barres: 8
    }
  ]
}
