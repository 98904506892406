import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import React, { RefObject, useCallback, useRef, useState } from 'react'
import { Button } from '../../components/button'
import { Counter } from '../../components/counter'
import { Animation } from './animation'
import styles from './count-in.module.scss'
import { UseCountIn } from '../../hooks/use-count-in'
import { useOnClickOutside } from '../../hooks/use-on-click-outside'
import { Container, Header } from '../../components/drop'

export const CountIn: React.FC<UseCountIn> = ({
  disabled,
  count,
  countIn,
  enabled,
  isStarted,
  lockIncrease,
  onSkip,
  onIncrease,
  onDecrease,
  onToggleCountIn
}) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const [open, setOpen] = useState(false)
  const onToggleDrop = useCallback(() => setOpen(!open), [open])
  const onClose = useCallback(() => setOpen(false), [])
  useOnClickOutside(ref, () => onClose())

  return (
    <div ref={ref} className={styles.container}>
      <Button
        id="countIn_header_button"
        opened={open}
        active={enabled}
        disabled={disabled}
        onClick={onToggleDrop}
        icon={<Icon name="count-in" width={20} height={20} />}
        iconDrop
      />

      <Container open={open} onClose={onClose}>
        <Header
          active={enabled}
          onToggle={onToggleCountIn}
          title={i18n._(t`modal.getapp.count`)}
        />

        <Counter
          isAvailable
          value={count}
          onIncrease={onIncrease}
          onDecrease={onDecrease}
          lockIncrease={lockIncrease}
          title={i18n._(t`clicks_before`)}
        />
      </Container>

      {isStarted && <Animation countIn={countIn} onSkip={onSkip} />}
    </div>
  )
}
