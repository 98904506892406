export default {
  key: 'F',
  suffix: 'aug9',
  positions: [
    {
      frets: '101021',
      fingers: '102043'
    },
    {
      frets: '565665',
      fingers: '121341',
      barres: 5,
      capo: true
    },
    {
      frets: 'x87889',
      fingers: '021334',
      barres: 8
    },
    {
      frets: 'dcdcex',
      fingers: '213140',
      barres: 12,
      capo: true
    }
  ]
}
