import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ButtonIcon, Icon } from 'scala'
import React, { useCallback } from 'react'
import styles from './repeat-song.module.scss'
import { UseEffects } from '../../hooks/use-effects'
import { useAudioEngine } from '../../hooks/use-audio-engine'

export interface RepeatSongProps {
  effects?: UseEffects
}

export const RepeatSong: React.FC<RepeatSongProps> = ({ effects }) => {
  const { i18n } = useLingui()
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const isRepeating = useAudioEngine((p) => p.state.isRepeating)

  const onToggle = useCallback(() => effects?.toggleRepeat(), [effects])

  return (
    <div className={styles.container}>
      <ButtonIcon
        light
        onClick={onToggle}
        icon={<Icon name="repeat" />}
        title={i18n._(t`repeat_song`)}
        disabled={!readyToPlay}
        className={styles.button}
      />

      {isRepeating && (
        <div className={styles.active}>
          <Icon name="check-simple" />
        </div>
      )}
    </div>
  )
}
