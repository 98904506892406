import classnames from 'classnames'
import styles from './label.module.scss'

interface Label {
  text: string
  className?: string
}

export const Label: React.FC<Label> = ({ text, className }) => (
  <span className={classnames(styles.label, className)}>{text}</span>
)
