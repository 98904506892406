export default {
  key: 'E',
  suffix: '9',
  positions: [
    {
      frets: '020102',
      fingers: '020103'
    },
    {
      frets: '4x2432',
      fingers: '301421',
      barres: 2
    },
    {
      frets: '776777',
      fingers: '221334',
      barres: 7
    },
    {
      frets: '09999a',
      fingers: '011112',
      barres: 9
    }
  ]
}
