/* eslint-disable */
/* tslint:disable */

const BUILD_ID =
  process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
  `random-${Math.random().toString().substring(2)}`

export async function Multitrack({ webaudioManager, onStatusChange }) {
  const onMessageFromAudioNode = async (message) => {
    if (onStatusChange && message.status) {
      onStatusChange(message.status)
    }
  }

  const audioNode = await webaudioManager.createAudioNodeAsync(
    `/audio-engines/superpowered/processors/Multitrack.js?build-id=${BUILD_ID}`,
    'MultitrackWithFallback',
    onMessageFromAudioNode
  )
  audioNode.onprocessorerror = (e) => {
    console.error('audioNode:', e)
  }

  const api = {
    audioNode
  }

  let statusTimer

  api.loadChannels = (channels) => {
    if (channels.length) {
      clearInterval(statusTimer)
      statusTimer = setInterval(api.getStatus, 90)
    }

    audioNode.sendMessageToAudioScope({
      method: 'loadTracks',
      params: { tracks: channels }
    })
  }

  api.seek = (position) => {
    audioNode.sendMessageToAudioScope({
      method: 'seek',
      params: { position: parseInt(position) }
    })
  }

  api.pause = () => {
    audioNode.sendMessageToAudioScope({
      method: 'pause',
      params: null
    })
  }

  api.play = () => {
    webaudioManager.audioContext.resume()
    audioNode.sendMessageToAudioScope({
      method: 'play',
      params: null
    })
  }

  api.loop = (fromMs, toMs) => {
    audioNode.sendMessageToAudioScope({
      method: 'loop',
      params: { fromMs, toMs }
    })
  }

  api.unLoop = () => {
    audioNode.sendMessageToAudioScope({
      method: 'unLoop',
      params: null
    })
  }

  api.set = (key, value, channelId) => {
    const payload = {
      method: 'set',
      params: { key, value }
    }

    if (channelId) {
      payload.exclusiveToChannel = channelId
    }

    audioNode.sendMessageToAudioScope(payload)
  }

  api.getStatus = () => {
    audioNode.sendMessageToAudioScope({
      method: 'getStatus',
      params: null
    })
  }

  api.playbackRate = (value) => {
    api.set('playbackRate', value)
  }

  api.pitchShift = (value) => {
    api.set('pitchShiftCents', value)
  }

  api.enableSpatial = (value) => {
    audioNode.sendMessageToAudioScope({
      method: 'spatialize',
      params: {
        channelId: 'all-mixer-channels',
        value: { enabled: value }
      }
    })
  }

  api.masterVolume = (value) => {
    const volume = value > 1 ? 1 : value < 0 ? 0 : value
    audioNode.sendMessageToAudioScope({
      method: 'masterVolume',
      params: { value: parseFloat(volume) }
    })
  }

  api.channel = {
    volume: (channelId, value) => {
      api.set('volume', parseFloat(value), channelId)
    },
    spatialize: (channelId, value) => {
      audioNode.sendMessageToAudioScope({
        method: 'spatialize',
        params: {
          channelId,
          value
        }
      })
    },
    pan: (channelId, value) => {
      audioNode.sendMessageToAudioScope({
        method: 'pan',
        params: {
          channelId,
          value
        }
      })
    },
    mute: (channelId, value) => {
      audioNode.sendMessageToAudioScope({
        method: 'mute',
        params: {
          channelId,
          value
        }
      })
    },
    solo: (channelId, value) => {
      audioNode.sendMessageToAudioScope({
        method: 'solo',
        params: {
          channelId,
          value
        }
      })
    }
  }

  api.syncToFarthestPosition = () => {
    audioNode.sendMessageToAudioScope({
      method: 'syncToFarthestPosition',
      params: null
    })
  }

  api.repeat = (enabled) => {
    audioNode.sendMessageToAudioScope({
      method: 'repeat',
      params: { enabled }
    })
  }

  api.destruct = () => {
    // audioNode.destruct()
    // api.pause()
    audioNode.sendMessageToAudioScope({
      method: 'destroy',
      params: null
    })
    clearInterval(statusTimer)
  }

  return api
}
