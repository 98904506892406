import { t } from '@lingui/macro'
import React, { useEffect, useMemo, useRef } from 'react'
import { useLingui } from '@lingui/react'
import { Channel } from '../channel'
import {
  ChannelPlayer,
  StatePlayer,
  SettingsPlayer,
  DataPlayer,
  ControllerPlayer,
  Stem
} from '../../types'
import { useWindowSize } from '../../hooks/misc/use-window-size'
import { useControlChannel } from '../../hooks/use-control-channel'
import { PlayerApi } from '../../hooks/use-player-methods/use-player-methods'
import { Timeline } from '../timeline'
import styles from './multitrack.module.scss'
import { stems } from '../../utils/utils'
import { SplitLoaderWithMessage } from '../../components/split-loader-with-message'
import { getReprocessingMsg } from '../../utils/operations-utils'
import { useControlMetronome } from '../../hooks/use-control-metronome'

interface MultitrackProps {
  channels?: ChannelPlayer[]
  data?: DataPlayer
  playerApi: PlayerApi
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
}

export const Multitrack: React.FC<MultitrackProps> = ({
  channels,
  data,
  settings,
  initialState,
  controller,
  playerApi
}) => {
  const { i18n } = useLingui()
  const { width } = useWindowSize()
  const reorderedChannels = useMemo(() => {
    return stems.reduce((acc: ChannelPlayer[], stem) => {
      const hasStem = channels?.find(
        ({ id }) => id === stem && !id.includes('metronome')
      )
      if (hasStem) {
        acc.push(hasStem)
      }
      return acc
    }, [])
  }, [channels])

  const {
    changedChannels,
    loading,
    onMute,
    onSolo,
    onChangePan,
    onChangeVolume,
    onResetChannels,
    setChangedChannels
  } = useControlChannel({
    playerApi,
    initialState,
    settings,
    channels,
    controller
  })

  const metronome = useControlMetronome({
    initialState,
    channels,
    playerApi,
    setChangedChannels
  })

  const loadingSplit = useMemo(
    () => data?.status === 'processing' || data?.status === 'failed',
    [data?.status]
  )

  const metronomeRef = useRef(metronome)

  useEffect(() => {
    const currentMetronome = metronomeRef.current

    currentMetronome.updateMetronome()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.controls}>
        {reorderedChannels.map((channel) => (
          <Channel
            key={channel.id}
            channel={channel}
            disabled={channel?.processing || channel?.error}
            loading={loading || channel?.processing || channel?.error}
            locked={settings?.channels?.[channel.id as Stem]?.limited}
            lockedForPro={
              settings?.channels?.[channel.id as Stem]?.limitedForPro
            }
            onMute={onMute}
            onSolo={onSolo}
            onChangePan={onChangePan}
            onChangeVolume={onChangeVolume}
            onLockedClick={controller?.onLockedFeatureClick}
          />
        ))}

        {metronome.activeMetronome && (
          <Channel
            key={metronome.activeMetronome.id}
            channel={metronome.activeMetronome}
            disabled={metronome.loading}
            loading={metronome.loading}
            onMute={metronome.onMuteMetronome}
            onSolo={metronome.onSoloMetronome}
            onChangePan={metronome.onPanMetronome}
            onChangeVolume={metronome.onVolumeMetronome}
            onLockedClick={controller?.onLockedFeatureClick}
          />
        )}

        <button
          type="button"
          onClick={onResetChannels}
          disabled={loading || !changedChannels}
          className={styles.buttonReset}
        >
          {i18n._(t`reset`)}
        </button>
      </div>

      {loadingSplit && (
        <SplitLoaderWithMessage
          className={styles.loader}
          isFailed={data?.status === 'failed'}
          message={i18n._(getReprocessingMsg(data?.status))}
        />
      )}

      {width > 639 && (
        <Timeline
          data={data}
          channels={channels}
          metronome={metronome}
          settings={settings}
          controller={controller}
          playerApi={playerApi}
        />
      )}

      <span className={styles.forceLoadFontArticulat}>.</span>
      <span className={styles.forceLoadFontChords}>.</span>
    </div>
  )
}
