export default {
  key: 'D',
  suffix: '7b5',
  positions: [
    {
      frets: 'xx0112',
      fingers: '000112',
      barres: 1
    },
    {
      frets: 'xx4534',
      fingers: '002413'
    },
    {
      frets: 'x5657x',
      fingers: '012130',
      barres: 5
    },
    {
      frets: 'axab9x',
      fingers: '203410'
    }
  ]
}
