import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLatest } from 'react-use'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import {
  ChannelPlayer,
  ControllerPlayer,
  SettingsPlayer,
  StatePlayer
} from '../../types'

export interface UseControlChannel {
  loading: boolean
  changedChannels: boolean
  onResetChannels(): void
  onMute(id: string, value: boolean): void
  onSolo(id: string, value: boolean): void
  onChangeVolume(value: number, id: string): void
  onChangePan(value: number, id: string): void
  setChangedChannels(value: boolean): void
}

interface UseControlChannelProps {
  playerApi?: PlayerApi
  initialState?: StatePlayer
  settings?: SettingsPlayer
  channels?: ChannelPlayer[]
  controller?: ControllerPlayer
}

export const useControlChannel = ({
  playerApi,
  initialState,
  settings,
  channels,
  controller
}: UseControlChannelProps): UseControlChannel => {
  const [changedChannels, setChangedChannels] = useState(false)
  const loading = useAudioEngine((audio) => !audio.state.isReadyToPlay)
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const channelsState = useAudioEngine((p) => p.channels)
  const channelsStateRef = useLatest(channelsState)

  const channelsFollowControls = useMemo(() => {
    if (!settings?.channels) return {}

    const list: { [key: string]: string[] } = {}
    const settingsChannels = settings?.channels as any
    Object.keys(settingsChannels).forEach((key) => {
      if (
        settingsChannels[key]?.followControl &&
        channels?.find((c) => c.id === key)
      ) {
        const follow = settingsChannels[key]?.followControl
        if (list[follow]) {
          list[follow].push(key)
        } else {
          list[follow] = [key]
        }
      }
    })

    return list
  }, [settings?.channels, channels])

  const channelsDefaultStateMap = useMemo(() => {
    const pairs = channels?.map((channel) => {
      const channelInitialState = initialState?.channels?.find(
        (channelState) => channelState.id === channel.id
      )

      const {
        isMuted = channel.id?.includes('metronome'),
        isSolo = false,
        volume = 0.75,
        pan = 0
      } = (settings?.channelInitialStateAsDefault && channelInitialState) || {}

      return [channel.id, { isMuted, isSolo, volume, pan }]
    })

    return Object.fromEntries(pairs || [])
  }, [channels, initialState?.channels, settings?.channelInitialStateAsDefault])

  const onMute = useCallback(
    (id: string, value: boolean) => {
      playerApi?.channel.mute(id, value)

      if (channelsFollowControls[id]) {
        channelsFollowControls[id].forEach((channelToFollow) => {
          playerApi?.channel.mute(channelToFollow, value)
        })
      }

      controller?.onEventDispatch?.({
        event: 'stem_modified',
        value: id
      })
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'stem_changed'
      })
      setChangedChannels(true)
    },
    [playerApi?.channel, channelsFollowControls, controller]
  )

  const onSolo = useCallback(
    (id: string, value: boolean) => {
      playerApi?.channel.solo(id, value)

      if (channelsFollowControls[id]) {
        channelsFollowControls[id].forEach((channelToFollow) => {
          playerApi?.channel.solo(channelToFollow, value)
        })
      }

      controller?.onEventDispatch?.({
        event: 'stem_modified',
        value: id
      })
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'stem_changed'
      })
      setChangedChannels(true)
    },
    [playerApi?.channel, channelsFollowControls, controller]
  )

  const onChangeVolume = useCallback(
    (value: number, id: string) => {
      playerApi?.channel.volume(id, value)

      if (channelsFollowControls[id]) {
        channelsFollowControls[id].forEach((channelToFollow) => {
          playerApi?.channel.volume(channelToFollow, value)
        })
      }

      controller?.onEventDispatch?.({
        event: 'stem_modified',
        value: id
      })
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'stem_changed'
      })
      setChangedChannels(true)
    },
    [playerApi?.channel, channelsFollowControls, controller]
  )

  const onChangePan = useCallback(
    (value: number, id: string) => {
      playerApi?.channel.pan(id, value)

      if (channelsFollowControls[id]) {
        channelsFollowControls[id].forEach((channelToFollow) => {
          playerApi?.channel.pan(channelToFollow, value)
        })
      }

      controller?.onEventDispatch?.({
        event: 'stem_modified',
        value: id
      })
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'stem_changed'
      })
      setChangedChannels(true)
    },
    [playerApi?.channel, channelsFollowControls, controller]
  )

  const onResetChannels = useCallback(() => {
    channels?.forEach((channel) => {
      if (!channelsDefaultStateMap[channel.id]) {
        return
      }

      const { isMuted, isSolo, volume, pan } =
        channelsDefaultStateMap[channel.id]

      playerApi?.channel.mute(channel.id, isMuted)
      playerApi?.channel.solo(channel.id, isSolo)
      playerApi?.channel.volume(channel.id, volume)
      playerApi?.channel.pan(channel.id, pan)
    })

    controller?.onEventDispatch?.({
      event: 'reset_interaction',
      value: 'general_reset'
    })
    setChangedChannels(false)
  }, [channels, channelsDefaultStateMap, playerApi?.channel, controller])

  useEffect(() => {
    if (!isReadyToPlay) {
      return
    }

    setChangedChannels(
      Object.values(channelsStateRef.current).some((channel) => {
        const defaultState = channelsDefaultStateMap[channel.id]

        return (
          defaultState &&
          (channel.isMuted !== defaultState.isMuted ||
            channel.isSolo !== defaultState.isSolo ||
            channel.volume !== defaultState.volume ||
            channel.pan !== defaultState.pan)
        )
      })
    )
  }, [channelsStateRef, channelsDefaultStateMap, isReadyToPlay])

  return {
    loading,
    changedChannels,
    onMute,
    onSolo,
    onChangePan,
    onChangeVolume,
    onResetChannels,
    setChangedChannels
  }
}
