export default {
  key: 'B',
  suffix: '9',
  positions: [
    {
      frets: 'x21222',
      fingers: '021334',
      barres: 2
    },
    {
      frets: '744645',
      fingers: '411312',
      barres: 4,
      capo: true
    },
    {
      frets: '797879',
      fingers: '131214',
      barres: 7,
      capo: true
    },
    {
      frets: 'xx98a9',
      fingers: '002143'
    }
  ]
}
