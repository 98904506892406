import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useRouter } from 'next/router'
import { useContext } from 'react'
import { useAsyncFn } from 'react-use'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'
import { trigger } from '../../../lib/events'
import { SongDropped } from '../../../types'
import { useEvent } from '../../analytics'

interface UseCreatePlaylist {
  loading: boolean
  value?: boolean
  error?: Error | undefined
  createPlaylist(i: {
    taskId?: string
    qtdPlaylists?: number | null
    events?: {
      [key: string]: any
    }
  }): void
}

interface UseCreateProps {
  addTaskToPlaylist?(i: SongDropped): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useCreatePlaylist = ({
  addTaskToPlaylist
}: UseCreateProps): UseCreatePlaylist => {
  const { i18n } = useLingui()
  const { sendEvent } = useEvent()
  const router = useRouter()
  const {
    userToken,
    toast: { add: addToast }
  } = useContext(Context)

  const [state, createPlaylist] = useAsyncFn(
    async ({
      taskId,
      qtdPlaylists,
      events = {}
    }: {
      taskId?: string
      qtdPlaylists?: number | null
      events?: {
        [key: string]: any
      }
    }) => {
      trigger('playlist:create-playlist')
      MoisesCLI.auth(userToken)

      try {
        const result = await MoisesCLI.createPlaylist(
          i18n
            ._(t`create.playlist.name`)
            .replace(/1/g, qtdPlaylists ? `${qtdPlaylists + 1}` : '1')
            .replace(/\*/g, '')
        )

        if (result.id) {
          if (taskId && typeof taskId === 'string' && addTaskToPlaylist) {
            addTaskToPlaylist({
              taskId,
              playlistId: result.id
            })
          }

          sendEvent({
            name: 'playlist_created',
            category: 'retention',
            customAttributes: {
              playlist_id: result.id,
              media_added_to_playlist:
                taskId && typeof taskId === 'string' ? 1 : 0,
              timestamp: new Date().toISOString(),
              ...events
            }
          })

          router.push(`/setlist/${result.id}`)
        }
      } catch (e: any) {
        addToast({
          icon: null,
          closable: true,
          type: 'error',
          title: i18n._(t`error.create.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      trigger('playlist:create-playlist-done')
      return true
    },
    [userToken, router, addToast, addTaskToPlaylist, sendEvent]
  )

  return {
    ...state,
    createPlaylist
  }
}
