import classnames from 'classnames'
import React from 'react'
import styles from './panel-aside.module.scss'
import { usePanelAside } from './edits/store'
import { ControllerPlayer, DataPlayer, SettingsPlayer } from '../../types'
import { Sections } from '../sections'
import { UseEffects } from '../../hooks/use-effects'
import { Posts } from '../posts'

interface PanelAsideProps {
  data?: DataPlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  effects?: UseEffects
}

export const PanelAside: React.FC<PanelAsideProps> = ({
  data,
  settings,
  controller,
  effects
}) => {
  const { isOpen, close, showSections, showPosts } = usePanelAside()

  return (
    <div
      className={classnames(styles.container, {
        [styles.opened]: isOpen(),
        [styles.small]: showSections
      })}
    >
      {showSections && (
        <Sections
          data={data}
          settings={settings}
          controller={controller}
          effects={effects}
        />
      )}

      {showPosts && (
        <Posts data={data} controller={controller} onClose={close} />
      )}
    </div>
  )
}
