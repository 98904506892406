import React from 'react'
import styles from './styles.module.scss'
import { ControlMedia } from '../control-media'
import { PlayerApi } from '../../hooks/use-player-methods/use-player-methods'
import { StatePlayer, SettingsPlayer, ControllerPlayer } from '../../types'
import { PoweredMoises } from '../powered-moises'
import { UseEffects } from '../../hooks/use-effects'

interface FooterProps {
  player?: PlayerApi
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  effects: UseEffects
  children?: React.ReactNode
}

export const Footer: React.FC<FooterProps> = ({
  children,
  initialState,
  settings,
  player,
  effects,
  controller
}) => {
  return (
    <div className={styles.footer}>
      <div className={styles.counterweight} />

      <ControlMedia
        player={player}
        initialState={initialState}
        settings={settings}
        effects={effects}
        controller={controller}
      />

      <div className={styles.actions}>
        {children}
        {settings?.logoMoises && <PoweredMoises />}
      </div>
    </div>
  )
}
