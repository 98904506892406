import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

interface CoverProps {
  className?: string
  cover?: string
  song?: string
  artist?: string
}

export const Cover: React.FC<CoverProps> = ({
  className,
  cover,
  song,
  artist
}) => {
  return (
    <div className={classnames(className, styles.container)}>
      {cover && (
        <img className={styles.cover} src={cover} alt={artist || song} />
      )}
      <div>
        {song && <p className={styles.song}>{song}</p>}
        {artist && <p className={styles.artist}>{artist}</p>}
      </div>
    </div>
  )
}
