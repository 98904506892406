import { Dispatch, SetStateAction, useState } from 'react'
import { useCycle } from '../use-cycle'
import { useRepeat } from '../use-repeat'
import { UseSongKey, useSongKey } from '../use-song-key'
import { UseSongTempo, useSongTempo } from '../use-song-tempo'
import {
  DataPlayer,
  StatePlayer,
  SettingsPlayer,
  ControllerPlayer
} from '../../types'
import { PlayerApi } from '../use-player-methods/use-player-methods'

export interface UseEffects {
  // spatialAudio: UseSpatialAudio
  songKey: UseSongKey
  songTempo: UseSongTempo
  toggleCycle(enabled?: boolean, rangeMs?: [number, number]): void
  toggleRepeat(enabled?: boolean): void
  setCountInEnabled: Dispatch<SetStateAction<boolean>>
}

interface UseEffectsProps {
  data?: DataPlayer
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  playerApi?: PlayerApi
}

export const useEffects = ({
  data,
  initialState,
  settings,
  controller,
  playerApi
}: UseEffectsProps): UseEffects => {
  const [countInEnabled, setCountInEnabled] = useState(false)

  const songKey = useSongKey({
    data,
    initialState,
    settings,
    controller,
    playerApi
  })
  const songTempo = useSongTempo({
    data,
    initialState,
    settings,
    controller,
    playerApi
  })
  const toggleCycle = useCycle({
    initialState,
    playerApi,
    controller,
    countInEnabled
  })
  const toggleRepeat = useRepeat({ initialState, playerApi, controller })

  return {
    songKey,
    songTempo,
    // spatialAudio,
    toggleCycle,
    toggleRepeat,
    setCountInEnabled
  }
}
