import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import React, { RefObject, useCallback, useRef, useState } from 'react'
import { Button } from '../../components/button'
import { Counter } from '../../components/counter'
import { Container } from '../../components/drop/container'
import { Header } from '../../components/drop/header'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import { UseSongKey } from '../../hooks/use-song-key'
import { Select } from '../../components/form'
import styles from './song-key.module.scss'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { SettingsPlayer } from '../../types'
import { FeatureNotAvailable } from '../../components/feature-not-available'

interface SongKeyProps extends UseSongKey {
  settings?: SettingsPlayer
}

export const SongKey: React.FC<SongKeyProps> = ({ settings, ...songKey }) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const [open, setOpen] = useState(false)
  const onToggleDrop = useCallback(() => setOpen(!open), [open])
  const isAvailable = useAudioEngine(({ api }) =>
    api.getAvailableFeatures().has('pitch')
  )
  const onClose = useCallback(() => setOpen(false), [])
  useOnClickOutside(ref, () => onClose())

  const {
    enabled,
    disabled,
    value,
    loading,
    error,
    originalTuning,
    estimatedTuning,
    setEstimatedTuning
  } = songKey
  const viewOnly = !settings?.pitch?.edit

  if (!settings?.pitch?.view) {
    return null
  }

  return (
    <div ref={ref} className={styles.container}>
      <Button
        id="songKey_header_button"
        opened={open}
        active={enabled}
        disabled={viewOnly || disabled}
        onClick={onToggleDrop}
        className={styles.button}
        text={disabled ? '---' : `${value}`}
        icon={<Icon name="pitch" width={20} height={20} />}
        iconDrop
        viewOnly={viewOnly}
      />

      <Container open={open} onClose={onClose}>
        <Header
          error={error}
          title={i18n._(t`song_key`)}
          description={
            loading ? (
              i18n._(t`song_key_detecting`)
            ) : error ? (
              i18n._(t`failed_detection`)
            ) : originalTuning && isAvailable ? (
              <div className={styles.estimated}>
                <div>{i18n._(t`tuning_message`)} (Hz): </div>
                <Select
                  controlledValue={estimatedTuning}
                  hideArrow
                  options={[...Array(25).keys()].map((i) => {
                    return {
                      text: (428 + i === originalTuning
                        ? `${428 + i} (detected)`
                        : 428 + i
                      ).toString(),
                      value: (i + 428).toString()
                    }
                  })}
                  onChange={(e) => setEstimatedTuning(parseInt(e, 10))}
                  className={styles.select}
                />
              </div>
            ) : undefined
          }
        />

        <Counter
          {...songKey}
          className={styles.counter}
          isAvailable={isAvailable}
        />

        {!isAvailable && settings?.pitch?.textNotAvailable ? (
          <FeatureNotAvailable
            text={
              <span // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: settings.pitch.textNotAvailable.replace(
                    /\*\*([^*]+)\*\*/g,
                    `<a class="${styles.link}" target="_blank" href="https://desktop.moises.ai/">$1</a>`
                  )
                }}
              />
            }
          />
        ) : null}
      </Container>
    </div>
  )
}
