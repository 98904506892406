import { Tooltip } from 'scala'
import React from 'react'
import classNames from 'classnames'
import styles from './shortcut-tooltip.module.scss'

interface ShortcutTooltip {
  title: string
  children: React.ReactNode
  className?: string
  tipPosition?: 'top' | 'bottom' | 'left' | 'right'
  disabled?: boolean
  delayedOut?: boolean
}

export const ShortcutTooltip: React.FC<ShortcutTooltip> = ({
  title,
  children,
  className = '',
  tipPosition,
  disabled = false,
  delayedOut = false
}) => {
  return (
    <Tooltip
      className={classNames(
        styles.tooltip,
        { [styles.disabled]: disabled },
        { [styles.delayedOut]: delayedOut },
        className
      )}
      theme="dark"
      top={tipPosition === 'top'}
      bottom={tipPosition === 'bottom'}
      left={tipPosition === 'left'}
      right={tipPosition === 'right'}
    >
      <p className={styles.tooltipTitle}>{title}</p>
      {children}
    </Tooltip>
  )
}

interface ShortcutTooltipWrapper {
  children: React.ReactNode
  enabled?: boolean
}

export const ShortcutTooltipWrapper: React.FC<ShortcutTooltipWrapper> = ({
  children,
  enabled
}) => (
  <span
    className={classNames(styles.tooltipWrapper, {
      [styles.disabled]: !enabled
    })}
  >
    {children}
  </span>
)
