import { useEffect, useRef } from 'react'
import { useAudioEngine } from '../use-audio-engine'
import { StatePlayer } from '../../types'
import { PlayerApi } from '../use-player-methods/use-player-methods'

export function useRepeatInitialState({
  initialState,
  toggleRepeat
}: {
  initialState?: StatePlayer
  toggleRepeat?: PlayerApi['repeat']
}): void {
  const defined = useRef<boolean>(false)
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)

  useEffect(() => {
    if (!readyToPlay || defined.current) {
      return
    }

    defined.current = true

    if (initialState?.isRepeating) {
      toggleRepeat?.(true)
    }
  }, [readyToPlay, toggleRepeat, initialState?.isRepeating])

  // useEffectOnChange(
  //   () => trigger('player:channel-changed'),
  //   [isRepeating],
  //   !onGetChannelSettings
  // )
}
