/* eslint-disable */
/* tslint:disable */
import React from 'react'
import { Barre } from './Barre'
import { Dot } from './Dot'
import { Neck } from './Neck'

const onlyDots = (chord: any) =>
  chord.frets
    .map((f: any, index: any) => ({ position: index, value: f }))
    .filter((f: any) => !chord.barres || chord.barres.indexOf(f.value) === -1)

export const Chord: React.FC<any> = ({ chord, instrument, lite = false }) =>
  chord ? (
    <svg
      width="100%"
      xmlns="http://www.w3.org/2000/svg"
      preserveAspectRatio="xMinYMin meet"
      viewBox="0 0 80 70"
    >
      <g transform="translate(13, 13)">
        <Neck
          tuning={instrument.tunings.standard}
          strings={instrument.strings}
          frets={chord.frets}
          capo={chord.capo}
          fretsOnChord={instrument.fretsOnChord}
          baseFret={chord.baseFret}
          lite={lite}
        />

        {chord.barres &&
          chord.barres.map((barre: any, index: any) => (
            <Barre
              key={index}
              capo={index === 0 && chord.capo}
              barre={barre}
              finger={
                chord.fingers && chord.fingers[chord.frets.indexOf(barre)]
              }
              frets={chord.frets}
              lite={lite}
            />
          ))}

        {onlyDots(chord).map((fret: any) => (
          <Dot
            key={fret.position}
            string1={instrument.strings - fret.position}
            fret={fret.value}
            strings={instrument.strings}
            finger={chord.fingers && chord.fingers[fret.position]}
            lite={lite}
          />
        ))}
      </g>
    </svg>
  ) : null

// Chord.propTypes = {
//   chord: PropTypes.any,
//   instrument: instrumentPropTypes,
//   lite: PropTypes.bool
// }

// Chord.defaultProps = {
//   lite: false
// }

// export default Chord
