import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { DownloadQueueContainer } from '..'
import { useSetupAnalytics } from '../../hooks/analytics'
import { useRedirectToLoginOnAuthenticationTimeout } from '../../hooks/authentication'
import { ValidateUserTerms } from '../../modules/accept-terms'
import { ModalGetApp } from '../../modules/modal-get-app'
import { ModalKeyboardShortcuts } from '../../modules/modal-keyboard-shortcuts'
import { UserSubscribePending } from '../../modules/subscribe-pending'
// import { AlertGetApp } from '../alert-get-app'
import { AppFrameWrapper } from './app-frame-wrapper'
import { AuthenticatedContainerType } from './types'
import { EventPlaylistPage } from '../../modules/playlist/event-playlist-page'
import { ModalReferralLink } from '../../modules/referral-link/modal-referral-link'
import { ModalCampaign } from '../../modules/modal-campaign'

export const routesToNotShowAppFrame = [
  '/',
  '/login',
  '/demo',
  '/demo2',
  '/demo-unified',
  '/test',
  '/test2',
  '/logout',
  '/test-urls',
  '/reset-password',
  '/ios',
  '/android',
  '/not-found',
  '/lyric-writer/[id]',
  '/lyric-writer/[id]/[title]',
  '/lyric-writer/demo',
  '/onboarding'
]

const bypassRoutesWhenUserIsLogged = [
  '/lyric-writer/demo',
  '/lyric-writer/[id]',
  '/lyric-writer/[id]/[title]'
]

const getRoutes = (isUserLoogedIn: boolean): string[] =>
  routesToNotShowAppFrame.filter((route) => {
    if (!isUserLoogedIn) return true

    if (bypassRoutesWhenUserIsLogged.includes(route)) return false

    return true
  })

export const AuthenticatedContainer = ({
  children
}: AuthenticatedContainerType): ReactElement => {
  const isUserLoogedIn = useRedirectToLoginOnAuthenticationTimeout()
  const { onUserLoaded } = useSetupAnalytics()
  const { pathname } = useRouter()

  const routes = getRoutes(isUserLoogedIn)

  if (routes.includes(pathname)) {
    return children
  }

  return (
    <AppFrameWrapper onUserLoaded={onUserLoaded}>
      <>
        <UserSubscribePending />
        <ValidateUserTerms />
        <EventPlaylistPage />
        {/* <AlertGetApp /> */}
        <ModalGetApp />
        <ModalReferralLink />
        <ModalCampaign />
        <ModalKeyboardShortcuts />
        <DownloadQueueContainer />
        {children}
      </>
    </AppFrameWrapper>
  )
}
