import { Icon } from 'scala'
import { FC, RefObject, useCallback, useRef, useState } from 'react'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import { Button } from '../../components/button'
import { SettingsPlayer } from '../../types'
import { Container, Header } from '../../components/drop'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { useOnClickOutside } from '../../hooks/use-on-click-outside'
import { Counter } from '../../components/counter'
import styles from './song-tempo.module.scss'
import { UseSongTempo } from '../../hooks/use-song-tempo'
import { FeatureNotAvailable } from '../../components/feature-not-available'

interface SongTempoProps extends UseSongTempo {
  settings?: SettingsPlayer
}

export const SongTempo: FC<SongTempoProps> = ({ settings, ...songTempo }) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const [open, setOpen] = useState(false)
  const onToggleDrop = useCallback(() => setOpen(!open), [open])
  const viewOnly = !settings?.playbackRate?.edit
  const isAvailable = useAudioEngine((p) =>
    p.api.getAvailableFeatures().has('tempo')
  )
  const onClose = useCallback(() => setOpen(false), [])
  useOnClickOutside(ref, () => onClose())

  const { enabled, disabled, value, type, loading, error, isPlaybackSpeed } =
    songTempo

  if (!settings?.playbackRate?.view) {
    return null
  }

  return (
    <div ref={ref} className={styles.container}>
      <Button
        id="songTempo_header_button"
        opened={open}
        active={enabled}
        disabled={disabled || viewOnly}
        onClick={onToggleDrop}
        className={styles.button}
        text={disabled ? '---' : `${value}`}
        icon={<Icon name="metronome" width={20} height={20} />}
        iconDrop
        viewOnly={viewOnly}
      />

      <Container open={open} onClose={onClose}>
        <Header
          error={error}
          title={
            isPlaybackSpeed
              ? i18n._(t`checkout.compare.playback`)
              : i18n._(t`speed_changer_label`)
          }
          description={
            loading
              ? i18n._(t`beats_detecting`)
              : error
              ? i18n._(t`failed_detection`)
              : undefined
          }
        />

        <Counter
          title={isPlaybackSpeed ? null : type}
          {...songTempo}
          isAvailable={isAvailable}
        />

        {!isAvailable && settings?.playbackRate?.textNotAvailable ? (
          <FeatureNotAvailable
            text={
              <span // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: settings.playbackRate.textNotAvailable.replace(
                    /\*\*([^*]+)\*\*/g,
                    `<a class="${styles.link}" target="_blank" href="https://desktop.moises.ai/">$1</a>`
                  )
                }}
              />
            }
          />
        ) : null}
      </Container>
    </div>
  )
}
