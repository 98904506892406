interface beat {
  time: number
  beatNum: number
}

export const generateFakeBeatMap = (
  beatMap: beat[],
  durationMs: number
): any => {
  const newBeatMap = [...beatMap]

  if (
    beatMap[beatMap.length - 1] &&
    beatMap[beatMap.length - 1].time < durationMs
  ) {
    const beats = beatMap.slice(4, 14)
    let beatIncrement = 0
    let beatNumMaxValue = 0
    let beatBefore = { time: 0 }

    beats?.forEach((i) => {
      if (i?.beatNum > beatNumMaxValue) {
        beatNumMaxValue = i?.beatNum
      }
      const diff = i.time - beatBefore.time
      beatBefore = i
      beatIncrement = diff
    })

    const totalBeatsMissing =
      Math.ceil(durationMs / 1000 / beatIncrement) - beatMap.length

    let lastBeatTime = beatMap[beatMap.length - 1]?.time || 0
    let lastBeatNum = beatMap[beatMap.length - 1]?.beatNum || 1

    for (let index = 0; index < totalBeatsMissing; index++) {
      lastBeatNum += 1
      if (lastBeatNum > beatNumMaxValue) {
        lastBeatNum = 1
      }

      lastBeatTime += beatIncrement

      newBeatMap.push({
        time: lastBeatTime,
        beatNum: lastBeatNum
      })
    }
  }

  return newBeatMap
}

export const generateFakeBeatMapLegacy = (
  beatMap: number[],
  durationMs: number
): any => {
  const newBeatMap = [...beatMap]

  if (beatMap[beatMap.length - 1] && beatMap[beatMap.length - 1] < durationMs) {
    const beats = beatMap.slice(4, 14)
    let beatIncrement = 0
    let beatBefore = 0

    beats?.forEach((i) => {
      const diff = i - beatBefore
      beatBefore = i
      beatIncrement = diff
    })

    const totalBeatsMissing =
      Math.ceil(durationMs / 1000 / beatIncrement) - beatMap.length

    let lastBeatTime = beatMap[beatMap.length - 1] || 0

    for (let index = 0; index < totalBeatsMissing; index++) {
      lastBeatTime += beatIncrement
      newBeatMap.push(lastBeatTime)
    }
  }

  return newBeatMap
}
