export default {
  key: 'F#',
  suffix: '5',
  positions: [
    {
      frets: '24xxxx',
      fingers: '130000'
    },
    {
      frets: 'x9bxxx',
      fingers: '013000'
    },
    {
      frets: '244xxx',
      fingers: '134000'
    }
  ]
}
