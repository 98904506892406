import classnames from 'classnames'
import { SplitLoader } from '../../components/split-loader'
import styles from './styles.module.scss'
import { StateOperation } from '../../components/state-operation'

export interface Props {
  id: string
  active: boolean
  disabled?: boolean
  title: string
  description: string
  loading?: boolean
  succeeded?: boolean
  locked?: boolean
  isHiFi?: boolean
  onClick: any
}

export const ChangeOperationsItem: React.FC<Props> = ({
  id,
  active,
  disabled,
  title,
  description,
  loading,
  succeeded,
  locked,
  isHiFi,
  onClick
}) => {
  return (
    <li
      className={classnames(styles.container, {
        [styles.activeItem]: active,
        [styles.disabled]: active
        // [styles.locked]: item.locked
      })}
    >
      <button
        type="button"
        data-id={id}
        onClick={onClick}
        disabled={disabled}
        className={styles.button}
      >
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{description}</span>

        {loading ? (
          <SplitLoader className={styles.loader} />
        ) : succeeded || isHiFi || locked ? (
          <StateOperation
            size="small"
            hifi={isHiFi}
            locked={locked}
            check={succeeded}
            className={styles.state}
          />
        ) : null}
      </button>
    </li>
  )
}
