export default {
  key: 'F',
  suffix: 'dim',
  positions: [
    {
      frets: 'xx34x4',
      fingers: '001203'
    },
    {
      frets: 'x86x67',
      fingers: '041023'
    },
    {
      frets: 'x89a9x',
      fingers: '012430'
    },
    {
      frets: 'dbxdcx',
      fingers: '310420'
    }
  ]
}
