export default {
  key: 'F#',
  suffix: 'maj11',
  positions: [
    {
      frets: '223322',
      fingers: '112311',
      barres: 2,
      capo: true
    },
    {
      frets: 'xx4466',
      fingers: '001134',
      barres: 4,
      capo: true
    },
    {
      frets: 'x98a09',
      fingers: '021403'
    },
    {
      frets: '999ab9',
      fingers: '111241',
      barres: 9,
      capo: true
    }
  ]
}
