import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, { useCallback } from 'react'
import { Playlist } from '../../../types'
import styles from './playlist-line.module.scss'

interface PlaylistLineProps {
  id?: string
  className?: string
  playlist: Playlist
  unreadCount?: number
  ActionsPlaylist?: React.ReactNode
}

export const PlaylistLine: React.FC<PlaylistLineProps> = ({
  id,
  className,
  playlist,
  unreadCount,
  ActionsPlaylist
}) => {
  const { i18n } = useLingui()
  const { push } = useRouter()

  const onClick = useCallback(
    () => push(`/setlist/${playlist.id}/?reference=library`),
    [push, playlist]
  )

  return (
    <div className={classnames(className, styles.container)}>
      <button id={id} type="button" onClick={onClick} className={styles.button}>
        <div className={styles.iconContainer}>
          <Icon name="playlist" />
        </div>

        <span className={styles.info}>
          <p className={styles.title}>{playlist.name}</p>
          <p className={styles.countSongs} id="songs_count">
            {playlist.tracks?.totalCount === 1
              ? i18n
                  ._(t`playlist.song`)
                  .replace(/\*1\*/g, `${playlist.tracks?.totalCount}`)
              : i18n
                  ._(t`playlist.songs`)
                  .replace(/\*3\*/g, `${playlist.tracks?.totalCount}`)}
          </p>
        </span>

        {!!playlist.guests.totalCount && (
          <div className={styles.iconShared}>
            <Icon name="user-group" />
          </div>
        )}

        {unreadCount && unreadCount > 0 ? (
          <span
            className={classnames(styles.unreadCount, {
              [styles.large]: unreadCount > 99
            })}
          >
            <p className={styles.count}>
              {unreadCount > 99 ? '99+' : unreadCount}
            </p>
          </span>
        ) : null}
      </button>

      {ActionsPlaylist &&
        React.isValidElement(ActionsPlaylist) &&
        React.cloneElement(ActionsPlaylist as React.ReactElement<any>, {
          playlistId: playlist.id
        })}
    </div>
  )
}
