import { useRouter } from 'next/router'
import React, { useCallback, useContext, useMemo } from 'react'
import { Button, Context } from 'scala'
import { Group, GroupOwner, GroupMember } from 'scala/src/types/group.types'
import styles from '../../../modules/group/manage/manage.module.scss'

type UseDataManageGroup = {
  owner: GroupOwner
  members: GroupMember[] | undefined
  titleGroup: string
  subtitleGroup: string
  disclaimerGroup: string | null
  group: Group
  groupIsFull: boolean
  modalTexts: {
    title: string
    info: string
    confirmLabel: string
    cancelLabel: string
    onConfirm: () => void
  }
  ActionManage: React.JSX.Element
  isOwner: boolean
  isMember: boolean
  isPremium: boolean
  isPro: boolean
  isActiveManageGroup: boolean
  seats: number | undefined
}

export const useDataManageGroup = (): UseDataManageGroup => {
  const { user } = useContext(Context)
  const { push } = useRouter()

  const group: Group = useMemo(() => user?.groups?.[0], [user?.groups])

  const owner = useMemo(() => {
    if (group?.owner) {
      return group?.owner
    }
    return {
      name: user?.name,
      email: user?.email,
      avatar: user?.profilePictureUrl
    }
  }, [group, user])

  const seats = useMemo(() => group?.currentSeats, [group])

  const members = useMemo(() => group?.members, [group])

  const isPremium = useMemo(() => user?.subscription?.isPremium, [user])
  const isPro = useMemo(() => user?.subscription?.isPro, [user])

  const titleGroup = useMemo(() => {
    if (isPro) {
      return 'Moises Pro Group'
    }

    if (isPremium) {
      return 'Moises Premium Group'
    }

    return 'My group'
  }, [isPro, isPremium])

  const subtitleGroup = useMemo(() => {
    if (group?.isOwner) {
      return `${group?.occupiedSeats}/${group?.currentSeats} licenses`
    }

    if (isPremium || isPro) {
      return '1 license'
    }

    return '0/0 license'
  }, [isPro, isPremium, group])

  const disclaimerGroup = useMemo(() => {
    if (!isPro && !isPremium) {
      return 'Save up to 40% with group plans'
    }
    return null
  }, [isPremium, isPro])

  const handleManageLicense = useCallback(() => {
    push({ pathname: '/pricing/', query: { toggle: 'group' } })
  }, [push])

  const ActionManage = useMemo(() => {
    const title =
      user?.subscription?.subscriptionType === 'group-owner'
        ? 'Manage licenses'
        : 'Start a group'

    return (
      <Button
        quiet
        small
        radius={1}
        title={title}
        className={styles.buttonManage}
        onClick={handleManageLicense}
      />
    )
  }, [user, handleManageLicense])

  const groupIsFull = useMemo(() => user?.groups?.[0]?.isFull, [user])

  const modalTexts = useMemo(() => {
    const commonProps = {
      cancelLabel: 'Cancel',
      onConfirm: handleManageLicense
    }

    if (groupIsFull) {
      return {
        ...commonProps,
        title: 'No licenses available',
        info: 'Grow your group with additional licenses at a great price.',
        confirmLabel: 'Grow group'
      }
    }

    return {
      ...commonProps,
      title: 'Save with a group plan',
      info: 'Connect and save with your musician connections on the group plan.',
      confirmLabel: 'Start group plan'
    }
  }, [groupIsFull, handleManageLicense])

  const isOwner = useMemo(() => group && !!group?.isOwner, [group])

  const isMember = useMemo(() => group && !group?.isOwner, [group])

  const isActiveManageGroup = useMemo(
    () => user?.featureFlags?.webGroupPlan,
    [user]
  )

  return {
    titleGroup,
    subtitleGroup,
    disclaimerGroup,
    ActionManage,
    owner,
    members,
    groupIsFull,
    modalTexts,
    group,
    isOwner,
    isMember,
    isActiveManageGroup,
    isPro,
    isPremium,
    seats
  }
}
