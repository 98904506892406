export function createPositionAnalyzer(engineType, engineName) {
  const label = engineName ? `${engineType} / ${engineName}` : engineType

  let lastDebouncedWarn = Date.now()

  const canWarn = () => {
    return Date.now() - lastDebouncedWarn >= 1000
  }

  const debounceWarn = (...args) => {
    if (canWarn()) {
      lastDebouncedWarn = Date.now()
      console.warn(...args)
    }
  }

  let hasHighDiff = false

  /**
   * Check whether there are channels out of sync.
   */
  return (/** @type {{ id: string; positionMs: number }[]} */ positions) => {
    if (positions.length < 2) {
      return
    }

    const sorted = [...positions].sort((a, b) => a.positionMs - b.positionMs)
    const first = sorted[0]
    const last = sorted[sorted.length - 1]
    const diffMs = Math.round(last.positionMs - first.positionMs)

    // Ignores small differences
    if (diffMs >= 10) {
      hasHighDiff = true
      debounceWarn(
        '[%s] Channels out of sync by %s ms: %s and %s',
        label,
        diffMs,
        first.id,
        last.id
      )
    } else if (hasHighDiff && canWarn()) {
      hasHighDiff = false
      console.warn('[%s] Channels back in sync', label)
    }
  }
}
