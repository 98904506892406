import { Tooltip } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './input-range.module.scss'

interface InputRangeProps {
  className?: string
  showTooltip?: boolean
  value: number
  min: number
  max: number
  step: number
  disabled?: boolean
  onChange?(e: React.ChangeEvent<HTMLInputElement>): void
  onClick?(e: React.MouseEvent<HTMLInputElement>): void
}

export const InputRange: React.FC<InputRangeProps> = ({
  className,
  showTooltip,
  value,
  min,
  max,
  step,
  disabled,
  onChange,
  onClick
}) => (
  <div className={classnames(className, styles.container)}>
    <input
      className={classnames(styles.range, {
        [styles.disabled]: disabled
      })}
      type="range"
      min={min}
      max={max}
      step={step}
      value={value}
      onClick={onClick}
      onChange={onChange}
      style={{
        backgroundSize: disabled
          ? '0'
          : max !== 100
          ? `${(value * 100) / max}%`
          : `${
              max / 3.0 > value
                ? `calc(${value}% + 10px)`
                : max / 1.6 > value
                ? `calc(${value}% + 5px)`
                : `${(value * 100) / max}%`
            }`
      }}
    />

    {showTooltip && (
      <div className={styles.tooltipContainer}>
        <span>
          <span
            style={{
              left: `${value}%`,
              position: 'relative'
            }}
          >
            <Tooltip
              title={Math.trunc(value) === 0 ? '0' : Math.trunc(value)}
              className={styles.tooltip}
            />
          </span>
        </span>
      </div>
    )}
  </div>
)
