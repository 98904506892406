import { useCallback, useEffect, useState } from 'react'
import * as Sentry from '@sentry/nextjs'
import { useIdleTimer } from 'react-idle-timer'
import { useEffectOnce } from 'react-use'
import { UserToken } from '../../../context/types'
import { FirebaseAuth, moisesSDK } from '../../../lib/firebase'
import { off, on } from '../../../lib/events'

const delayRefreshOnRecentlyCreatedAccount = async (
  userPayload: any
): Promise<void> => {
  if (!userPayload) return

  const isOnlyPassword =
    userPayload.providerData.length === 1 &&
    userPayload.providerData.find(
      ({ providerId }: { providerId: string }) => providerId === 'password'
    )

  const accountRecentlyCreated = Date.now() - userPayload.createdAt < 5000

  if (isOnlyPassword && accountRecentlyCreated) {
    // eslint-disable-next-line no-new
    await new Promise((resolve) => setTimeout(resolve, 3000))
  }
}

export const useFirebaseToken = (): UserToken => {
  const [userToken, setUserToken] = useState<UserToken>(null)

  FirebaseAuth.useDeviceLanguage()

  const notifyAllFrames = useCallback((newToken: string | null) => {
    const iframes = [
      ...global.window.document.querySelectorAll('iframe').values()
    ]

    iframes
      .filter((frame) => frame.src)
      .map((frame) => ({ url: new URL(frame.src), frame }))
      .filter(({ url }) => url.hostname.match(/moises\.ai$/))
      .forEach(({ frame, url }) => {
        if (frame.contentWindow === null) return
        frame.contentWindow.postMessage(
          {
            type: 'refresh-token',
            payload: newToken
          },
          `${url.protocol}//${url.hostname}${url.port ? `:${url.port}` : ''}`
        )
      })
  }, [])

  const setNewUserToken = useCallback(
    (newToken: UserToken) => {
      if (newToken !== userToken) {
        if (newToken) {
          moisesSDK.auth.updateFirebaseToken(newToken)
        }
        setUserToken(newToken)
      }
      notifyAllFrames(newToken)
    },
    [userToken, notifyAllFrames]
  )

  const refreshFirebaseToken = useCallback(async () => {
    const { currentUser } = FirebaseAuth

    await delayRefreshOnRecentlyCreatedAccount(currentUser?.toJSON())

    currentUser
      ?.getIdToken()
      .then(setNewUserToken)
      .catch((e: any) => {
        Sentry.captureMessage('Firebase: refresh token', {
          level: 'error',
          extra: { error: e }
        })
      })
  }, [setNewUserToken])

  const handleOnActive = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('activity registered after 3 min idle: refreshing fb token')
    refreshFirebaseToken()
  }, [refreshFirebaseToken])

  useIdleTimer({
    timeout: 1000 * 60 * 3, // trigger on activity after 3 minutes idle
    onActive: handleOnActive,
    debounce: 500
  })

  useEffect(() => {
    const unsubscribe = FirebaseAuth.onIdTokenChanged(async (user) => {
      if (user) {
        refreshFirebaseToken()
      } else {
        global.window.rudderstackUserLoaded = false
        setUserToken(null)
      }
    })

    return () => unsubscribe()
  }, [refreshFirebaseToken])

  const handleRefreshInvalidToken = useCallback(async () => {
    // eslint-disable-next-line no-console
    console.log('authentication failed on fetch graphql: refreshing fb token')
    refreshFirebaseToken()
  }, [refreshFirebaseToken])

  useEffectOnce(() => {
    on('firebase:refresh-token', handleRefreshInvalidToken)

    if (typeof window !== 'undefined') {
      window.addEventListener('message', (event) => {
        if (event?.data?.type === 'token-expired') {
          refreshFirebaseToken()
        }
      })
    }

    return () => {
      off('firebase:refresh-token', handleRefreshInvalidToken)
    }
  })

  return userToken
}
