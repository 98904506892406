import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { AppFrame } from 'scala'
import { useRouter } from 'next/router'
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import { UserContext } from 'scala/src/types'
import * as Sentry from '@sentry/nextjs'
import { config, getClientHeaders } from '../../config'
import { Context } from '../../context'
import { useRedirectToLoginOnAuthenticationTimeout } from '../../hooks/authentication'
import { isDesktopApp } from '../../hooks/authentication/use-authentication/providers/helpers'
import { trigger } from '../../lib/events'
import { AuthenticatedContainerType } from './types'
import { Sidebar } from '../../modules/sidebar/sidebar'
import { AlertGetApp } from '../alert-get-app'
import { useFcmToken } from '../../hooks/user/use-fcm-token'

const clientHeaders = getClientHeaders()

function setupSentry(user: UserContext): void {
  Sentry.configureScope((scope) => {
    scope.setUser({ id: user.id })
  })
}

export const AppFrameWrapper: React.FC<AuthenticatedContainerType> = ({
  children,
  onUserLoaded
}) => {
  useRedirectToLoginOnAuthenticationTimeout()
  const { i18n } = useLingui()
  const { pathname, push } = useRouter()
  const {
    userToken,
    modalGetApp: { onOpen: onOpenModal },
    modalShortcutsMenu: { onOpen: onOpenModalShortcutsMenu },
    campaign: { onVerifyActiveGlobalCampaign }
  } = useContext(Context)

  const [campaignGlobalVerified, setCampaignGlobalVerified] = useState(false)
  const { getFcmToken, isPermissionGranted } = useFcmToken()

  useEffect(() => {
    if (userToken && !campaignGlobalVerified) {
      setCampaignGlobalVerified(true)
      onVerifyActiveGlobalCampaign(userToken)
    }
  }, [userToken, campaignGlobalVerified, onVerifyActiveGlobalCampaign])

  const strings = useMemo(() => {
    const extraLabels = !isDesktopApp
      ? { 'actions.downloadDesktopApp': i18n._(t`get_desktop_app`) }
      : {}

    return {
      'label.beta': i18n._(t`beta`),
      'header.state.loadingFailed': i18n._(t`task.list.state.failed`),
      'account.labelPremium': i18n._(t`label.premium`),
      'label.support': i18n._(t`label.support`),
      unlock_all_features: i18n._(t`unlock_all_features`),
      manageSubscription: i18n._(t`manageSubscription`),
      'label.shortcuts': i18n._(t`keyboard_shortcuts`),
      'account.settings': i18n._(t`header.nav.user.settings`),
      'account.premium': i18n._(t`header.nav.user.getpremium`),
      'account.signOut': i18n._(t`header.nav.user.logout`),
      'actions.getapp': i18n._(t`actions.getapp`),
      ...extraLabels
    }
  }, [i18n])

  const [tokenRegistered, setTokenRegistered] = useState(false)
  const setFcmToken = useCallback(
    async (enabled: boolean) => {
      if (!tokenRegistered && enabled && (await isPermissionGranted())) {
        await getFcmToken({ update: true })
        setTokenRegistered(true)
      }
    },
    [isPermissionGranted, getFcmToken, tokenRegistered]
  )

  const handleUserLoaded = useCallback(
    (user: any) => {
      if (onUserLoaded) {
        onUserLoaded(user)
        setupSentry(user)
      }

      setFcmToken(user?.featureFlags?.webNotification)
    },
    [onUserLoaded, setFcmToken]
  )

  const handleNavigation = useCallback(
    (path: string) => {
      switch (path) {
        case '/support':
          window.open(path, '_blank', 'noopener')
          break
        case '/logout':
          trigger('purge:tasks')
          push(path)
          break
        case '/get-app':
          onOpenModal()
          break
        case '/keyboard-shortcuts-help':
          onOpenModalShortcutsMenu()
          break
        case '/get-desktop-app':
          window.open('https://desktop.moises.ai/', '_blank', 'noopener')
          break
        default:
          push(path)
          break
      }
    },
    [push, onOpenModal, onOpenModalShortcutsMenu]
  )

  const navItems = useMemo(
    () =>
      [
        {
          label: i18n._(t`label.library`),
          id: 'library_tab_button',
          link: '/library',
          active: pathname === '/library' || pathname.includes('/playlist')
        },
        {
          label: i18n._(t`lyric_writer`),
          id: 'lyric_writer_tab_button',
          link: '/lyric-writer',
          active: pathname === '/lyric-writer'
        },
        {
          label: 'Voice Studio',
          id: 'daw_tab_button',
          link: '/voice2',
          active: pathname === '/voice2'
        }
      ].filter((item) => !!item),
    [i18n, pathname]
  )

  return (
    <AppFrame
      strings={strings}
      navItems={navItems}
      auth={userToken}
      onUserLoaded={handleUserLoaded}
      onNavigate={handleNavigation}
      currentPathName={pathname}
      clientHeaders={clientHeaders}
      apiUrl={`${config.graphql.endpoint}`}
      Sidebar={<Sidebar />}
      AlertGetApp={<AlertGetApp />}
    >
      {children}
    </AppFrame>
  )
}
