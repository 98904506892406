import { useContext } from 'react'
import { useAsyncFn } from 'react-use'
import * as Sentry from '@sentry/nextjs'
import { Context as ScalaContext } from 'scala'
import { config } from '../../../config'
import { ReferralLink } from '../../../types/referral-link.types'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'
import { usePlanName } from '../../plans/use-plan-name'

interface UseFetchReferralLinks {
  error: boolean
  loading: boolean
  fetchReferralLinks(): Promise<ReferralLink[]>
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const useFetchReferralLinks = (): UseFetchReferralLinks => {
  const {
    userToken,
    failedAuthentication: { increaseErrorCount }
  } = useContext(Context)
  const { user } = useContext(ScalaContext)
  const { plan } = usePlanName()

  const userName = user?.name || user.email

  const domain =
    config.env === 'stage' ? 'studio-stage.moises.ai' : 'studio.moises.ai'

  const [state, fetchReferralLinks] = useAsyncFn(async (): Promise<
    ReferralLink[]
  > => {
    if (!userToken || !user?.featureFlags) {
      return []
    }

    MoisesCLI.auth(userToken)
    let result = []

    try {
      const { referralLinks } = await MoisesCLI.fetchReferralLinks()
      result = referralLinks.map((item) => ({
        ...item,
        url: `https://${domain}/login?signup&redirect=claim-referral-link/${
          item.id
        }&user=${encodeURIComponent(userName)}&plan=${plan}`
      }))
    } catch (e: any) {
      increaseErrorCount()
      Sentry.captureMessage('Error fetching referral links', {
        level: 'error',
        extra: {
          isAuthFailed: e?.response?.errors?.some(
            (i: any) => i?.extensions?.code === 'UNAUTHENTICATED'
          ),
          codes: e?.response?.errors
            ?.map((i: any) => i?.extensions?.code)
            ?.join(', '),
          error: e
        }
      })
      throw new Error(e)
    }

    return result
  }, [userToken])

  return {
    error: !!state.error,
    loading: state.loading,
    fetchReferralLinks
  }
}
