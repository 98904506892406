import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Button } from 'scala'
import React, { useRef } from 'react'
import { useEffectOnce } from 'react-use'
import styles from './animation.module.scss'

interface AnimationProps {
  countIn: number | null
  onSkip(): void
}

export const Animation: React.FC<AnimationProps> = ({ countIn, onSkip }) => {
  const { i18n } = useLingui()
  const refButtonSkip: any = useRef()

  useEffectOnce(() => {
    refButtonSkip.current.focus()
  })

  return (
    <div className={styles.container}>
      <p className={styles.count}>{countIn}</p>

      <Button
        small
        stroke
        light
        transparent
        onClick={onSkip}
        title={i18n._(t`skip`)}
        className={styles.button}
        refElem={refButtonSkip}
      />
    </div>
  )
}
