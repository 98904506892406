import { useRouter } from 'next/router'
import { useCallback, useMemo, useState } from 'react'
import { useEffectOnce } from 'react-use'
import { config } from '../../config'
import { UserToken } from '../../context/types'
import { Moises as MoisesNew } from '../../lib/cli'
import {
  PlanPrice,
  Plans,
  useCheckoutCommon
} from '../checkout-new/common/use-checkout-common'

export interface ValidateCoupon {
  coupon?: string
  userToken?: UserToken
  cycle: 'monthly' | 'yearly'
  plan: 'premium' | 'pro'
}

export interface ValidateCouponResponse {
  error?: 'INVALID_COUPON' | 'INVALID_COUPON_PLAN'
  campaign?: Campaign
}

export interface Campaign {
  id: string
  name: string
  endAt: string
  couponCode: string
  eligiblePlans: string[]
  planNames: string[]
  discountPercentage: number
  metadata: any
}

interface PlanDiscount {
  global: Plans['global']
  pro: Plans['pro']
}

export interface UseCampaign {
  plans: PlanDiscount | null
  isGlobalCampaign: boolean
  hasCampaignYearly: boolean
  hasCampaignMonthly: boolean
  hasCampaignForPlanCycle: (
    plan: 'premium' | 'pro',
    cycle: 'monthly' | 'yearly'
  ) => boolean
  activeCampaign: Campaign | null
  promoEndAtFormated: string | null
  onVerifyActiveGlobalCampaign(userToken: string): void
  onValidateCoupon(data: ValidateCoupon): Promise<ValidateCouponResponse>
  onClearCoupon(): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

const setDiscount = (
  plan: 'premium' | 'pro',
  cycle: 'monthly' | 'yearly',
  activeCampaign: Campaign,
  price: PlanPrice
): PlanPrice => {
  const { eligiblePlans, planNames, discountPercentage } = activeCampaign

  if (eligiblePlans.includes(cycle) && planNames.includes(plan)) {
    return {
      price: (((discountPercentage - 100) / 100) * -price.regularPrice).toFixed(
        2
      ),
      regularPrice: price.regularPrice,
      monthlyPrice: price.monthlyRegularPrice,
      monthlyRegularPrice: (
        ((discountPercentage - 100) / 100) *
        -price.monthlyRegularPrice
      ).toFixed(2)
    }
  }

  return {
    regularPrice: price.regularPrice,
    monthlyRegularPrice: price.monthlyRegularPrice
  }
}

export const useCampaign = (): UseCampaign => {
  const { query } = useRouter()
  const { plans } = useCheckoutCommon()
  const [isGlobalCampaign, setIsGlobalCampaign] = useState(false)
  const [activeCampaign, setActiveCampaign] = useState<Campaign | null>(null)
  const [couponCode, setCouponCode] = useState<string | undefined>(undefined)

  const promoEndAtFormated = useMemo(() => {
    if (!activeCampaign?.endAt) {
      return null
    }

    const date = new Date(activeCampaign.endAt)
    return `${date.toLocaleString('default', {
      month: 'long'
    })} ${date.getDate()}, ${date.getFullYear()}`
  }, [activeCampaign?.endAt])

  useEffectOnce(() => {
    setCouponCode(query.coupon as string | undefined)
  })

  const onVerifyActiveGlobalCampaign = useCallback(
    async (userToken: string) => {
      if (userToken) {
        MoisesCLI.auth(userToken)

        const coupon = (couponCode || query.coupon) as string

        if (coupon) {
          const result = await MoisesCLI.getCampaignIndividual(coupon)
          setIsGlobalCampaign(false)
          setCouponCode(coupon)

          if (result.campaign) {
            setActiveCampaign(result.campaign)
          }
        } else {
          const result = await MoisesCLI.getGlobalCampaign()

          if (result.campaign && result?.flags?.offerModeOnWeb) {
            setIsGlobalCampaign(true)
            setActiveCampaign(result.campaign)
          }
        }
      }
    },
    [query, couponCode]
  )

  const onValidateCoupon = useCallback(
    async ({
      coupon,
      cycle,
      plan,
      userToken
    }: ValidateCoupon): Promise<ValidateCouponResponse> => {
      if (!userToken || !coupon) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      MoisesCLI.auth(userToken)
      const result = await MoisesCLI.getCampaignIndividual(coupon)

      if (!result.campaign) {
        return {
          error: 'INVALID_COUPON'
        }
      }

      if (
        !result.campaign.eligiblePlans.includes(cycle) ||
        !result.campaign.planNames.includes(plan)
      ) {
        return {
          error: 'INVALID_COUPON_PLAN'
        }
      }

      if (result.campaign) {
        setActiveCampaign(result.campaign)
      }

      return {
        campaign: result.campaign
      }
    },
    []
  )

  const onClearCoupon = useCallback(() => {
    setActiveCampaign(null)
    setCouponCode(undefined)
  }, [])

  const plansDiscount: PlanDiscount | null = useMemo(() => {
    if (!activeCampaign || !plans) {
      return null
    }

    return {
      global: {
        currencyCode: plans.global.currencyCode,
        currencySymbol: plans.global.currencySymbol,
        price: {
          monthly: setDiscount(
            'premium',
            'monthly',
            activeCampaign,
            plans.global.price.monthly
          ),
          yearly: setDiscount(
            'premium',
            'yearly',
            activeCampaign,
            plans.global.price.yearly
          )
        }
      },
      pro: {
        currencyCode: plans.pro.currencyCode,
        currencySymbol: plans.pro.currencySymbol,
        price: {
          monthly: setDiscount(
            'pro',
            'monthly',
            activeCampaign,
            plans.pro.price.monthly
          ),
          yearly: setDiscount(
            'pro',
            'yearly',
            activeCampaign,
            plans.pro.price.yearly
          )
        }
      }
    }
  }, [activeCampaign, plans])

  const hasCampaignForPlanCycle = useCallback(
    (plan: 'premium' | 'pro', cycle: 'monthly' | 'yearly') => {
      return (
        !!activeCampaign?.eligiblePlans.includes(cycle) &&
        !!activeCampaign.planNames.includes(plan)
      )
    },
    [activeCampaign]
  )

  return {
    activeCampaign,
    isGlobalCampaign,
    promoEndAtFormated,
    plans: plansDiscount,
    hasCampaignYearly: !!activeCampaign?.eligiblePlans.includes('yearly'),
    hasCampaignMonthly: !!activeCampaign?.eligiblePlans.includes('monthly'),
    hasCampaignForPlanCycle,
    onVerifyActiveGlobalCampaign,
    onValidateCoupon,
    onClearCoupon
  }
}
