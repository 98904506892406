import { useEffect, useState } from 'react'
import { useAudioEngine } from '../use-audio-engine'
import { ControllerPlayer, SettingsPlayer } from '../../types'

interface Props {
  settings?: SettingsPlayer
  controller?: ControllerPlayer
}

const time = 60000

export const useControlProgress = ({ controller }: Props): void => {
  const positionMs = useAudioEngine((p) => p.state.positionMs)
  const [controlProgress, setControlProgress] = useState(true)

  useEffect(() => {
    if (controlProgress && positionMs > time) {
      setControlProgress(false)
      controller?.onEventDispatch?.({ event: 'paywall', value: '60-seconds' })
    } else if (!controlProgress && positionMs < time) {
      setControlProgress(true)
    }
  }, [positionMs, controlProgress, controller])
}
