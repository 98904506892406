import { Player } from '@lottiefiles/react-lottie-player'
import classnames from 'classnames'
import { animation } from './animation'
import styles from './split-loader.module.scss'

interface SplitLoaderParams {
  className?: string
}

export const SplitLoader: React.FC<SplitLoaderParams> = ({ className }) => {
  return (
    <div className={classnames(styles.player, className)}>
      <Player loop autoplay src={animation} className={styles.loader} />
    </div>
  )
}
