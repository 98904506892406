import instruments from './db'

export interface ChordsDiagram {
  key: string
  suffix: string
  positions: [
    {
      frets: string
      fingers: string
      barres?: number
      capo?: boolean
    }
  ]
}

// cases of non-matching suffixes, probably need to add more
const suffixMap: any = {
  '': 'major',
  'maj7(9)': 'maj9',
  'm7♭5': 'm7b5',
  '7(9)': '9',
  '7(♯9)': '7#9',
  '7(♭9)': '7b9',
  '7(13)': '13',
  'maj7(6)': 'maj7', // maj76
  'Δ7(6)': 'maj7', // maj76
  'maj6(7)': 'maj7', // maj76
  maj6: '6',
  '7(b9)': '7b9',
  '7(#11)': '7b5',
  '7(9,#11)': '9#11',
  '7(9, #11)': '9#11',
  '7(b13)': 'maj7#5', // 7#5
  'aug(b7)': 'aug7',
  'maj(9)': 'add9',
  'aug(b7,9)': 'aug9',
  min6: 'm6',
  min7: 'm7',
  min9: 'm7', // m79
  min11: 'm7', // min79
  minmaj7: 'mmaj7',
  'min(9)': 'm9',
  'dim7(b13)': 'dim7', // dim7sharp5
  hdim7: 'm7b5',
  sus4: 'sus4',
  'sus4(b7)': '7sus4',
  'sus2(b7)': 'sus2',
  '7(♭13)': '7',
  Δ7: 'maj7',
  'Δ7(9)': 'maj9',
  '+': 'aug',
  '+7': 'aug7',
  '+7(9)': 'aug9',
  '-': 'minor',
  '-6': 'm6',
  '7(#9)': '7#9',
  'min6(9)': 'm69',
  '-6(9)': 'm69',
  'm6(9)': 'm69',
  '-7': 'm7',
  '-7(9)': 'm7',
  '-7(9,11)': 'm7', // m79
  '-Δ7': 'mmaj7',
  '-(9)': 'm9',
  o: 'dim',
  o7: 'dim7',
  'o7(♭13)': 'dim7', // dim7sharp5
  ø7: 'm7b5',
  '7sus2': 'sus2',
  'aug7(9)': 'aug9',
  'm7(9)': 'm7',
  'm7(9,11)': 'm7',
  'm(9)': 'm9',
  'dim7(♭13)': 'dim7'
}

const getFinalChords = (suffix: string, list: any[]): any => {
  let finalChords = list.find((i: any) => i.suffix === suffix)
  if (!finalChords) {
    finalChords = list.find((i: any) => i.suffix === suffixMap[suffix])
  }
  // Fallback to major or minor if suffix not found
  // comment this if you want to show nothing or test the ones with errors
  // if (!finalChords) {
  //   finalChords = list.find((i: any) =>
  //     i.suffix === isMinor ? 'minor' : 'major'
  //   )
  // }
  return finalChords
}

const mapKeys: { [key: string]: string } = {
  C: 'C',
  Db: 'Csharp',
  Dbm: 'Csharp',
  D: 'D',
  Eb: 'Eb',
  E: 'E',
  F: 'F',
  Gb: 'Fsharp',
  G: 'G',
  Ab: 'Ab',
  A: 'A',
  Bb: 'Bb',
  B: 'B',
  'C#': 'Csharp',
  'F#': 'Fsharp',
  Cm: 'C',
  'C#m': 'Csharp',
  Dm: 'D',
  Ebm: 'Eb',
  Em: 'E',
  Fm: 'F',
  'F#m': 'Fsharp',
  Gm: 'G',
  'G#': 'Ab',
  'G#m': 'Ab',
  Am: 'A',
  Bbm: 'Bb',
  Bm: 'B',
  'D#': 'Eb',
  'D#m': 'Eb',
  Abm: 'Ab',
  'A#': 'Bb',
  'A#m': 'Bb'
}

// old version
// export const getDiagram = (
//   key?: string,
//   keyType?: string
// ): ChordsDiagram | null => {
//   if (!key || !mapKeys[key]) {
//     return null
//   }

//   const { guitar }: { guitar: any } = instruments

//   // console.log(guitar)

//   const list: ChordsDiagram[] = guitar.chords[mapKeys[key]]

//   const map: any = {
//     // maj: 'major',
//     m: 'minor'
//   }
//   const suffix =
//     keyType?.length === 0
//       ? 'major'
//       : keyType && map[keyType]
//       ? map[keyType]
//       : keyType

//   if (!list || !list.length) {
//     return null
//   }

//   return list.find((i: any) => i.suffix === suffix) || null
// }

export const getDiagram = (
  key: string,
  suffix: string
): ChordsDiagram | null => {
  if (!key || !mapKeys[key]) {
    return null
  }

  const { guitar }: { guitar: any } = instruments

  const list: ChordsDiagram[] = guitar.chords[mapKeys[key]]

  if (!list || !list.length) {
    return null
  }

  const finalChords = getFinalChords(suffix, list)

  return finalChords
}
