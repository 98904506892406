import { Icon } from 'scala'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import styles from './styles.module.scss'

export type ItemSwitch = {
  id?: string
  title: string
  active?: boolean
  disabled?: boolean
  locked?: boolean
  onClick(): void
}

interface Props {
  className?: string
  dark?: boolean
  items: ItemSwitch[]
  onClickLockedItem?(): void
}

export const SwitchChannel: React.FC<Props> = ({
  className,
  dark,
  items,
  onClickLockedItem
}) => {
  const activeIndex = useMemo(
    () => items.findIndex((item: ItemSwitch) => item.active),
    [items]
  )

  return (
    <ul
      data-index={activeIndex}
      data-total={items.length}
      className={classnames(className, styles.list, {
        [styles.dark]: dark,
        [styles.activeSecond]: activeIndex === 1
      })}
    >
      {items.map((item: ItemSwitch) => (
        <li key={item.title} className={styles.item}>
          <button
            id={item.id}
            type="button"
            disabled={item.disabled}
            onClick={item.locked ? onClickLockedItem : item.onClick}
            className={classnames(styles.button, {
              [styles.active]: item.active
            })}
          >
            {item.locked && (
              <Icon
                name="lock"
                width={16}
                height={16}
                className={styles.icon}
              />
            )}
            {item.title}
          </button>
        </li>
      ))}
    </ul>
  )
}
