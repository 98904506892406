export default {
  key: 'C#',
  suffix: 'maj13',
  positions: [
    {
      frets: 'x41311',
      fingers: '042301'
    },
    {
      frets: 'x44566',
      fingers: '011234',
      barres: 4,
      capo: true
    },
    {
      frets: '988898',
      fingers: '211131',
      barres: 8,
      capo: true
    },
    {
      frets: '99aab9',
      fingers: '112341',
      barres: 9,
      capo: true
    }
  ]
}
