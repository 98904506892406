import { DropMenu, Icon } from 'scala'
import classnames from 'classnames'
import React, {
  ReactElement,
  ReactNode,
  RefObject,
  useCallback,
  useRef,
  useState
} from 'react'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import styles from './actions-task.module.scss'
import { RepeatSong, RepeatSongProps } from '../repeat-song'
import { UseEffects } from '../../hooks/use-effects'
import { trigger } from '../../lib/events'

export interface ActionsTaskProps {
  className?: string
  position?: 'left' | 'right' | 'leftTop'
  highlight?: boolean
  actions?: ReactNode[]
  effects?: UseEffects
}

function renderAction(
  action: ReactNode | 'repeat',
  { effects }: RepeatSongProps
): ReactNode {
  if (!React.isValidElement(action)) {
    return null
  }

  if (action.type === RepeatSong) {
    // return <RepeatSong effects={effects} />
    return React.cloneElement(action as ReactElement<RepeatSongProps>, {
      effects
    })
  }

  // return React.isValidElement(action) && React.cloneElement(action)
  return action
}

export const ActionsTask: React.FC<ActionsTaskProps> = ({
  className,
  position = 'right',
  highlight,
  actions,
  effects
}) => {
  const ref: RefObject<any> = useRef()
  const [showDrop, setShowDrop] = useState(false)

  useOnClickOutside(ref, () => setShowDrop(false))

  const handleClickShowOptions = useCallback(
    (event: any) => {
      event.stopPropagation()
      setShowDrop(!showDrop)
      trigger('instructions:close-tooltip')
    },
    [showDrop]
  )

  return (
    <div ref={ref} className={styles.container}>
      <div
        className={classnames(styles.drop, className, {
          [styles.show]: showDrop
        })}
      >
        <button
          type="button"
          className={styles.button}
          onClick={handleClickShowOptions}
        >
          <Icon
            name="more"
            className={classnames(styles.iconMore, {
              [styles.active]: highlight
            })}
          />
        </button>

        <DropMenu
          className={classnames(styles.dropMenu, {
            [styles.show]: showDrop,
            [styles.left]: position === 'left',
            [styles.leftTop]: position === 'leftTop',
            [styles.right]: position === 'right'
          })}
        >
          {actions?.map((action, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>{renderAction(action, { effects })}</div>
          ))}
        </DropMenu>

        <div id="player-actions-portal" />
      </div>
    </div>
  )
}
