import { useCallback, useContext, useMemo } from 'react'
import { Context } from 'scala'
import { useRouter } from 'next/router'
import { useDataManageGroup } from '../use-data-manage-group'

type UseAllowGroupPlan = {
  allowGroupPlan: boolean
  redirectToHome: () => void
}

export const useAllowGroupPlan = (): UseAllowGroupPlan => {
  const { user } = useContext(Context)
  const { push } = useRouter()
  const { isOwner, isActiveManageGroup } = useDataManageGroup()

  const isFree = useMemo(() => user?.subscription?.plan === 'free', [user])

  const isStripeSubscription = useMemo(
    () => user?.subscription?.details?.providerName === 'stripe',
    [user]
  )

  const allowGroupPlan = useMemo(
    () => isActiveManageGroup && (isOwner || isFree || isStripeSubscription),
    [isActiveManageGroup, isStripeSubscription, isOwner, isFree]
  )

  const redirectToHome = useCallback(() => {
    push('/library')
  }, [push])

  return {
    allowGroupPlan,
    redirectToHome
  }
}
