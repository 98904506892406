export default {
  key: 'G',
  suffix: '7b5',
  positions: [
    {
      frets: '3x342x',
      fingers: '203410'
    },
    {
      frets: 'xx5667',
      fingers: '001234'
    },
    {
      frets: 'xa9a09',
      fingers: '031402'
    },
    {
      frets: 'xabacx',
      fingers: '012130',
      barres: 10,
      capo: true
    }
  ]
}
