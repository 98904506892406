function getBeatTime(beat: { time: number } | number | undefined): number {
  if (typeof beat === 'number') {
    return Math.round(beat * 1000)
  }

  return (beat?.time ?? NaN) * 1000
}

/**
 * Finds the nearest beat time using binary search.
 */
function findNearestBeatTime(
  position: number,
  beats: { time: number }[] | null,
  { maxDistance = Infinity }: { maxDistance?: number } = {}
): number {
  if (!beats?.length) {
    return position
  }

  let left = 0
  let right = beats.length - 1
  let mid = 0

  while (left <= right) {
    mid = Math.floor((left + right) / 2)

    if (getBeatTime(beats[mid]) === position) {
      break
    }

    if (getBeatTime(beats[mid]) < position) {
      left = mid + 1
    } else {
      right = mid - 1
    }
  }

  const nextTime = getBeatTime(beats[mid])
  const prevTime = getBeatTime(beats[mid - 1]) ?? nextTime

  const shouldUsePrev =
    Math.abs(position - prevTime) < Math.abs(position - nextTime)
  const time = shouldUsePrev ? prevTime : nextTime
  const isTooFar = Math.abs(position - time) > maxDistance
  return isTooFar ? position : time
}

/**
 * Move the range positions to the nearest beats.
 */
export function moveRangeMsToNearestBeats(
  rangeMs: [number, number],
  beats: { time: number }[] | null,
  {
    maxDistance = Infinity,
    leftOnly = false
  }: {
    maxDistance?: number
    leftOnly?: boolean
  } = {}
): [number, number] {
  const [originalFrom, originalTo] = rangeMs

  const from = findNearestBeatTime(originalFrom, beats, { maxDistance })

  if (leftOnly) {
    const to = originalTo + (from - originalFrom)
    return [from, to]
  }

  const to = findNearestBeatTime(originalTo, beats, { maxDistance })
  return [from, to]
}
