import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './button.module.scss'

interface ButtonProps {
  className?: string
  disabled?: boolean
  active?: boolean
  opened?: boolean
  text?: string
  icon?: React.ReactNode
  extraMarginIconDrop?: boolean
  iconDrop?: boolean
  viewOnly?: boolean
  id?: string
  onClick?(): void
}

export const Button: React.FC<ButtonProps> = ({
  className,
  iconDrop,
  icon,
  text,
  active,
  opened,
  disabled,
  extraMarginIconDrop,
  viewOnly,
  id,
  onClick
}) => {
  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      disabled={disabled || viewOnly}
      className={classnames(className, styles.button, {
        [styles.active]: active,
        [styles.opened]: opened,
        [styles.disabled]: disabled,
        [styles.viewOnly]: viewOnly
      })}
    >
      {icon}
      {text && (
        <p
          className={classnames(styles.text, {
            [styles.extraMargin]: !iconDrop || viewOnly
          })}
        >
          {text}
        </p>
      )}
      {iconDrop && !viewOnly && (
        <Icon
          name="chevron"
          width={16}
          height={16}
          className={classnames(styles.icon, {
            [styles.active]: active,
            [styles.invert]: opened,
            [styles.extraMargin]: !text || extraMarginIconDrop
          })}
        />
      )}
    </button>
  )
}
