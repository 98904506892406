import { useCallback, useMemo } from 'react'
import { UseCountIn } from '../use-count-in'
import { UseEffects } from '../use-effects'
import { useAudioEngine } from '../use-audio-engine'
import { useEventListener } from '../misc/use-event-listener'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { ControllerPlayer, SettingsPlayer } from '../../types'
import { trigger } from '../../lib/events'

interface UseControlPlayerProps {
  playerApi: PlayerApi
  effects?: UseEffects
  countIn?: UseCountIn
  settings?: SettingsPlayer
  controller?: ControllerPlayer
}

export const useControlPlayer = ({
  playerApi,
  effects,
  settings,
  countIn,
  controller
}: UseControlPlayerProps): void => {
  const KEY_SPACE = useMemo(() => [32, 'Space'], [])
  const KEY_C = useMemo(() => [67, 'KeyC'], [])
  const KEY_R = useMemo(() => [82, 'KeyR'], [])
  const KEY_ARROW_LEFT = useMemo(() => [37, 'KeyArrowLeft'], [])
  const KEY_ARROW_UP = useMemo(() => [38, 'KeyArrowUp'], [])
  const KEY_ARROW_RIGHT = useMemo(() => [39, 'KeyArrowRight'], [])
  const KEY_ARROW_DOWN = useMemo(() => [40, 'KeyArrowDown'], [])
  const KEY_ZERO = useMemo(() => [48, 'KeyZero'], [])
  const KEY_ZERO_NUMPAD = useMemo(() => [96, 'KeyZeroNumpad'], [])

  const isPlaying = useAudioEngine((p) => p.state.isPlaying)
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const isLooping = useAudioEngine((p) => p.state.isLooping)

  const togglePlay = useCallback(() => {
    if (isPlaying) {
      playerApi?.pause()
    } else {
      playerApi?.play()
    }
  }, [playerApi, isPlaying])

  const handleCycle = useCallback(() => {
    effects?.toggleCycle()
  }, [effects])

  const handleRepeat = useCallback(() => {
    effects?.toggleRepeat()
  }, [effects])

  const handleVolumeDown = useCallback(() => {
    playerApi.masterVolumeDecrease(0.05)
    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'master_volume'
    })
  }, [playerApi, controller])

  const handleVolumeUp = useCallback(() => {
    playerApi?.masterVolumeIncrease(0.05)
    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'master_volume'
    })
  }, [playerApi, controller])

  const handlePlayFromBeggining = useCallback(() => {
    if (isLooping) {
      trigger('timeline:seek-cycle', { seconds: 0 })
    } else {
      playerApi?.seek(0)
    }
  }, [playerApi, isLooping])

  const handleMoveForward = useCallback(() => {
    playerApi.forward(10)
  }, [playerApi])

  const handleMoveBackward = useCallback(() => {
    playerApi.backward(10)
  }, [playerApi])

  const onHandlerKeyDown = useCallback(
    (e: any) => {
      if (
        !settings?.shortcuts ||
        e.target.matches('textarea') ||
        e.target.matches('input') ||
        e?.ctrlKey ||
        e?.altKey ||
        e?.shiftKey ||
        e?.metaKey
      ) {
        return
      }

      // prevent controls when editing text
      if (isReadyToPlay && !e.target.getAttribute('contenteditable')) {
        switch (e.keyCode) {
          case KEY_SPACE[0]:
            if (countIn?.isStarted) break
            e.preventDefault()
            togglePlay()
            break
          case KEY_C[0]:
            e.preventDefault()
            handleCycle()
            break
          case KEY_R[0]:
            e.preventDefault()
            handleRepeat()
            break
          case KEY_ZERO[0]:
          case KEY_ZERO_NUMPAD[0]:
            e.preventDefault()
            handlePlayFromBeggining()
            break
          case KEY_ARROW_LEFT[0]:
            if (countIn?.isStarted) break
            e.preventDefault()
            handleMoveBackward()
            break
          case KEY_ARROW_UP[0]:
            e.preventDefault()
            handleVolumeUp()
            break
          case KEY_ARROW_RIGHT[0]:
            if (countIn?.isStarted) break
            e.preventDefault()
            handleMoveForward()
            break
          case KEY_ARROW_DOWN[0]:
            e.preventDefault()
            handleVolumeDown()
            break
          default:
        }
      }
    },
    [
      countIn,
      KEY_SPACE,
      KEY_C,
      KEY_R,
      KEY_ZERO,
      KEY_ZERO_NUMPAD,
      KEY_ARROW_DOWN,
      KEY_ARROW_LEFT,
      KEY_ARROW_RIGHT,
      KEY_ARROW_UP,
      isReadyToPlay,
      settings?.shortcuts,
      handleCycle,
      handleRepeat,
      togglePlay,
      handlePlayFromBeggining,
      handleMoveBackward,
      handleMoveForward,
      handleVolumeDown,
      handleVolumeUp
    ]
  )
  useEventListener('keydown', onHandlerKeyDown)
}
