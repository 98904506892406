import React from 'react'
import styles from './skeleton.module.scss'

export const Skeleton: React.FC = () => {
  return (
    <div className={styles.container}>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  )
}
