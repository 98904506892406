import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { AudioEngine, AudioEngineType } from '../../lib/audio-engines/types'
import { useSupportsSuperpowered } from '../../lib/audio-engines/hooks'
import { getAudioEngine } from '../../lib/audio-engines/get-audio-engine'

const AUDIO_ENGINE_TYPES: Set<string> = new Set<AudioEngineType>([
  'superpowered',
  'wavesjs'
])

export function useAudioEngineType(): AudioEngineType {
  const { query } = useRouter()
  const audioEngineStorageItem = useMemo(
    () => 'localStorage' in globalThis && localStorage.getItem('audio-engine'),
    []
  )
  const supportsSuperpowered = useSupportsSuperpowered()

  if (AUDIO_ENGINE_TYPES.has(query.engine as string)) {
    return query.engine as AudioEngineType
  }

  if (
    audioEngineStorageItem &&
    AUDIO_ENGINE_TYPES.has(audioEngineStorageItem)
  ) {
    return audioEngineStorageItem as AudioEngineType
  }

  return supportsSuperpowered ? 'superpowered' : 'wavesjs'
}

function createAudioEngineHook(key?: string): AudioEngine {
  return ((...args: []) => {
    const type = useAudioEngineType()
    const hook = getAudioEngine(type, key)
    return hook(...args)
  }) as AudioEngine
}

export const useAudioEngine = createAudioEngineHook()

export const useCountInAudioEngine = createAudioEngineHook('count-in')
