import { Context as ContextAppFrame } from 'scala'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useRouter } from 'next/router'
import { UserContext } from 'scala/src/types'
import { usePricing } from '../../../pricing/use-pricing'
import { Context } from '../../../../context'
import { config } from '../../../../config'

export interface PlanPrice {
  price?: string
  regularPrice: string
  monthlyPrice?: string
  monthlyRegularPrice: string
  paymentLink?: string
  planId?: string
}

export interface Plan {
  currencyCode: string
  currencySymbol: string
  price: {
    [key: string]: PlanPrice
    monthly: PlanPrice
    yearly: PlanPrice
  }
}

export interface Plans {
  global: Plan
  pro: Plan
}

interface UseCheckoutCommon {
  cycle: 'monthly' | 'yearly'
  plan: 'premium' | 'pro'
  plans: Plans
  onChangePlan(e: React.ChangeEvent<HTMLSelectElement>): void
  onPaymentFail(reason?: string | undefined): void
}

const getLinkPayment = (
  cycle: any,
  env: string,
  provider: string,
  user: UserContext
): string => {
  const hasTrial = !user?.subscription?.hasUsedStripeTrial

  const payment = cycle?.providers?.[env]?.[provider]?.paymentLink?.find(
    (p: any) => p?.hasTrial === hasTrial
  )

  if (!payment?.link) {
    return ''
  }

  let link = payment?.link

  if (user) {
    link += `?client_reference_id=${user?.id}&prefilled_email=${user?.email}`
  }

  if (payment?.promoCode) {
    link += `&prefilled_promo_code=${payment?.promoCode}`
  }

  return link
}

const getPlanId = (cycle: any, env: string, provider: string): string => {
  return cycle?.providers?.[env]?.[provider]?.planIds?.[0] || ''
}

export const useCheckoutCommon = (): UseCheckoutCommon => {
  const router = useRouter()
  const { user } = useContext(ContextAppFrame)

  const [cycle, setCycle] = useState<'monthly' | 'yearly'>(
    router.query?.cycle === 'monthly' ? 'monthly' : 'yearly'
  )

  const [plan, setPlan] = useState<'premium' | 'pro'>(
    router.query?.plan === 'pro' ? 'pro' : 'premium'
  )

  const {
    toast: { add: addToast }
  } = useContext(Context)

  const { premium: planPremium, pro: planPro } = usePricing()

  const global: Plan = useMemo(() => {
    const { env } = config
    const planGlobalMonthly = planPremium.cycles.find(
      (c) => c.name === 'monthly'
    )

    const planGlobalYearly = planPremium.cycles.find((c) => c.name === 'yearly')

    return {
      currencyCode: planPremium.currencyCode,
      currencySymbol: planPremium.currencySymbol,
      price: {
        monthly: {
          regularPrice: planGlobalMonthly?.regularPrice.toFixed(2) || '0.00',
          monthlyRegularPrice: parseFloat(
            (planGlobalMonthly?.friendlyFormat || '0').replace(',', '.')
          ).toFixed(2),
          planId: getPlanId(planGlobalMonthly, env, 'stripe')
        },
        yearly: {
          regularPrice: planGlobalYearly?.regularPrice.toFixed(2) || '0.00',
          monthlyRegularPrice: parseFloat(
            (planGlobalYearly?.friendlyFormat || '0').replace(',', '.')
          ).toFixed(2),
          planId: getPlanId(planGlobalYearly, env, 'stripe')
        }
      }
    }
  }, [planPremium])

  const pro: Plan = useMemo(() => {
    const { env } = config
    const planMonthly = planPro.cycles.find((c) => c.name === 'monthly')
    const planYearly = planPro.cycles.find((c) => c.name === 'yearly')

    return {
      currencyCode: planPro.currencyCode,
      currencySymbol: planPro.currencySymbol,
      price: {
        monthly: {
          regularPrice: planMonthly?.regularPrice.toFixed(2) || '0.00',
          monthlyRegularPrice: parseFloat(
            (planMonthly?.friendlyFormat || '0').replace(',', '.')
          ).toFixed(2),
          paymentLink: getLinkPayment(planMonthly, env, 'stripe', user),
          planId: getPlanId(planMonthly, env, 'stripe')
        },
        yearly: {
          regularPrice: planYearly?.regularPrice.toFixed(2) || '0.00',
          monthlyRegularPrice: parseFloat(
            (planYearly?.friendlyFormat || '0').replace(',', '.')
          ).toFixed(2),
          paymentLink: getLinkPayment(planYearly, env, 'stripe', user),
          planId: getPlanId(planYearly, env, 'stripe')
        }
      }
    }
  }, [planPro, user])

  const onChangePlan = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const [cycleValue, nameValue] = e.target.value.split('-')
      setCycle(cycleValue as 'monthly' | 'yearly')
      setPlan(nameValue as 'premium' | 'pro')
    },
    []
  )

  const onPaymentFail = useCallback(
    (reason: string | undefined) => {
      addToast({
        icon: null,
        type: 'error',
        closable: true,
        description: reason || 'Payment failed'
      })
    },
    [addToast]
  )

  return {
    plan,
    cycle,
    plans: {
      global, // premium
      pro
    },
    onChangePlan,
    onPaymentFail
  }
}
