import { useEffect, useRef } from 'react'
import { equals } from 'ramda'
import { useDebouncedCallback } from 'use-debounce'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { ControllerPlayer, StatePlayer } from '../../types'
import { AudioEngineState } from '../../lib/audio-engines/types'
import { UseCountIn } from '../use-count-in'
import { UseEffects } from '../use-effects'
import { usePanel } from '../../modules/panel/edits/store'
import { usePanelAsideStore } from '../../modules/panel-aside/edits/store'

interface UsePersistStateProps {
  controller?: ControllerPlayer
  countIn: UseCountIn
  effects?: UseEffects
}

interface PersistenceDependencies {
  channels: AudioEngineState['channels']
  masterVolume: number
  isLooping: boolean
  loop: Exclude<AudioEngineState['state']['loop'], null>
  isRepeating: boolean
  countIn: UseCountIn
  typeChords: string | null
  showChords: boolean
  showLyrics: boolean
  showSections: boolean
}

function applyChanges(
  state: StatePlayer,
  {
    channels,
    masterVolume,
    isLooping,
    loop,
    isRepeating,
    countIn,
    typeChords,
    showLyrics,
    showChords,
    showSections
  }: PersistenceDependencies
): boolean {
  const newState = {
    channels: Object.values(channels).map((channel) => ({
      id: channel.id,
      volume: channel.volume,
      realVolume: channel.realVolume,
      pan: channel.pan,
      isMuted: channel.isMuted,
      isSolo: channel.isSolo,
      playbackRate: channel.playbackRate * 100,
      pitchShiftCents: channel.pitchShiftCents
    })),
    typeChords,
    masterVolume,
    loop: {
      isLooping,
      fromMs: loop?.fromMs ?? 0,
      toMs: loop?.toMs ?? 0
    },
    isRepeating,
    countIn: {
      enabled: countIn.enabled,
      count: countIn.count
    },
    panels: {
      showLyrics,
      showChords,
      showSections
    }
  }

  if (equals(state, newState)) {
    return false
  }

  Object.assign(state, newState)
  return true
}

export const usePersistState = ({
  controller,
  countIn,
  effects
}: UsePersistStateProps): void => {
  const watching = !!controller?.onStateChanged
  const stateRef = useRef<StatePlayer>({ channels: [] })
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const channels = useAudioEngine((p) => p.channels)
  const masterVolume = useAudioEngine((p) => p.state.masterVolume)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const loop = useAudioEngine((p) => p.state.loop) ?? undefined
  const isRepeating = useAudioEngine((p) => p.state.isRepeating)
  const showLyrics = usePanel((state) => state.showLyrics)
  const showChords = usePanel((state) => state.showChords)
  const typeChords = usePanel((state) => state.typeChords)
  const showSections = usePanelAsideStore((state) => state.showSections)

  const update = useDebouncedCallback(
    (state: StatePlayer, dependencies: PersistenceDependencies) => {
      const changed = applyChanges(state, dependencies)

      if (changed) {
        controller?.onStateChanged?.({
          ...state,
          key: effects?.songKey?.value,
          bpm: effects?.songTempo?.value,
          tempo: effects?.songTempo?.relativeValue,
          semitones: effects?.songKey?.pitch,
          newTuning: effects?.songKey?.estimatedTuning,
          originalTuning: effects?.songKey?.originalTuning
        })
      }
    },
    500,
    { maxWait: 1000 }
  )

  useEffect(() => {
    if (watching && isReadyToPlay) {
      update(stateRef.current, {
        channels,
        masterVolume,
        isLooping,
        loop,
        isRepeating,
        countIn,
        typeChords,
        showLyrics,
        showChords,
        showSections
      })
    }
  }, [
    isReadyToPlay,
    watching,
    controller,
    update,
    channels,
    masterVolume,
    isLooping,
    loop,
    isRepeating,
    countIn,
    typeChords,
    showLyrics,
    showChords,
    showSections
  ])
}
