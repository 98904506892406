export default {
  key: 'E',
  suffix: '9#11',
  positions: [
    {
      frets: '010100',
      fingers: '010200'
    },
    {
      frets: '050354',
      fingers: '030142'
    },
    {
      frets: 'x76776',
      fingers: '021341',
      barres: 6,
      capo: true
    },
    {
      frets: 'cbcbbc',
      fingers: '213114',
      barres: 11,
      capo: true
    }
  ]
}
