import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React from 'react'
import styles from './styles.module.scss'

export interface Props {
  className?: string
  total?: number
  loading?: boolean
}

export const TrackListHeader: React.FC<Props> = ({
  className,
  total,
  loading
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      <p className={styles.title} id="songs_count">
        {total && !loading
          ? total === 1
            ? i18n._(t`playlist.song`).replace(/\*1\*/g, `${total}`)
            : i18n._(t`playlist.songs`).replace(/\*3\*/g, `${total}`)
          : null}
      </p>

      <span />
      <p className={styles.key}>{i18n._(t`label_song_key`)}</p>
      <p className={styles.bpm}>bpm</p>
      <span />
      <span />
    </div>
  )
}
