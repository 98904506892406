import { create } from 'zustand'
import { immer } from 'zustand/middleware/immer'
import { Player } from './player'
import { AudioEngine, AudioEnginePlayerApi, AudioEngineState } from '../types'
import { FnQueue, queuedFn } from '../../../utils/queued-fn'
import { attachEmulatedFeatures } from '../emulated-features'

const AVAILABLE_FEATURES = new Set(['loop', 'repeat'])

const INITIAL_WAVES: any = []

const INITIAL_BEAT_MAP: any = null

const INITIAL_STATE = {
  isReadyToPlay: false,
  isPlaying: false,
  isLooping: false,
  isRepeating: false,
  isSpatialized: false,
  loop: null,
  durationMs: 0,
  positionMs: 0,
  masterVolume: 1,
  pitchShiftCents: 0,
  playbackRate: 1,
  channel: {}
}

export const createWavesJsHook = (playerId: string): AudioEngine => {
  console.log('wavesjs %s create', playerId)

  const loadQueue = new FnQueue()

  let player: any
  let initialized = false
  let controller: any

  const playerApi: AudioEnginePlayerApi = {
    seek: (position) => {
      player.seek(position)
    },
    pause: () => {
      player.pause()
    },
    play: () => {
      player.play()
    },
    loop: (fromMs, toMs) => {
      player.loop(fromMs, toMs)
    },
    unLoop: () => {
      player.unLoop()
    },
    playbackRate: (value) => {
      player.playbackRate(value)
    },
    pitchShift: (factor: any) => {
      const semitones = parseFloat(factor) / 100
      player.pitchShift(2 ** (semitones / 12))
    },
    enableSpatial: (value) => {
      player.enableSpatial(value)
    },
    masterVolume: (value) => {
      player.masterVolume(value)
    },
    mute: (value) => {
      player.mute(value)
    },
    repeat: () => {
      // Emulated feature
      // player.repeat(enabled)
    },
    channel: {
      volume: (channelId, value) => {
        player.channel.volume(channelId, value)
      },
      spatialize: (channelId, value) => {
        player.channel.spatialize(channelId, value)
      },
      pan: (channelId, value) => {
        player.channel.pan(channelId, value)
      },
      mute: (channelId, value) => {
        player.channel.mute(channelId, value)
      },
      solo: (channelId, value) => {
        player.channel.solo(channelId, value)
      }
    }
  }

  const store = create(
    immer<AudioEngineState>((set, get) => ({
      engine: 'wavesjs',
      beatMap: INITIAL_BEAT_MAP,
      waveforms: INITIAL_WAVES,
      state: INITIAL_STATE,
      channels: {},
      requestedLoadedChannels: [],
      loadMultitrackPlayer: queuedFn(async () => {
        if (initialized) {
          return
        }

        initialized = true

        player = await Player({
          playerId,
          fade: playerId === 'main',
          onStatusChange: (state: any) => {
            // Emulated feature
            // eslint-disable-next-line no-param-reassign
            delete state.isRepeating
            set({
              state: { ...get().state, ...state },
              channels: state.channel
            })
          }
        })
      }),
      loadWaveforms: async (channels) => {
        if (!channels.some((i) => i.wavesUrl)) {
          return
        }

        controller = new AbortController()
        const { signal } = controller
        const options = {
          signal,
          priority: 'low'
        }
        console.time('generate waveform')
        const listWaveData = channels.map((i) => {
          if (!i?.wavesUrl) return {}
          return fetch(i.wavesUrl, options)
            .then((response) => response.json())
            .catch(() => {})
        })
        const result = await Promise.all(listWaveData)

        const dataWaveforms: any = result.map((b: any, index) => ({
          id: channels[index].id,
          data: b || {}
        }))
        console.timeEnd('generate waveform')

        if (dataWaveforms?.length) {
          set({ waveforms: dataWaveforms })
        }
      },
      api: {
        ...playerApi,
        getAvailableFeatures: () => AVAILABLE_FEATURES,
        loadBeatMap: async (beatMapUrl) => {
          const result = await fetch(beatMapUrl).then((response) =>
            response.json()
          )
          if (result?.length) {
            set({ beatMap: result })
          }
        },
        loadChannels: loadQueue.queued(async (channels) => {
          await get().loadMultitrackPlayer()
          get().loadWaveforms(channels)

          const channelsWithUrl = channels.filter((channel: any) => channel.url)

          const channelsPairs = channelsWithUrl.map((channel: any) => [
            channel.id,
            channel.url
          ])
          const channelsMap = Object.fromEntries(channelsPairs)

          set({ requestedLoadedChannels: channelsWithUrl })

          await player.loadChannels(channelsMap)

          channelsWithUrl.forEach((channel: any) => {
            playerApi.channel.volume(channel.id, channel.volume ?? 1)
            playerApi.channel.pan(channel.id, channel.pan ?? 0)
            playerApi.channel.mute(channel.id, channel.isMuted ?? false)
            playerApi.channel.solo(channel.id, channel.isSolo ?? false)
          })
        }),
        destroy: async () => {
          loadQueue.abort()
          if (controller) {
            controller.abort()
          }

          loadQueue.queued(async () => {
            if (player) {
              playerApi.pause()
              await player.loadChannels({})
            }
            set({ waveforms: INITIAL_WAVES })
          })()
        }
      }
    }))
  )

  attachEmulatedFeatures(store)

  return store
}
