import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import styles from './lyrics-timeline.module.scss'
import { Skeleton } from './skeleton'
import { StateFeature } from '../state-feature/state-feature'
import { Timeline } from './timeline'
import { LyricsItem } from '../../hooks/use-lyrics-timeline'
import { ControllerPlayer, DataPlayer, SettingsPlayer } from '../../types'

export interface LyricsProps {
  className?: string
  smallVersion?: boolean
  list?: LyricsItem[]
  data?: DataPlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
}

export const LyricsTimeline: React.FC<LyricsProps> = ({
  className,
  smallVersion,
  list,
  data,
  settings,
  controller
}) => {
  const { i18n } = useLingui()

  const { empty, update, error, processing } = useMemo(() => {
    return {
      empty: data?.lyrics?.empty,
      availableLanguages: data?.lyrics?.availableLanguages,
      blocked: data?.lyrics?.blocked,
      error: data?.lyrics?.status === 'failed',
      update: data?.lyrics?.status === 'update',
      processing: data?.lyrics?.status === 'loading'
    }
  }, [data?.lyrics])

  return (
    <div className={classnames(className, styles.container)}>
      {
        // Disabled selection of lyrics from user
        // empty || !list ? (
        //   <Empty
        //     blocked={blocked}
        //     addLyrics={controller?.lyrics?.onAddLyrics}
        //     languages={availableLanguages || []}
        //     smallVersion={smallVersion}
        //   />
        // ) :
        error ? (
          <StateFeature icon="no-lyrics" title={i18n._(t`no_transcription`)} />
        ) : empty || !list || (processing && !update) ? (
          <Skeleton
            isProcessing={(empty || !list || processing) && !update}
            smallVersion={smallVersion}
          />
        ) : (
          <Timeline
            list={list}
            smallVersion={smallVersion}
            limited={!!settings?.lyrics?.limited}
            onLockedClick={controller?.onLockedFeatureClick}
          />
        )
      }
    </div>
  )
}
