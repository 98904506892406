import { useCallback, useState } from 'react'
import { isDesktopApp } from '../../authentication/use-authentication/providers/helpers'
import { compareVersions } from './utils'

export interface UsePluginContext {
  loading: boolean
  needUpdate: {
    stems: boolean
    mastering: boolean
    studio: boolean
  }
  needInstall: {
    stems: boolean
    mastering: boolean
    studio: boolean
  }
  release: {
    stems: string | null
    mastering: string | null
    studio: string | null
  }
  needUpdateDesktopApp: boolean
  showNotification: boolean
  setViewedNotification: () => void
  checkVersionInstaled(): void
  isVersionDesktopAppValid(): boolean
}

const STORAGE_VERSION_PLUGIN = 'moises:plugin-version'
export const DESKTOP_APP_VERSION_REQUIRED = '1.0.48'

export const usePluginContext = (): UsePluginContext => {
  const [loading, setLoading] = useState(false)
  const [needUpdateDesktopApp, setNeedUpdateDesktopApp] = useState(false)

  const [stemVersionRelease, setStemVersionRelease] = useState<string | null>(
    null
  )

  const [stemNeedInstall, setStemNeedInstall] = useState(false)
  const masteringNeedInstall = false
  const studioNeedInstall = false

  const [stemNeedUpdate, setStemNeedUpdate] = useState(false)
  const masteringNeedUpdate = false
  const studioNeedUpdate = false

  const [showNotification, setShowNotification] = useState(false)

  const setViewedNotification = useCallback(() => {
    if (!stemVersionRelease) return
    localStorage.setItem(STORAGE_VERSION_PLUGIN, stemVersionRelease)
    setShowNotification(false)
  }, [stemVersionRelease])

  const verifyNotification = useCallback((version?: string) => {
    if (!version || localStorage?.getItem(STORAGE_VERSION_PLUGIN) !== null) {
      return
    }
    setShowNotification(true)
  }, [])

  const isVersionDesktopAppValid = useCallback(() => {
    if (!isDesktopApp) {
      setNeedUpdateDesktopApp(false)
      return true
    }

    const versionDesktopApp = navigator?.userAgent?.split('-')?.[1]
    if (
      versionDesktopApp &&
      compareVersions(versionDesktopApp, DESKTOP_APP_VERSION_REQUIRED) === -1
    ) {
      setNeedUpdateDesktopApp(true)
      return false
    }
    setNeedUpdateDesktopApp(false)
    return true
  }, [])

  const checkVersionInstaled = useCallback(async () => {
    if (!isDesktopApp) {
      return
    }
    setLoading(true)

    if (!isVersionDesktopAppValid()) {
      setStemNeedInstall(true)
    } else {
      const [userData, release] = await Promise.all([
        window?.electron?.checkVersionUser('STEMS'),
        window?.electron?.checkVersionReleased('STEMS')
      ])

      if (release?.version) {
        setStemVersionRelease(release?.version)
        verifyNotification(release?.version)
      }

      if (!userData?.version) {
        setStemNeedInstall(true)
      } else if (userData?.version && release?.version) {
        setStemNeedInstall(false)
        setStemNeedUpdate(
          compareVersions(userData?.version, release?.version) === -1
        )
      }
    }

    setLoading(false)
  }, [isVersionDesktopAppValid, verifyNotification])

  return {
    loading,
    needUpdate: {
      stems: stemNeedUpdate,
      studio: studioNeedUpdate,
      mastering: masteringNeedUpdate
    },
    needInstall: {
      stems: stemNeedInstall,
      studio: studioNeedInstall,
      mastering: masteringNeedInstall
    },
    release: {
      stems: stemVersionRelease,
      mastering: null,
      studio: null
    },
    needUpdateDesktopApp,
    showNotification,
    setViewedNotification,
    checkVersionInstaled,
    isVersionDesktopAppValid
  }
}
