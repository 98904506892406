export default {
  key: 'E',
  suffix: 'm/F#',
  positions: [
    {
      frets: '222000',
      fingers: '123000'
    },
    {
      frets: '222003',
      fingers: '123004'
    },
    {
      frets: '222453',
      fingers: '111342',
      barres: 2
    }
  ]
}
