import { Icon } from 'scala'
import React, { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import styles from './styles.module.scss'
import {
  ControllerPlayer,
  DataPlayer,
  FeaturesPlayer,
  SettingsPlayer
} from '../../types'
import { Cover } from '../cover'
import { SongKey } from '../../modules/song-key'
import { SongTempo } from '../../modules/song-tempo'
import { UseEffects } from '../../hooks/use-effects'
import { UseCountIn } from '../../hooks/use-count-in'
import { CountIn } from '../../modules/count-in'
import { ActionsTask } from '../../modules/actions'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { DownloadTask } from '../../modules/download-task'
import { ChangeOperations } from '../../modules/change-operations'
import { InfoTrack } from '../../modules/info-track'

interface Props {
  settings?: SettingsPlayer
  data?: DataPlayer
  controller?: ControllerPlayer
  effects: UseEffects
  countIn: UseCountIn
  features?: FeaturesPlayer
}

export const Header: React.FC<Props> = ({
  settings,
  data,
  controller,
  effects,
  countIn,
  features
}) => {
  const { query, push } = useRouter()

  const isDemo = useMemo(() => data?.isDemo, [data?.isDemo])

  const showOldChangeSeparations = useMemo(
    () => !isDemo && settings?.showOldChangeOperations,
    [isDemo, settings?.showOldChangeOperations]
  )

  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)
  const isRepeating = useAudioEngine((p) => p.state.isRepeating)

  const onGoPreviousHistory = useCallback(() => {
    if (query?.previous) {
      push(`/${query.previous}`)
    } else {
      window.history.go(-1)
    }
  }, [query?.previous, push])

  return (
    <div className={styles.header}>
      <div className={styles.content}>
        {settings?.backButton && (
          <button
            id="mixer_back_button"
            type="button"
            className={styles.buttonPrevious}
            onClick={onGoPreviousHistory}
          >
            <Icon name="arrow" />
          </button>
        )}

        <div className={styles.cover}>
          <Cover
            song={data?.filename}
            cover="/assets/images/cover-default.svg"
          />
        </div>
      </div>

      <div className={styles.actions}>
        {/* legacy change flow dropdown list */}
        {showOldChangeSeparations && (
          <ChangeOperations
            settings={settings}
            controller={controller}
            options={data?.separateOptions}
          />
        )}

        {!isDemo && features?.ChangeOperations}

        {data?.infoTrack ? <InfoTrack {...data.infoTrack} /> : null}

        <SongTempo {...effects.songTempo} settings={settings} />

        <SongKey {...effects.songKey} settings={settings} />

        {settings?.countIn?.view && <CountIn {...countIn} />}

        {/* {user?.featureFlags?.webPlayerSpatialAudio && effects && (
          <SpatialAudio {...effects.spatialAudio} />
        )} */}
      </div>

      {settings?.export?.view && (
        <div className={styles.download}>
          <DownloadTask
            files={data?.export}
            disabled={loading}
            controller={controller}
            limited={settings?.export?.limited}
            onLockedFeatureClick={controller?.onLockedFeatureClick}
          />
        </div>
      )}

      {settings?.actions?.length && (
        <ActionsTask
          className={styles.more}
          actions={settings.actions}
          effects={effects}
          highlight={isRepeating}
        />
      )}
    </div>
  )
}
