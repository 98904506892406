// import Chord from '@tombatossals/react-chords/lib/Chord'
import classnames from 'classnames'
import React, { useMemo } from 'react'
import { Chord } from './Chord'
import styles from './chords-diagram.module.scss'

interface ChordsDiagramProps {
  className?: string
  small?: boolean
  inactive?: boolean
  diagram?: [
    {
      frets: string
      fingers: string
      barres?: number
      capo?: boolean
    }
  ]
}

const strChord2array = (str: string): number[] =>
  str
    .split('')
    .map((char) => (char.toLowerCase() === 'x' ? -1 : parseInt(char, 16)))

const processString = (strings: string[] | string): any =>
  Array.isArray(strings) ? strings : strChord2array(strings)

const processbaseFret = (frets: number[]): number =>
  Math.max(...frets) > 4 ? Math.min(...frets.filter((f) => f > 0)) : 1

const processBarres = (
  barres: number | number[] | undefined,
  baseFret: number
): number[] =>
  barres
    ? (Array.isArray(barres) ? barres : [barres]).map((barre) =>
        baseFret > 1 ? barre - baseFret + 1 : barre
      )
    : []

const processFrets = (frets: number[], baseFret: number): number[] =>
  frets.map((fret: number) =>
    baseFret > 1 ? (fret > 0 ? fret - baseFret + 1 : fret) : fret
  )

const processFingers = (fingers?: string): number[] =>
  fingers ? processString(fingers) : []

export const ChordsDiagram: React.FC<ChordsDiagramProps> = ({
  className,
  diagram,
  inactive,
  small
}) => {
  const instrument = useMemo(
    () => ({
      strings: 6,
      fretsOnChord: 4,
      baseFret: 3,
      name: 'Guitar',
      keys: [],
      tunings: {
        standard: ['E', 'A', 'D', 'G', 'B', 'E']
      }
    }),
    []
  )

  const chord = useMemo(() => {
    if (!diagram || !(diagram.length > 0)) {
      return null
    }

    const data = diagram[0]

    const frets = processString(data.frets)
    const baseFret = processbaseFret(frets)

    return {
      fingers: processFingers(data.fingers),
      frets: processFrets(frets, baseFret),
      barres: processBarres(data.barres, baseFret),
      baseFret: processbaseFret(frets),
      capo: data.capo
    }
  }, [diagram])

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.small]: small,
        [styles.inactive]: inactive
      })}
    >
      {chord && (
        <div className={styles.content}>
          <Chord chord={chord} instrument={instrument} />
        </div>
      )}
    </div>
  )
}
