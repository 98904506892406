import { Icon } from 'scala'
import { RefObject, useCallback, useRef, useState } from 'react'
import { Button } from '../../components/button'
import { Container } from '../../components/drop/container'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import styles from './info-track.module.scss'
import { InfoTrack as InfoTrackType } from '../../types'

export const InfoTrack: React.FC<InfoTrackType> = ({
  title,
  subtitle,
  items = []
}) => {
  const ref: RefObject<any> = useRef()

  const [open, setOpen] = useState(false)
  const onToggleDrop = useCallback(() => setOpen(!open), [open])
  useOnClickOutside(ref, () => setOpen(false))

  return (
    <div ref={ref} className={styles.container}>
      <Button
        iconDrop
        icon={
          <Icon
            name="music-note"
            width={20}
            height={20}
            className={styles.icon}
          />
        }
        opened={open}
        onClick={onToggleDrop}
        extraMarginIconDrop
        text={subtitle}
        className={styles.button}
      />

      <Container open={open} className={styles.drop}>
        <div className={styles.content}>
          {title ? <p className={styles.title}>{title}</p> : null}
          {title ? <p className={styles.subtitle}>{subtitle}</p> : null}
          {items?.length > 0 ? (
            <p className={styles.small}>
              {items.map((item, index) => (
                <span key={item} className={styles.item}>
                  {index !== 0 ? ' •' : ''} {item}
                </span>
              ))}
            </p>
          ) : null}
        </div>
      </Container>
    </div>
  )
}
