import { pixelsToSeconds, secondsToPixels } from '../../utils/utils'

export function getSamplesPerPixel(container: HTMLDivElement | null): number {
  if (!container || !window.getComputedStyle) {
    return 1000
  }

  return parseInt(
    window.getComputedStyle(container).width.replace('px', ''),
    10
  )
}

export function convertRangeMsToRange(
  rangeMs: [number, number],
  duration: number,
  samplesPerPixel: number
): [number, number] {
  const from = secondsToPixels(rangeMs[0], samplesPerPixel, duration)
  const to = secondsToPixels(rangeMs[1], samplesPerPixel, duration)
  if (from > to) {
    return [to, from]
  }
  return [from, to]
}

export function convertRangeToRangeMs(
  range: [number, number],
  duration: number,
  samplesPerPixel: number
): [number, number] {
  const from = pixelsToSeconds(range[0], samplesPerPixel, duration)
  const to = pixelsToSeconds(range[1], samplesPerPixel, duration)
  if (from > to) {
    return [to, from]
  }
  return [from, to]
}
