import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { FormCheckbox } from 'scala'
import classnames from 'classnames'
import React from 'react'
import { Label } from '../../label'
import styles from './header.module.scss'

interface HeaderProps {
  className?: string
  title: string
  description?: any
  active?: boolean
  error?: boolean
  preview?: boolean
  showToggle?: boolean
  onToggle?(): void
  editable?: boolean
}

export const Header: React.FC<HeaderProps> = ({
  className,
  title,
  description,
  active,
  error,
  preview,
  showToggle = true,
  onToggle,
  editable
}) => {
  const { i18n } = useLingui()

  return (
    <button
      type="button"
      onClick={onToggle}
      disabled={!(showToggle && onToggle)}
      className={classnames(className, styles.button)}
    >
      <p className={styles.title}>
        {title}
        {preview && !editable && (
          <Label text={i18n._(t`beta`)} className={styles.label} />
        )}
      </p>

      {description && (
        <span
          className={classnames(styles.description, { [styles.error]: error })}
        >
          {description}
        </span>
      )}

      {showToggle && onToggle && (
        <FormCheckbox active={active} className={styles.checkbox} />
      )}
    </button>
  )
}
