import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon, useDrop } from 'scala'
import classnames from 'classnames'
import { useRouter } from 'next/router'
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { SongDropped } from '../../../types'
import styles from './playlist-item.module.scss'
import { Info } from './info'

interface PlaylistItemProps {
  index: number
  id: string
  name: string
  type?: string
  unreadCount?: number
  ActionsPlaylist?: React.ReactNode
  onSongDropped(item: SongDropped): void
  disableDrop?: boolean
  isShared?: boolean
  isGlobal?: boolean
}

export const PlaylistItem: React.FC<PlaylistItemProps> = ({
  index,
  id,
  name,
  type,
  unreadCount,
  ActionsPlaylist,
  onSongDropped,
  disableDrop,
  isShared
}): ReactElement => {
  const { i18n } = useLingui()
  const ref = useRef<null | HTMLDivElement>(null)
  const { query, push } = useRouter()
  const [active, setActive] = useState(false)

  const tooltip = useMemo(
    () =>
      ['JAMSESSION', 'COLLECTION', 'BRANDED'].includes(type || '')
        ? i18n._(t`label_public`)
        : i18n._(t`collaborative_playlist`),
    [type, i18n]
  )

  const icon = useMemo(
    () =>
      ['JAMSESSION', 'COLLECTION', 'BRANDED'].includes(type || '')
        ? 'globe'
        : 'user-group',
    [type]
  )

  const handlePlaylist = useCallback(() => {
    push(`/setlist/${id}/?reference=playlist_tab`)
  }, [id, push])

  const onDrop = useCallback(
    ({ taskId, taskName }: SongDropped) => {
      onSongDropped({
        playlistId: id,
        playlistName: name,
        taskId,
        taskName
      })
    },
    [id, name, onSongDropped]
  )

  const [{ isActive, canDrop, isOver }, drop] = useDrop(() => ({
    accept: 'AddSongToPlaylist',
    drop: onDrop,
    collect: (monitor) => ({
      isActive: monitor.canDrop() && monitor.isOver(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  }))

  useEffect(() => {
    setActive(query?.playlistId === id)
    if (query?.playlistId === id && ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      })
    }
  }, [id, name, query, ref])

  return (
    <div ref={ref}>
      <div
        ref={!disableDrop ? drop : undefined}
        className={classnames(styles.item, {
          [styles.isActive]: isActive,
          [styles.canDrop]: canDrop,
          [styles.isOver]: isOver
        })}
      >
        <div className={styles.button}>
          <Info
            title={name}
            active={active}
            onClick={handlePlaylist}
            unreadCount={unreadCount}
            className={styles.buttonIcon}
            id={`playlist_button_${index + 1}`}
            tooltip={tooltip}
            icon={isShared && <Icon name={icon} width={16} height={16} />}
          />

          <div className={styles.actions}>
            {ActionsPlaylist &&
              React.isValidElement(ActionsPlaylist) &&
              React.cloneElement(ActionsPlaylist as React.ReactElement<any>, {
                className: styles.more
              })}
          </div>
        </div>
      </div>
    </div>
  )
}
