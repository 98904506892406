import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { ButtonIcon, DropMenu, Icon } from 'scala'
import classnames from 'classnames'
import React, { RefObject, useCallback, useRef, useState } from 'react'
import styles from './actions-section.module.scss'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { ToggleCycleFn } from '../../hooks/use-cycle'

export interface ActionsSectionProps {
  className?: string
  canEdit?: boolean
  onRenameSection(): void
  onToggleCycle?: ToggleCycleFn
}

export const ActionsSection: React.FC<ActionsSectionProps> = ({
  className,
  canEdit,
  onToggleCycle,
  onRenameSection
}) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const [showDrop, setShowDrop] = useState(false)
  useOnClickOutside(ref, () => setShowDrop(false))
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const player = useAudioEngine((p) => p.api)

  const handleClickShowOptions = useCallback(
    (event: any) => {
      event.stopPropagation()
      setShowDrop(!showDrop)
    },
    [showDrop]
  )

  const onRename = useCallback(
    (event: any) => {
      event.stopPropagation()
      setShowDrop(false)
      onRenameSection()
    },
    [onRenameSection]
  )

  const onActiveLoop = useCallback(() => {
    setShowDrop(false)

    if (!isLooping) {
      player?.pause()
      setTimeout(() => {
        if (onToggleCycle) {
          onToggleCycle(true)
        }
        player?.play()
      }, 300)
    }
  }, [isLooping, player, onToggleCycle])

  return (
    <span ref={ref} className={styles.container}>
      <span
        className={classnames(styles.drop, className, {
          [styles.show]: showDrop
        })}
      >
        <button
          type="button"
          className={styles.buttonOpenDrop}
          onClick={handleClickShowOptions}
        >
          <Icon
            name="more"
            width={16}
            height={16}
            className={styles.iconMore}
          />
        </button>

        <DropMenu
          className={classnames(styles.dropMenu, {
            [styles.show]: showDrop
          })}
        >
          <ButtonIcon
            light
            onClick={onActiveLoop}
            className={styles.button}
            icon={<Icon name="play-on-repeat" />}
            title={i18n._(t`loop_label`)}
          />

          {canEdit && (
            <ButtonIcon
              light
              onClick={onRename}
              className={styles.button}
              icon={<Icon name="pen" />}
              title={i18n._(t`song.options.rename`)}
            />
          )}
        </DropMenu>
      </span>
    </span>
  )
}
