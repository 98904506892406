import {
  MouseEventHandler,
  RefObject,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { Button } from '../../components/button'
import { Container } from '../../components/drop/container'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import styles from './change-operations.module.scss'
import { SeparateOption, ControllerPlayer, SettingsPlayer } from '../../types'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { ChangeOperationsItem } from '../change-operations-item'

export interface ChangeOperationsParams {
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  disabled?: boolean
  options?: SeparateOption[]
}

export const ChangeOperations: React.FC<ChangeOperationsParams> = ({
  settings,
  controller,
  options
}) => {
  const ref: RefObject<any> = useRef()
  const [open, setOpen] = useState(false)
  const selected = options?.find((option) => option.active)
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)

  const enabled = useMemo(() => {
    const hasAvailableOption = options?.some(
      (option) =>
        !option.active && !option.locked && option.status === 'succeeded'
    )

    return isReadyToPlay || hasAvailableOption
  }, [isReadyToPlay, options])

  const onToggleDrop = useCallback(() => setOpen(!open), [open])

  const onSelect = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (event) => {
      const type = event.currentTarget.dataset.id
      const option = options?.find((op) => op.type === type)

      if (!option || option.active) {
        return
      }

      controller?.onChangeOperation?.(option)
      setOpen(false)
    },
    [controller, options]
  )

  useOnClickOutside(ref, () => setOpen(false))

  if (!settings?.changeOperations || !options?.length) {
    return null
  }

  return (
    <div ref={ref} className={styles.container}>
      <Button
        iconDrop
        icon={null}
        opened={open}
        onClick={onToggleDrop}
        extraMarginIconDrop
        text={selected?.title}
        disabled={!enabled}
      />

      <Container open={open} className={styles.drop}>
        <ul className={styles.list}>
          {options.map((item) => (
            <ChangeOperationsItem
              key={`item-drop-${item.type}-${item.title}`}
              id={item.type}
              locked={item.locked}
              isHiFi={item?.isHiFi}
              loading={item.status === 'loading'}
              succeeded={item.status === 'succeeded'}
              active={item.active}
              disabled={
                item.active || (item.status === 'idle' && !item.processable)
              }
              title={item.title}
              description={item.description}
              onClick={onSelect}
            />
          ))}
        </ul>
      </Container>
    </div>
  )
}
