import React, { useEffect, useRef } from 'react'
import styles from './styles.module.scss'
import { generateFakeBeatMap, generateFakeBeatMapLegacy } from './utils'

export const BeatGrid: React.FC<any> = ({
  beatMap = [],
  loading,
  bits,
  wantedWidth,
  bgColor,
  scale,
  height = 50,
  barWidth,
  durationMs,
  barGap,
  barColor
}) => {
  const canvasRef = useRef(null)

  useEffect(() => {
    if (!beatMap?.length || !durationMs || !canvasRef.current) {
      return
    }

    const newBeatMap =
      beatMap[0]?.time !== undefined
        ? generateFakeBeatMap(beatMap, durationMs)
        : generateFakeBeatMapLegacy(beatMap, durationMs)

    const canvas: HTMLCanvasElement = canvasRef.current
    // const len = canvas.width / scale
    const cc = canvas.getContext('2d') as CanvasRenderingContext2D
    // const h2 = canvas.height / scale / 2
    // const maxValue = 2 ** (bits - 1)
    // const width = barWidth
    // const gap = barGap
    // const barStart = width + gap

    cc.clearRect(0, 0, canvas.width, canvas.height)

    cc.save()
    cc.fillStyle = bgColor
    cc.scale(scale, scale)

    const songSuration = durationMs / 1000

    function getBeatTime(pos: any): any {
      if (typeof pos === 'object') {
        return pos.time
      }
      return pos
    }

    const barHeight = 20

    // eslint-disable-next-line no-restricted-syntax
    for (const beat of newBeatMap) {
      const isDownbeat = beat?.beatNum === 4
      cc.fillStyle = isDownbeat ? bgColor : '#2F2F2F'

      const positionFinal = Math.round(
        (getBeatTime(beat) / songSuration) * wantedWidth
      )
      cc.fillRect(positionFinal, 0, 3, isDownbeat ? barHeight * 1.5 : barHeight)
    }

    cc.restore()
  }, [
    canvasRef,
    bgColor,
    beatMap,
    barGap,
    barWidth,
    bits,
    durationMs,
    scale,
    wantedWidth
  ])

  return (
    <div
      style={{
        height
      }}
      className={styles.container}
    >
      <canvas
        style={{
          width: '100%',
          height,
          backgroundColor: barColor,
          display: !loading ? 'flex' : 'none'
        }}
        height={height}
        width={wantedWidth}
        ref={canvasRef}
      />
    </div>
  )
}
