import { useCallback, useEffect } from 'react'
import { useLatest, useUnmount } from 'react-use'
import { useDebouncedCallback } from 'use-debounce'
import { off, on } from '../../lib/events'
import { useStoreTimeline } from '../../data/timeline-store'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { PlayerApi } from '../use-player-methods/use-player-methods'

interface UseTimeline {
  rangeSeconds: [number, number]
  onSeekTo(seconds: number): void
  onChangeCycle(range: [number, number]): void
  onChangeInputRange(e: React.ChangeEvent<HTMLInputElement>): void
}

interface UseTimelineProps {
  playerApi?: PlayerApi
}

export const useTimeline = ({ playerApi }: UseTimelineProps): UseTimeline => {
  const rangeMs = useStoreTimeline((state) => state.rangeMs)
  const setRangeMs = useStoreTimeline((state) => state.setRangeMs)
  const clearStore = useStoreTimeline((state) => state.clearStore)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const isRepeating = useAudioEngine((p) => p.state.isRepeating)
  const isPlaying = useAudioEngine((p) => p.state.isPlaying)
  const positionMs = useAudioEngine((p) => p.state.positionMs)
  const isReadyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const realtime = useLatest({ isPlaying, isLooping, isRepeating, positionMs })

  const onSeekTo = useCallback(
    (seconds: number) => {
      if (!isReadyToPlay) return
      playerApi?.seek(seconds)
    },
    [playerApi, isReadyToPlay]
  )

  const onSeekCycle = useCallback(
    (data: any) => onSeekTo(data?.detail?.seconds || 0),
    [onSeekTo]
  )

  const onChangeCycle = useCallback(
    ([fromMs, toMs]: [number, number], forcePause = true, resize = false) => {
      const isReset = fromMs === 0 && toMs === 0
      const isArea = fromMs !== toMs

      if (!isArea && !isReset) {
        return
      }

      setRangeMs([fromMs, toMs])

      if (!realtime.current.isLooping) {
        return
      }

      if (forcePause && realtime.current.isPlaying) {
        playerApi?.pause()
      }

      if (isReset) {
        playerApi?.unLoop()
      }

      if (!resize && isArea) {
        playerApi?.loop(fromMs, toMs)
      }
    },
    [playerApi, realtime, setRangeMs]
  )

  const onSetCycle = useCallback(
    (data: any) => {
      const { fromSeconds, toSeconds } = data.detail

      if (fromSeconds < 0 || toSeconds < 0) {
        return
      }

      onChangeCycle([fromSeconds, toSeconds], false)

      if (!isLooping) {
        onSeekTo(fromSeconds)
      }
    },
    [isLooping, onSeekTo, onChangeCycle]
  )

  const defineNewRange = useCallback(() => {
    onChangeCycle(rangeMs, false, true)
  }, [rangeMs, onChangeCycle])

  const calculateResize = useCallback(() => {
    setTimeout(() => defineNewRange(), 50)
  }, [defineNewRange])

  const handleResize = useDebouncedCallback(() => {
    defineNewRange()
  }, 200)

  const onChangeInputRange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const seconds = Math.abs(parseInt(e.currentTarget.value, 10))
      onSeekTo(seconds)
    },
    [onSeekTo]
  )

  useEffect(() => {
    on('timeline:set-cycle', onSetCycle)
    on('timeline:seek-cycle', onSeekCycle)
    on('timeline:resize-timeline', calculateResize)
    window.addEventListener('resize', handleResize)

    return () => {
      off('timeline:set-cycle', onSetCycle)
      off('timeline:seek-cycle', onSeekCycle)
      off('timeline:resize-timeline', calculateResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [handleResize, onSetCycle, calculateResize, onSeekCycle])

  useUnmount(() => {
    clearStore()
  })

  return {
    rangeSeconds: rangeMs,
    onSeekTo,
    onChangeCycle,
    onChangeInputRange
  }
}
