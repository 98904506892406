import major from './major'
import minor from './minor'
import dim from './dim'
import dim7 from './dim7'
import sus2 from './sus2'
import sus4 from './sus4'
import sus2sus4 from './sus2sus4'
import c7sus4 from './7sus4'
import alt from './alt'
import aug from './aug'
import _5 from './5'
import _6 from './6'
import _69 from './69'
import _7 from './7'
import _7b5 from './7b5'
import aug7 from './aug7'
import _9 from './9'
import _9b5 from './9b5'
import aug9 from './aug9'
import _7b9 from './7b9'
import _7sharp9 from './7#9'
import _11 from './11'
import _9sharp11 from './9#11'
import _13 from './13'
import maj7 from './maj7'
import maj7b5 from './maj7b5'
import maj7sharp5 from './maj7#5'
import maj9 from './maj9'
import maj11 from './maj11'
import maj13 from './maj13'
import m6 from './m6'
import m7 from './m7'
import m7b5 from './m7b5'
import m9 from './m9'
import m69 from './m69'
import m11 from './m11'
import mmaj7 from './mmaj7'
import mmaj7b5 from './mmaj7b5'
import mmaj9 from './mmaj9'
import mmaj11 from './mmaj11'
import add9 from './add9'
import madd9 from './madd9'
import _B from './_B'
import _Fsharp from './_F#'
import _D from './_D'
import _E from './_E'
import _F from './_F'

export default [
  major,
  minor,
  dim,
  dim7,
  sus2,
  sus4,
  sus2sus4,
  c7sus4,
  alt,
  aug,
  _5,
  _6,
  _69,
  _7,
  _7b5,
  aug7,
  _9,
  _9b5,
  aug9,
  _7b9,
  _7sharp9,
  _11,
  _9sharp11,
  _13,
  maj7,
  maj7b5,
  maj7sharp5,
  maj9,
  maj11,
  maj13,
  m6,
  m7,
  m7b5,
  m9,
  m69,
  m11,
  mmaj7,
  mmaj7b5,
  mmaj9,
  mmaj11,
  add9,
  madd9,
  _Fsharp,
  _B,
  _D,
  _E,
  _F
]
