import { t } from '@lingui/macro'
import { Icon, Modal, Button } from 'scala'
import React, { useContext } from 'react'
import { Context } from '../../../context'
import { ReferralLinkList } from './referral-link-list'
import styles from './modal-referral-link.module.scss'

export const ModalReferralLink: React.FC = () => {
  const {
    modalReferralLink: { show, onDismiss }
  } = useContext(Context)

  if (!show) return null

  return (
    <Modal
      label={t`refer_friend_title`}
      targetId="moises-app"
      isOpen={show}
      onClose={onDismiss}
      modalClassName={styles.modal}
      shouldCloseOnOverlayClick
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <h3 className={styles.title}>{t`refer_friend_title`}</h3>
          <Button
            transparent
            light
            className={styles.closeButton}
            icon={<Icon name="close" className={styles.closeIcon} />}
            onClick={onDismiss}
          />
        </div>
        <hr className={styles.divider} />
        <div className={styles.content}>
          <ReferralLinkList />
        </div>
      </div>
    </Modal>
  )
}
