import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React, { CSSProperties, useMemo } from 'react'
import styles from './timeline.module.scss'
import {
  ChannelPlayer,
  ControllerPlayer,
  DataPlayer,
  SettingsPlayer
} from '../../types'
import { Waveforms } from '../timeline-waveform'
import { TimelineCycle } from '../timeline-cycle'
import { PlayerApi } from '../../hooks/use-player-methods/use-player-methods'
import { useTimeline } from '../../hooks/use-timeline'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { Cursor } from '../../components/timeline-cursor'
import { UseControlMetronome } from '../../hooks/use-control-metronome'
import { Metronome } from '../../components/metronome'
import { SplitLoaderWithMessage } from '../../components/split-loader-with-message'
import { getReprocessingMsg } from '../../utils/operations-utils'

interface TimelineProps {
  className?: string
  data?: DataPlayer
  channels?: ChannelPlayer[]
  metronome?: UseControlMetronome
  controller?: ControllerPlayer
  settings?: SettingsPlayer
  playerApi?: PlayerApi
}

export const Timeline: React.FC<TimelineProps> = ({
  className,
  data,
  channels = [],
  metronome,
  settings,
  controller,
  playerApi
}) => {
  const { i18n } = useLingui()
  const { onSeekTo, onChangeCycle, onChangeInputRange } = useTimeline({
    playerApi
  })
  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const durationMs = useAudioEngine((p) => p.state.durationMs)
  const count = useMemo(
    () => channels.filter(({ id }) => !id.includes('metronome')).length,
    [channels]
  )

  const positionMetronome = useMemo<CSSProperties>(() => {
    return {
      position: 'absolute',
      left: '10px',
      top: `${55 + 70 * count}px`,
      width: 'calc(100% - 10px)'
    }
  }, [count])

  return (
    <div className={classnames(className, styles.container)}>
      <div
        className={styles.content}
        style={{
          minHeight: `${
            (channels.filter(({ id }) => !id.includes('metronome')).length +
              1) *
              55 +
            150
          }px`
        }}
      >
        <Cursor
          loading={loading}
          channels={channels}
          durationMs={durationMs || 0}
          onChangeRange={onChangeInputRange}
        />

        <TimelineCycle
          onSeekTo={onSeekTo}
          onChangeCycle={onChangeCycle}
          duration={durationMs || 0}
          isLooping={isLooping}
        />

        <Waveforms
          channels={channels}
          settings={settings}
          activeMetronome={metronome?.activeMetronome?.id}
          metronomeLoading={metronome?.loading}
        />

        {metronome &&
          metronome?.metronomes &&
          metronome?.metronomes?.length > 1 &&
          !metronome?.loading && (
            <div style={{ ...positionMetronome }}>
              <Metronome
                metronomes={metronome.metronomes}
                limited={settings?.channels?.metronome?.showLock}
                active={metronome.activeMetronome?.id}
                onChange={metronome.onChangeMetronome}
                onLockedClick={controller?.onLockedFeatureClick}
                stems={channels.length}
              />
            </div>
          )}
      </div>

      {(data?.status === 'update' ||
        data?.status === 'adapt' ||
        (data?.status !== 'processing' && data?.customTracks?.processing)) && (
        <SplitLoaderWithMessage
          className={styles.loader}
          style={{ paddingTop: `${count * 10}px` }}
          message={i18n._(
            getReprocessingMsg(
              data?.customTracks?.processing ? 'PROCESSING' : data?.status
            )
          )}
        />
      )}
    </div>
  )
}
