import { useLingui } from '@lingui/react'
import { ButtonIcon, DropMenu } from 'scala'
import classnames from 'classnames'
import React, { RefObject, useCallback, useMemo, useRef, useState } from 'react'
import { useEffectOnce } from 'react-use'
import styles from './rename-section.module.scss'
import { useOnClickOutside } from '../../hooks/misc/use-on-click-outside'
import { SectionsLabel, sectionsLocalizations } from './sections'

export interface RenameSectionProps {
  className?: string
  valueOriginal?: string
  index: number
  onCloseRenameSection(): void
  onChangeSection?(value: string, index: number): void
}

export const RenameSection: React.FC<RenameSectionProps> = ({
  className,
  index,
  valueOriginal = '',
  onChangeSection,
  onCloseRenameSection
}) => {
  const { i18n } = useLingui()
  const ref: RefObject<any> = useRef()
  const refInput: RefObject<any> = useRef()

  const [value, setValue] = useState(valueOriginal)

  const onClose = useCallback(
    (newValue?: string) => {
      if (onChangeSection) {
        const update = newValue || value
        const isNotEmpty = !(update?.replace(/\s/g, '').length === 0)
        if (isNotEmpty) {
          onChangeSection(update, index)
        }
      }
      setTimeout(() => {
        onCloseRenameSection()
      }, 150)
    },
    [index, value, onChangeSection, onCloseRenameSection]
  )

  useOnClickOutside(ref, () => {
    onClose()
  })

  const onChange = useCallback((e: any) => {
    setValue(e.currentTarget.value)
  }, [])

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.keyCode === 13) {
        onClose()
      }
    },
    [onClose]
  )

  const onClick = useCallback(
    (newValue: string) => {
      setValue(newValue)
      onClose(newValue)
    },
    [onClose]
  )

  const items = useMemo(() => {
    const sectionKeys = Object.keys(sectionsLocalizations) as SectionsLabel[]

    const buttons = sectionKeys.map((sectionKey) => {
      const sectionsLocalization =
        sectionsLocalizations[sectionKey as SectionsLabel]

      return i18n._(sectionsLocalization)
    })

    return buttons.map((i, key) => ({
      title: i,
      onClick: (event: any) => {
        event.stopPropagation()
        onClick(sectionKeys[key])
      }
    }))
  }, [i18n, onClick])

  useEffectOnce(() => {
    refInput.current.focus()
  })

  return (
    <span ref={ref} className={classnames(styles.container, className)}>
      <input
        ref={refInput}
        type="text"
        value={value}
        placeholder="Name"
        onChange={onChange}
        onKeyDown={onKeyDown}
        className={styles.input}
        maxLength={25}
      />

      <DropMenu className={styles.dropMenu}>
        {items.map((i) => (
          <ButtonIcon
            key={i.title}
            light
            title={i.title}
            onClick={i.onClick}
            className={styles.item}
          />
        ))}
      </DropMenu>
    </span>
  )
}
