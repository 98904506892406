import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './counter.module.scss'

interface CounterProps {
  className?: string
  value: any
  title?: any
  enabled?: boolean
  isAvailable?: boolean
  lockIncrease?: boolean
  lockDecrease?: boolean
  disableReset?: boolean
  onReset?(): void
  onIncrease(): void
  onDecrease(): void
}

export const Counter: React.FC<CounterProps> = ({
  className,
  enabled,
  title,
  value,
  isAvailable,
  lockIncrease,
  lockDecrease,
  disableReset = false,
  onReset,
  onIncrease,
  onDecrease
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      {title && <p className={styles.title}>{title}</p>}

      <div className={styles.controls}>
        <div className={styles.buttons}>
          <button
            type="button"
            disabled={!isAvailable}
            onClick={onDecrease}
            className={classnames(styles.button, {
              [styles.locked]: lockDecrease
            })}
          >
            <Icon
              width={lockDecrease ? 16 : 24}
              height={lockDecrease ? 16 : 24}
              name={lockDecrease ? 'lock' : 'minus'}
            />
          </button>

          <div className={styles.label}>{value}</div>

          <button
            type="button"
            disabled={!isAvailable}
            onClick={onIncrease}
            className={classnames(styles.button, {
              [styles.locked]: lockIncrease
            })}
          >
            <Icon
              width={lockIncrease ? 16 : 24}
              height={lockIncrease ? 16 : 24}
              name={lockIncrease ? 'lock' : 'plus'}
            />
          </button>
        </div>
      </div>

      {!disableReset && onReset && (
        <button
          type="button"
          onClick={onReset}
          disabled={!enabled}
          className={styles.buttonReset}
        >
          {i18n._(t`reset`)}
        </button>
      )}
    </div>
  )
}
