import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import classnames from 'classnames'
import React from 'react'
import styles from './waveform-processing.module.scss'

interface WaveformStateProps {
  className?: string
  isProcessing?: boolean
  isFailed?: boolean
}

export const WaveformState: React.FC<WaveformStateProps> = ({
  className,
  isFailed,
  isProcessing
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      {isProcessing ? (
        <p className={styles.label}>{i18n._(t`metronome_generating`)}</p>
      ) : isFailed ? (
        <p className={styles.error}>{i18n._(t`failed_detection`)}</p>
      ) : null}
    </div>
  )
}
