export const samplesToSeconds = (
  samples: number,
  sampleRate: number
): number => {
  return samples / sampleRate
}

export const secondsToSamples = (
  seconds: number,
  sampleRate: number
): number => {
  return Math.ceil(seconds * sampleRate)
}

export const samplesToPixels = (
  samples: number,
  resolution: number
): number => {
  return Math.floor(samples / resolution)
}

export const pixelsToSamples = (pixels: number, resolution: number): number => {
  return Math.floor(pixels * resolution)
}

export const pixelsToSeconds = (
  pixels: number,
  samplesPerPixel: number,
  duration: number
): number => {
  return Math.round(duration * (pixels / samplesPerPixel))
}

export const secondsToPixels = (
  seconds: number,
  samplesPerPixel: number,
  duration: number
): number => {
  return Math.round(samplesPerPixel * (seconds / duration))
}

export const fmtMSS = (seconds: number): string => {
  let s = Math.ceil(seconds / 1000) || 0
  // eslint-disable-next-line no-return-assign
  return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
}
