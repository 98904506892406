import React from 'react'
import styles from './progress-bar.module.scss'

interface ProgressBarProps {
  onChangeRange(e: React.ChangeEvent<HTMLInputElement>): void
  loading: boolean
  durationMs: number
  progressMs: number
}

const fmtMSS = (seconds: number): string => {
  let s = Math.floor(seconds / 1000) || 0
  // eslint-disable-next-line no-return-assign
  return (s - (s %= 60)) / 60 + (s > 9 ? ':' : ':0') + s
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  loading,
  durationMs,
  progressMs,
  onChangeRange
}) => {
  const progress = (progressMs * 100) / durationMs
  const progressPxAdjustment =
    durationMs / 5.0 > progressMs
      ? 10
      : durationMs / 2.5 > progressMs
      ? 7
      : durationMs / 1.6 > progressMs
      ? 5
      : 0

  return (
    <div className={styles.container}>
      <p className={styles.label}>{fmtMSS(progressMs)}</p>

      <div className={styles.content}>
        <input
          className={styles.range}
          type="range"
          min={0}
          max={durationMs}
          disabled={loading}
          value={progressMs}
          onChange={onChangeRange}
          style={{
            backgroundSize: `calc(${progress}% + ${progressPxAdjustment}px)`
          }}
        />
      </div>

      <p className={styles.label}>{fmtMSS(durationMs)}</p>
    </div>
  )
}
