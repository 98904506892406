import { t } from '@lingui/macro'
import React, { useMemo } from 'react'
import { useLingui } from '@lingui/react'
import classNames from 'classnames'
import { ChannelPlayer, SettingsPlayer } from '../../types'
import { PlayerApi } from '../../hooks/use-player-methods/use-player-methods'
import styles from './styles.module.scss'
import { SwitchChannel } from '../../components/singletrack/switch-channel'
import { ItemSwitch } from '../../components/switch-task'
import { useControlChannels } from '../../hooks/singletrack/use-control-channels'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { AudioEngineState } from '../../lib/audio-engines/types'
import { Skeleton } from '../timeline-waveform/skeleton'
import { WaveformState } from '../timeline-waveform/waveform-processing'
import { CursorSmall } from '../../components/timeline-cursor-small'
import { WaveformCanvas } from '../timeline-waveform/waveform-canvas'

interface Props {
  type?: string
  channels?: ChannelPlayer[]
  playerApi: PlayerApi
  settings?: SettingsPlayer
}

export const Singletrack: React.FC<Props> = ({
  type,
  channels,
  settings,
  playerApi
}) => {
  const { i18n } = useLingui()
  const colors = ['#63FB97', '#a8a8a8', '#535353']

  const durationMs = useAudioEngine((p) => p.state.durationMs)
  const waveformsData = useAudioEngine((p) => p.waveforms)
  const loading = useAudioEngine((p: any) => !p.state.isReadyToPlay)

  const {
    channelActived,
    hasChannelOriginal,
    onActiveChannel,
    onChangeInputRange
  } = useControlChannels({
    playerApi,
    channels,
    type
  })

  const itemsSwitch: ItemSwitch[] = useMemo(() => {
    const items = [
      {
        title:
          type === 'mastered'
            ? i18n._(t`task.label.mastered.tracks`)
            : i18n._(t`task.label.denoised.tracks`),
        active: channelActived === type,
        onClick: () => onActiveChannel(type as string)
      }
    ]

    if (hasChannelOriginal) {
      items.push({
        title: i18n._(t`task.label.original`),
        active: channelActived === 'original',
        onClick: () => onActiveChannel('original')
      })
    }

    return items
  }, [i18n, channelActived, hasChannelOriginal, type, onActiveChannel])

  const waveforms = useMemo(() => {
    const stems = [type, 'original']
    return stems.reduce((acc: any, stem) => {
      const hasStem = waveformsData?.find(({ id }) => id === stem)
      if (hasStem) {
        const stateStem = channels?.find(({ id }) => id === stem)
        acc.push({
          ...hasStem,
          error: stateStem?.error,
          processing: stateStem?.processing
        })
      }
      return acc
    }, [] as AudioEngineState['waveforms'])
  }, [waveformsData, channels, type])

  return (
    <div className={styles.container}>
      <SwitchChannel items={itemsSwitch} className={styles.switch} />

      <div className={styles.waveforms}>
        <CursorSmall
          loading={loading}
          durationMs={durationMs || 0}
          onChangeRange={onChangeInputRange}
        />
        {loading || !waveforms || waveforms.length === 0 ? (
          <Skeleton channelLength={1} className={styles.waveform} />
        ) : (
          waveforms.map((channel: any, index: number) =>
            channel?.error || channel?.processing ? (
              <WaveformState
                key={channel?.id}
                isFailed={channel?.error}
                className={styles.waveform}
              />
            ) : (
              <WaveformCanvas
                key={channel.id}
                id={channel.id}
                data={channel.data}
                wantedWidth={5000}
                durationMs={durationMs}
                height={70}
                index={index}
                barColor={colors[index]}
                bgColor="#080808"
                barGap={0}
                barWidth={1}
                scale={1}
                bits={16}
                offset={0}
                limited={
                  settings?.channels?.metronome?.limited &&
                  channel.id.includes('metronome')
                }
                className={classNames(styles.waveform, {
                  [styles.hide]: channel.id !== channelActived
                })}
              />
            )
          )
        )}
      </div>
    </div>
  )
}
