import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import React, { useCallback, useMemo } from 'react'
import { useEffectOnce, useUnmount } from 'react-use'
import classNames from 'classnames'
import styles from './chords-button.module.scss'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { UseChords } from '../../hooks/use-chords'
import { Button } from '../../components/button'
import { ChordsComponent } from '../../components/chords'
import {
  ControllerPlayer,
  DataPlayer,
  SettingsPlayer,
  StatePlayer
} from '../../types'
import { UseEffects } from '../../hooks/use-effects'
import { usePanel } from '../panel/edits/store'

interface ChordsProps {
  disabled?: boolean
  settings?: SettingsPlayer
  initialState?: StatePlayer
  controller?: ControllerPlayer
  effects: UseEffects
  data?: DataPlayer
  onLockedClick?: ControllerPlayer['onLockedFeatureClick']
}

type ChordsType = {
  list: UseChords
  error: boolean
  loading: boolean
  empty: boolean
  processing: boolean
}

export const ChordsButton: React.FC<ChordsProps> = ({
  data,
  disabled,
  initialState,
  settings,
  effects,
  controller,
  onLockedClick
}) => {
  const { i18n } = useLingui()
  const progressMs = useAudioEngine((p) => p.state.positionMs)
  const { showChords, onShowChords, close } = usePanel()
  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)

  const onToggleChords = useCallback(() => {
    onShowChords(!showChords)

    if (!showChords) {
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'chords'
      })
    }
  }, [onShowChords, showChords, controller])

  useEffectOnce(() => {
    if (initialState?.panels?.showChords) {
      onToggleChords()
    }
  })

  const chords = useMemo<ChordsType>(
    () => ({
      list: data?.chords?.data,
      error: data?.chords?.status === 'failed',
      loading: loading || data?.chords?.status === 'loading',
      empty: Boolean(data?.chords?.empty),
      processing: data?.chords?.status === 'loading'
    }),
    [data?.chords, loading]
  )

  const typeChords = usePanel((state) => state.typeChords)

  const chordsType = useMemo(() => {
    return typeChords || initialState?.typeChords || settings?.chords?.type
  }, [initialState?.typeChords, settings?.chords?.type, typeChords])

  const onOpenModalPremium = useCallback(() => {
    onLockedClick?.('chords-lock')
  }, [onLockedClick])

  useUnmount(() => {
    close()
  })

  return (
    <Button
      id="chords_footer_button"
      text={i18n._(t`Chords`)}
      active={showChords}
      disabled={disabled}
      onClick={onToggleChords}
      className={styles.button}
      icon={
        progressMs === 0 ||
        showChords ||
        chords.empty ||
        chords.error ||
        chords.processing ||
        chords.loading ? (
          <Icon
            width={20}
            height={20}
            name="chords-detection"
            className={classNames(styles.icon, {
              [styles.large]: chordsType !== 'prev_chord'
            })}
          />
        ) : (
          <ChordsComponent
            barVersion
            chords={chords}
            songKey={effects?.songKey?.currentKey}
            pitch={effects?.songKey?.pitch || 0}
            shouldLock={Boolean(settings?.chords?.limited)}
            chordsType={chordsType}
            onLockedClick={onOpenModalPremium}
            audioTime={progressMs || 0}
            className={styles.chordsComponent}
          />
        )
      }
    />
  )
}
