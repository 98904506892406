export default {
  key: 'E',
  suffix: 'm/B',
  positions: [
    {
      frets: 'x22000',
      fingers: '012000'
    },
    {
      frets: 'x22003',
      fingers: '012003'
    },
    {
      frets: 'x22453',
      fingers: '011342'
    },
    {
      frets: '779987',
      fingers: '113421',
      barres: 7,
      capo: true
    }
  ]
}
