import React from 'react'
import styles from './avatar.module.scss'

interface Props {
  title: string
  image: string
}

export const Avatar: React.FC<Props> = ({ title, image }) => {
  return (
    <div className={styles.container}>
      <img
        width={41}
        height={41}
        src={image}
        alt={title}
        className={styles.image}
      />

      <p className={styles.title}>{title}</p>
    </div>
  )
}
