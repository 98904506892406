import { AudioEngine } from './types'

/**
 * Attaches the emulated features to the audio engine, e.g. repeat.
 *
 * Although repeat is supported by the audio engines, it's emulated in the
 * player to make the count-in work properly.
 */
export function attachEmulatedFeatures(audioEngine: AudioEngine): void {
  function repeat(enabled: boolean): void {
    audioEngine.setState((state) => ({
      ...state,
      state: { ...state.state, isRepeating: enabled }
    }))
  }

  audioEngine.setState((state) => ({
    ...state,
    api: { ...state.api, repeat }
  }))
}
