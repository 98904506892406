export default {
  key: 'E',
  suffix: 'maj9',
  positions: [
    {
      frets: '021102',
      fingers: '031204'
    },
    {
      frets: '422442',
      fingers: '211341',
      barres: 2,
      capo: true
    },
    {
      frets: 'x7687x',
      fingers: '021430'
    },
    {
      frets: 'xx9b9b',
      fingers: '001314',
      barres: 9,
      capo: true
    }
  ]
}
