export default {
  key: 'E',
  suffix: '/D#',
  positions: [
    {
      frets: 'xx1100',
      fingers: '001200'
    },
    {
      frets: 'x66454',
      fingers: '034121',
      barres: 4
    },
    {
      frets: 'bb99cc',
      fingers: '231144',
      barres: 9
    }
  ]
}
