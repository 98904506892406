/**
 * Function queue that will run defined functions in order.
 *
 * @example
 * const q = new FnQueue()
 * const persistData = q.queued((data) => ...)
 * const readData = q.queued(() => ...)
 * persistData(...) // Asynchronous call that takes time
 * const data = await readData() // Will wait for persistData to finish
 */
export class FnQueue {
  private promise = Promise.resolve()

  private abortController: AbortController | null = null

  queued<T extends any[]>(
    fn: (...args: any) => Promise<void>
  ): (...args: T) => Promise<void> {
    return async (...args: T) => {
      this.abortController = new AbortController()
      this.promise = this.promise.then(() =>
        fn(...args, this.abortController?.signal)
      )
      return this.promise
    }
  }

  abort(): void {
    if (this.abortController) {
      this.abortController.abort()
    }
  }
}

/**
 * Adds queue behavior to a function.
 *
 * @example
 * const downloadFiles = queuedFn(() => ...)
 * downloadFiles() // Asynchronous call that takes time
 * downloadFiles() // Will wait for the first call to finish
 */
export const queuedFn = <T extends any[]>(
  fn: (...args: T) => Promise<void>
): ((...args: T) => Promise<void>) => {
  return new FnQueue().queued(fn)
}
