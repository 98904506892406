import classnames from 'classnames'
import React from 'react'
import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import styles from './skeleton.module.scss'

interface SkeletonProps {
  className?: string
  channelLength?: number
  newStem?: boolean
}

export const Skeleton: React.FC<SkeletonProps> = ({
  className,
  channelLength = 2,
  newStem = false
}) => {
  const { i18n } = useLingui()

  return (
    <div className={classnames(className, styles.container)}>
      {[...Array(channelLength)].map((i, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <span key={`c-skeleton-${index}-${i || 1}`} className={styles.waveform}>
          <img
            alt="Loading waveform"
            className={styles.mask}
            src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
            style={{
              backgroundImage: `url('/assets/images/skeleton/waveform-${
                index + 1
              }.svg')`
            }}
          />
        </span>
      ))}
      {newStem && (
        <p className={styles.message}>{i18n._(t`mixer_alert_adding_tracks`)}</p>
      )}
    </div>
  )
}
