import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import { ChordsDiagram } from '../chords-diagram'
import { LockPremium } from '../lock-premium'
import styles from './chords.module.scss'
import { Skeleton } from './sheleton'
import { StateFeature } from '../state-feature'
import { useChords } from '../../hooks/use-chords/use-chords'
import { UseChords } from '../../hooks/use-chords'

export interface ChordsProps {
  shouldLock: boolean
  showBass?: boolean
  chordsType?: string
  smallVersion?: boolean
  barVersion?: boolean
  chords: {
    processing: boolean
    empty: boolean
    error: boolean
    loading: boolean
    list: UseChords
  }
  songKey?: string
  pitch: number
  audioTime: number
  className?: string
  hideDiagram?: boolean
  onLockedClick(): void
}

// eslint-disable-next-line max-len
// font: https://github.com/moises-ai/moises-android/pull/1640/files#diff-7bbb55a205fe5712f97fb0b5bdd19e85f4c7e618551217454f56e21e2c37c418
const formatChord = (text?: string): string => {
  if (!text) return ''
  return text
    .replace(/([0-9(\s,])♯/g, '$1>')
    .replace(/([0-9(])♭/g, '$1<')
    .replace('b', '@')
    .replace(/m(?!aj)/g, 'M')
    .replace('Δ', '^')
    .replace('ø', '\\')
    .replace(/diM/g, 'dim')
}

export const ChordsComponent: React.FC<ChordsProps> = ({
  className,
  shouldLock,
  showBass,
  smallVersion,
  barVersion,
  audioTime,
  songKey,
  pitch,
  chordsType,
  hideDiagram,
  chords: { list: chords, loading, error, empty, processing },
  onLockedClick
}) => {
  const { i18n } = useLingui()
  const { previous, current, next } = useChords({
    type: chordsType,
    pitch,
    songKey,
    chords,
    audioTime: audioTime / 1000,
    shouldLock,
    loading,
    error
  })

  return (
    <div className={classnames(className, styles.container)}>
      <div
        className={classnames(styles.chords, {
          [styles.extraMargin]: !barVersion,
          [styles.hideDiagram]: hideDiagram
        })}
      >
        {(empty || error) && !barVersion ? (
          <StateFeature
            icon="no-chords-detection"
            title={i18n._(t`no_chords_available`)}
            description={i18n._(t`reupload_again`)}
          />
        ) : processing && !barVersion ? (
          <StateFeature icon="clock" title={i18n._(t`chords_generating`)} />
        ) : loading && !barVersion ? (
          <Skeleton small={smallVersion} />
        ) : (
          <div className={styles.content}>
            <div className={styles.items}>
              {!barVersion && (
                <div className={styles.item}>
                  <ChordsDiagram
                    small
                    inactive
                    diagram={previous.diagram?.positions}
                    className={styles.diagram}
                  />
                  <span
                    className={classnames(
                      styles.chord,
                      styles.inactive,
                      styles.left,
                      {
                        [styles.smallVersion]: smallVersion
                      }
                    )}
                  >
                    {formatChord(previous.transposed)}
                    {showBass &&
                      previous?.bass &&
                      `/${formatChord(previous.bass)}`}
                  </span>
                </div>
              )}

              <div className={styles.item}>
                {!barVersion && (
                  <ChordsDiagram
                    diagram={current.diagram?.positions}
                    className={styles.diagram}
                  />
                )}
                {barVersion && shouldLock && audioTime / 1000 >= 59 ? (
                  <div className={styles.iconLock}>
                    <Icon name="lock" width={20} height={20} />
                  </div>
                ) : (
                  <span
                    className={classnames(
                      styles.chord,
                      styles.active,
                      styles.center,
                      {
                        [styles.barVersion]: barVersion,
                        [styles.barVersionSimple]:
                          barVersion && chordsType === 'prev_chord',
                        [styles.smallVersion]: smallVersion
                      }
                    )}
                  >
                    {formatChord(current.transposed)}
                    {!barVersion &&
                      showBass &&
                      current?.bass &&
                      `/${formatChord(current.bass)}`}
                  </span>
                )}
              </div>

              {!barVersion && (
                <div className={styles.item}>
                  {shouldLock && audioTime / 1000 >= 59 ? (
                    <LockPremium
                      type="full"
                      onClick={onLockedClick}
                      className={styles.lock}
                    />
                  ) : (
                    <div className={styles.item}>
                      <ChordsDiagram
                        small
                        diagram={next.diagram?.positions}
                        className={styles.diagram}
                      />
                      <span
                        className={classnames(
                          styles.chord,
                          styles.activeNext,
                          styles.right,
                          {
                            [styles.smallVersion]: smallVersion
                          }
                        )}
                      >
                        {formatChord(next.transposed)}
                        {showBass && next?.bass && `/${formatChord(next.bass)}`}
                      </span>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
