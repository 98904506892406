import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useEffectOnChange = (
  fn: EffectCallback,
  deps: DependencyList
): void => {
  const ignoredFirst = useRef(false)

  useEffect(() => {
    if (!ignoredFirst.current) {
      ignoredFirst.current = true
      return () => {}
    }

    return fn()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
