export default {
  key: 'B',
  suffix: '6',
  positions: [
    {
      frets: 'x2110x',
      fingers: '031200'
    },
    {
      frets: 'x24444',
      fingers: '013333',
      barres: 4
    },
    {
      frets: '7x989x',
      fingers: '103240'
    },
    {
      frets: 'xx9b9b',
      fingers: '001314',
      barres: 9,
      capo: true
    }
  ]
}
