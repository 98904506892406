import { useCallback, useEffect, useMemo, useState } from 'react'
import { ChannelPlayer } from '../../../types'
import { useAudioEngine } from '../../use-audio-engine'
import { PlayerApi } from '../../use-player-methods/use-player-methods'

export interface UseControlChannels {
  loading: boolean
  channelActived: string
  hasChannelOriginal: boolean
  onActiveChannel(id: string): void
  onChangeInputRange(e: React.ChangeEvent<HTMLInputElement>): void
}

interface Props {
  type?: string
  playerApi?: PlayerApi
  channels?: ChannelPlayer[]
}

export const useControlChannels = ({
  type = '',
  playerApi,
  channels
}: Props): UseControlChannels => {
  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)

  const [channelActived, setChannelActived] = useState(type)
  const [defined, setDefined] = useState(false)

  useEffect(() => {
    if (loading || defined) {
      return
    }

    setDefined(true)
    channels?.forEach(({ id }) => {
      playerApi?.channel.mute(id, id !== type)
    })

    setChannelActived(channels?.filter((i) => i?.id === type)?.[0]?.id || '')
  }, [defined, loading, channels, playerApi, type])

  const hasChannelOriginal = useMemo(
    () => !!channels?.find((i) => i.id === 'original'),
    [channels]
  )

  const onActiveChannel = useCallback(
    (id: string) => {
      channels?.forEach((i) => {
        playerApi?.channel.mute(i.id, true)
      })
      playerApi?.channel.mute(id, false)
      setChannelActived(id)
    },
    [playerApi?.channel, channels]
  )

  const onSeekTo = useCallback(
    (seconds: number) => {
      if (loading) return
      playerApi?.seek(seconds)
    },
    [playerApi, loading]
  )

  const onChangeInputRange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const seconds = Math.abs(parseInt(e.currentTarget.value, 10))
      onSeekTo(seconds)
    },
    [onSeekTo]
  )

  return {
    loading,
    channelActived,
    hasChannelOriginal,
    onActiveChannel,
    onChangeInputRange
  }
}
