import { useCallback, useMemo } from 'react'
import { times } from 'ramda'

interface UseResamplerProps {
  containerWidth: number
  samples?: number[]
  scale: number
  amplitudeFactor: number
}

interface UseResampler {
  canvasWidth: number
  resample: (pixelIndex: number) => [number, number]
}

export function useResampler({
  containerWidth,
  samples,
  scale,
  amplitudeFactor
}: UseResamplerProps): UseResampler {
  const nSamples = (scale * (samples?.length || 0)) / 2

  // Calculates the width of the canvas based on the number of pixels available
  // and number of samples, also preventing fractional pixel values, which
  // could cause visual artifacts or inconsistencies
  const canvasWidth = useMemo(() => {
    // Stretch
    if (nSamples <= containerWidth) {
      return nSamples * Math.ceil(containerWidth / nSamples)
    }

    // Shrink, but only if the waveform is 2.5x or more larger than the
    // container
    const factor =
      1 / Math.floor(nSamples / Math.min(nSamples, containerWidth * 2.5))
    return nSamples * factor
  }, [containerWidth, nSamples])

  const samplesPerPixel = nSamples / (canvasWidth / scale)

  /**
   * Gets the interval of samples to fit in the given pixel index.
   */
  const getSamplesIntervalAt = useCallback(
    (pixelIndex: number, channel: 'L' | 'R'): number[] => {
      if (!samples) {
        return [0]
      }

      return times((i) => {
        const sampleIndex = Math.floor(pixelIndex * samplesPerPixel + i) * 2
        return samples[sampleIndex + Number(channel === 'R')] / amplitudeFactor
      }, Math.ceil(samplesPerPixel))
    },
    [amplitudeFactor, samples, samplesPerPixel]
  )

  /**
   * Makes the waveform samples fit in the calculated canvas width. It may
   * need to fit multiple samples in a single pixel or repeat samples to fill
   * the canvas.
   *
   * Tested algorithms:
   * - Average: killed the amplitude, lost the peaks
   * - Nth: random results, lost peaks sometimes
   * - Min/max (current): result very similar to the original when requesting
   *   low amount of pixels per second
   */
  const resample = useCallback(
    (pixelIndex: number): [number, number] => {
      const minPeaks = getSamplesIntervalAt(pixelIndex, 'L')
      const maxPeaks = getSamplesIntervalAt(pixelIndex, 'R')
      const min = Math.min(...minPeaks)
      const max = Math.max(...maxPeaks)
      return [min, max]
    },
    [getSamplesIntervalAt]
  )

  return { canvasWidth, resample }
}
