import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { convertSongKeyToShortStyle, isMajor } from '../../utils/chords'
import {
  DataPlayer,
  StatePlayer,
  SettingsPlayer,
  ControllerPlayer
} from '../../types'
import {
  decomposePitch,
  convertShortKeyToFullStyle,
  getListKeys,
  getListTempo,
  getTuningOffset
} from './utils'
import { PlayerApi } from '../use-player-methods/use-player-methods'
import { useEffectOnChange } from '../misc/use-effect-on-change'

export interface UseSongKey {
  error: boolean
  disabled: boolean
  loading: boolean
  value: string | number
  currentKey: string
  pitch: number
  enabled: boolean
  lockIncrease: boolean
  lockDecrease: boolean
  originalTuning: number | null
  estimatedTuning: any
  setEstimatedTuning: (value: number) => void
  onReset(): void
  onIncrease(): void
  onDecrease(): void
}

interface UseKeyProps {
  data?: DataPlayer
  initialState?: StatePlayer
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  playerApi?: PlayerApi
}

const VALUE_DEFAULT = 0

export const useSongKey = ({
  data,
  initialState,
  settings,
  controller,
  playerApi
}: UseKeyProps): UseSongKey => {
  const defined = useRef(false)
  const [pitch, setPitch] = useState(VALUE_DEFAULT)
  const originalTuning = data?.metadata?.tuning || null
  const [estimatedTuning, setEstimatedTuningValue] = useState<number | null>(
    null
  )
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const isAvailable = useAudioEngine((p) =>
    p.api.getAvailableFeatures().has('pitch')
  )
  const enabled =
    readyToPlay &&
    (pitch !== VALUE_DEFAULT ||
      (estimatedTuning !== null && estimatedTuning !== originalTuning))
  const limit = settings?.pitch?.limited ?? Infinity

  const list = useMemo(() => {
    const key = data?.metadata?.key || ''
    const keyShorted = convertSongKeyToShortStyle(key)
    const listKeys = getListKeys(keyShorted, isMajor(key))
    return getListTempo(keyShorted, listKeys)
  }, [data?.metadata?.key])

  const { key, value } = useMemo(() => {
    const item = list.find((k) => k.value === pitch)
    const valueCalc = item?.title || VALUE_DEFAULT
    const keyCalc = convertShortKeyToFullStyle(valueCalc)
    return { key: keyCalc, value: valueCalc }
  }, [list, pitch])

  const { lockIncrease, lockDecrease } = useMemo(() => {
    const { limited, max } = settings?.pitch || {}
    const shoudLock = Boolean(limited && max && limited < max)

    return {
      lockDecrease: pitch <= -limit && shoudLock,
      lockIncrease: pitch >= limit && shoudLock
    }
  }, [pitch, limit, settings?.pitch])

  const { shouldLockOnNextIncrease, shouldLockOnNextDecrease } = useMemo(() => {
    const { limited, max, preLock } = settings?.pitch || {}
    const shoudLock = Boolean(limited && max && limited < max)

    return {
      shouldLockOnNextIncrease:
        preLock && pitch >= 0 && pitch - 1 <= -limit && shoudLock,
      shouldLockOnNextDecrease:
        preLock && pitch <= 0 && pitch + 1 >= limit && shoudLock
    }
  }, [pitch, limit, settings?.pitch])

  useEffect(() => {
    if (estimatedTuning === null && originalTuning !== null) {
      setEstimatedTuningValue(originalTuning)
    }
  }, [estimatedTuning, originalTuning])

  useEffectOnChange(() => {
    if (readyToPlay) {
      const pitchShift =
        pitch * 100 + getTuningOffset(estimatedTuning, originalTuning)
      playerApi?.pitchShift(pitchShift)
    }
  }, [pitch, estimatedTuning, playerApi, originalTuning, readyToPlay])

  const setEstimatedTuning = useCallback(
    (val: number) => {
      setEstimatedTuningValue(val)
      controller?.onEventDispatch?.({
        event: 'feature_interaction',
        value: 'tuning'
      })
    },
    [controller]
  )

  const onIncrease = useCallback(() => {
    if (lockIncrease) {
      controller?.onLockedFeatureClick?.('pitch-shift')
      return
    }

    const current = list.findIndex((k) => k.value === pitch)
    const next = list[current + 1] ? list[current + 1] : null

    if (next) {
      setPitch(next.value)
    }

    if (shouldLockOnNextIncrease) {
      controller?.onLockedFeatureClick?.('pitch-shift', true)
    }

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'pitch_changer'
    })
  }, [lockIncrease, shouldLockOnNextIncrease, list, controller, pitch])

  const onDecrease = useCallback(() => {
    if (lockDecrease) {
      controller?.onLockedFeatureClick?.('pitch-shift')
      return
    }

    const current = list.findIndex((k) => k.value === pitch)
    const previous = list[current - 1] ? list[current - 1] : null

    if (previous) {
      setPitch(previous.value)
    }

    if (shouldLockOnNextDecrease) {
      controller?.onLockedFeatureClick?.('pitch-shift', true)
    }

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'pitch_changer'
    })
  }, [lockDecrease, shouldLockOnNextDecrease, list, controller, pitch])

  const onReset = useCallback(() => {
    const pitchDefault = list.find((k) => k.value === VALUE_DEFAULT)

    setPitch(pitchDefault?.value || VALUE_DEFAULT)
    setEstimatedTuningValue(originalTuning)

    controller?.onEventDispatch?.({
      event: 'reset_interaction',
      value: 'pitch_reset'
    })
  }, [list, originalTuning, setEstimatedTuningValue, controller])

  useEffect(() => {
    if (!readyToPlay || defined.current) {
      return
    }

    defined.current = true

    if (!isAvailable || initialState?.pitchShiftCents === undefined) {
      return
    }

    const { basePitch, tuning } = decomposePitch(
      initialState?.pitchShiftCents,
      originalTuning || 0
    )
    const presetPitch = list.find((k) => k.value * 100 === basePitch)

    if (
      presetPitch?.value === undefined ||
      Math.abs(presetPitch?.value) > limit
    ) {
      return
    }

    setPitch(presetPitch.value)
    setEstimatedTuningValue(tuning)
  }, [
    initialState?.pitchShiftCents,
    isAvailable,
    limit,
    list,
    originalTuning,
    readyToPlay
  ])

  return {
    disabled: !readyToPlay,
    error: data?.metadata?.status === 'failed',
    loading: data?.metadata?.status === 'loading',
    value: value === 0 ? '0' : value,
    currentKey: key,
    pitch,
    enabled,
    lockDecrease,
    lockIncrease,
    onReset,
    onIncrease,
    onDecrease,
    setEstimatedTuning,
    originalTuning,
    estimatedTuning
  }
}
