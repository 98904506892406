import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon, Tooltip } from 'scala'
import React, { useCallback } from 'react'
import classNames from 'classnames'
import { StateOperation } from 'player'
import { SeparationOption } from '../../../../hooks/use-default-separation'
import styles from './info-default-separation.module.scss'

interface InfoDefaultSeparation extends SeparationOption {
  moveForward(): void
  onShowSeparationType?(): void
  moveForwardDisabled: boolean
}

export const InfoDefaultSeparation: React.FC<InfoDefaultSeparation> = ({
  title,
  description,
  isHiFi,
  moveForwardDisabled,
  moveForward,
  onShowSeparationType
}) => {
  const { i18n } = useLingui()

  const handleShowSeparationType = useCallback(() => {
    if (!onShowSeparationType) {
      return
    }

    onShowSeparationType()
    moveForward()
  }, [moveForward, onShowSeparationType])

  return (
    <div
      aria-hidden
      className={classNames(styles.container, {
        [styles.disabled]: moveForwardDisabled
      })}
      onClick={!moveForwardDisabled ? handleShowSeparationType : undefined}
    >
      <span className={styles.content}>
        <div className={styles.title}>
          <p>{i18n._(t`default_separation_title`)}:</p>
          <p>
            <strong>{title} </strong>
          </p>

          <div
            className={classNames(styles.iconContainer, {
              [styles.large]: isHiFi
            })}
          >
            <Icon name="info" width={14} height={14} className={styles.icon} />
            <Tooltip
              className={styles.tooltip}
              title={i18n._(t`default_separation_title`)}
              description={i18n._(t`default_for_upload`)}
            />

            {isHiFi && (
              <StateOperation hifi size="small" className={styles.hifi} />
            )}
          </div>
        </div>

        <p className={styles.description}>{description}</p>
      </span>
    </div>
  )
}
