import { useCallback, useEffect, useState } from 'react'
import { useInterval } from 'react-use'

export interface UseCountdown {
  ended?: boolean
  days: number
  hours: string
  minutes: string
  seconds: string
}

export const useCountdown = ({
  endDate,
  disabled = false,
  interval = 1000
}: {
  endDate: string | number
  disabled?: boolean
  interval?: number
}): UseCountdown => {
  const [days, setDays] = useState(0)
  const [hours, setHours] = useState('00')
  const [minutes, setMinutes] = useState('00')
  const [seconds, setSeconds] = useState('00')
  const [ended, setEnded] = useState(false)

  const calculateExpiresAt = useCallback(() => {
    const date = new Date(endDate)
    const now = new Date()
    const diff = date.getTime() - now.getTime()

    const days1 = Math.floor(diff / (1000 * 60 * 60 * 24))
    const hours1 = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
    const minutes1 = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60))
    const seconds1 = Math.floor((diff % (1000 * 60)) / 1000)

    if (days1 > 0 || hours1 > 0 || minutes1 > 0 || seconds1 > 0) {
      setEnded(false)
      setDays(days1)
      setHours(String(hours1).padStart(2, '0'))
      setMinutes(String(minutes1).padStart(2, '0'))
      setSeconds(String(seconds1).padStart(2, '0'))
    } else if (endDate) {
      setEnded(true)
    }
  }, [endDate])

  useEffect(() => {
    calculateExpiresAt()
  }, [calculateExpiresAt])

  useInterval(
    () => {
      calculateExpiresAt()
    },
    !ended && !disabled ? interval : null
  )

  return {
    ended,
    days,
    hours,
    minutes,
    seconds
  }
}
