export default {
  key: 'D',
  suffix: 'aug',
  positions: [
    {
      frets: 'xx0332',
      fingers: '000231'
    },
    {
      frets: 'x5433x',
      fingers: '032110',
      barres: 3
    },
    {
      frets: 'a9877x',
      fingers: '432110',
      barres: 7
    },
    {
      frets: 'axcbbx',
      fingers: '104230'
    }
  ]
}
