import classnames from 'classnames'
import React from 'react'
import styles from './skeleton.module.scss'

interface SkeletonProps {
  small?: boolean
}

export const Skeleton: React.FC<SkeletonProps> = ({ small }) => {
  return (
    <div>
      <span
        className={classnames(styles.skeleton, {
          [styles.small]: small
        })}
      />
      <span
        className={classnames(styles.skeleton, {
          [styles.small]: small
        })}
      />
      <span
        className={classnames(styles.skeleton, {
          [styles.small]: small
        })}
      />
    </div>
  )
}
