import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import { TrackSelected } from '../../../tasks/track-selected'
import styles from './select-local-file.module.scss'

export const SelectLocalFile: React.FC<any> = ({
  file,
  fileSize,
  onRemoveFile,
  isDragActive,
  isDragReject,
  getRootProps,
  getInputProps,
  errorValidationMsg,
  fileDurationFormatted
}) => {
  const { i18n } = useLingui()

  if (file) {
    return (
      <TrackSelected
        title={file.name}
        size={fileSize}
        duration={fileDurationFormatted}
        onRemove={onRemoveFile}
      />
    )
  }

  return (
    <div className={styles.container}>
      <div
        {...getRootProps({
          className: classnames(styles.dropzone, {
            [styles.dropzoneActive]: isDragActive,
            [styles.dropzoneReject]: isDragReject
          })
        })}
      >
        <input {...getInputProps()} />

        {!file && (
          <div className={styles.contentButton}>
            <div className={styles.addButton}>
              <Icon name="plus" className={styles.addButtonIcon} />
            </div>
            <div>
              <p className={styles.label}>
                {i18n._(t`upload.type.file.drop`).replace(/\*/g, '')}
              </p>

              <p className={styles.labelSupported}>
                {i18n._(t`upload.format.label`)} {i18n._(t`task.format.mp3`)},{' '}
                {i18n._(t`task.format.wav`)}, {i18n._(t`task.format.flac`)},{' '}
                {i18n._(t`task.format.m4a`)}, {i18n._(t`task.format.mp4`)},{' '}
                {i18n._(t`task.format.mov`)}, {i18n._(t`task.format.wma`)}
              </p>
            </div>
          </div>
        )}
      </div>

      {errorValidationMsg && <p>{errorValidationMsg}</p>}
    </div>
  )
}
