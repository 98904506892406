export default {
  key: 'F#',
  suffix: 'm7',
  positions: [
    {
      frets: '2x222x',
      fingers: '203330'
    },
    {
      frets: 'exeeex',
      fingers: '203330'
    },
    {
      frets: '242222',
      fingers: '131111',
      barres: 2,
      capo: true
    },
    {
      frets: 'xx4655',
      fingers: '001423'
    },
    {
      frets: '99b9a9',
      fingers: '113121',
      barres: 9,
      capo: true
    },
    {
      frets: 'xxbbac',
      fingers: '002314'
    }
  ]
}
