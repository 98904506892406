export default {
  key: 'B',
  suffix: 'maj7',
  positions: [
    {
      frets: '224342',
      fingers: '113241',
      barres: 2,
      capo: true
    },
    {
      frets: 'xx4446',
      fingers: '001114',
      barres: 4,
      capo: true
    },
    {
      frets: '798877',
      fingers: '142311',
      barres: 7,
      capo: true
    },
    {
      frets: 'x99bbb',
      fingers: '011333',
      barres: [9, 11],
      capo: true
    }
  ]
}
