import main from './main'
import tunings from './tunings'
import keys from './keys'
import suffixes from './suffixes'
import chords from './chords'

export default {
  main,
  tunings,
  keys,
  suffixes,
  chords
}
