import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './lock-premium.module.scss'

interface ButtonProps {
  className?: string
  style?: React.CSSProperties
  onClick(): void
  type?: 'full' | 'label' | 'icon' | 'pill'
  customText?: string
}

export const LockPremium: React.FC<ButtonProps> = ({
  className,
  style,
  type = 'label',
  customText = null,
  onClick
}) => {
  const { i18n } = useLingui()

  return (
    <button
      type="button"
      onClick={onClick}
      className={classnames(
        styles.button,
        {
          [styles.icon]: type === 'icon',
          [styles.full]: type === 'full',
          [styles.pill]: type === 'pill',
          [styles.label]: type === 'label'
        },
        className
      )}
      style={style}
    >
      <Icon
        name="lock"
        width={type === 'label' ? 16 : type === 'pill' ? 17 : 24}
        height={type === 'label' ? 16 : type === 'pill' ? 17 : 24}
      />
      {(type === 'label' && (
        <p className={styles.label}>{customText ?? i18n._(t`label.premium`)}</p>
      )) ||
        ((type === 'full' || type === 'pill') && (
          <p className={styles.label}>
            {customText ?? i18n._(t`premium_only`)}
          </p>
        ))}
    </button>
  )
}
