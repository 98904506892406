export const getColorsStems = (size?: number): string[] => {
  if (size === 2) {
    return ['#00DAE8', '#A6FAFF']
  }

  if (size === 3) {
    return ['#00DAE8', '#A6FAFF', '#63FB97']
  }

  if (size === 4) {
    return ['#00DAE8', '#38EAF5', '#A6FAFF', '#63FB97']
  }

  if (size === 5) {
    return ['#00DAE8', '#38EAF5', '#78F7FF', '#A6FAFF', '#63FB97']
  }

  if (size === 6) {
    return ['#00DAE8', '#38EAF5', '#78F7FF', '#A6FAFF', '#79FBBA', '#63FB97']
  }

  if (size === 7) {
    return [
      '#22C8ED',
      '#00DAE8',
      '#38EAF5',
      '#78F7FF',
      '#A6FAFF',
      '#79FBBA',
      '#63FB97'
    ]
  }

  if (size === 8) {
    return [
      '#22C8ED',
      '#00DAE8',
      '#38EAF5',
      '#78F7FF',
      '#A6FAFF',
      '#90FAE1',
      '#79FBBA',
      '#63FB97'
    ]
  }

  if (size === 9) {
    return [
      '#44B6F3',
      '#22C8ED',
      '#00DAE8',
      '#38EAF5',
      '#78F7FF',
      '#A6FAFF',
      '#90FAE1',
      '#79FBBA',
      '#63FB97'
    ]
  }

  if (size === 10) {
    return [
      '#65A5F9',
      '#44B6F3',
      '#22C8ED',
      '#00DAE8',
      '#38EAF5',
      '#78F7FF',
      '#A6FAFF',
      '#90FAE1',
      '#79FBBA',
      '#63FB97'
    ]
  }

  return [
    '#65A5F9',
    '#65A5F9',
    '#65A5F9',
    '#65A5F9',
    '#44B6F3',
    '#22C8ED',
    '#00DAE8',
    '#38EAF5',
    '#78F7FF',
    '#A6FAFF',
    '#90FAE1',
    '#90FAE1',
    '#79FBBA',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97',
    '#63FB97'
  ]
}
