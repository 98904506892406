export default {
  key: 'Eb',
  suffix: 'm7b5',
  positions: [
    {
      frets: 'xx1222',
      fingers: '001222',
      barres: 2
    },
    {
      frets: 'x6767x',
      fingers: '013240'
    },
    {
      frets: 'xx7879',
      fingers: '001214',
      barres: 7
    },
    {
      frets: 'bxbbax',
      fingers: '203410'
    }
  ]
}
