import { Icon } from 'scala'
import classnames from 'classnames'
import React from 'react'
import styles from './operation-state.module.scss'

interface OperationStateProps {
  className?: string
  icon: string
  title: string
}

export const OperationState: React.FC<OperationStateProps> = ({
  className,
  icon,
  title
}) => {
  return (
    <div className={classnames(className, styles.container)}>
      <Icon name={icon} width={24} height={24} className={styles.icon} />
      <p className={styles.title}>{title}</p>
    </div>
  )
}
