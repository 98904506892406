import React, { useCallback, useRef } from 'react'
import classnames from 'classnames'
import { useEventListener } from '../../hooks/misc/use-event-listener'
import styles from './pan.module.scss'

interface PanProps {
  disabled?: boolean
  className?: string
  value: number
  onChange?(value: number): void
}

export const Pan: React.FC<PanProps> = ({
  className,
  disabled,
  value,
  onChange
}) => {
  const inputRange = useRef<any>(null)

  const onWheelRange = useCallback(
    (e: any) => {
      e.preventDefault()
      e.stopPropagation()

      const wheelVal =
        e.wheelDelta ||
        -e.detail ||
        e.originalEvent.wheelDelta ||
        -e.originalEvent.detail ||
        -(e.originalEvent.deltaY * 25) ||
        null

      if (!wheelVal) {
        return
      }

      let newValue = parseFloat(e.currentTarget.value)
      newValue = wheelVal > 0 ? newValue + 0.1 : newValue - 0.1
      newValue = newValue > 1 ? 1 : newValue < -1 ? -1 : newValue

      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  useEventListener('wheel', onWheelRange, inputRange)

  const onChangeValue = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let newValue = parseFloat(e.currentTarget.value)
      newValue = newValue > -0.1 && newValue < 0.1 ? 0 : newValue

      if (onChange) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  const onClickRange = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (e.detail === 2 && onChange) {
        onChange(0)
      }
    },
    [onChange]
  )

  return (
    <div className={classnames(className, styles.container)}>
      <div className={styles.pan}>
        <span
          className={styles.marker}
          style={{
            transform: `rotate(${
              value > 0 ? value * 180 : value < 0 ? value * 180 : 0
            }deg)`
          }}
        >
          <span
            style={{ backgroundColor: `${value === 0 ? '#FFF' : '#63FB97'}` }}
          />
        </span>
        <div className={styles.left}>
          <div className={styles.circular}>
            <div className={styles.inner} />
            <div className={styles.circle}>
              <div className={styles.bar}>
                <div
                  className={styles.progress}
                  style={{
                    transform: `rotate(${value < 0 ? -value * 180 : 0}deg)`
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.circular}>
            <div className={styles.inner} />
            <div className={styles.circle}>
              <div className={styles.bar}>
                <div
                  className={styles.progress}
                  style={{
                    transform: `rotate(${value > 0 ? value * 180 : 0}deg)`
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.range}>
        <input
          ref={inputRange}
          type="range"
          min="-1"
          max="1"
          step="0.01"
          value={value}
          disabled={disabled}
          onChange={onChangeValue}
          onClick={onClickRange}
        />
      </div>

      <svg
        className={styles.labelLeft}
        width="4"
        height="6"
        viewBox="0 0 4 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0V5.6H3.648V4.64H1.056V0H0Z" fill="#666666" />
      </svg>

      <svg
        className={styles.labelRight}
        width="5"
        height="6"
        viewBox="0 0 5 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M4.90401 1.76C4.90401 0.696 4.16801 0 3.11201 0H0.64801V5.6H1.70401V3.52H2.62401L3.64801 5.6H4.84001L3.68801 3.44C4.41601 3.232 4.90401 2.616 4.90401 1.76ZM1.70401 0.96H3.00801C3.51201 0.96 3.84801 1.272 3.84801 1.76C3.84801 2.248 3.51201 2.568 3.00801 2.568H1.70401V0.96Z"
          fill="#666666"
        />
      </svg>
    </div>
  )
}
