import { shallow } from 'zustand/shallow'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useCountInAudioEngine, useAudioEngine } from '../use-audio-engine'

export interface UseClickTrack {
  firstBeatDetected: number
  onInit(): void
  onPlayClickTrack(): void
}

interface UseClickTrackProps {
  bpm: number
}

export const useClickTrack = ({ bpm }: UseClickTrackProps): UseClickTrack => {
  const [loaded, setLoaded] = useState(false)
  const countInApi = useCountInAudioEngine((p) => p.api)
  const isReadyToPlay = useCountInAudioEngine((p) => p.state.isReadyToPlay)
  const masterVolume = useAudioEngine<number>((p) => p.state.masterVolume)
  const metronomeVolume = useAudioEngine<number>((player: any) => {
    const value = player.channels?.metronome?.volume
    return value === undefined || value === '' ? 1 : value
  }, shallow)

  const beatMap = useAudioEngine((player) => player.beatMap)

  useEffect(() => {
    if (isReadyToPlay) {
      countInApi?.channel?.volume('click', metronomeVolume)
      countInApi?.playbackRate(bpm / 100)
      countInApi?.masterVolume(masterVolume)
    }
  }, [countInApi, isReadyToPlay, bpm, masterVolume, metronomeVolume])

  const beatsDetected = useMemo(() => {
    if (beatMap) {
      // Old version
      if (typeof beatMap[0] === 'number') {
        return beatMap[0]
      }

      // Current version
      if (typeof beatMap[0] === 'object' && beatMap[0]?.time) {
        return beatMap[0].time
      }
    }

    // Fallback
    return 0
  }, [beatMap])

  const onInit = useCallback(() => {
    if (loaded) {
      return
    }

    setLoaded(true)
    countInApi?.loadChannels([
      {
        id: 'click',
        url: '/assets/audio/clicktrack.wav',
        volume: metronomeVolume
      }
    ])
  }, [loaded, countInApi, metronomeVolume])

  const onPlayClickTrack = useCallback(() => {
    if (isReadyToPlay) {
      countInApi?.seek(0)
      countInApi?.play()
    }
  }, [countInApi, isReadyToPlay])

  return {
    firstBeatDetected: (beatsDetected ?? 0) * 1000,
    onInit,
    onPlayClickTrack
  }
}
