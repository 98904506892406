import { useCallback, useState } from 'react'

export interface UseModalReferralLink {
  show: boolean
  onOpen(): void
  onDismiss(): void
}

export const useModalReferralLink = (): UseModalReferralLink => {
  const [show, setShow] = useState(false)

  const onOpen = useCallback(() => {
    setShow(true)
  }, [])

  const onDismiss = useCallback(() => setShow(false), [])

  return {
    show,
    onOpen,
    onDismiss
  }
}
