export default {
  key: 'F',
  suffix: 'add9',
  positions: [
    {
      frets: 'xx3213',
      fingers: '003214'
    },
    {
      frets: 'xx3065',
      fingers: '001043'
    },
    {
      frets: 'x87585',
      fingers: '032141',
      barres: 5,
      capo: true
    },
    {
      frets: 'x87088',
      fingers: '021034'
    }
  ]
}
