import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickTrack } from '../use-click-track'
import { useAudioEngine } from '../use-audio-engine/use-audio-engine'
import { off, on, trigger } from '../../lib/events'
import { StatePlayer, SettingsPlayer, ControllerPlayer } from '../../types'

export interface UseCountIn {
  disabled: boolean
  enabled: boolean
  isStarted: boolean
  count: number
  countIn: number | null
  lockIncrease: boolean
  onToggleCountIn(): void
  onIncrease(): void
  onDecrease(): void
  onSkip(): void
}

interface UseCountInProps {
  bpm?: string | number
  settings?: SettingsPlayer
  controller?: ControllerPlayer
  initialState?: StatePlayer
}

export const useCountIn = ({
  bpm = 100,
  settings,
  controller,
  initialState
}: UseCountInProps): UseCountIn => {
  const timeoutRef = useRef<any>(null)
  const [defined, setDefined] = useState(false)
  const [isStarted, setIsStarted] = useState(false)
  const [enabled, setEnabled] = useState(false)
  const [count, setCount] = useState(4)
  const [countIn, setCountIn] = useState<number | null>(null)
  const [metronomeActive, setMetronomeActive] = useState<string>('metronome')

  const audioEngine = useAudioEngine((p) => p.engine)
  const playerApi = useAudioEngine((p) => p.api)
  const readyToPlay = useAudioEngine((p) => p.state.isReadyToPlay)
  const isLooping = useAudioEngine((p) => p.state.isLooping)
  const bpmValue = useMemo(() => {
    return typeof bpm === 'string' && bpm.includes('%')
      ? parseInt(bpm.replace('%', ''), 10)
      : (bpm as number)
  }, [bpm])
  const { firstBeatDetected, onInit, onPlayClickTrack } = useClickTrack({
    bpm: bpmValue
  })

  const shouldLock = useMemo<boolean>(() => {
    return Boolean(
      settings?.countIn?.limited &&
        settings?.countIn?.max &&
        settings?.countIn?.limited < settings?.countIn?.max
    )
  }, [settings?.countIn?.max, settings?.countIn?.limited])

  const lockIncrease = useMemo(() => {
    return Boolean(
      shouldLock &&
        settings?.countIn?.limited &&
        count >= settings?.countIn?.limited
    )
  }, [count, shouldLock, settings?.countIn?.limited])

  const shouldLockOnNextIncrease = useMemo(() => {
    return Boolean(
      shouldLock &&
        settings?.countIn?.limited &&
        count + 1 >= settings?.countIn?.limited
    )
  }, [count, shouldLock, settings?.countIn?.limited])

  const onToggleCountIn = useCallback(() => {
    if (!enabled) {
      onInit()
    }

    const newValue = !enabled
    setEnabled(newValue)

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'count_in'
    })
  }, [enabled, onInit, controller])

  const onIncrease = useCallback(() => {
    if (lockIncrease) {
      controller?.onLockedFeatureClick?.('count-in')
      return
    }

    const { limited, max } = settings?.countIn ?? {}
    const newValue =
      max && count >= max
        ? count
        : limited && count + 1 > limited
        ? limited
        : count + 1

    setCount(newValue)

    if (shouldLockOnNextIncrease) {
      controller?.onLockedFeatureClick?.('count-in')
    }

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'count_in'
    })
  }, [
    lockIncrease,
    shouldLockOnNextIncrease,
    settings?.countIn,
    count,
    controller
  ])

  const onDecrease = useCallback(() => {
    let newValue = count - 1
    newValue = newValue || 1
    setCount(newValue)

    controller?.onEventDispatch?.({
      event: 'feature_interaction',
      value: 'count_in'
    })
  }, [count, controller])

  const onStartCountIn = useCallback(
    (newValue: number, total: number) => {
      let countInBpm = bpmValue

      if (metronomeActive === 'metronome_double') {
        countInBpm *= 2
      } else if (metronomeActive === 'metronome_half') {
        countInBpm /= 2
      }

      const time = (60 / countInBpm || 100) * 1000
      const value = newValue + 1
      setCountIn(value)

      timeoutRef.current = setTimeout(
        () => {
          if (value < total) {
            onStartCountIn(value, total)
            onPlayClickTrack()

            if (value === total - 1) {
              setTimeout(() => {
                trigger('player:play-media')
              }, time / 1.2)
            }
          } else if (value === 1 && total === 1) {
            trigger('player:play-media')
            onStartCountIn(0, 0)
          } else {
            setCountIn(null)
            setIsStarted(false)
          }
        },
        value === 1 && total === 1 ? time / 1.2 : time
      )
    },
    [bpmValue, metronomeActive, onPlayClickTrack]
  )

  const onSkip = useCallback(() => {
    setCountIn(null)
    setIsStarted(false)
    trigger('player:play-media')
    clearTimeout(timeoutRef.current)
  }, [])

  const handleSetMetronome = useCallback(
    ({ detail: value }: any): void => setMetronomeActive(value),
    []
  )

  const handleStartCountIn = useCallback(() => {
    if (isStarted) {
      return
    }

    if (!enabled) {
      trigger('player:play-media')
    } else {
      playerApi?.pause()
      setIsStarted(true)

      if (!isLooping) {
        /**
         * wavesjs precisa de ajuste fino
         * para corrigir a transição de áudio entre o
         * countin e a primeiro beat da música quando
         * o metronomo está ligado.
         */
        const firstBeat =
          audioEngine === 'wavesjs'
            ? firstBeatDetected - 150
            : firstBeatDetected
        playerApi.seek(firstBeat)
      }

      setTimeout(() => {
        onStartCountIn(0, count)
        onPlayClickTrack()
      }, 500)
    }
  }, [
    audioEngine,
    playerApi,
    enabled,
    count,
    isStarted,
    isLooping,
    firstBeatDetected,
    onStartCountIn,
    onPlayClickTrack
  ])

  useEffect(() => {
    if (!readyToPlay || defined) {
      return
    }

    setDefined(true)

    if (initialState?.countIn?.enabled && initialState?.countIn?.count) {
      const settedCountIn = initialState?.countIn.count
      setCount(
        settings?.countIn?.limited &&
          settedCountIn > settings?.countIn?.limited &&
          shouldLock
          ? settings?.countIn?.limited
          : settedCountIn
      )
      setEnabled(initialState?.countIn?.enabled)

      if (initialState?.countIn.enabled) {
        onInit()
      }
    }
  }, [
    readyToPlay,
    defined,
    initialState?.countIn,
    initialState?.countIn?.enabled,
    initialState?.countIn?.count,
    settings?.countIn?.limited,
    shouldLock,
    onInit
  ])

  useEffect(() => {
    on('player:start-count-in', handleStartCountIn)
    on('player:metronome-changed', handleSetMetronome)

    return () => {
      off('player:start-count-in', handleStartCountIn)
      off('player:metronome-changed', handleSetMetronome)
    }
  }, [handleStartCountIn, handleSetMetronome])

  return {
    disabled: !readyToPlay,
    enabled,
    isStarted,
    count,
    countIn,
    lockIncrease,
    onSkip,
    onIncrease,
    onDecrease,
    onToggleCountIn
  }
}
