import classnames from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import styles from './chords.module.scss'
import { SelectCustom } from '../../components/form/select-custom'
import { useAudioEngine } from '../../hooks/use-audio-engine'
import { usePanel } from '../panel/edits/store'
import { UseChords, useChordsType } from '../../hooks/use-chords'
import { ChordsComponent } from '../../components/chords'
import {
  ControllerPlayer,
  DataPlayer,
  SettingsPlayer,
  StatePlayer
} from '../../types'

interface ChordsProps {
  className?: string
  smallVersion?: boolean
  pitch?: number
  songKey?: string
  hideDiagram?: boolean
  data?: DataPlayer
  settings?: SettingsPlayer
  initialState?: StatePlayer
  controller?: ControllerPlayer
  onLockedClick?: ControllerPlayer['onLockedFeatureClick']
}

export type ChordsType = {
  list: UseChords
  error: boolean
  loading: boolean
  empty: boolean
  processing: boolean
}

export const Chords: React.FC<ChordsProps> = ({
  data,
  className,
  pitch,
  songKey,
  hideDiagram,
  settings,
  controller,
  initialState,
  smallVersion,
  onLockedClick
}) => {
  const progressMs = useAudioEngine((p) => p.state.positionMs)
  const loading = useAudioEngine((p) => !p.state.isReadyToPlay)

  const chords = useMemo<ChordsType>(
    () => ({
      list: data?.chords?.data,
      error: data?.chords?.status === 'failed',
      loading: loading || data?.chords?.status === 'loading',
      empty: Boolean(data?.chords?.empty),
      processing: data?.chords?.status === 'processing'
    }),
    [data?.chords, loading]
  )

  const handleLockedClick = useCallback(() => {
    onLockedClick?.('chords-lock')
  }, [onLockedClick])

  const { showLyrics, setShowEditChords, setTypeChords, typeChords } = usePanel(
    (state) => state
  )

  const chordsTypeState = useMemo(() => {
    return typeChords || initialState?.typeChords || settings?.chords?.type
  }, [initialState?.typeChords, settings?.chords?.type, typeChords])

  const { chordsType, typeOptions, onChangeType } = useChordsType({
    chords,
    controller,
    type: chordsTypeState,
    setTypeChords
  })

  useEffect(() => {
    setShowEditChords(true)

    return () => {
      setShowEditChords(false)
    }
  }, [setShowEditChords])

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.hideDiagram]: hideDiagram
      })}
    >
      {settings?.chords?.edit && !showLyrics && typeOptions?.length ? (
        <div className={styles.editGroup}>
          <SelectCustom
            value={chordsType}
            options={typeOptions}
            onChange={onChangeType}
          />
        </div>
      ) : null}

      <ChordsComponent
        chords={chords}
        songKey={songKey}
        pitch={pitch || 0}
        hideDiagram={hideDiagram}
        chordsType={chordsType}
        shouldLock={Boolean(settings?.chords?.limited)}
        showBass={Boolean(settings?.chords?.bass)}
        smallVersion={smallVersion}
        audioTime={progressMs || 0}
        onLockedClick={handleLockedClick}
      />
    </div>
  )
}
