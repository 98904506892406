import React from 'react'

const offsets: any = {
  4: {
    x: 10,
    y: 10,
    length: 40
  },
  6: {
    x: 0,
    y: 0,
    length: 50
  }
}

// const getNeckHorizonalLine = (pos: any, strings: any) =>
//   `M ${offsets[strings].x} ${12 * pos} H ${offsets[strings].length}`

// const getNeckVerticalLine = (pos: any, strings: any) =>
//   `M ${offsets[strings].y + pos * 10} 0 V 48`

// const getNeckPath = (strings: any, fretsOnChord: any) =>
//   Array.apply(null, Array(fretsOnChord + 1))
//     .map((_, pos) => getNeckHorizonalLine(pos, strings))
//     .join(' ')
//     .concat(
//       Array.apply(null, Array(strings))
//         .map((_, pos) => getNeckVerticalLine(pos, strings))
//         .join(' ')
//     )

const getBarreOffset = (
  strings: any,
  frets: any,
  baseFret: any,
  capo: any
): any => {
  return strings === 6
    ? frets[0] === 1 || capo
      ? baseFret > 9
        ? -12
        : -11
      : baseFret > 9
      ? -10
      : -7
    : frets[0] === 1 || capo
    ? baseFret > 9
      ? -1
      : 0
    : baseFret > 9
    ? 3
    : 4
}

export const Neck: React.FC<any> = ({
  tuning,
  frets,
  strings,
  // fretsOnChord,
  baseFret = 1,
  capo,
  lite = false
}) => {
  return (
    <g>
      <rect x="0" y="0" width="50" height="48" fill="#BFBFBF" />
      {/* verticais */}
      <rect x="0.1" y="11" width="50" height="1.5" fill="#999" />
      <rect x="0.1" y="23" width="50" height="1.5" fill="#999" />
      <rect x="0.1" y="35" width="50" height="1.5" fill="#999" />
      <rect x="0.1" y="46.5" width="50" height="1.5" fill="#999" />
      {/* horizontais */}
      <rect x="10" y="0" width="0.5" height="48" fill="#333" />
      <rect x="20" y="0" width="0.5" height="48" fill="#333" />
      <rect x="30" y="0" width="0.5" height="48" fill="#333" />
      <rect x="40" y="0" width="0.5" height="48" fill="#333" />
      {/* <path
        stroke="#ff0"
        strokeWidth="0.5"
        strokeLinecap="square"
        strokeLinejoin="square"
        d={getNeckPath(strings, fretsOnChord)}
      /> */}
      {baseFret === 1 ? (
        <path
          stroke="#444"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          d={`M ${offsets[strings].x} 0 H ${offsets[strings].length}`}
        />
      ) : (
        <text
          fill="#fff"
          fontSize="0.25rem"
          fontFamily="Articulat, helvetica, arial, sans-serif"
          x={getBarreOffset(strings, frets, baseFret, capo)}
          y="8"
        >
          {baseFret}fr
        </text>
      )}
      {!lite && (
        <g>
          {tuning.slice().map((note: any, index: any) => (
            <text
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              fill="#fff"
              opacity="0.2"
              fontSize="0.25rem"
              fontFamily="Articulat, helvetica, arial, sans-serif"
              textAnchor="middle"
              x={offsets[strings].x + index * 10}
              y="54"
            >
              {note}
            </text>
          ))}
        </g>
      )}
    </g>
  )
}

// Neck.propTypes = {
//   tuning: PropTypes.array,
//   frets: PropTypes.array,
//   capo: PropTypes.bool,
//   strings: PropTypes.number.isRequired,
//   baseFret: PropTypes.oneOf([0, 1, 2, 3, 4, 5, 6,
// 7, 8, 9, 10, 11, 12, 13, 14]),
//   fretsOnChord: PropTypes.number.isRequired,
//   lite: PropTypes.bool
// }

// Neck.defaultProps = {
//   baseFret: 1,
//   lite: false
// }

// export default Neck
