export default {
  key: 'Ab',
  suffix: 'm7b5',
  positions: [
    {
      frets: 'xx0102',
      fingers: '000103'
    },
    {
      frets: '4x443x',
      fingers: '203410'
    },
    {
      frets: 'xx6777',
      fingers: '001222',
      barres: 7
    },
    {
      frets: 'xbcbcx',
      fingers: '013240'
    }
  ]
}
