import React from 'react'
import { Icon } from 'scala'
import styles from './error.module.scss'

export const Error: React.FC = () => {
  return (
    <div className={styles.container}>
      <Icon name="smiley" width={97} height={97} className={styles.icon} />

      <p className={styles.title}>Oops! Page not found.</p>
      <p className={styles.description}>
        The page you&apos;re looking for doesn&apos;t exist or has been deleted.
      </p>
    </div>
  )
}
