import { createSuperpoweredHook } from './superpowered/superpowered-creator'
import { createWavesJsHook } from './wavesjs/wavesjs-creator'
import { AudioEngine, AudioEngineType } from './types'

const CREATOR_MAP = {
  superpowered: createSuperpoweredHook,
  wavesjs: createWavesJsHook
}

const POOL_MAP = {
  superpowered: {} as Record<string, AudioEngine>,
  wavesjs: {} as Record<string, AudioEngine>
}

export function getAudioEngine(
  type: AudioEngineType,
  key = 'main'
): AudioEngine {
  const create = CREATOR_MAP[type]
  const pool = POOL_MAP[type]

  pool[key] ??= create(key)
  return pool[key]
}
