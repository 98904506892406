/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-use-before-define */

import { clone, difference, intersection, pick } from 'ramda'
import { generateMetronomeFromUrl } from './util/generateMetronome'
import { createPositionAnalyzer } from '../../../../public/audio-engines/utils'

type State = typeof INITIAL_STATE

interface ChannelState {
  id: string
  volume: number
  realVolume: number
  pan: number
  isMuted?: boolean
  isSolo?: boolean
  otherChannelIsSolo?: boolean
  ready: boolean
}

const INITIAL_STATE = {
  isReadyToPlay: false,
  isPlaying: false,
  isMuted: false,
  isLooping: false,
  isRepeating: false,
  // isSpatialized: false,
  masterVolume: 1,

  loop: null as { fromMs: number; toMs: number } | null,

  durationMs: 0,
  positionMs: 0,

  pitchShiftCents: 1,
  playbackRate: 1,
  beatMap: [],
  channel: {} as Record<string, ChannelState>
}

function createPlayerId(): string {
  return Math.floor(Math.random() * 900 + 100).toString()
}

export async function Player({
  playerId = createPlayerId(),
  fade = true,
  onStatusChange
}: {
  playerId?: string
  fade?: boolean
  onStatusChange: (state: State) => void
}) {
  const WavesAudio: any = await import('waves-audio' as any)
  const { getAudioBufferFromUrl } = await import(
    'audio-buffer-download-and-cache'
  )

  // eslint-disable-next-line new-cap
  const scheduler = new WavesAudio.getScheduler()
  const audioContext = WavesAudio.audioContext as AudioContext
  const masterGainNode = audioContext.createGain()

  await audioContext.audioWorklet.addModule(
    '/audio-engines/wavesjs/worklet/phase-vocoder.js'
  )
  const phaseVocoderNode = new AudioWorkletNode(
    audioContext,
    'phase-vocoder-processor'
  )

  masterGainNode.connect(phaseVocoderNode)
  phaseVocoderNode.connect(audioContext.destination)

  let state = clone(INITIAL_STATE)

  let channelIdWithLongestDuration: string | null = null

  type Channel = Awaited<ReturnType<typeof setupChannel>> & { id: string }

  const channels: Record<string, Channel> = {}
  // @ts-ignore
  class PositionDisplay extends WavesAudio.TimeEngine {
    period: number

    constructor() {
      super()
      this.period = 0.05
    }

    advanceTime(time: number) {
      const positionMs = channelIdWithLongestDuration
        ? Math.round(
            channels[channelIdWithLongestDuration].playControl.currentPosition *
              1000
          )
        : 0

      if (positionMs >= state.durationMs) {
        pause()
        seek(0)
      }

      return time + this.period
    }
  }

  const positionDisplay = new PositionDisplay()

  function invalidate() {
    onStatusChange(state)
  }

  function setState(newState = {}) {
    state = { ...state, ...newState }
    invalidate()
  }

  const analyzePositions = createPositionAnalyzer()

  setInterval(() => {
    const positions = Object.values(channels).map((channel) => ({
      id: channel.id,
      positionMs: Math.round(channel.playControl.currentPosition * 1000)
    }))
    analyzePositions(positions)

    if (positions.length > 0) {
      const last = positions[positions.length - 1]
      setState({ positionMs: last.positionMs })
    }
  }, 100)

  async function loadChannels(channelUrlMap: Record<string, string>) {
    const requestedChannels = Object.keys(channelUrlMap)
    const existingChannels = Object.keys(channels)

    const newChannels = difference(requestedChannels, existingChannels)
    const keptChannels = intersection(existingChannels, requestedChannels)
    const discardedChannels = difference(existingChannels, requestedChannels)

    removeChannels(discardedChannels)

    try {
      await addChannels(pick(newChannels, channelUrlMap))
      syncChannels(newChannels, keptChannels)
    } catch (err: any) {
      throw new Error(err)
    }
  }

  async function addChannels(channelUrlMap: Record<string, string>) {
    const urls = Object.values(channelUrlMap)

    console.time(`${playerId} loadChannels`)
    const loadedChannels = await Promise.all(urls.map(setupChannel))
    console.timeEnd(`${playerId} loadChannels`)

    let index = 0

    Object.keys(channelUrlMap).forEach((id) => {
      state.channel[id] = {
        id,
        volume: 1,
        realVolume: 1,
        pan: 0,
        ready: true
      }
      channels[id] = { id, ...loadedChannels[index++] }
    })
  }

  function removeChannels(ids: string[]) {
    ids.forEach((id) => {
      channels[id].playerEngine.disconnect()
      channels[id].playerEngine.buffer = null
      channels[id].panNode.disconnect()
      channels[id].gainNode.disconnect()
      delete channels[id]
      delete state.channel[id]
    })
  }

  function syncChannels(newChannels: string[], keptChannels: string[]) {
    if (Object.keys(channels).length === 0) {
      setState(clone(INITIAL_STATE))
      return
    }

    let maxDuration = 0
    // const buffers: Record<string, any> = {}

    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    for (const id in channels) {
      // buffers[id] = channels[id].buffer

      if (channels[id].buffer.duration > maxDuration) {
        maxDuration = channels[id].buffer.duration
        channelIdWithLongestDuration = id
      }
    }

    state = {
      ...state,
      durationMs: Math.round(maxDuration * 1000),
      isReadyToPlay: true
    }

    // Apply state to new channels

    const [existingChannelId] = keptChannels
    const currentPosition =
      channels[existingChannelId]?.playControl.currentPosition || 0

    newChannels.forEach((id) => {
      channels[id].playControl.seek(currentPosition)

      if (state.isLooping && state.loop) {
        const { fromMs, toMs } = state.loop
        channels[id].playControl.setLoopBoundaries(fromMs / 1000, toMs / 1000)
        channels[id].playControl.loop = true
      }

      if (state.isRepeating) {
        channels[id].playControl.setLoopBoundaries(0, state.durationMs / 1000)
        channels[id].playControl.loop = true
      }

      if (state.isPlaying) {
        channels[id].playControl.start()
      }
    })
  }

  async function setupChannel(url: string) {
    const track = await setupTrack(url)
    const playControl = new WavesAudio.PlayControl(track.playerEngine)

    // playControl.setLoopBoundaries(0, buffer.duration);
    // playControl.loop = false;

    return {
      playControl,
      ...track
    }
  }

  async function downloadAndDecodeAudioFromUrl(url: string) {
    const { arrayBuffer } = await getAudioBufferFromUrl({ url })
    return audioContext.decodeAudioData(arrayBuffer)
  }

  async function setupTrack(url: string) {
    let buffer

    if (url.includes('.json')) {
      const metronome = await generateMetronomeFromUrl(url)
      buffer = metronome.renderedBuffer
      state = {
        ...state,
        beatMap: metronome.beatMap as any
      }
    } else {
      buffer = await downloadAndDecodeAudioFromUrl(url)
    }

    const playerEngine = new WavesAudio.PlayerEngine(buffer)
    playerEngine.buffer = buffer
    // playerEngine.cyclic = false;

    if (fade) {
      playerEngine.fadeTime = 0.3
    }

    const panNode = audioContext.createStereoPanner()
    const gainNode = audioContext.createGain()

    // Tone.setContext(audioContext);
    // const vol = new Tone.Volume(-12)
    // const panVol = new Tone.PanVol(0, 0)
    // var reverb = new Tone.Reverb(1.2);
    // const gainNode = new Tone.Gain(1)
    // const panner = new Tone.Panner(0.0)
    // const panVol = new Tone.PanVol(0, 0)

    playerEngine.connect(panNode)
    panNode.connect(gainNode)
    gainNode.connect(masterGainNode)

    return { playerEngine, gainNode, panNode, buffer }
  }

  function pause() {
    Object.values(channels).forEach((channel) => {
      channel.playControl.pause()
    })

    if (positionDisplay.master !== null) {
      scheduler.remove(positionDisplay)
    }

    state = {
      ...state,
      isPlaying: false
    }
  }

  function play() {
    if (audioContext.state === 'suspended') {
      audioContext.resume()
    }

    Object.values(channels).forEach((channel) => {
      channel.playControl.start()
    })

    if (positionDisplay.master === null) {
      scheduler.add(positionDisplay)
    }

    state = {
      ...state,
      isPlaying: true
    }
  }

  function limitSeekPosition(positionMs: number) {
    const { fromMs, toMs } =
      state.isLooping && state.loop
        ? state.loop
        : { fromMs: 0, toMs: state.durationMs }
    return positionMs >= fromMs && positionMs < toMs ? positionMs : fromMs
  }

  function seek(positionMsRequested: number) {
    const positionMs = limitSeekPosition(positionMsRequested)

    Object.values(channels).forEach((channel) => {
      channel.playControl.seek(positionMs / 1000)
    })

    state = {
      ...state,
      positionMs
    }
  }

  function mute(shouldMute: boolean) {
    if (shouldMute) {
      masterGainNode.gain.setValueAtTime(0, audioContext.currentTime)
    } else {
      masterGainNode.gain.setValueAtTime(
        state.masterVolume,
        audioContext.currentTime
      )
    }

    state = {
      ...state,
      isMuted: shouldMute
    }
  }

  function masterVolume(value: number) {
    const nextVolume = value
    masterGainNode.gain.setValueAtTime(nextVolume, audioContext.currentTime)
    state = {
      ...state,
      masterVolume: nextVolume,
      isMuted: false
    }
  }

  function playbackRate(factor: number) {
    state = {
      ...state,
      playbackRate: factor
    }

    Object.values(channels).forEach((channel) => {
      channel.playControl.speed = state.playbackRate
    })

    pitchShift(state.pitchShiftCents)
  }

  function pitchShift(factor: number) {
    state = {
      ...state,
      pitchShiftCents: factor
    }

    const pitchFactor = phaseVocoderNode.parameters.get('pitchFactor')

    if (pitchFactor) {
      pitchFactor.value = (state.pitchShiftCents * 1) / state.playbackRate
    }
  }

  function loop(fromMs: number, toMs: number) {
    const fixedFromMs = Math.min(state.durationMs, Math.max(0, fromMs))
    const fixedToMs = Math.min(state.durationMs, Math.max(fixedFromMs, toMs))

    Object.values(channels).forEach((channel) => {
      if (state.isPlaying) {
        channel.playControl.pause()
      }

      channel.playControl.setLoopBoundaries(
        fixedFromMs / 1000,
        fixedToMs / 1000
      )
      // eslint-disable-next-line no-param-reassign
      channel.playControl.loop = true
      channel.playControl.seek(fixedFromMs / 1000)

      if (state.isPlaying) {
        channel.playControl.start()
      }
    })

    state = {
      ...state,
      isLooping: true,
      isRepeating: false,
      loop: { fromMs: fixedFromMs, toMs: fixedToMs }
    }
  }

  function unLoop() {
    Object.values(channels).forEach((channel) => {
      channel.playControl.loop = false
    })

    state = {
      ...state,
      isLooping: false,
      loop: null
    }
  }

  function repeat(enabled: boolean) {
    if (enabled) {
      Object.values(channels).forEach((channel) => {
        channel.playControl.setLoopBoundaries(0, state.durationMs / 1000)
        channel.playControl.loop = true
      })

      state = {
        ...state,
        isLooping: false,
        isRepeating: true,
        loop: null
      }
    } else {
      Object.values(channels).forEach((channel) => {
        channel.playControl.loop = false
      })

      state = {
        ...state,
        isRepeating: false
      }
    }
  }

  const channel = {
    volume: (channelId: string, value: number) => {
      if (!channels[channelId]) return
      channels[channelId].gainNode.gain.setValueAtTime(
        state.channel[channelId].isMuted ? 0 : value,
        audioContext.currentTime
      )

      state.channel[channelId].volume = value
      state.channel[channelId].realVolume = value
    },
    spatialize: () => {
      // TODO
    },
    pan: (channelId: string, value: number) => {
      if (!channels[channelId]) return
      const nextPan = Math.min(1, Math.max(-1, value))
      channels[channelId].panNode.pan.setValueAtTime(
        nextPan,
        audioContext.currentTime
      )

      state.channel[channelId].pan = nextPan
    },
    mute: (channelId: string, value: boolean) => {
      const realVolume = value ? 0 : state.channel[channelId]?.volume

      if (!channels[channelId]) return
      channels[channelId].gainNode.gain.setValueAtTime(
        realVolume,
        audioContext.currentTime
      )

      state.channel[channelId].isMuted = value
      state.channel[channelId].realVolume = realVolume
    },
    solo: (channelId: string, value: boolean) => {
      if (!state.channel[channelId]) return
      state.channel[channelId].isSolo = value

      if (value) {
        state.channel[channelId].isMuted = false
      }

      const thereIsOneSolo = getSoloChannels().length > 0

      Object.values(state.channel).forEach((ch) => {
        const otherChannelIsSolo = !ch.isSolo && thereIsOneSolo
        state.channel[channelId].otherChannelIsSolo = otherChannelIsSolo

        const volume = ch.isMuted || otherChannelIsSolo ? 0 : ch.volume
        channels[ch.id].gainNode.gain.setValueAtTime(
          volume,
          audioContext.currentTime
        )
        ch.realVolume = volume
      })
    }
  }

  function getSoloChannels() {
    return Object.values(state.channel)
      .filter((ch) => ch.isSolo)
      .map((ch) => ch.id)
  }

  return {
    loadChannels,
    masterVolume,
    mute,
    channel,
    play,
    pause,
    seek,
    pitchShift,
    playbackRate,
    loop,
    unLoop,
    repeat
  }
}
