export default {
  key: 'Ab',
  suffix: 'sus2',
  positions: [
    {
      frets: '4xx344',
      fingers: '200134'
    },
    {
      frets: '466x46',
      fingers: '123014',
      barres: 4,
      capo: true
    },
    {
      frets: '666896',
      fingers: '111341',
      barres: 6,
      capo: true
    },
    {
      frets: 'bbddbb',
      fingers: '113411',
      barres: 11,
      capo: true
    }
  ]
}
