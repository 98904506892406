import React from 'react'
import styles from './post.module.scss'
import { Attachments } from './attachments'
import { ControllerPlayer, TaskPost } from '../../types'

interface Props {
  content: TaskPost['content']
  attachments: TaskPost['attachments']
  children: React.ReactNode
  onEventDispatch?: ControllerPlayer['onEventDispatch']
}

export const Post: React.FC<Props> = ({
  children,
  content,
  attachments,
  onEventDispatch
}) => {
  return (
    <div className={styles.container}>
      {children}

      <div
        className={styles.content}
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: content }}
      />

      <Attachments
        attachments={attachments}
        onEventDispatch={onEventDispatch}
      />
    </div>
  )
}
