export default {
  key: 'C#',
  suffix: 'aug7',
  positions: [
    {
      frets: 'x43201',
      fingers: '043201'
    },
    {
      frets: 'x47465',
      fingers: '014132',
      barres: 4,
      capo: true
    },
    {
      frets: '987x07',
      fingers: '431002'
    },
    {
      frets: '9x9aax',
      fingers: '102340'
    }
  ]
}
