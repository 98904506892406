import { t } from '@lingui/macro'
import { useLingui } from '@lingui/react'
import { useCallback, useContext, useState } from 'react'
import { config } from '../../../config'
import { Context } from '../../../context'
import { Moises as MoisesNew } from '../../../lib/cli'
import { trigger } from '../../../lib/events'
import { SongDropped, Task } from '../../../types'
import { useCreatePlaylist } from '../use-create-playlist'

interface UsePlaylist {
  valueCreatePlaylist?: boolean
  loadingCreatePlaylist: boolean
  createPlaylist(i: {
    taskId?: string
    qtdPlaylists?: number | null
    events?: {
      [key: string]: any
    }
  }): void
  addTaskToPlaylist(item: SongDropped): void
  deleteTaskFromPlaylist(trackOrders: number, playlistIdParam: string): void

  openModalConfirmation: boolean
  onDismissModalConfirmation(): void
  onConfirmAddTaskToPlaylist(item: SongDropped): void
}

const MoisesCLI = MoisesNew({ apiEndpoint: config.api.endpoint })

export const usePlaylist = (): UsePlaylist => {
  const { i18n } = useLingui()
  const {
    userToken,
    toast: { add: addToast }
  } = useContext(Context)

  const [taskId, setTaskId] = useState<string | null>(null)
  const [playlistId, setPlaylistId] = useState<string | null>(null)

  const [openModalConfirmation, setOpenedModalConfirmation] = useState(false)
  const onOpenModalConfirmation = useCallback(
    () => setOpenedModalConfirmation(true),
    []
  )
  const onDismissModalConfirmation = useCallback(
    () => setOpenedModalConfirmation(false),
    []
  )

  const onConfirmAddTaskToPlaylist = useCallback(
    async (songDropped?: SongDropped) => {
      onDismissModalConfirmation()

      MoisesCLI.auth(userToken)

      try {
        if (!songDropped) {
          throw Error()
        }

        await MoisesCLI.addTrackToPlaylist({
          trackId: taskId || songDropped?.taskId,
          playlistId: playlistId || songDropped?.playlistId
        })

        trigger('playlist:revalidate-tasks')
      } catch (e: any) {
        addToast({
          icon: null,
          closable: true,
          type: 'error',
          title: i18n._(t`error.add.song.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      setTaskId(null)
      setPlaylistId(null)
      return true
    },
    [onDismissModalConfirmation, addToast, userToken, i18n, taskId, playlistId]
  )

  const addTaskToPlaylist = useCallback(
    async (songDropped: SongDropped) => {
      MoisesCLI.auth(userToken)

      try {
        const { track } = (await MoisesCLI.track({
          id: songDropped.taskId
        })) as { track: Task }

        setTaskId(songDropped.taskId)
        setPlaylistId(songDropped.playlistId)

        const hasTrackOnPlaylist = track.playlists.find(
          ({ id }: { id: string }) => id === songDropped.playlistId
        )

        if (hasTrackOnPlaylist) {
          onOpenModalConfirmation()
        } else {
          onConfirmAddTaskToPlaylist(songDropped)
        }
      } catch (e: any) {
        addToast({
          icon: null,
          closable: true,
          type: 'error',
          title: i18n._(t`error.add.song.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }
    },
    [
      userToken,
      i18n,
      addToast,
      onOpenModalConfirmation,
      onConfirmAddTaskToPlaylist
    ]
  )

  const deleteTaskFromPlaylist = useCallback(
    async (trackOrders: number, playlistIdParam: string) => {
      MoisesCLI.auth(userToken)

      try {
        await MoisesCLI.removeTrackFromPlaylist({
          playlistId: playlistIdParam,
          trackOrders
        })
      } catch (e: any) {
        addToast({
          icon: null,
          closable: true,
          type: 'error',
          title: i18n._(t`error.removing.song.playlist.title`),
          description: i18n
            ._(t`error.create.playlist.description`)
            .replace(/\*/g, '')
        })
      }

      return true
    },
    [addToast, userToken, i18n]
  )

  const {
    loading: loadingCreatePlaylist,
    value: valueCreatePlaylist,
    createPlaylist
  } = useCreatePlaylist({ addTaskToPlaylist })

  return {
    valueCreatePlaylist,
    loadingCreatePlaylist,
    createPlaylist,
    addTaskToPlaylist,
    deleteTaskFromPlaylist,

    openModalConfirmation,
    onConfirmAddTaskToPlaylist,
    onDismissModalConfirmation
  }
}
