import { plans } from '@moises-ai/pricing/dist/plans'
import { euroZoneCountryCodes } from '@moises-ai/pricing/dist/euro-zone'
import { highZoneCountryCodes } from '@moises-ai/pricing/dist/high-zone'
import { type iPlanData } from '@moises-ai/pricing/dist/types'
import { Context } from 'scala'
import { useContext } from 'react'
import useCountry from '../misc/use-country/use-country'

interface UsePricing {
  pro: iPlanData
  premium: iPlanData
  countryCode: string
}

const australia = {
  countryCode: 'AU',
  pro: plans.pro.AU,
  premium: plans.premium.AU
}

const europe = {
  countryCode: 'ES', // any EU-zone country code
  pro: plans.pro.EUR,
  premium: plans.premium.EUR
}

const brazil = {
  countryCode: 'BR',
  pro: plans.pro.BR,
  premium: plans.premium.BR
}

const britain = {
  countryCode: 'GB',
  pro: plans.pro.GB,
  premium: plans.premium.GB
}

const canada = {
  countryCode: 'CA',
  pro: plans.pro.CA,
  premium: plans.premium.CA
}

const usa = {
  countryCode: 'US',
  pro: plans.pro.US,
  premium: plans.premium.US
}

const japan = {
  countryCode: 'JP',
  pro: plans.pro.JP,
  premium: plans.premium.JP
}

const korea = {
  countryCode: 'KR',
  pro: plans.pro.KR,
  premium: plans.premium.KR
}

const mexico = {
  countryCode: 'MX',
  pro: plans.pro.MX,
  premium: plans.premium.MX
}

const highGlobal = (countryCode?: string | null): any => ({
  countryCode: countryCode || 'NONE',
  pro: plans.pro.HIGH_GLOBAL,
  premium: plans.premium.HIGH_GLOBAL
})

const global = (countryCode?: string | null): any => ({
  countryCode: countryCode || 'NONE',
  pro: plans.pro.GLOBAL,
  premium: plans.premium.GLOBAL
})

export const usePricing = (): UsePricing => {
  const { countryCode } = useCountry()
  const { user } = useContext(Context)
  const activeStripeCurrency = user?.subscription?.activeStripeCurrency

  if (activeStripeCurrency === 'EUR') return europe
  if (activeStripeCurrency === 'BRL') return brazil
  if (activeStripeCurrency === 'GBP') return britain
  if (activeStripeCurrency === 'CAD') return canada
  if (activeStripeCurrency === 'AUD') return australia
  if (activeStripeCurrency === 'JPY') return japan
  if (activeStripeCurrency === 'KRW') return korea
  if (activeStripeCurrency === 'MXN') return mexico

  if (activeStripeCurrency === 'USD') {
    if (countryCode === 'US') return usa
    if (highZoneCountryCodes.includes(countryCode || '')) {
      return highGlobal(countryCode)
    }

    return global(null)
  }

  if (countryCode === 'US') return usa
  if (countryCode === 'BR') return brazil
  if (countryCode === 'GB') return britain
  if (countryCode === 'CA') return canada
  if (countryCode === 'AU') return australia
  if (countryCode === 'JP') return japan
  if (countryCode === 'KR') return korea
  if (countryCode === 'MX') return mexico

  if (euroZoneCountryCodes.includes(countryCode || '')) {
    return europe
  }

  if (highZoneCountryCodes.includes(countryCode || '')) {
    return highGlobal(countryCode)
  }

  return global(countryCode)
}
