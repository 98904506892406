import { useLingui } from '@lingui/react'
import { t } from '@lingui/macro'
import classnames from 'classnames'
import React, { useCallback } from 'react'
import { shallow } from 'zustand/shallow'
import { useAudioEngine } from '../../hooks/use-audio-engine/use-audio-engine'
import { InputRange } from '../../components/input-range'
import { LockPremium } from '../../components/lock-premium'
import { Pan } from '../pan'
import { ChannelPlayer } from '../../types'
import { getStemLabel } from '../../utils/utils'
import { Controls } from '../../components/controls'
import styles from './channel.module.scss'

interface ChannelProps {
  className?: string
  loading?: boolean
  locked?: boolean
  lockedForPro?: boolean
  channel: ChannelPlayer
  disabled?: boolean
  limitedMetronome?: boolean
  onSolo(id: string, value: boolean): void
  onMute(id: string, value: boolean): void
  onChangePan(value: number, id: string): void
  onChangeVolume(value: number, id: string): void
  onLockedClick?(
    type: 'channel-lock' | 'channel-lock-pro' | 'metronome-limited'
  ): void
}

export const Channel: React.FC<ChannelProps> = ({
  className,
  loading,
  locked,
  lockedForPro,
  channel,
  limitedMetronome,
  onSolo,
  onMute,
  onChangePan,
  onChangeVolume,
  onLockedClick
}) => {
  const { i18n } = useLingui()
  const disabled = loading || locked || lockedForPro
  const isOnlyPro = lockedForPro

  const { isMuted, isSolo, volume, realVolume, pan } = useAudioEngine(
    (player) => {
      if (!player.channels[channel.id]) {
        return {
          isMuted: true,
          isSolo: false,
          volume: 0.75,
          realVolume: 0.75,
          pan: 0
        }
      }

      return {
        isMuted: player.channels[channel.id]?.isMuted,
        isSolo: player.channels[channel.id]?.isSolo,
        volume: player.channels[channel.id]?.volume,
        realVolume: player.channels[channel.id]?.realVolume,
        pan: player.channels[channel.id]?.pan
      }
    },
    shallow
  )

  const handleMute = useCallback(
    (value: boolean) => {
      if (disabled) {
        return
      }

      onMute(channel.id, value)
    },
    [disabled, channel, onMute]
  )

  const handleSolo = useCallback(
    (value: boolean) => {
      if (disabled) {
        return
      }

      onSolo(channel.id, value)
    },
    [disabled, channel, onSolo]
  )

  const handleVolume = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (disabled) {
        return
      }

      onChangeVolume(parseInt(e.currentTarget.value, 10) / 100, channel.id)
    },
    [disabled, channel, onChangeVolume]
  )

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement>) => {
      if (disabled) {
        return
      }

      if (e.detail === 2) {
        onChangeVolume(0.75, channel.id)
      }
    },
    [disabled, channel, onChangeVolume]
  )

  const handlePan = useCallback(
    (value: number) => {
      if (disabled) {
        return
      }

      onChangePan(value, channel.id)
    },
    [disabled, channel, onChangePan]
  )

  const onLockChannelClick = useCallback(() => {
    onLockedClick?.('channel-lock')
  }, [onLockedClick])

  const onLockProChannelClick = useCallback(() => {
    onLockedClick?.('channel-lock-pro')
  }, [onLockedClick])

  const onLockedMetronomeClick = useCallback(() => {
    onLockedClick?.('metronome-limited')
  }, [onLockedClick])

  return (
    <div
      className={classnames(className, styles.container, {
        [styles.disabled]: disabled
      })}
    >
      <div className={styles.column}>
        <div className={styles.line}>
          <Controls
            onMute={handleMute}
            onSolo={handleSolo}
            isSolo={disabled ? false : isSolo}
            isMuted={disabled ? false : isMuted}
            isSilentMuted={disabled ? false : volume !== realVolume}
          />
          <p className={styles.title}>
            {channel.id ? getStemLabel(channel.id, i18n) : null}
          </p>
        </div>

        <InputRange
          min={0}
          max={100}
          step={1}
          value={disabled ? 75 : volume * 100}
          disabled={disabled || isMuted || volume !== realVolume}
          onChange={handleVolume}
          onClick={handleClick}
          showTooltip
        />
      </div>

      <div className={styles.column}>
        <Pan onChange={handlePan} value={disabled ? 0 : pan} />
      </div>

      {!loading && (
        <>
          {isOnlyPro && (
            <LockPremium
              onClick={onLockProChannelClick}
              className={styles.lock}
              customText={i18n._(t`label.pro`)}
            />
          )}
          {locked && (
            <LockPremium onClick={onLockChannelClick} className={styles.lock} />
          )}
          {limitedMetronome && (
            <span className={styles.lockIcon}>
              <LockPremium type="icon" onClick={onLockedMetronomeClick} />
            </span>
          )}
        </>
      )}
    </div>
  )
}
