import { Icon } from 'scala'
import React from 'react'
import styles from './header.module.scss'

interface Props {
  title: string
  onClose?(): void
}

export const Header: React.FC<Props> = ({ title, onClose }) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>

      {onClose && (
        <button type="button" onClick={onClose} className={styles.button}>
          <Icon name="close" />
        </button>
      )}
    </div>
  )
}
